



















import { defineComponent } from '@nuxtjs/composition-api';
import { useNamespacedState } from 'vuex-composition-helpers';
import { NavigationState } from '@/store/navigation/types';
import { industryNationalLinks } from '@/utils/navigation';

export default defineComponent({
  name: 'DesktopDropdownMenu',
  props: {
    isNational: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { dropdownContent } = useNamespacedState<NavigationState>('navigation', [
      'dropdownContent',
    ]);
    return { dropdownContent, industryNationalLinks };
  },
});
