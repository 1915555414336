export enum UserRoles {
  CompanyAdmin = 'company_admin',
  CompanyProfileEditor = 'company_profile_editor',
  CompanyJobPoster = 'job_poster',
  UnlimitedJobPoster = 'unlimited_job_poster',
  GlobalAdmin = 'admin',
  AtsAdmin = 'ats_admin',
  DrupalAdmin = 'drupal_admin',
  Writer = 'writer',
  AccountManager = 'account_manager',
  SalesRep = 'sales_rep',
  DataAnalyst = 'data_analyst',
  Impersonate = 'impersonate',
  AccessCandidateViewer = 'access_candidate_viewer',
}
