


























/* eslint-disable bix/no-component-imports */
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useAuthentication } from '@/hooks/useAuthentication';

import { registrationClickMetadata } from '@/components/navigation/registrationMetadata';
import regions from '@/api/regions';
import { getPath } from '@/utils/navigation';

export default defineComponent({
  name: 'SignButtons',
  setup() {
    const { signup, signin } = useAuthentication();
    const redirectPath = computed(() => getPath());
    const isNational = regions.isNational();
    const newUserReturnUrl = isNational
      ? 'https://' + regions.getRegionHostName() + `/`
      : 'https://' + regions.getRegionHostName();
    const existingUserReturnUrl = `https://${
      window.location.hostname
    }/login?destination=${encodeURIComponent(getPath())}`;
    return {
      signup,
      signin,
      registrationClickMetadata,
      redirectPath,
      isNational,
      newUserReturnUrl,
      existingUserReturnUrl,
    };
  },
});
