/**
 * Shared company utility methods
 */

import { userModule } from '@/utils/store-accessor';
import { ensure } from '@/utils/types';

import { Company, Industry } from '@/store/companies/types';
import { Job } from '@/store/jobs/types';
import { ICompany, IJob } from '@/typings/api/external-api-gql';
import constants from '@/enums/constants';
import regions from '@/api/regions';

/**
 * @returns A "City, State" formatted string if both
 * values are defined, or a best effort if not.
 */
export const getCompanyLocation = (city?: string, state?: string) => {
  const locationText = [];
  if (city) locationText.push(city);
  if (state) locationText.push(state);

  return locationText.join(', ');
};

/**
 * @returns path based on If user is logged In or logged out.
 */
export const addYourCompanyHref = (): string => {
  if (userModule.isAuthenticated) {
    return '/node/add/company';
  }

  return `/login?destination=${encodeURIComponent('/node/add/company')}`;
};

/**
 * @returns A `Job` object mapped from `IJob`.
 */
export const getJobFromIJob = (IJob: IJob): Partial<Job> => {
  return {
    company_id: IJob.company.id,
    category_id: IJob.categoryID,
    experience_level: IJob.experienceLevel,
    id: IJob.id,
    location: IJob.location,
    original_location: IJob.originalLocation,
    region_id: IJob.regionID,
    remote_status: IJob.remoteStatus,
    title: IJob.title,
    alias: IJob.url,
    easy_apply: IJob.isEasyApply,
    hybrid: IJob.isHybrid,
    body: IJob.body,
    body_summary: IJob.bodySummary,
  };
};

/**
 * @returns A `Company` object mapped from `ICompany`.
 */
export const getICompanyFromCompany = (ICompany: ICompany): Partial<Company> => {
  return {
    adjectives: ICompany.adjectives,
    alias: ICompany.alias,
    city: ICompany.city,
    get culture_topics() {
      const cultureTopics = ensure(ICompany.cultureTopics);

      return cultureTopics.map((cultureTopic) => {
        const topic = ensure(cultureTopic);

        return {
          category: topic.category || '',
          company_id: topic!.companyID,
          featured_name: topic!.featuredName || '',
          featured_title: topic!.featuredTitle || '',
          get files() {
            const fileArray = ensure(topic?.fileData);
            if (fileArray) {
              return fileArray.map((file) => file?.uri || '');
            }

            return [] as any;
          },
          id: topic!.id,
          overview: topic!.overview || '',
          quote_name: topic!.quoteName || '',
          quote_question: topic!.quoteQuestion || '',
          quote_text: topic!.quoteText || '',
          quote_title: topic!.quoteTitle || '',
          title: topic!.title || '',
        };
      });
    },
    elite_page_type: ICompany.elitePageType || '',
    email: ICompany.email || '',
    // TODO exitHistory: company.exit_history.map((exit) => {
    //   return {
    //     exit_type: exit.exit_type,
    //     exit_amt: exit.amount,
    //     exit_date: string;
    //     exit_post_valuation_amount: number;
    //     exit_acquirer_investor: string;
    //   }
    // }),
    get technologies_extra() {
      ICompany.extraTechnologies?.map((extraTech) => {
        if (extraTech) {
          return {
            type_name: extraTech.categoryName,
            id: extraTech.id,
            name: extraTech.name,
          };
        }
      });

      return [] as any;
    },
    header_url: ICompany.featuredImage,
    // TODO funding: ICompany.funding,
    funding: [],
    galleries: ICompany.galleries,
    id: ICompany.id,
    get industries() {
      const industryArray = ensure(ICompany.industries);
      const transformed = industryArray?.map((industryObj) => {
        const industry = ensure(industryObj);
        return {
          id: industry.id,
          name: industry.name,
          seo_name: industry.urlName,
          type_id: -1,
        };
      });

      return (ensure(transformed) || []) as Industry[];
    },
    // TODO
    // insidersView: company.insiders_view.map((item) => {
    //   return {
    //     fourOneOne:
    //   };
    // }),
    intro_header: ICompany.introHeader || '',
    limited_listings: ICompany.isLimitedListings,
    jobs: ensure(ICompany.jobDetails).map((job) => getJobFromIJob(job!)) as any,
    last_updated: new Date(ICompany.lastUpdated),
    local_employees: ICompany.localEmployees,
    logo: ICompany.logo,
    // TODO
    // metaTags: {
    //   basicMetaTag: {
    //     title: ICompany.basic_metatag.title,
    //     description: ICompany.basic_metatag.description,
    //   },
    //   metaTags: ICompany.metatags.map((tag) => {
    //     return {
    //       contentID: tag.content_id,
    //       id: tag.id,
    //       type: tag.type,
    //       value: tag.value,
    //     };
    //   }),
    // },
    metatags: [],
    mini_description: ICompany.miniDescription || '',
    mission: ICompany.mission,
    title: ICompany.name,
    offices: ensure(ICompany.offices).map((office) => {
      return {
        city: office!.city,
        country: office!.country,
        state: office!.state,
        postal_code: office!.postalCode,
        street_address_1: office!.streetAddress1,
        street_address_2: office!.streetAddress2,
        neighborhood_description: office!.neighborhoodDescription,
        headquarters: office!.headquarters,
        nearest_market: office!.nearestMarket,
        company_id: ICompany.id,
      };
    }),
    office_type: ICompany.officeType,
    // TODO get perks() {
    //   const perksArray = [];
    //   ensure(ICompany.perks).forEach((perk) => {
    //     if (perk) {
    //       perksArray.push({

    //       })
    //     }
    //     return {
    //       description: perk!.description,
    //       details: {
    //         id: perk.id,
    //         name: perk.name,
    //         urlName: perk.name,
    //       },
    //       id: perk.id,
    //       perkWeight: perk.weight,
    //     };
    // }),
    // },
    perks_overview: ICompany.perksOverview || '',
    publish_insiders_page: ICompany.publishInsidersPage,
    show_funding: ICompany.showFunding,
    state: ICompany.state,
    get subscription_id() {
      const obj = Object.entries(constants.SUBSCRIPTIONS).find(
        (x) => x[0] === ICompany.subscription.level
      );
      return obj?.[1] || 1;
    },
    technologies: ensure(ICompany.technologies).map((tech) => {
      return {
        type_name: tech!.categoryName,
        id: tech!.id,
        name: tech!.name,
        type_id: -1, // TODO
      };
    }),
    total_employees: ICompany.totalEmployees,
    url: ICompany.alias,
    video: ICompany.video || '',
    why_work_with_us: ICompany.whyWorkWithUs || '',
    year_founded: new Date(ensure(ICompany.yearFounded)),
  };
};

/**
 * @returns A `Job` object mapped from `IJob`.
 */
export const breadcrumbSchema = (
  title: string,
  alias: string,
  tabTitle: string | undefined,
  tabPath: string | undefined
) => {
  let itemList = [
    {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': 'https://builtin.com/companies',
        name: 'Companies',
      },
    },
    {
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': `${regions.getRegionBaseURL()}` + alias,
        name: title,
      },
    },
  ];
  if (tabTitle && tabPath) {
    itemList[2] = {
      '@type': 'ListItem',
      position: 3,
      item: {
        '@id': `${regions.getRegionBaseURL()}` + alias + '/' + tabPath,
        name: title + ' ' + tabTitle,
      },
    };
  }
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemList,
  };
};
