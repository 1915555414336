












import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { SearchCompany } from '@/store/search/types';

@Component({
  props: {
    company: {
      type: Object,
    },
  },
})
export default class CompanySearchResult extends Vue {
  industryList(company: SearchCompany) {
    return company.industries.replace(/,/g, ', ');
  }
}
