




























































































































































































































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useNamespacedGetters, useState } from 'vuex-composition-helpers';
import { orderBy } from 'lodash-es';
import apiRegions from '@/api/regions';

import constants from '@/enums/constants';
import { getAllRegions } from '@/api/regions';
import { RootState } from '@/store/types';

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

import { regionNameMapper } from '@/utils/formatting';
import { forEmployerURL } from '@/utils/navigation';

export default defineComponent({
  setup: () => {
    const { regions } = useState<RootState>(['regions']);
    const currentRegionID = apiRegions.getCurrentRegionID();
    const forEmployerlink = forEmployerURL();
    const marketLogo = computed(() => `assets/site-logos/${currentRegionID}-full-white.svg`);
    const communities = computed(() =>
      orderBy(
        getAllRegions().filter((region) => region.id !== currentRegionID),
        (x) => x.name
      )
    );

    const region = computed(() => regions.value.find((r) => r.id === currentRegionID));
    const regionName = computed(() => region.value?.name || '');
    const social = computed(() => ({
      twitter: region.value?.twitter || constants.BI_TWITTER,
      facebook: region.value?.facebook || constants.BI_FACEBOOK,
      instagram: constants.BI_INSTAGRAM,
      linkedin: region.value?.linked_in || constants.BI_LINKEDIN,
    }));

    const { isAuthenticated } = useNamespacedGetters('UserModule', ['isAuthenticated']);

    const donotSellInfoPopup = () => {
      try {
        (window as any).OneTrust.ToggleInfoDisplay();
      } catch (error) {}
    };

    const homepagePath = computed(() => {
      return '/';
    });

    return {
      communities,
      currentRegionID,
      forEmployerlink,
      currentYear: new Date().getFullYear(),
      isAuthenticated,
      isMarket: apiRegions.isMarket(),
      isNational: apiRegions.isNational(),
      knowledgebaseSupportUrl: constants.KNOWLEDGEBASE_SUPPORT_URL,
      knowledgebaseUrl: constants.KNOWLEDGEBASE_URL,
      marketLogo,
      regionName,
      regionNameMapper,
      homepagePath,
      shareFeedbackUrl: constants.SHARE_FEEDBACK_URL,
      social,
      icons: {
        facebook: faFacebook,
        twitter: faTwitter,
        linkedin: faLinkedin,
        instagram: faInstagram,
      },
      donotSellInfoPopup,
    };
  },
});
