import appVars, { imageCDNs } from '@/enums/appVars';
import constants from '@/enums/constants';
import { BreakpointThresholds } from 'vuetify/types/services/breakpoint';
import { isFullUrl } from './formatting';

/**
 * Max number of company fallback images
 * Correlates to `frontend/public/assets/company/fallback-image-__.jpg`
 */
export const MAX_COMPANY_FALLBACK_IMGS = 39;

// Hostnames that Cloudflare can optimize images on.
const optimizableHosts = [
  'builtinchicago.org',
  'builtincolorado.com',
  'builtinla.com',
  'builtinaustin.com',
  'builtinnyc.com',
  'builtinboston.com',
  'builtinseattle.com',
  'builtinsf.com',
  'builtin.com',
];

/**
 * Extend Vuetify's breakpoints type to add an `xl` property
 */
export interface BixBreakpoints extends BreakpointThresholds {
  xl: number;
  [index: string]: number;
}

/**
 * Cloudflare image resizing option interface.
 * Docs: https://developers.cloudflare.com/images/url-format.
 */
export interface CloudflareImgOptions {
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad'; // resize behavior
  sharpen?: number; // sharpen amount
  f?: 'auto'; // image format
  g?: string; // crop gravity
  q?: number; // image quality
  [index: string]: string | number | undefined;
}

/**
 * Object for a set of responsive images using Vuetify's breakpoint
 * names as keys.
 */
export interface ResponsiveImageSet {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  [index: string]: string;
}

// Cloudflare image resizing options to apply by default.
const defaultCloudflareOptions: CloudflareImgOptions = {
  fit: 'crop',
  sharpen: 0.3,
  f: 'auto',
  q: constants.CLOUDFLARE_IMG_QUALITY,
};

export default {
  /**
   * Generate an image URL for a Drupal image URI.
   *
   * @param uri Publicly inaccessible reference to a Drupal image.
   * @param styleName A Drupal image style as it appears in an image URL.
   * @param regionID Company region that hosts the file.
   * @param drupalSrc Boolean if img src is direct from Drupal with rawurlencode()
   * @return A publicly available image URL using the given image style.
   */
  getImageFullCdnURL(
    uri: string,
    styleName: string,
    regionID: number,
    drupalSrc: boolean = false
  ): string {
    const imageCDN = imageCDNs[regionID];
    if (!uri || !imageCDN) return '';

    // Test if the URI is actually a URL, and if it is, return it unmodified.
    if (isFullUrl(uri)) return uri;

    // Check on drupal uri.
    if (drupalSrc && uri.startsWith('public://')) {
      // some files may have a prefix public://, which we don't want in this path.
      uri = uri.replace(/public:\/\//, '');

      // Users may have uploaded assets with special characters that we
      // need to escape; however, they may also have created subfolders,
      // and we don't want the slashes in that subfolder path to be
      // escaped. (%2F = /).
      // escape() is deprecated so we should use encodeURIComponent() instead.
      // Drupal uses rawurlencode() - returns a string in which all non-alphanumeric characters
      // except -_.~ have been replaced.
      // encodeURIComponent() escapes all characters except A-Z a-z 0-9 - _ . ! ~ * ' ( )
      // so we handle !*'() via replace here.
      uri = encodeURIComponent(uri)
        .replace(/!/g, '%21')
        .replace(/\*/g, '%2A')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/%2F/g, '/');
    } else if (uri.startsWith('public://')) {
      // some files may have a prefix public://, which we don't want in this path.
      // also, some files may be URL encoded and some may not, so we'll make them all decoded.
      const normalized = decodeURIComponent(uri.replace(/public:\/\//, ''));

      // Users may have uploaded assets with special characters that we
      // need to escape; however, they may also have created subfolders,
      // and we don't want the slashes in that subfolder path to be
      // escaped. (%2F = /)
      uri = encodeURIComponent(normalized).replace(/%2F/g, '/');
    }

    if (!styleName) return `${imageCDN}/${uri}`;

    return `${imageCDN}/styles/${styleName}/public/${uri}`;
  },

  /**
   * Generate the URL for an optimized and optionally resized image from Cloudflare.
   * Cloudflare options: https://developers.cloudflare.com/images/url-format
   *
   * @param imgUri Publicly inaccessible reference to a Drupal image.
   * @param regionID Company region that hosts the file.
   * @param imgWidth The desired image width.
   * @param imgHeight The desired image height.
   * @param cloudflareOptions Object of Cloudflare image options to merge with default options.
   * @return URL to the optimized image.
   */
  getResponsiveImage(
    imgUri: string,
    regionId: number,
    imgWidth?: number,
    imgHeight?: number,
    cloudflareOptions: CloudflareImgOptions = defaultCloudflareOptions,
    drupalSrc: boolean = false
  ): string {
    // Compile a full URL from the URI.
    const imageUrl = this.getImageFullCdnURL(imgUri, '', regionId, drupalSrc);
    return this.getResponsiveImageFromURL(imageUrl, imgWidth, imgHeight, cloudflareOptions);
  },

  /**
   * Generates a responsive image from a full url.
   * This differs from `getResponsiveImage` because it does not require
   * a region id, and expects a full URL.
   *
   * @param imageUrl Full url for the image
   * @param imgWidth The desired image width.
   * @param imgHeight The desired image height.
   * @param cloudflareOptions Object of Cloudflare image options to merge with default options.
   * @return URL to the optimized image.
   */
  getResponsiveImageFromURL(
    imageUrl: string,
    imgWidth?: number,
    imgHeight?: number,
    cloudflareOptions: CloudflareImgOptions = {}
  ) {
    if (!imageUrl) return '';

    // Cloudflare can't proxy local images,
    // so don't try if that's the current environment.
    if (appVars.CurrentEnv.isDev) return imageUrl;

    // Cloudflare can't optimize SVGs.
    const imageIsSvg = /\.svg$/i.test(imageUrl);
    if (imageIsSvg) return imageUrl;

    const { origin, pathname, hostname } = new URL(imageUrl);

    // Don't optimize images from non-Built In hostnames.
    const isImageHostOptimizable = optimizableHosts.some((validHost) => {
      return new RegExp(validHost, 'i').test(hostname);
    });

    if (!isImageHostOptimizable) return imageUrl;

    // Use default options as a base and apply any overrides.
    cloudflareOptions = {
      ...defaultCloudflareOptions,
      ...cloudflareOptions,
    };

    // If the image isn't being resized,
    // omit default resize-specific options.
    if (!imgHeight && !imgWidth) {
      delete cloudflareOptions.fit;
      delete cloudflareOptions.sharpen;
    }

    let cloudflareOptsArray: string[] = Object.keys(cloudflareOptions).map((optionKey) => {
      const optionValue = cloudflareOptions[optionKey];

      return optionValue ? `${optionKey}=${optionValue}` : '';
    });

    if (imgWidth) cloudflareOptsArray.push(`w=${imgWidth}`);

    if (imgHeight) cloudflareOptsArray.push(`h=${imgHeight}`);

    // Filter out any falsey values
    cloudflareOptsArray = cloudflareOptsArray.filter((value) => !!value);

    // Cloudflare takes comma-separated URL options
    return `${origin}/cdn-cgi/image/${cloudflareOptsArray.join(',')}${pathname}`;
  },

  /**
   * Get an object of Vuetify breakpoint names as keys and optimized
   * image paths for each breakpoint as values.
   *
   * @param imgPath URI or URL to an image.
   * @param breakpoints Vuetify breakpoint thresholds object.
   * @param height The desired height of the returned image.
   * @param regionID Optional company region that hosts the file.
   * @param cloudflareOptions Object of Cloudflare image options.
   * @return Object of scaled image URLs.
   */
  getResponsiveImageSet(
    imgPath: string,
    breakpoints: BixBreakpoints,
    height?: number,
    regionId?: number,
    cloudflareOptions: CloudflareImgOptions = {}
  ): ResponsiveImageSet {
    const responsiveImages: any = {};

    // Vuetify's breakpoint service doesn't use an `xl` key.
    // Add one set to a falsey value so no images at this breakpoint are resized.
    Object.keys({ ...breakpoints, xl: 0 }).forEach((breakpointName: string) => {
      const newImgWidth: number | undefined = breakpoints[breakpointName];
      let imageUrl = '';

      // Don't resize images at `xl`.
      if (breakpointName === 'xl') {
        height = undefined;
      }

      // Get a compiled image URL for each breakpoint threshold.
      if (regionId) {
        imageUrl = this.getResponsiveImage(
          imgPath,
          regionId,
          newImgWidth,
          height,
          cloudflareOptions
        );
      } else {
        imageUrl = this.getResponsiveImageFromURL(imgPath, newImgWidth, height, cloudflareOptions);
      }

      responsiveImages[breakpointName] = imageUrl;
    });

    return responsiveImages;
  },

  /**
   * Given a company ID, get a consistent company fallback image.
   * Useful in creating variety when there may be duplicate
   * fallback images on a page or in a component.
   *
   * @param companyId A company ID, `0` by default.
   * @return URL to a company's fallback image
   */
  getCompanyFallbackImage(companyId: number = 0) {
    // Fallback image filenames are in the format `fallback-image-__.jpg`,
    // so ensure filenames have 2-digit IDs
    const fallbackImgId = `${companyId % MAX_COMPANY_FALLBACK_IMGS}`.padStart(2, '0');
    return `${appVars.BaseUrl}/assets/company/fallback-image-${fallbackImgId}.jpg`;
  },

  /**
   * @returns The src for a transparent placeholder SVG.
   * Useful in preventing layout shift with images that haven't been loaded yet.
   * More: https://css-tricks.com/preventing-content-reflow-from-lazy-loaded-images/
   */
  getPlaceholderImage: (width: number | string = 1, height: number | string = 1) => {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
  },
};
