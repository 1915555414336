import Cookies from 'js-cookie';
import { EXPERIMENT_ASSIGNMENT, NAMESPACE, EXPERIMENT_NAMES } from './constants';
import { Experiment, ExperimentVariant } from './types';
import track from '@/utils/track';
import { sample } from 'lodash-es';

const domain = window.location.hostname;

export const formatCookieName = (experimentName: string) => `${NAMESPACE}-${experimentName}`;

/**
 * getExperiment checks for an existing experiment variant Cookie
 * If one exists, it'll return it. If an experiment is not found, it will
 * return null.
 */
export function getExperiment(name: string): ExperimentVariant | null {
  const cookieName = formatCookieName(name);

  try {
    return JSON.parse(Cookies.get(cookieName) || '') || null;
  } catch {
    return null;
  }
}

type AddExperimentOptions = {
  cookieExpirationInDays?: number;
};

/**
 * addExperiment sets an experiment in a Cookie under the `experiments-` namespace,
 * preserving existing values in the namespace
 */
export function addExperiment(
  name: string,
  value: ExperimentVariant,
  opts: AddExperimentOptions = {}
) {
  const cookieName = formatCookieName(name);

  const productionSettings = {
    expires: opts.cookieExpirationInDays || 90, // Default to expiring the cookie after 90 days, just so it goes away after experiment is over.
    domain, // Scope cookies to hostname so it is unique per Built In environment.
    secure: true,
  };

  Cookies.set(
    cookieName,
    JSON.stringify(value),
    process.env.NODE_ENV !== 'test' ? productionSettings : {}
  );
}

/**
 * removeExperiment deletes an experiment cookie
 * preserving other cookies in the experiments namespace
 */
export function removeExperiment(name: string) {
  const cookieName = formatCookieName(name);

  Cookies.remove(cookieName);
}

/**
 * getFormattedVariantMetadata formats data to send to the gracking api
 *
 * @param variant an individual experiment variant
 * @returns data formatted to send to the tracking api
 */
export const getFormattedVariantMetadata = (
  experimentName: string,
  variant: ExperimentVariant
): Experiment => ({
  name: experimentName,
  variant_id: variant.id,
  metadata: {
    text: variant.text,
  },
});

/**
 * sampleVariant checks for an existing experiment variant
 * If one exists, it'll return it.
 * If one does not exist (variant has not been assigned yet). It'll assign
 * a random one and return it.
 *
 * Each of the variants are equally weighted.
 */
export const sampleVariant = (
  experimentName: string,
  variants: ExperimentVariant[],
  fallbackVariant: ExperimentVariant
): ExperimentVariant => {
  try {
    const signUpExperiment = getExperiment(experimentName);

    if (signUpExperiment) return signUpExperiment;

    const randomVariant = sample(variants);

    if (!randomVariant) return fallbackVariant;

    addExperiment(experimentName, randomVariant);

    track.logEvent(
      EXPERIMENT_ASSIGNMENT,
      getFormattedVariantMetadata(experimentName, randomVariant)
    );
    return randomVariant;
  } catch {
    return fallbackVariant;
  }
};

/**
 * isAssignedVariant returns if the user has been assigned to a specific experiment
 *
 * @returns boolean representing experiment assignment state
 */
export const isAssignedVariant = (experimentName: string) => getExperiment(experimentName) !== null;

// getOngoingExperiments returns an array of all experiments currently set in a user's cookies
export const getOngoingExperiments = (): Experiment[] => {
  const experiments: Experiment[] = [];

  Object.values(EXPERIMENT_NAMES).forEach((name) => {
    const variant = getExperiment(name);
    if (variant) {
      experiments.push(getFormattedVariantMetadata(name, variant));
    }
  });

  return experiments;
};
