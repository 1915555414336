
























import { defineComponent, computed } from '@nuxtjs/composition-api';

import { useVuetify } from '@/hooks/useVuetify';
import regions, { isNational } from '@/api/regions';

export default defineComponent({
  props: {
    regionId: {
      type: Number,
      required: true,
    },
  },
  setup: (props) => {
    const { breakpoints } = useVuetify();

    const isMobile = computed(() => {
      return breakpoints.value.mdAndDown;
    });
    const desktopName = computed(() => `assets/site-logos/${props.regionId}.svg`);
    const mobileName = computed(() => `assets/site-logos/skinny_${props.regionId}.svg`);

    const widthDesktop = '138';
    const heightDesktop = computed(() => (isNational() ? '49' : '52'));

    const widthMobile = computed(() => (isNational() ? '67' : '38'));

    const siteName = computed(() => {
      const region = regions.getCurrentRegionName();
      return `Built In ${region ? `${region}` : 'National'}`;
    });

    const homepagePath = computed(() => {
      return '/';
    });

    return {
      breakpoints,
      isMobile,
      desktopName,
      mobileName,
      widthDesktop,
      heightDesktop,
      widthMobile,
      siteName,
      homepagePath,
      isNational: isNational(),
    };
  },
});
