

















import {
  computed,
  defineComponent,
  ref,
  watch,
  useRouter,
  useRoute,
} from '@nuxtjs/composition-api';
import { useVueSiteDetect } from '@/hooks/useVueSiteDetect';
import { useNamespacedGetters } from 'vuex-composition-helpers';
import { regionDomains } from '@/enums/appVars';
import { addStateToUrl } from '@/utils/http';
import { Route } from 'vue-router';

const otherRegions = Object.values(regionDomains).filter((region) => region !== location.hostname);

/**
 * Built In wrapper component for `<a />` and `<nuxt-link />` elements.
 *
 * Renders an `<a>` for non-Nuxt pages, such as pages served by Drupal. Causes a reload.
 * Renders `<nuxt-link>` for Vue pages, improving frontend performance and user experience. Does not cause a reload.
 *
 * If linking to another Built In domain, adds a state parameter to the link to
 * preserve user authentication.
 */
export default defineComponent({
  props: {
    /** for `nuxt-link`s */
    to: String,
    /** for `a` elements */
    href: String,
    /** used if link should be a `nuxt-link` if authenticated, or `a` link if not */
    checkAuth: Boolean,
  },
  setup: (props, ctx) => {
    const { isVue: onVueSite } = useVueSiteDetect();
    const { isAuthenticated } = useNamespacedGetters('UserModule', ['isAuthenticated']);
    const router = useRouter();
    const route = useRoute();
    const currentPath = ref(router.currentRoute.path);
    watch(
      () => route.value,
      (r: Route) => {
        currentPath.value = r.path;
      },
      { immediate: true }
    );
    // TODO: Support hrefs that start with `//`
    /**
     * Returns the href, and if cross built in domain will include the state param.
     *
     * This will disreguard hrefs that start with `//`
     */
    const anchorHref = computed(() => {
      const href = props.href || props.to || '';

      if (href.startsWith('/') || href.includes(location.hostname) || href.startsWith('mailto')) {
        return href;
      }

      const linkingToAnotherBuiltInDomain = otherRegions.some((region) => href.includes(region));

      if (linkingToAnotherBuiltInDomain) {
        const state = isAuthenticated.value ? { isAuthenticated: isAuthenticated.value } : {};
        return addStateToUrl(href, {
          ...state,
          referredBy: `${location.hostname}${currentPath.value}`,
        });
      }
      return href;
    });

    const attrs = computed(() => {
      const linkAttrs = Object.assign({}, ctx.attrs);
      delete linkAttrs.href;
      delete linkAttrs.to;
      return linkAttrs;
    });

    const authCheck = computed(() => {
      if (!props.checkAuth) {
        return true;
      }

      return isAuthenticated.value;
    });

    const cassiniRedirects = ['/jobs', '/company', '/companies', '/profile'];

    const isCassini = computed(() => {
      const href = props.href || props.to || '';
      return cassiniRedirects.some((redirect) => {
        if (href.startsWith(redirect)) {
          return true;
        }
        if (href === '/') {
          return true;
        }
        return false;
      });
    });

    return {
      onVueSite,
      anchorHref,
      attrs,
      authCheck,
      isCassini,
    };
  },
});
