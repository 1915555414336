import Vue from 'vue';
import { AxiosResponse } from 'axios';
import { cloneDeep, noop } from 'lodash-es';

import onboarding from '@/api/onboarding';
import {
  ActionNames as ActionNamesHomepage,
  CompanyParams,
  LimitSlidesCompanies,
} from '@/store/homepage/types';
import {
  ActionNames,
  City,
  CompanyValue,
  Expertise,
  RemoteNonUSID,
  RemoteUSID,
  Skill,
  State,
  Topic,
  YearsList,
  OnboardingActions,
  OnboardingGetters,
  OnboardingGettersObject,
  OnboardingMutations,
  GetterNamesEnum,
  ExpertiseList,
  Profile,
  ProfileMutation,
} from './types';
import { MarketStepsFlow, StepsFlow } from './flow';
import { Region, RootActionNames } from '@/store/types';
import regions from '@/api/regions';
import history from '@/utils/history';
import onboardingAPI, { onboardingCityList, transformApiCity } from '@/utils/onboarding';
import dayjs from 'dayjs';
import { ExperiencesList } from '@/store/jobs/types';
import user from '@/api/user';

const regionID = regions.getCurrentRegionID();
const isMarket = regions.isMarket();

const state = (): State => ({
  step: 'Step0',
  previousStep: '',
  steps: regionID == regions.getNationalID() ? StepsFlow : MarketStepsFlow,
  profile: {
    id: 0,
    created_at: '',
    market_id: 0,
    onboarded: 0,
    onboarding_complete: 0,
    step: 0,
    first_name: '',
    last_name: '',
    email: '',
    user_email: '',
    picture: '',
    avatar: '',
    why: 0,
    cancel_why_jobs: 0,
    cancel_why_companies: 0,
    cancel_why_topics: 0,
    home_city: 0,
    home_location: 0,
    other_country: '',
    other_state: '',
    other_city: '',
    expertise: [],
    skills: [],
    topics: [],
    cities: [],
    work_relocate: 0,
    work_relocate_paid: 0,
    education: [],
    projects: [],
    experience: [],
    company_values: [],
    cities_read: [],
    resume_job_title: '',
    resume_company_name: '',
    resume_company_id: 0,
    resume_linked_in: '',
    resume: '',
    quick_bio: '',
    personal_url: '',
    kaggle_url: '',
    github_url: '',
    dribbble_url: '',
    twitter_url: '',
    content_subscription: 0,
    content_subscription_local: -1,
    jobs_subscription: 0,
    events_subscription: 0,
    has_orders: false,
    available_job_slots: 0,
    registration_source: '',
    phone_number: '',
    resume_list: [],
    default_resume: {
      id: 0,
      name: '',
      url: '',
      created_at: '',
      updated_at: '',
    },
    job_titles: [],
  },
  expertise_list: [],
  years_list: YearsList,
  skills: [],
  company_values: [],
  cities: [],
  // TODO remove national cities from onboarding PODC-2531
  nationalCities: [], // national cities data, used only for market sites, if user have select national location, we need  display national locations in a user profile(profile/edit page)
  topics: [],
});

const getters: OnboardingGetters = {
  cities: (state) =>
    state.cities.filter((city: City) => city.id != RemoteUSID && city.id != RemoteNonUSID),
  /**
   *  national cities data, used only for market sites, if user have select national location, we need  display national locations in a user profile(profile/edit page)
   * @param state
   * @param getters
   * @param rootState
   */
  nationalCities: (state, getters, rootState) =>
    rootState.nationalRegions
      .filter((city: Region) => city.id != RemoteUSID && city.id != RemoteNonUSID)
      .map(transformApiCity),
  [ActionNames.userSkills.name]: (state) => {
    const skills = state.profile.skills;
    const active_skills = new Array(skills.length);
    for (const key in skills) {
      active_skills[key] = skills[key].skill_id;
    }
    return active_skills;
  },
  userPhoto: (state) => {
    return state.profile.avatar;
  },
  userLocation: (state) => {
    let location: string = '';
    const states: { [key: string]: string } = {
      Austin: 'TX',
      Boston: 'MA',
      Chicago: 'IL',
      Colorado: 'CO',
      'Los Angeles': 'CA',
      'New York City': 'NY',
      Seattle: 'WA',
      'San Francisco': 'CA',
    };
    // U.S based user
    if (state.profile.home_city < RemoteUSID) {
      const city = state.cities.find((city) => city.id == state.profile.home_city);
      if (city && city.name != undefined) {
        const userState = states[city.name] ? ', ' + states[city.name] : '';
        location = city.name + userState;
      }
    } else if (state.profile.home_city == RemoteUSID) {
      // Other U.S based location
      if (state.profile.other_state) {
        location = state.profile.other_state;
        if (state.profile.other_city) {
          location = state.profile.other_city + ', ' + location;
        }
      }
    } else {
      // Other Non-U.S based location
      if (state.profile.other_country) {
        location = state.profile.other_country;
      }
    }
    return location;
  },
  userFullName: (state) => {
    const full_name: string[] = [state.profile.first_name, state.profile.last_name];
    return full_name.join(' ');
  },
  hasRemoteFlag: (state): boolean => {
    return state.profile?.cities.some((c) => c.id == RemoteUSID);
  },
  userCompaniesParams: (state) => {
    let cities = state.profile.cities || [];

    if (isMarket) {
      const index = cities.findIndex((city) => city.id == regions.getCurrentRegionID());
      if (index == -1) {
        cities = [
          {
            id: regions.getCurrentRegionID(),
            locations: [],
          },
        ];
      } else {
        cities = [cities[index]];
      }
    } else if (!isMarket && !cities.length && state.profile.home_city) {
      // Added user home city to params if cities is empty
      const homeCity = state.profile.home_city;
      if (homeCity != RemoteUSID && homeCity != RemoteNonUSID) {
        cities = [
          {
            id: homeCity,
            locations: [],
          },
        ];
      }
    }
    const params: CompanyParams = {
      count: LimitSlidesCompanies.toString(),
      regions: cities.map((el: City) => el.id).join(','),
    };
    return params;
  },
  [OnboardingGettersObject.userPosition]: (state) => {
    const { experience } = state.profile;
    const position = experience?.find((work) => {
      return work.year_to > dayjs().year();
    });
    return position ? position : null;
  },
  [GetterNamesEnum.userPreferredWorkLocations]: (state) => {
    return state.profile.cities;
  },
  relocateOption: (state) => {
    const { work_relocate, work_relocate_paid } = state.profile;
    return {
      work_relocate: work_relocate || 0,
      work_relocate_paid: work_relocate_paid || 0,
    };
  },
  [GetterNamesEnum.userYearOfExperience]: (state): string[] => {
    const { profile } = state;
    const userExpertiseLevel =
      profile.expertise.length && profile.expertise[0].level != undefined
        ? profile.expertise[0].level
        : '';

    if (userExpertiseLevel) {
      const userLevel = ExperiencesList.find((experienceItem) =>
        experienceItem.values.includes(userExpertiseLevel)
      );

      if (userLevel) {
        return userLevel.values;
      }
    }
    return [];
  },
  [GetterNamesEnum.userSelectedExpertise]: (state): ExpertiseList | null => {
    const { profile, expertise_list } = state;
    const userExpertise = profile.expertise;
    const selectedExpertiseData = expertise_list.find((expertise) => {
      return userExpertise.some(
        (userExpertiseItem) => expertise.id === userExpertiseItem.expertise_id
      );
    });
    return selectedExpertiseData ?? null;
  },
  [GetterNamesEnum.userAvailableJobSlots]: (state): number => {
    return state.profile.available_job_slots;
  },
};

const mutations: OnboardingMutations = {
  [ActionNames.expertises.name]: (state, expertise_list) => {
    state.expertise_list = expertise_list;
  },
  [ActionNames.skills.name]: (state, skills: Skill[]) => {
    state.skills = skills;
  },
  [ActionNames.companyValues.name]: (state, company_values: CompanyValue[]) => {
    company_values.forEach((cv) => {
      if (cv.attr_subs != null && cv.attr_subs.length > 0) {
        cv.attr_subs = cv.attr_subs.filter(
          // Exclude Other, Co-Working Space or Incubator, Angel or VC Firm
          (cv) => cv.id != 172 && cv.id != 202 && cv.id != 163
        );
      }
      return cv;
    });
    state.company_values = company_values;
  },
  [ActionNames.topics.name]: (state, topics: Topic[]) => {
    state.topics = topics;
  },
  [ActionNames.cities.name]: (state, cities: any) => {
    state.cities = onboardingCityList(cities);
  },
  userProfile: (state: State, data: Profile) => {
    state.profile = data;
  },
  updateUserProfileForm: (state: State, profileData: ProfileMutation) => {
    const profileState = cloneDeep(state.profile);
    state.profile = {
      ...profileState,
      ...profileData,
    };
  },
  [ActionNames.userProfileCancelWhy.name]: (state, options) => {
    let why = state.profile.why;
    if (options.cancel_why_companies != undefined) {
      why = onboardingAPI.removeWhyCompanies(why);
    }
    if (options.cancel_why_jobs != undefined) {
      why = onboardingAPI.removeWhyJobs(why);
    }
    if (options.cancel_why_topics != undefined) {
      why = onboardingAPI.removeWhyTopics(why);
    }
    state.profile.why = why;
  },
  [ActionNames.setProfileAvatar.name]: (state, avatar) => {
    if (avatar != undefined) {
      Vue.set(state.profile, 'avatar', avatar);
    }
  },
  [ActionNames.setProfilePicture.name]: (state, picture) => {
    if (picture != undefined) {
      Vue.set(state.profile, 'picture', picture);
    }
  },
  [ActionNames.setResumeName.name]: (state, resume) => {
    if (resume != undefined) {
      Vue.set(state.profile, 'resume', resume);
    }
  },
  [ActionNames.nextStep.name]: (state, step: number) => {
    // Validate user authentication
    let flow = state.steps.find((step: any) => {
      if (step.why != undefined) {
        const index = step.why.findIndex((why: any) => why === state.profile.why);
        if (index != -1) {
          return step;
        }
      }
    });
    if (flow === undefined) {
      flow = state.steps[0];
    }
    const currentStepIndex = flow.flow.indexOf(step) + 1;
    const prevIndex = flow.flow.indexOf(step);
    state.previousStep = 'Step' + flow.flow[prevIndex].toString();
    if (currentStepIndex !== flow.flow.length) {
      state.step = 'Step' + flow.flow[currentStepIndex].toString();
      return;
    }

    // Complete onboarding
    window.location.href = '/onboarding/welcome';
  },
  [ActionNames.setOnboardingComplete.name]: (state, step) => {
    state.steps.find((item: any) => {
      if (item.why != undefined) {
        const index_why = item.why.findIndex((why: any) => why === state.profile.why);
        const index_flow = item.flow.findIndex((flow: any) => flow === step);
        if (index_why != -1 && index_flow != -1 && item.flow.length === index_flow + 1) {
          state.profile.onboarding_complete = 1;
        }
      }
    });
  },
  [ActionNames.nationalCities.name]: (state, cities) => {
    state.nationalCities = onboardingCityList(cities);
  },
  _clearAuth(stateCurrent) {
    const defaultState = state();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stateCurrent.profile = { ...defaultState.profile };
  },
  prevStep(state) {
    state.step = state.previousStep;
  },
};

const actions: OnboardingActions = {
  userProfile1: async ({ commit, state }) => {
    const data = {
      first_name: state.profile.first_name,
      last_name: state.profile.last_name,
      user_email: state.profile.user_email,
      home_city: state.profile.home_city,
      other_country: state.profile.other_country,
      home_location: state.profile.home_location,
      other_state: state.profile.other_state,
      other_city: state.profile.other_city,
      why: 0,
    };
    if (state.profile.home_city == RemoteNonUSID) {
      data.why = 2;
    }
    const profile = await onboarding.stepPost(
      {
        data,
        step: 'step1',
      },
      noop
    );
    commit('userProfile', profile);
  },
  userProfile2: async ({ state, commit }) => {
    const profile = await onboarding.stepPost(
      {
        data: {
          why: state.profile.why,
        },
        step: 'step2',
      },
      noop
    );
    commit('userProfile', profile);
  },
  userProfile3: async ({ dispatch, state, commit }, options) => {
    const profile = await onboarding.stepPost(
      {
        data: {
          expertise: state.profile.expertise,
          why: state.profile.why,
          skills: state.profile.skills,
          cities: state.profile.cities.flatMap((cv) => cv.locations),
        },
        step: 'step3',
        popup: options.popup,
      },
      (response: AxiosResponse) => {
        if (response.status == 200) {
          if (options.popup || options.empty_state) {
            dispatch(ActionNamesHomepage.step.action(), options, {
              root: true,
            });
          }
        }
      }
    );
    commit('userProfile', profile);
  },
  userProfile4: async ({ dispatch, state, commit }, options) => {
    const profile = await onboarding.stepPost(
      {
        data: {
          skills: state.profile.skills,
          onboarding_complete: state.profile.onboarding_complete,
        },
        step: 'step4',
        popup: options.popup,
      },
      (response: AxiosResponse) => {
        if (response.status == 200) {
          if (options.popup) {
            // TODO need refactor, use  @watch probably on homepage component
            dispatch(ActionNamesHomepage.step.action(), options, {
              root: true,
            });
          }
        }
      }
    );
    commit('userProfile', profile);
  },
  userProfile5: async ({ dispatch, commit, state }, options) => {
    const profile = await onboarding.stepPost(
      {
        data: {
          cities: options.cities.flatMap((cv: City) => cv.locations),
          work_relocate: state.profile.work_relocate,
          work_relocate_paid: state.profile.work_relocate_paid,
          onboarding_complete: state.profile.onboarding_complete,
        },
        step: 'step5',
        popup: options.popup,
      },
      (response: AxiosResponse) => {
        if (response.status == 200) {
          if (options.popup) {
            dispatch(ActionNamesHomepage.step.action(), options, {
              root: true,
            });
          }
        }
      }
    );
    commit('userProfile', profile);
  },
  userProfile6: async ({ dispatch, commit, state }, options) => {
    const data: any = {};
    data.why = state.profile.why;
    if (options.topics.length) {
      data.topics = options.topics.join(',');
    }
    if (options.read_cities.length) {
      data.read_cities = options.read_cities.join(',');
    }
    const profile = await onboarding.stepPost(
      {
        data,
        step: 'step6',
        popup: options.popup,
      },
      (response: AxiosResponse) => {
        if (response.status == 200) {
          if (options.popup || options.empty_state) {
            dispatch(ActionNamesHomepage.userTechNews.action(), false, {
              root: true,
            });
            dispatch(ActionNamesHomepage.step.action(), options, {
              root: true,
            });
          }
        }
      }
    );
    commit('userProfile', profile);
  },
  userProfile7: async ({ dispatch, commit, state }, options) => {
    let company_values = state.profile.company_values.flatMap((cv) => cv.attr_subs);
    if (options?.company_values) {
      company_values = options.company_values.flatMap((cv: any) => cv.attr_subs);
    }
    const data: any = {
      company_values,
      why: options?.why ? options.why : state.profile.why,
    };
    if (!options?.empty_state) {
    } else {
      if (options?.skills) {
        data.skills = options.skills;
      }
      if (options?.expertise) {
        data.expertise = options.expertise;
      }
      if (options?.cities?.length) {
        data.cities = options.cities.flatMap((cv: any) => cv.locations);
      }
    }
    const profile = await onboarding.stepPost(
      {
        data,
        step: 'step7',
        popup: options.popup,
      },
      (response: AxiosResponse) => {
        if (response.status == 200) {
          if (options?.popup) {
            dispatch(ActionNamesHomepage.step.action(), options, {
              root: true,
            });
          }
        }
      }
    );
    commit('userProfile', profile);
  },
  userProfile8: async ({ state, dispatch, commit }, data) => {
    data.resumeInfo.onboarding_complete = state.profile.onboarding_complete;
    const profile = await onboarding.stepPost(
      {
        data: data.resumeInfo,
        step: 'step8',
      },
      async (response: AxiosResponse) => {
        if (response.status == 200) {
          await onboarding.stepPostFile(data.formData, (response: AxiosResponse) => {
            if (response.status == 200) {
              if (!data.popup) {
                commit(ActionNames.nextStep.name, 8);
              }
              dispatch(
                ActionNamesHomepage.step.action(),
                { popup: data.popup },
                {
                  root: true,
                }
              );
            }
          });
        }
      }
    );
    commit('userProfile', profile);
  },
  [ActionNames.userProfileCancelWhy.name]: async ({ commit, state }, options) => {
    commit(ActionNames.userProfileCancelWhy.name, options);
    const profile = await onboarding.stepPost(
      {
        data: {
          why: state.profile.why,
          cancel_why_jobs: state.profile.cancel_why_jobs,
          cancel_why_companies: state.profile.cancel_why_companies,
          cancel_why_topics: state.profile.cancel_why_topics,
        },
        step: 'cancel_why',
      },
      (response: AxiosResponse) => {
        return response.status == 200;
      }
    );
    commit('userProfile', profile);
  },
  getExpertises: ({ state, commit }) => {
    if (!state.expertise_list.length) {
      commit(RootActionNames.setHistory, { name: ActionNames.expertises.action() }, { root: true });
      return onboarding.getExpertise((expertise_list: Expertise[]) => {
        commit(ActionNames.expertises.name, expertise_list);
      });
    }
    return Promise.resolve(state.expertise_list);
  },
  [ActionNames.companyValues.name]: ({ commit, rootState }) => {
    if (!history.exist(rootState.history, ActionNames.companyValues.action())) {
      commit(
        RootActionNames.setHistory,
        { name: ActionNames.companyValues.action() },
        { root: true }
      );
      return onboarding.getCompanyValues((company_values: CompanyValue[]) => {
        commit(ActionNames.companyValues.name, company_values);
      });
    }
  },
  [ActionNames.topics.name]: ({ commit, rootState }) => {
    if (!history.exist(rootState.history, ActionNames.topics.action())) {
      commit(RootActionNames.setHistory, { name: ActionNames.topics.action() }, { root: true });
      return onboarding.getTopics((topics: Topic[]) => {
        let filtered_topics: Topic[] = [];
        filtered_topics = topics
          .filter(
            // Exclude Employer Branding, Employee Engagement, Job Descriptions, Recruiting, Editors' Picks, Expert Contributors
            (t: Topic) =>
              t.id != 547 && t.id != 549 && t.id != 546 && t.id != 576 && t.id != 591 && t.id != 592
          )
          .map((item: any) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
        commit(ActionNames.topics.name, filtered_topics);
      });
    }
  },
  [ActionNames.skills.name]: ({ commit, rootState, state }, exp_id = 0) => {
    if (state.profile.expertise.length > 0 || exp_id > 0) {
      const expertise_id = exp_id > 0 ? exp_id : state.profile.expertise[0].expertise_id;
      if (!history.exist(rootState.history, ActionNames.skills.action(), [expertise_id])) {
        commit(
          RootActionNames.setHistory,
          {
            name: ActionNames.skills.action(),
            params: [expertise_id].toString(),
          },
          { root: true }
        );
        return onboarding.getSkills(expertise_id, (response: AxiosResponse) => {
          if (response.status == 200) {
            const skills_list = response.data[expertise_id];
            commit(ActionNames.skills.name, skills_list);
          }
        });
      }
    }
  },
  userProfile: async (context) => {
    try {
      const data = await onboarding.getProfileAsync();
      context.commit(ActionNames.userProfile.name, data);
      return true;
    } catch {
      /* nop */
    }
    return false;
  },
  nextStep: ({ commit, state }, step) => {
    if (step == 1 && state.profile.home_city == RemoteNonUSID) {
      step = 2;
    }
    commit(ActionNames.nextStep.name, step);
  },
  [ActionNames.setOnboardingPrevStep.name]: ({ commit, state }) => {
    if (state.previousStep) {
      commit('prevStep');
    }
  },
  addResume: async ({ commit }, formData: FormData) => {
    try {
      const profile = await user.addResume(formData);
      commit('userProfile', profile);
      return true;
    } catch {
      /* nop */
    }
    return false;
  },
  deleteResume: async ({ commit }, resumeId: number) => {
    try {
      const profile = await user.deleteResume(resumeId);
      commit('userProfile', profile);
      return true;
    } catch {
      /* nop */
    }
    return false;
  },
  setDefaultResume: async ({ commit }, resumeId: number) => {
    try {
      const profile = await user.setDefaultResume(resumeId);
      commit('userProfile', profile);
      return true;
    } catch {
      /* nop */
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
