import { MutationTree } from 'vuex';

import dashboard from '@/api/dashboard';
import companies from '@/api/companies';
import {
  ActionNames,
  APIError,
  CompanySearchUpdateParams,
  State,
  CompanyOverview,
  QuicksightDashboard,
  DashboardActions,
} from '@/store/dashboard/types';

import { CompanySearchParam } from '@/store/companies/types';
import { SearchCompany } from '@/store/search/types';

const toCompanySearchParam = ({
  companyNamePart,
  maxResults,
}: CompanySearchUpdateParams): CompanySearchParam => ({
  company_name_part: companyNamePart,
  max_results: maxResults,
  region_id: 0,
});

const state = (): State => ({
  error: '',
  company: {
    loadingOverview: false,
    loadingSearch: false,
    companies: [],
    titleToId: {},
    overview: {
      id: 0,
      region_id: 0,
      alias: '',
      url: '',
      title: '',
      logo: '',
      industries: [],
      market_site_company_id: 0,
      subscription_id: 1,
      last_updated: new Date(),
    },
  },
  quicksightDashboardData: {
    loading: false,
    companyId: 0,
    url: 'https://us-west-2.quicksight.aws.amazon.com',
    salesforceID: 0,
  },
});

/*
 * Mutations pertaining to the `Company Search By Name` section
 */
const companySearchMutations: MutationTree<State> = {
  /*
   *  sets Options for a company search by name
   */
  [ActionNames.receiveCompaniesSuccess]: (state, companies: Array<SearchCompany>) => {
    const titleToId = companies
      ? companies.reduce(
          (running: any, current: any) => ({
            ...running,
            [current.title]: current.id,
          }),
          {}
        )
      : {};
    state.company.companies = companies;
    state.company.titleToId = titleToId;
  },

  /*
   * sets error state when company search fails.
   */
  [ActionNames.receiveCompaniesFailure]: (state, error: APIError) => {
    state.error = error.message;
  },

  /*
   * Toggles loading state when company search is in flight
   */
  [ActionNames.loadingCompanies]: (state, isLoading: boolean) => {
    state.company.loadingSearch = isLoading;
  },
};

/*
 * Mutations pertaining to the `CompanyOverview` section
 */
const companyOverviewMutations: MutationTree<State> = {
  /*
   * Sets the company overview on success
   */
  [ActionNames.receiveCompanyOverviewSuccess]: (state, overview: CompanyOverview) => {
    state.company.overview = { ...state.company.overview, ...overview };
  },

  /*
   * Sets sets an error when company Overview retrieval fails
   */
  [ActionNames.receiveCompanyOverviewFailure]: (state, error: APIError) => {
    state.error = error.message;
  },

  /*
   * Toggles Company Overview loading status
   */
  [ActionNames.loadingCompanyOverview]: (state, isLoading: boolean) => {
    state.company.loadingOverview = isLoading;
  },
};

/*
 * Mutations pertaining to the `QuicksightDashboard` section
 */
const QuicksightDashboardMutations: MutationTree<State> = {
  /*
   * Sets the Quicksight dashboard on success
   */
  [ActionNames.receiveQuicksightDashboardURLSuccess]: (state, data: QuicksightDashboard) => {
    state.quicksightDashboardData = { ...state.quicksightDashboardData, ...data };
  },

  /*
   * Sets sets an error when Quicksight dashboard fails
   */
  [ActionNames.receiveQuicksightDashboardURLFailure]: (state, error: APIError) => {
    state.error = error.message;
  },

  /*
   * Toggles Quicksight dashboard loading status
   */
  [ActionNames.loadingQuicksightDashboardURL]: (state, isLoading: boolean) => {
    state.quicksightDashboardData.loading = isLoading;
  },
};

const mutations: MutationTree<State> = {
  ...companySearchMutations,
  ...companyOverviewMutations,
  ...QuicksightDashboardMutations,
};

const actions: DashboardActions = {
  fetchUniqueCompanies: async ({ commit }, updateParams) => {
    commit(ActionNames.loadingCompanies, true);
    try {
      const results = await companies.uniqueSearchByName(toCompanySearchParam(updateParams));
      commit(ActionNames.receiveCompaniesSuccess, results);
    } catch {
      commit(ActionNames.receiveCompaniesFailure, 'error fetching companies');
    }
    commit(ActionNames.loadingCompanies, false);
  },
  fetchCompanyOverview: async ({ commit }, id) => {
    let overview: CompanyOverview | Error;
    try {
      overview = await companies.getOverviewById(id);
      commit(ActionNames.receiveCompanyOverviewSuccess, overview);
    } catch (err: any) {
      overview = err;
      commit(ActionNames.receiveCompanyOverviewFailure, 'error fetching company overview');
    }
    commit(ActionNames.loadingCompanyOverview, false);
    return overview;
  },
  fetchQuicksightDashboardURL: async ({ commit }, param) => {
    let data: QuicksightDashboard | Error;
    try {
      data = await dashboard.getQuicksightDashboard(param);
      commit(ActionNames.receiveQuicksightDashboardURLSuccess, data);
    } catch (err: any) {
      data = err;
      commit(
        ActionNames.receiveQuicksightDashboardURLFailure,
        'error fetching quicksight dashboard'
      );
    }
    commit(ActionNames.loadingQuicksightDashboardURL, false);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
