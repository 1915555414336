import { WebStorageStateStore, UserManagerSettings } from 'oidc-client';
import appVars from '@/enums/appVars';
import regionUtils from '@/api/regions';
import { BixStorage } from './bixStorage';

const regionUri = regionUtils.getRegionBaseURL();
let baseDomain: string;
if (window.location.hostname === 'localhost') {
  baseDomain = 'localhost';
} else {
  baseDomain = window.location.hostname.split('.').slice(-2).join('.');
}

const oidcSettings = {
  userStore: new WebStorageStateStore({
    store: new BixStorage(baseDomain),
  }),
  authority: appVars.IdPAuthority,
  client_id: appVars.IdPClientId,
  redirect_uri: `${regionUri}/auth/callback.html`,
  automaticSilentRenew: false, // This is handled by the store.
  silent_redirect_uri: `${regionUri}/auth/silent-renew.html`,
  response_type: 'code',
  scope: `openid profile email api-external roles identity-mgmt ${
    regionUtils.isMarket() ? 'offline_access' : ''
  }`,
  post_logout_redirect_uri: `${regionUri}/user/logout`,
  filterProtocolClaims: true,
} as UserManagerSettings;

export default oidcSettings;
