var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasCompanyPermissions)?_c('div',{staticClass:"company-menu",attrs:{"data-testid":"admin-nav-company-menu"}},[_c('div',{staticClass:"company-preview"},[_c('BixLink',{attrs:{"to":_vm.companyLink,"data-testid":"admin-nav-company-link"}},[(_vm.locationPermissions.length)?_c('CompanyAvatar',{attrs:{"size":_vm.isNational && _vm.$vuetify.breakpoint.mdAndDown ? '32' : '40',"src":_vm.companyPhoto.url,"region-id":_vm.companyPhoto.region_id,"alt":_vm.companyTitle}}):_c('v-avatar',{attrs:{"size":_vm.isNational && _vm.$vuetify.breakpoint.mdAndDown ? '32' : '40'}},[_c('BixImg',{attrs:{"src":"assets/icons/employer-portal.svg","alt":"Employer Portal","width":"40","height":"40","data-testid":"admin-nav-icon"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dropdown"},[(_vm.hasCompanyPermissions)?_c('EmployerPortalNavLink',{attrs:{"show-separator":!!_vm.locationPermissions.length}}):_vm._e(),_vm._v(" "),(_vm.checkUserPermission('company_profile_editor', 'company_admin'))?_c('BixLink',{attrs:{"href":(_vm.marketUrl + "/node/" + _vm.drupalCompanyId + "/edit"),"data-testid":"admin-nav-edit-company-profile-link"}},[_vm._v("\n        Edit Company Profile\n      ")]):_vm._e(),_vm._v(" "),(_vm.checkUserPermission('job_poster', 'company_admin'))?_c('BixLink',{attrs:{"href":(_vm.marketUrl + "/manage-jobs/" + _vm.drupalCompanyId),"data-testid":"admin-nav-edit-manage-jobs-link"}},[_vm._v("\n        Manage All Jobs\n      ")]):_vm._e(),_vm._v(" "),(_vm.checkUserPermission('company_admin'))?_c('BixLink',{attrs:{"to":("/tools/company/" + _vm.companyId + "/security/manage-admins"),"data-testid":"admin-nav-edit-manage-admins-link"}},[_vm._v("\n        Manage Admins\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.checkUserPermission('access_candidate_viewer') &&
          _vm.showAnalyticsDashboardLink &&
          _vm.AccessCandidateViewerFeatureFlagValue
        )?_c('BixLink',{attrs:{"target":"_blank","to":"/employer/candidates","data-testid":"admin-nav-candidates-viewer-link"}},[_vm._v("\n        Candidate Viewer\n      ")]):(
          _vm.checkUserPermission('company_admin') &&
          _vm.showAnalyticsDashboardLink &&
          !_vm.AccessCandidateViewerFeatureFlagValue
        )?_c('BixLink',{attrs:{"target":"_blank","to":"/employer/candidates","data-testid":"admin-nav-candidates-viewer-link"}},[_vm._v("\n        Candidate Viewer\n      ")]):_vm._e(),_vm._v(" "),(_vm.checkUserPermission('company_admin') && _vm.showAnalyticsDashboardLink)?_c('BixLink',{attrs:{"target":"_blank","href":_vm.dashboardURL,"data-testid":"admin-nav-analytics-dashboard-link"}},[_vm._v("\n        Analytics Dashboard\n      ")]):_vm._e(),_vm._v(" "),(_vm.checkUserPermission('company_admin') && _vm.showContentManagerLink)?_c('BixLink',{attrs:{"target":"_blank","href":_vm.appVars.ContentStudio,"data-testid":"admin-nav-content-manager-link"}},[_vm._v("\n        Content Studio\n      ")]):_vm._e()],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }