export enum TrackingActionNames {
  postInfo = 'postInfo',
  makeData = 'makeTrackingData',
}

export interface TrackingData {
  url: string;
  region_id: number;
  retargeting_tracking: number;
}
