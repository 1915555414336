import Vue from 'vue';
import { isNational } from '@/api/regions';
import { isCompanyAdmin } from '@/utils/user-permissions';
import { Component } from 'vue-property-decorator';

import { userModule } from '@/utils/store-accessor';

/* Mixin to provide authentication behaviors
 *
  Responsible for:
  - Checking if a user is authenticated
  - Authenticating a user
  - Signing a user out
 *
 * @deprecated Prefer useAuthentication hook
*/
@Component
export default class AuthenticationMixin extends Vue {
  /** @deprecated Prefer useAuthentication hook */
  get isAnonymous() {
    return !this.isAuthenticated;
  }

  /** @deprecated Prefer useAuthentication hook */
  get isAuthenticated() {
    return !!userModule?.isAuthenticated;
  }

  /**  @deprecated Prefer useAuthentication hook */
  public async signup(redirectUrl: string, skipOnboarding: boolean = false) {
    const payload = {
      redirectUrl:
        skipOnboarding || isNational()
          ? redirectUrl
          : `/onboarding?destination=${encodeURIComponent(redirectUrl)}`,
      useSignup: true,
    };
    await userModule.authenticate(payload);
  }

  /** @deprecated Prefer useAuthentication hook */
  public async signin(redirectUrl?: string) {
    const hasCompanyPermissions = isCompanyAdmin(userModule.profile?.permissions || []);
    if (hasCompanyPermissions) {
      redirectUrl = '/employer';
    }
    const payload = {
      redirectUrl: `${redirectUrl}`,
      useSignup: false,
    };
    await userModule.authenticate(payload);
  }

  /**
   * - Signs the user out through from identity and clears their information from the store
   * - Clears account upgrade flags so that different users on the same maching can
   *   go through the upgrade flow
   *
   * @deprecated Prefer useAuthentication hook
   */
  public async signout() {
    await userModule.signout();
  }
}
