






import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { ActionNames } from '@/store/companies/types';

@Component({
  methods: {
    ...mapGetters('companies', [ActionNames.getMarketURL]),
  },
})
export default class ButtonJobPost extends Vue {
  getMarketURL!: Function;

  get postJobUrl() {
    return `${this.getMarketURL()}/node/add/job`;
  }
}
