import { Location, Region } from '@/store/types';
import { shouldUseJobsApiSorting } from '@/api/jobs';
import { Experience, JobTitle } from '@/store/onboarding/types';

export const SortNameRecency: string = 'recency';
export const SortNameHotJobs: string = 'hot_jobs';
export const DefaultJobsApiSorting: string = !shouldUseJobsApiSorting()
  ? SortNameRecency
  : SortNameHotJobs;

export interface JobsWithCriterias {
  per_page?: number | string;
  company_id?: number | string;
  categories?: string;
  subcategories?: string;
  industry?: string;
  company_sizes?: string;
  regions?: string;
  locations?: string;
  jobs_board?: boolean;
  experiences?: string;
  remote?: number[];
  working_option?: number[];
  page?: number;
  search?: string;
  sortStrategy?: string;
  national?: boolean;
  job_locations?: string;
  company_locations?: string;
  elite?: boolean;
  company_ids?: string;
  job_ids?: string;
  hybridEnabled?: boolean;

  /**
   * This property is designed to work with the "company_ids" parameter.
   * It is necessary to load more high-volume, job postings for a single company
   * @see https://builtin.atlassian.net/browse/OAK-3620
   */
  exclude_job_ids?: string;
}

export interface JobsRouteParams {
  categoryPath?: string[];
  subCategoryPath?: string[];
  experiencePath?: string[];
  industryPath?: string[];
  companySizePath?: string[];
  regionPath?: string[];
  locationPath?: string[];
  remotePath?: string;
  multiLocationSelected?: boolean;
  ownLocationSelected?: boolean;
  sorting?: string;
  search?: string;
}

export interface JobsSearchCriterias {
  PageSize?: number | string;
  PageNumber?: number | string;

  LocationCoordinates?: string;
  SearchArea?: string;

  DaysSinceUpdated?: number | string;
  EarlyApplicant?: boolean;
  CompanyId?: number | string;
  CategoryId?: string;
  SubCategories?: string;
  Industries?: string;
  CompanySizes?: string;
  RegionId?: string;
  SiteId?: string;
  Locations?: string;
  IsJobsBoard?: boolean;
  Experiences?: string;
  RemotePreferences?: string;
  Search?: string;
  IsNational?: boolean;
  IsElite?: boolean;
}

export interface JobsDestinationParams {
  category?: string;
  subcategory?: string;
  remote?: string;
  nationwide?: boolean;
  region?: Region;
  location?: Location;
  experience?: string;
  experienceAll?: boolean;
  industry?: string;
  companySize?: string;
  industryAll?: boolean;
  sorting?: string;
  search?: string;
  page?: number;
}

export interface JobsFilterParams {
  category_name?: string;
  company_id?: number;
  per_page?: number;
}

export interface JobsLoadedHighVolumeParameters {
  company_id: number;
  job_ids: number[];
}

export interface JobTrackInfo {
  id: number;
  published_date: string | null;
  featured: boolean;
  subscription_id: number | undefined;
  insider_page: boolean | undefined;
  extra?: { [key: string]: any[] };
}

export interface JobsTrack {
  filters: JobsWithCriterias;
  jobs: JobTrackInfo[];
  sortStrategy: string;
}

export interface TechHubStat {
  name: string;
  region_id: number;
  rent: number;
  lunch: number;
  home_price: number;
  salary_developer: number;
  salary_design: number;
  salary_product: number;
  salary_marketing: number;
  companies_number: number;
}

export interface RealtimeFeatureFlag {
  id: number;
  name: string;
  value: 0 | 1;
  region_id: number;
}

export interface MinimalUserParams {
  email: string;
  integrity: string;
  first_name?: string;
  last_name?: string;
  region_id?: string;
  registration_source?: RegistrationSource;
}

// 'feature' is the feature name in evidently
// 'clientId' is a unique user identifier accessed from clientIdentifierService.getLocalId()
export interface EvidentlyFeatureFlagParams {
  feature: string;
  clientId?: string;
  userEmail?: string;
  url?: string;
}

// Potential registration sources defined in Users table
export enum RegistrationSource {
  goBackend = 'go_backend',
  drupal = 'drupal',
  newsletterSignupForm = 'newsletter_signup_form',
  jobApplyForm = 'job_apply_form',
  eventBus = 'event_bus',
  easyApply = 'easy_apply',
}

export interface Resume {
  id: number;
  name: string;
  url: string;
  created_at: string;
  updated_at: string;
}

export interface ProfileResponse {
  id: number;
  created_at: string;
  market_id?: number;
  onboarded: number;
  email: string;
  user_email?: string;
  first_name?: string;
  last_name?: string;
  picture?: string;
  avatar?: string;
  why?: number;
  cancel_why_jobs?: number;
  cancel_why_companies?: number;
  cancel_why_topics?: number;
  onboarding_complete?: number;
  step?: number;
  expertise: {
    id: number;
    user_id?: number;
    expertise_id: number;
    level: string;
    weight?: number;
  }[];
  experience: Experience[];
  skills: { id: number; level: string; skill_id: number; user_id?: number }[];
  cities: {
    city_id?: number;
    location_id: number;
  }[];
  work_relocate: number;
  work_relocate_paid: number;
  education: {
    organization: string;
    type: number;
    year: number;
  }[];
  home_city?: number;
  home_location?: number;
  other_country?: string;
  other_state?: string;
  other_city?: string;
  company_values: {
    attr_id?: number;
    id: number;
  }[];
  projects: {
    title: string;
    url: string;
    description: string;
  }[];
  read_cities?: string;
  topics?: string;
  resume_job_title?: string;
  resume_company_name?: string;
  resume_company_id?: number;
  resume_linked_in?: string;
  resume?: string;
  quick_bio?: string;
  personal_url?: string;
  kaggle_url?: string;
  dribbble_url?: string;
  github_url?: string;
  twitter_url?: string;
  content_subscription: number;
  content_subscription_local: number;
  has_orders: boolean;
  'available-job-slots': number;
  registration_source: null | string;
  phone_number: null | string;
  jobs_subscription: number;
  events_subscription: number;
  resume_list: Resume[];
  default_resume?: Resume;
  job_titles: JobTitle[];
}

export interface Place {
  placeId: string;
  text: string;
}

export interface PlaceData {
  addressNumber: string;
  city: string;
  country: string;
  label: string;
  latitude: number;
  longitude: number;
  municipality: string;
  postalCode: string;
  region: string;
  state: string;
  street: string;
  subRegion: string;
}
