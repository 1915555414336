import { OperationVariables } from '@apollo/client/core';

/** GraphQL query to get the insiders view data */
export { default as GetInsidersView } from './query-getInsidersView.gql';
/** GraphQL query to get job categories */
export { default as GetJobCategories } from './query-getJobCategories.gql';
/** GraphQL query to get a company's subscription */
export { default as GetCompanySubscription } from './query-getCompanySubscription.gql';
/** GraphQL query to get similar companies, and their open jobs, given a company ID */
export { default as GetSimilarCompaniesAndJobs } from './query-getSimilarCompaniesAndJobs.gql';
/** GraphQL query to get company reviews */
export { default as GetCompanyReviews } from './query-getCompanyReviews.gql';
/** GraphQL query to get company overview */
export { default as GetCompanyOverview } from './query-getCompanyOverview.gql';
/** GraphQL query to get filtered companies */
export { default as GetFilteredCompanies } from './query-getFilteredCompanies.gql';
/** GraphQL query to get company directory filters */
export { default as GetCompanyFilters } from './query-getCompanyFilters.gql';
/** GraphQL query to get a company by id */
export { default as GetCompanyById } from './query-getCompanyById.gql';
/** GraphQL query to get companies by ids */
export { default as GetCompaniesByIds } from './query-getCompaniesByIds.gql';
/** GraphQL query to get targeted remote companies */
export { default as GetTargetedRemoteCompanies } from './query-getTargetedRemoteCompanies.gql';
/** GraphQL query to get jobs by filters */
export { default as GetFilteredJobs } from './query-getFilteredJobs.gql';
/** GraphQL query to get jobs by filters for company */
export { default as GetCompanyJobs } from './query-getCompanyIJobs.gql';
/** GraphQL query to get company's technologies by company ID */
export { default as GetCompanyTechnologies } from './query-getCompanyTechnologies.gql';
/** GraphQL query to get company articles */
export { default as GetArticlesByCompanyId } from './query-getArticlesByCompanyId.gql';
/** GraphQL query to get brand boosted company articles */
export { default as GetBrandBoostCompanies } from './query-getBrandBoostCompanies.gql';
/** GraphQL query to get remote hiring company articles */
export { default as GetRemotelyHiringCompanies } from './query-getRemotelyHiringCompanies.gql';

/** Query parameters for getting company by alias and region */
export interface GetCompanyParams extends OperationVariables {
  /** The alias of the company */
  name: string;
  /** The region ID of the company */
  regionId: number;
}

/** Query parameters for getting company by ID */
export interface GetCompanyByIdParams extends OperationVariables {
  /** The ID of the company */
  id: number;
}

/** Query parameters for getting company articles by company ID */
export interface GetArticlesByCompanyIdParams extends OperationVariables {
  /** The ID of the company */
  companyID: number;
  /** national context */
  national: boolean;
}

export interface GetEasyApplyPageDataParams extends OperationVariables {
  companyId: number;
  jobCategoryId: number;
}
