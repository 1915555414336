import CompanyModule from './company';
import JobsModule from './jobs';
import UserModule from './user';

export { default as CompanyModule } from './company';
export { default as JobsModule } from './jobs';
export { default as UserModule } from './user';

/** A collection of fully typed vuex store modules */
export default {
  CompanyModule,
  JobsModule,
  UserModule,
};
