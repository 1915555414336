import { History } from '@/store/types';

export default {
  exist(history: History[], name: string, args: any[] = []) {
    const new_params = args.toString();
    let old_params = '';
    const item = history.find((l) => l.name === name);
    if (item && item.params) {
      old_params = item.params;
    }
    if (item) {
      if (new_params != old_params) {
        return false;
      }
      return true;
    }
    return false;
  },
  add(history: History[], item: History) {
    const items = history;
    const index = items.findIndex((l) => l.name === item.name);
    if (index !== -1) {
      items.splice(index, 1);
    }
    items.push(item);
    return items;
  },
  remove(history: History[], item: History) {
    const items = history;
    const index = items.findIndex((l) => l.name === item.name);
    if (index !== -1) {
      items.splice(index, 1);
    }
    return items;
  },
};
