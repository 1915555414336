






















import { defineComponent, computed } from '@nuxtjs/composition-api';
import AppVars from '@/enums/appVars';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';

import { theme } from '@/styles/variables/colors';

export default defineComponent({
  name: 'BixUserAvatar',
  props: {
    src: String,
    alt: String,
    size: {
      type: [String, Number],
      default: 80,
    },
    edit: Boolean,
    clickable: Boolean,
  },
  setup: (props, { emit }) => {
    const avatarSrc = computed(
      () => props.src || `${AppVars.BaseUrl}/assets/profilepic_fallback.jpg`
    );

    const onClick = function (e: MouseEvent) {
      emit('click', e);
    };

    return {
      theme,
      faPencil,
      avatarSrc,
      onClick,
    };
  },
});
