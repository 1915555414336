import { Enum } from 'enumify';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { Profile } from '@/store/onboarding/types';

export interface State {
  companies: CompanySearchResult[];
  IDtoTitle: Object;
}

export interface CompanySearchUpdateParams {
  companyNamePart: string;
  maxResults: number;
  regionId: number;
}

export interface CompanySearchResult {
  title: string;
  id: number;
  regionId: number;
}

export enum URLRegExps {
  site = '^(www.|http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
  kaggle = '^(www.|http://www.|https://www.|http://|https://)?kaggle.com/[A-z0-9_-]+/?',
  github = '^(www.|http://www.|https://www.|http://|https://)?github.com/[A-z0-9_-]+/?',
  dribbble = '^(www.|http://www.|https://www.|http://|https://)?dribbble.com/[A-z0-9_-]+/?',
  twitter = '^(www.|http://www.|https://www.|http://|https://)?twitter.com/[A-z0-9_-]+/?',
  linkedin = '^(www.|http://www.|https://www.|http://|https://)?linkedin.com/in/[A-z0-9_-]+/?',
}

export const AvatarAcceptedExt = '.jpg,.jpeg,.png';

export class ActionNames extends Enum {
  getter() {
    return 'user/' + this.name;
  }

  action(id: number) {
    return 'user/' + this.actionName(id);
  }

  mutation() {
    return 'user/' + this.name;
  }

  actionName(id: number) {
    return this.name + (id || '').toString();
  }
}

export interface UserActions extends ActionTree<State, RootState> {
  uploadFile: (context: ActionContext<State, RootState>, formData: FormData) => Promise<void>;
  editProfile: (context: ActionContext<State, RootState>, data: Profile | null) => Promise<void>;
}

ActionNames.initEnum([
  // getters
  'years',
  'educationYears',

  // mutations
  'receiveCompaniesSuccess',

  // actions
  'editProfile',
  'uploadFile',
  'fetchUserCompanies',
]);
