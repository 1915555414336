/**
 * errorCheck is used to log API errors to the console.
 * If the response is a HTTP 409 it will reload the page
 * @param error An Error thrown from an Api response
 */
export function errorCheck(error: any) {
  if (error.response && error.response.status == 409) {
    window.location.reload();
  } else {
    console.error(error);

    return error;
  }
}
