const middleware = {}

middleware['authGuard'] = require('../src/middleware/authGuard.ts')
middleware['authGuard'] = middleware['authGuard'].default || middleware['authGuard']

middleware['cassiniRedirects'] = require('../src/middleware/cassiniRedirects.ts')
middleware['cassiniRedirects'] = middleware['cassiniRedirects'].default || middleware['cassiniRedirects']

middleware['employerRedirect'] = require('../src/middleware/employerRedirect.ts')
middleware['employerRedirect'] = middleware['employerRedirect'].default || middleware['employerRedirect']

middleware['onboardingRedirect'] = require('../src/middleware/onboardingRedirect.ts')
middleware['onboardingRedirect'] = middleware['onboardingRedirect'].default || middleware['onboardingRedirect']

middleware['requirePermissions'] = require('../src/middleware/requirePermissions.ts')
middleware['requirePermissions'] = middleware['requirePermissions'].default || middleware['requirePermissions']

export default middleware
