/**
 * This file is for plugins that are only required by the
 * full vue site.
 */

import Vue from 'vue';
import VueMeta from 'vue-meta';

import './vueScrollactive';
import ga from '@/utils/ga';
import faIcons from './fontawesome';
import onVueSite from './onVueSite';

Vue.use(ga);
Vue.use(VueMeta, {
  keyName: 'head',
  refreshOnceOnNavigation: true,
});

export default () => {
  onVueSite();
  faIcons();
};
