/**
 * This file is for plugins that are required by both the drupal
 * navbar and full vue site.
 */
import Vue from 'vue';

import './mirage';
import bixLink from './bixLink';

Vue.config.productionTip = false;

export default () => {
  bixLink();
};
