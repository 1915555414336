import { regionDomains } from '@/enums/appVars';

export type BPWListType = 'overall' | 'small' | 'midsize' | 'paying' | 'benefits';

/**
 * Get the relevant "Best Places to Work" url path from a Built In domain name
 *
 * @param domain - the domain name from which the region-relevant Best Places to Work url path is derived
 * @param listType - [Optional] one of `overall` (default), `small` (for best small places to work), `midsize` (for best midsize places to work), `paying` (for best paying places to work), or `benefits` (for companies with best benefits)
 * @param year - [Optional] a fixed year for the BPW list
 */
export const getRegionBestPlacesToWorkPath = (
  domain: string,
  listType: BPWListType = 'overall',
  year: number = 0,
  updatedUrl: boolean = false
) => {
  const listTypeToPathPrefix: Record<BPWListType, string> = {
    overall: 'best-places-to-work',
    small: 'best-small-places-to-work',
    midsize: 'best-midsize-places-to-work',
    paying: 'best-paying-companies',
    benefits: 'companies-best-benefits',
  };
  let bestPlacesPath = '';
  if (updatedUrl) {
    bestPlacesPath = `${listTypeToPathPrefix[listType]}`;
    switch (domain) {
      case regionDomains[1]:
        bestPlacesPath = year
          ? `/awards/chicago/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-chicago`;
        break;
      case regionDomains[2]:
        bestPlacesPath = year
          ? `/awards/colorado/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-colorado`;
        break;
      case regionDomains[3]:
        bestPlacesPath = year
          ? `/awards/los-angeles/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-los_angeles`;
        break;
      case regionDomains[4]:
        bestPlacesPath = year
          ? `/awards/austin/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-austin`;
        break;
      case regionDomains[5]:
        bestPlacesPath = year
          ? `/awards/new-york-city/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-nyc`;
        break;
      case regionDomains[6]:
        bestPlacesPath = year
          ? `/awards/boston/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-boston`;
        break;
      case regionDomains[7]:
        bestPlacesPath = year
          ? `/awards/seattle/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-seattle`;
        break;
      case regionDomains[8]:
        bestPlacesPath = year
          ? `/awards/san-francisco/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-san_francisco`;
        break;
      default:
        bestPlacesPath = year
          ? `/awards/us/${year}/${bestPlacesPath}`
          : `/awards/${bestPlacesPath}-us`;
        break;
    }
    return bestPlacesPath;
  } else {
    bestPlacesPath = `/awards/${listTypeToPathPrefix[listType]}`;

    switch (domain) {
      case regionDomains[1]:
        bestPlacesPath = `${bestPlacesPath}-chicago`;
        break;
      case regionDomains[2]:
        bestPlacesPath = `${bestPlacesPath}-colorado`;
        break;
      case regionDomains[3]:
        bestPlacesPath = `${bestPlacesPath}-los_angeles`;
        break;
      case regionDomains[4]:
        bestPlacesPath = `${bestPlacesPath}-austin`;
        break;
      case regionDomains[5]:
        bestPlacesPath = `${bestPlacesPath}-nyc`;
        break;
      case regionDomains[6]:
        bestPlacesPath = `${bestPlacesPath}-boston`;
        break;
      case regionDomains[7]:
        bestPlacesPath = `${bestPlacesPath}-seattle`;
        break;
      case regionDomains[8]:
        bestPlacesPath = `${bestPlacesPath}-san_francisco`;
        break;
      default:
        break;
    }
    return year ? `${bestPlacesPath}-${year}` : bestPlacesPath;
  }
};
