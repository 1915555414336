export default {
  FRONTEND: '/frontend',
  COLORS: {
    0: 'all-jobs',
    146: 'finance',
    147: 'data',
    148: 'design',
    149: 'engineer',
    150: 'hr',
    151: 'internship',
    152: 'legal',
    153: 'marketing',
    154: 'operations',
    155: 'product',
    156: 'project-mgmt',
    157: 'sales',
    158: 'content',
  } as Record<number, string>,
  CTA_CATEGORIES: {
    147: 'Data Science',
    149: 'Dev + Engineer',
    155: 'Product',
    157: 'Sales',
  },
  MARKETSITE: 'market',
  MAINSITE: 'main',
  SHARE_FEEDBACK_URL:
    'https://docs.google.com/forms/d/e/1FAIpQLSfSt4XzHSWwOp_mBD8Tz1sY5X-f8VpCtrUKwyDErTHd3rVn9g/viewform?usp=sf_link',
  BI_FACEBOOK: 'https://facebook.com/builtinhq',
  BI_TWITTER: 'https://twitter.com/builtin',
  BI_LINKEDIN: 'https://www.linkedin.com/company/3763094',
  BI_INSTAGRAM: 'https://www.instagram.com/builtin/',
  ALL_JOBS_CATEGORY: -1,
  JOB_CATEGORIES: [
    { id: 147, name: 'Data + Analytics', type_id: 14 },
    { id: 148, name: 'Design + UX', type_id: 14 },
    { id: 149, name: 'Developer + Engineer', type_id: 14 },
    { id: 146, name: 'Finance', type_id: 14 },
    { id: 150, name: 'HR', type_id: 14 },
    { id: 151, name: 'Internships', type_id: 14 },
    { id: 152, name: 'Legal', type_id: 14 },
    { id: 153, name: 'Marketing', type_id: 14 },
    { id: 154, name: 'Operations', type_id: 14 },
    { id: 155, name: 'Product', type_id: 14 },
    { id: 156, name: 'Project Mgmt', type_id: 14 },
    { id: 157, name: 'Sales', type_id: 14 },
    { id: 158, name: 'Content', type_id: 14 },
  ],
  JOB_EXPERIENCES: [
    { id: 1, experience_level: 'Not Specified' },
    { id: 2, experience_level: 'Entry Level' },
    { id: 3, experience_level: 'Mid Level' },
    { id: 4, experience_level: 'Senior' },
  ],
  COMPANY_JOBS_PER_PAGE: 20,
  SELECT_ALL_CATEGORY: { id: 1000000000, name: 'Select All', type_id: 14 },
  LOCATIONS: {
    OTHER_US: 'Other US Location',
  },
  USER_EDIT_PROFILE_LINK: '/profile/edit',
  SUBSCRIPTIONS: {
    BASIC: 1,
    STANDARD: 2,
    PREMIUM: 3,
    ELITE: 4,
  },
  MARKETSITE_REGION_NAMES: {
    1: 'Chicago, IL',
    2: 'Colorado',
    3: 'Los Angeles, CA',
    4: 'Austin, TX',
    5: 'New York, NY',
    6: 'Boston, MA',
    7: 'Seattle, WA',
    8: 'San Francisco, CA',
  },
  // IHybridDetails onsiteTypeID
  ONSITE_TYPES: {
    NONE: 0,
    FLEXIBLE: 1,
    DAYS: 2,
    PERCENTAGE: 3,
  },
  // `ICompany` office types
  OFFICE_TYPES: {
    TRADITIONAL: 1,
    HYBRID: 2,
    FULLY_REMOTE: 3,
  },
  // Default JRS values (non-hybrid feature flag)
  JOBS_TYPES_JRS: {
    IN_OFFICE: 0,
    REMOTE_ONLY: 1,
    IN_OFFICE_AND_REMOTE: 2,
  },
  // Hybrid JRS feature flag values
  HYBRID_JOBS_TYPES_JRS: {
    IN_OFFICE: 1,
    REMOTE: 2,
    HYBRID: 3,
  },
  NOINDEX: {
    COMPANIES: '5',
    COMPANY: '6',
  },
  NOINDEXCOURSE: {
    COURSES: '5',
  },
  CLOUDFLARE_IMG_QUALITY: 80, // Cloudflare's default is 85
  KNOWLEDGEBASE_SUPPORT_URL: 'https://knowledgebase.builtin.com/s/contactsupport',
  KNOWLEDGEBASE_URL: 'https://knowledgebase.builtin.com/s/',
  ATS_TYPES: {
    GREENHOUSE: 'greenhouse',
    ASPENTECH: 'aspentech',
    LEVER: 'lever',
  },
};

/**
 * open to relocating option
 */
export enum RelocationType {
  PaidByCompany = 1, //  Only if the company pays for user relocation ,
  PaidByUser = 2, // User willing to pay for relocation costs
}

/**
 * Reasons that a company is included in a blog/article.
 * Corresponds to IArticle.Companies.Reason.
 * Matches dropdown in Drupal, found in BixGlobalMisc.php.
 */
export enum ReasonsForInclusion {
  ContractRoundupInclusion = 1,
  ContractEmployerStory = 2,
  ContractInsiderSpotlight = 3,
  ContractSponsorship = 4,
  CommunityPremium = 5,
  CommunityNonPremium = 6,
  SEOPremium = 7,
  SEONonPremium = 8,
  AMOverDeliverRequest = 9,
  SalesLeadGenRequest = 10,
  SalesCatalystToClostRequest = 11,
  ContractInsiderVideo = 12,
  ContractLaunchPartner = 13,
  SEOLegacyContentException = 14,
}
