















import { defineComponent } from '@nuxtjs/composition-api';

/**
 * Page layout with top nav/mobile bottom nav but no footer
 */
export default defineComponent({});
