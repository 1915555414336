import { render, staticRenderFns } from "./UserUnAuthMobile.vue?vue&type=template&id=8b1aaaca&scoped=true&"
import script from "./UserUnAuthMobile.vue?vue&type=script&lang=ts&"
export * from "./UserUnAuthMobile.vue?vue&type=script&lang=ts&"
import style0 from "./UserUnAuthMobile.vue?vue&type=style&index=0&id=8b1aaaca&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b1aaaca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SignButtons: require('/root/project/frontend/src/components/navigation/SignButtons.vue').default})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
