









































import {
  computed,
  defineComponent,
  onUnmounted,
  reactive,
  toRefs,
  watch,
} from '@nuxtjs/composition-api';

import { useNamespacedActions, useNamespacedGetters } from 'vuex-composition-helpers';

import {
  namespace as onboardingNamespace,
  OnboardingGetters,
  OnboardingGettersObject,
} from '@/store/onboarding/types';
import regions from '@/api/regions';
import { userModule } from '@/utils/store-accessor';

import { UserGetters } from '@/store/modules/user';
import { CompanyActions, namespace } from '@/store/modules/company';
import { ICompany } from '@/typings/api/external-api-gql';

export default defineComponent({
  name: 'UserProfileInfoMobile',
  setup() {
    const {
      fullName: newFullName,
      userPhoto: oldAvatar,
      userFullName: oldFullName,
      userPosition,
    } = useNamespacedGetters<OnboardingGetters>(onboardingNamespace, [
      'fullName',
      'userPhoto',
      'userFullName',
      OnboardingGettersObject.userPosition,
    ]);

    const { getPermissionsForRegion } = useNamespacedGetters<UserGetters>('UserModule', [
      'getPermissionsForRegion',
    ]);

    const { fetchCompanyOverview } = useNamespacedActions<CompanyActions>(namespace, [
      'fetchCompanyOverview',
    ]);
    const regionId = regions.getCurrentRegionID();
    const permissions = computed(() => getPermissionsForRegion.value(regionId));

    const firstCompanyAdmin = computed(
      () =>
        permissions.value.find(
          (perm) => perm && perm.locationId === regionId && perm.companyId != null
        )?.companyId
    );

    const state = reactive({
      overview: undefined as ICompany | undefined,
      isLoading: true,
      drupalCompanyId: undefined as number | undefined,
    });

    const companyTitle = computed(() => state.overview?.name || '');
    const companyPhoto = computed(() => {
      if (state.overview) {
        return {
          url: state.overview.logo || '',
          region_id: regions.getRegionID(state.overview.regionName),
        };
      }
      return {
        url: '',
        region_id: regions.getCurrentRegionID(),
      };
    });

    const companyWatcher = watch(
      () => firstCompanyAdmin,
      async (companyId) => {
        if (companyId.value == null) {
          return;
        }
        const result = (await fetchCompanyOverview({ id: companyId.value })) as ICompany;
        if (result?.id) {
          state.isLoading = false;
          state.overview = result;
          state.drupalCompanyId = result.marketSites[0].companyID || 0;
        }
      },
      {
        immediate: true,
      }
    );

    const avatar = computed(() => {
      return userModule.profile?.picture || oldAvatar.value;
    });

    onUnmounted(() => {
      companyWatcher();
    });

    const fullName = computed(() => newFullName.value || oldFullName.value);
    return {
      ...toRefs(state),
      fullName,
      avatar,
      userPosition,
      regionId,
      permissions,
      companyPhoto,
      companyTitle,
    };
  },
});
