





















import { defineComponent } from '@nuxtjs/composition-api';
import appVars from '@/enums/appVars';

export default defineComponent({
  name: 'GoogleOneTap',
  props: {
    newUserReturnUrl: {
      type: String,
      required: true,
    },
    existingUserReturnUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    const loginUri = appVars.IdPAuthority + '/External/OneTap';
    const clientId = appVars.GoogleClientId;
    return {
      loginUri,
      clientId,
    };
  },
});
