import { Context } from '@nuxt/types';

import { userModule } from '@/store';
import { UserRoles } from '@/enums/userRoles';

export const requirePermissionsMiddleware = (...perms: (string | UserRoles)[]) => {
  return (ctx: Context) => {
    if (!userModule.profile?.permissions?.some((perm) => perms.includes(perm.name))) {
      ctx.redirect('/');
    }
  };
};
