import { GetOptions } from '@/api/fetch/fetch.types';

/**
 * The fetch API will return a resolved promise even if the server returns
 * a status code that's >=400. ProcessResponse is used to intercept and read status codes
 * in order to propagate the approriate response.
 */
const processResponse = async (rsp: Response): Promise<any> => {
  if (rsp.ok) {
    switch (rsp.headers.get('content-type')) {
      case 'text/plain':
      case 'text/html':
        return rsp.text();
      case 'application/json':
      default:
        return rsp.json();
    }
  } else if (rsp && rsp.status === 409) {
    /* BUILT-599: Special Edge case here where our API will return a 409 status code if the vue app
     * is out ot date with the external API due to weird caching errors.
     */
    window.location.reload();
  } else {
    const payload = await (rsp.headers.get('content-type') === 'application/json'
      ? rsp.json()
      : rsp.text());
    const { status, statusText } = rsp;
    return Promise.reject({ status, statusText, ...payload });
  }
};

export const get = async (url: string, options?: GetOptions): Promise<any> => {
  let urlWithParams = url;

  if (options?.params) {
    const urlParams = new URLSearchParams();

    for (const key in options.params) {
      if (options.params.hasOwnProperty(key)) {
        // @ts-ignore
        const value = options.params[key];

        if (typeof value === 'boolean') {
          urlParams.append(key, value.toString());
        } else {
          urlParams.append(key, value);
        }
      }
    }

    urlWithParams += `?${urlParams.toString()}`;
  }

  try {
    const response = await fetch(urlWithParams, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return processResponse(response);
  } catch (err: any) {
    throw Error(err);
  }
};

export const post = async (url: string, body: Object): Promise<any> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return processResponse(response);
  } catch (err: any) {
    throw Error(err);
  }
};
