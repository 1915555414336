import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import { CompanyModule, JobsModule } from '@/store/modules';
import { genModuleAccessor, ExtractGetterTypes, ExtractTypes } from './vuex-shim';
import UserModule, {
  State as UserState,
  UserGetters,
  UserActions,
  UserNamespace,
} from '@/store/modules/user';
import {
  State as CompanyState,
  CompanyGetters,
  CompanyActions,
  namespace as companyNamespace,
} from '@/store/modules/company';

let companyModule: CompanyState & ExtractGetterTypes<CompanyGetters> & ExtractTypes<CompanyActions>;
let jobsModule: JobsModule;
let userModule: UserState & ExtractGetterTypes<UserGetters> & ExtractTypes<UserActions>;

/**
 * Initializes the fully typed module accessors
 * @param store - The current store the modules will be bound to
 */
function initializeStore(store: Store<any>): void {
  companyModule = genModuleAccessor<CompanyState, CompanyGetters, CompanyActions, unknown>(
    store,
    CompanyModule,
    companyNamespace
  );
  jobsModule = getModule(JobsModule, store);
  userModule = genModuleAccessor<UserState, UserGetters, UserActions, unknown>(
    store,
    UserModule,
    UserNamespace
  );
}

export {
  initializeStore as initializeStores,
  /** The fully typed company module */
  companyModule,
  /** The fully typed jobs module */
  jobsModule,
  /** The fully typed user module */
  userModule,
};
