import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=dc307100&scoped=true&"
import script from "./Search.vue?vue&type=script&lang=ts&"
export * from "./Search.vue?vue&type=script&lang=ts&"
import style0 from "./Search.vue?vue&type=style&index=0&id=dc307100&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc307100",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BixLink: require('/root/project/frontend/src/components/BixLink.vue').default,CompanySearchResult: require('/root/project/frontend/src/components/CompanySearchResult.vue').default,BixImg: require('/root/project/frontend/src/components/images/BixImg.vue').default,BixLink: require('/root/project/frontend/src/components/BixLink.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
