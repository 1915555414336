import { Context } from '@nuxt/types';
import { ActionNames as Onboarding } from '@/store/onboarding/types';

import { userModule } from '@/utils/store-accessor';
import { removeQueryParam } from '@/utils/http';
import regionUtils from '@/api/regions';
import bixAxios from '@/api/bixAxios';

/**
 * syncDrupalAuth handles cases where a user is logged into the
 * vue app but not the drupal app
 */
const syncDrupalAuth = async () => {
  const regionUri = regionUtils.getRegionBaseURL();
  let drupalStatus = '';

  try {
    const { profile } = userModule;
    const response = await bixAxios.get(`${regionUri}/drupal-api/sync-auth/${profile?.subjectId}`);
    drupalStatus = response.data;

    if (drupalStatus === 'logged-out') {
      const { pathname, search } = window.location;
      const destination = encodeURIComponent(`${pathname}${search}`);

      window.location.href = `/bilogin?destination=${destination}`;
    }
  } catch (err: any) {
    if (
      [404, 403].includes(err.response.status) &&
      ['not-found', 'blocked'].includes(err.response.data)
    ) {
      await userModule.signout();
    }
    console.error(err);
  }
};

/**
 * Saves the authenticated user to the store when loading the application.
 *
 * This dispatches the user profile fetch as well to preserve existing
 * load behaviors from legacy auth preload_user_profile plugin
 */
export default async ({ app, route, store }: Context) => {
  try {
    if (!userModule.isAuthenticated && route.name != 'login') {
      const success = await userModule.authenticateCallback();

      if (success) {
        await syncDrupalAuth();
        store.dispatch(Onboarding.userProfile.action());
        return;
      }

      if (app.routeState.isAuthenticated) {
        const payload = {
          redirectUrl: removeQueryParam(route.fullPath, 'state'),
          useSignup: false,
        };
        await userModule.authenticate(payload);
        store.dispatch(Onboarding.userProfile.action());
      }
    }
  } catch (err) {
    console.error('Failed to initialize authentication', err);
  }
};
