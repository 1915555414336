import Vue from 'vue';
import { Context } from '@nuxt/types/app';
import VueApollo from 'vue-apollo';
import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { setContext } from 'apollo-link-context';
import appVars from '@/enums/appVars';
import { userModule } from '@/utils/store-accessor';
import ExternalApis from '@/enums/externalApiRoutesEnum';

export const getDefaultClient = (): ApolloClient<NormalizedCacheObject> =>
  Vue.prototype.$apolloProvider.defaultClient;

export const getAuthenticatedClient = (): ApolloClient<NormalizedCacheObject> =>
  Vue.prototype.$apolloProvider.clients.authenticated;

export const getIdentityClient = (): ApolloClient<NormalizedCacheObject> =>
  Vue.prototype.$apolloProvider.clients.identity;

export const getLearningLabGqlClient = (): ApolloClient<NormalizedCacheObject> =>
  Vue.prototype.$apolloProvider.clients.learningLab;

export default (ctx: Context) => {
  const { app } = ctx;

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: userModule.authHeader,
      },
    };
  });

  const httpExternalApiLink = new HttpLink({
    uri: `${appVars.ExternalApi}/graphql`,
  });

  const httpIdentityLink = new HttpLink({
    uri: `${appVars.IdPAuthority}/graphql`,
    credentials: 'include',
  });

  const httpLearningLabLink = new HttpLink({
    uri: `${ExternalApis.CourseGqlApi}`,
  });

  const cache = new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          companyByID: {
            merge: true,
          },
        },
      },
    },
  });

  app.apolloProvider = new VueApollo({
    defaultClient: new ApolloClient({
      link: httpExternalApiLink,
      cache,
    }),
    clients: {
      authenticated: new ApolloClient({
        link: authLink.concat(httpExternalApiLink as any) as any,
        cache,
      }),
      identity: new ApolloClient({
        link: authLink.concat(httpIdentityLink as any) as any,
        cache: new InMemoryCache({
          addTypename: false,
        }),
      }),
      learningLab: new ApolloClient({
        link: httpLearningLabLink,
        cache: new InMemoryCache({
          addTypename: false,
        }),
      }),
    },
  });
  Vue.prototype.$apolloProvider = app.apolloProvider;
};
