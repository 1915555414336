import AppVars from '@/enums/appVars';

const generateSessionId = (val?: any) =>
  val
    ? (val ^ ((Math.random() * 16) >> (val / 4))).toString(16)
    : '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, generateSessionId);

const THIRTY_MINUTES = 30 * 60 * 1000; /* ms */

const getCurrentSessionId = () => {
  const raw = window.localStorage.getItem(AppVars.UserSessionIdKey);
  if (raw) {
    try {
      const { sessionId, timestamp } = JSON.parse(raw);
      const date = new Date(timestamp);
      if (new Date().getTime() - date.getTime() < THIRTY_MINUTES && sessionId) {
        return sessionId;
      }
    } catch (_e) {
      // nothing to do
    }
  }
  return generateSessionId();
};

export interface UserSessionIdService {
  getValidSessionId(userSessionIdentifier?: string): string;
}

export const userSessionIdService = {
  getValidSessionId(userSessionIdentifier?: string) {
    const sessionId = userSessionIdentifier || getCurrentSessionId();
    const timestamp = new Date();
    window.localStorage.setItem(
      AppVars.UserSessionIdKey,
      JSON.stringify({
        sessionId,
        timestamp,
      })
    );
    return sessionId;
  },
};
