export interface State {
  message: string;
  customEmail: string;
  useCache: string;
}

export enum ActionNames {
  sendContentEmail = 'sendContentEmail',
  sendContentGroupEmail = 'sendContentGroupEmail',
  sendContentAllCombinationsEmail = 'sendContentAllCombinationsEmail',
}

export enum MutationNames {
  setEmailMessage = 'setEmailMessage',
  updateEmailCache = 'updateEmailCache',
}
