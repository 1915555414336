import { MutationTree, ActionTree, ActionContext } from 'vuex/types/index';
import { State as OnboardingState } from './onboarding/types';
import { State as HomepageState } from './homepage/types';
import { State as UserState } from './modules/user';

export interface RootState {
  /**
   * `currentSite` is the SSR-safe equivalent of the browser-only `window.location` object and should
   *  be used instead of invoking `window.location` as there is no `window` object when on the server.
   */
  currentSite: {
    host: string;
    initialPath: string;
  };
  regions: Region[];
  nationalRegions: Region[];
  currentRegion: Region;
  history: History[];
  countries: Country[];
  loading: string[];
  loadingOverlay: string[];
  nationalNavbarCollapsed: false; //determines if sub-nav is hidden when scrolling
  // Modules
  onboarding?: OnboardingState;
  homepage?: HomepageState;
  UserModule?: UserState;
}

export interface RootMutations extends MutationTree<RootState> {
  setCurrentRegion: (state: RootState, id: number) => void;
}

export interface RootActions extends ActionTree<RootState, RootState> {
  getRegions: (ctx: ActionContext<RootState, RootState>) => Promise<void> | undefined;
  getNationalRegions: (ctx: ActionContext<RootState, RootState>) => Promise<void> | undefined;
  getAutocompleteCountries: (ctx: ActionContext<RootState, RootState>) => Promise<void>;
}

export interface Region {
  id: number;
  name: string;
  state: string;
  code: string;
  url: string;
  country: string;
  twitter: string;
  linked_in: string;
  facebook: string;
  created_at: string;
  updated_at: string;
  locations: Location[];
  timezone_name: string;
  site_name: string;
  alias: string;
}

export interface Location {
  id: number;
  name: string;
  region_id: number;
  alias: string;
  seo_name: string;
  taxonomy_id: number;
}

export interface Country {
  name: string;
  states: State[];
}

export interface State {
  name: string;
}

export interface Metatag {
  id: number;
  type: string;
  content_id: number;
  value: string;
}

export interface BasicMetatag {
  title: string;
  description: string;
}

export enum RootActionNames {
  setCurrentSiteHost = 'setCurrentSiteHost',
  setCurrentSiteInitialPath = 'setCurrentSiteInitialPath',
  getRegions = 'getRegions',
  setRegions = 'setRegions',
  getNationalRegions = 'getNationalRegions',
  setNationalRegions = 'setNationalRegions',
  setCurrentRegion = 'setCurrentRegion',
  setHistory = 'setHistory',
  removeHistory = 'removeHistory',
  getAutocompleteCountries = 'getAutocompleteCountries',
  setAutocompleteCountries = 'setAutocompleteCountries',
  loadingAdd = 'loadingAdd',
  loadingOverlayAdd = 'loadingOverlayAdd',
  loadingRemove = 'loadingRemove',
  loadingOverlayRemove = 'loadingOverlayRemove',
  nationalNavbarCollapse = 'nationalNavbarCollapse',
}

export interface AuthenticationResponse {
  bearer_token: string;
}

export interface AutocompleteCountriesResponse {
  countries: Country[];
}

export interface History {
  name: string;
  params?: string;
}

export interface regionsRequestParams {
  national?: boolean;
}
