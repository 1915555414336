import { Context } from '@nuxt/types';
import { isNational } from '@/api/regions';
import { userModule } from '@/utils/store-accessor';
import { RegistrationSource } from '@/api/types';
import onboarding from '@/api/onboarding';

export default async ({ redirect, route, store }: Context) => {
  if (!userModule.isAuthenticated || isNational()) {
    return;
  }

  const {
    profile: { onboarded, onboarding_complete, registration_source },
  } = store.state.onboarding;

  let skipOnboarding = false;
  if (!onboarding_complete && localStorage && localStorage.getItem('skipOnboarding') === 'true') {
    const noop = () => {};
    try {
      //update main user data.
      await onboarding.stepPost({ step: 'step0' }, noop);
      localStorage.removeItem('skipOnboarding');
    } catch (e) {
      console.error(e);
    }
    return;
  }

  if (
    registration_source === RegistrationSource.goBackend &&
    onboarded === 1 &&
    !onboarding_complete &&
    !skipOnboarding
  ) {
    const destination = encodeURIComponent(route.fullPath);
    return redirect('/onboarding', { destination });
  }
};
