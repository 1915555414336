import { IModifyProfileInput, ISendOneTimeLoginEmailInputType } from '@/typings/api/identity-gql';
import { OperationVariables } from '@apollo/client/core';

/** GraphQL query to get the current user profile */
export { default as GetUserProfile } from './query-getUserProfile.gql';
/** GraphQL query to get the current user profile */
export { default as GetUserResume } from './query-getUserResume.gql';
/** GraphQL mutation to update the user profile on identity */
export { default as UpdateIdentityProfile } from './mutation-updateIdentityProfile.gql';
/** GraphQL mutation to update the user profile on identity */
export { default as UpdateUserRoles } from './mutation-updateUserRoles.gql';
/** GraphQL mutation to update the user profile on identity */
export { default as GetIdpProfile } from './query-getIdpProfile.gql';
/** GraphQL mutation to send a one time login email */
export { default as SendOneTimeLogin } from './mutation-sendOneTimeLogin.gql';
/** GraphQL query to look up a users subject id by email address */
export { default as LookupSubjectId } from './query-lookupSubjectId.gql';
/** GraphQL query to look up a users principal by email address */
export { default as GetPrincipalByEmail } from './query-getPrincipalByEmail.gql';
/** GraphQL query to fetch an antiforgery token to use in identity requests */
export { default as GetIdpAntiForgery } from './query-getIdpAntiForgery.gql';
/** GraphQL query to get user prinicals related to a company */
export { default as GetCompanyPrincipals } from './query-getCompanyPrincipals.gql';
/** GraphQL mutation to create a company administrator user */
export { default as CreateAdminUser } from './mutation-createAdminUser.gql';

/** Query parameters for getting the user profile */
export interface GetUserProfileParams extends OperationVariables {
  /** The regionId of the user, required for drupalId */
  regionId: number;
}

/** Mutation parameters for updating the users identtiy profile */
export interface UpdateIdentityProfileParams extends OperationVariables {
  /** The user profile modifications */
  profile: IModifyProfileInput;
}

export interface SendOneTimeLoginParams extends OperationVariables {
  email: ISendOneTimeLoginEmailInputType;
}

export interface LookupSubjectIdParams extends OperationVariables {
  email: string;
}

export interface LookupPrincipalParams extends OperationVariables {
  email: string;
}
