import { Module } from 'vuex';

import { getDefaultClient } from '@/plugins/vueApollo';
import { GetCompanySubscription, GetCompanyByIdParams, GetCompanyOverview } from '@/graphql';
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex/types/index';
import { IQuery, ICompany } from '@/typings/api/external-api-gql';
import { RootState } from '../types';
interface Dictionary<T> {
  [key: number]: T;
}
export const namespace = 'CompanyModule';

/** The state structure of CompanyModule */
export interface State {
  companies: Dictionary<ICompany>;
  currentCompanyId?: number;
}

export interface CompanyActions extends ActionTree<State, RootState> {
  fetchCompanySubscription: (
    ctx: ActionContext<State, RootState>,
    params: GetCompanyByIdParams
  ) => Promise<any>;
  fetchCompanyOverview: (
    ctx: ActionContext<State, RootState>,
    params: GetCompanyByIdParams
  ) => Promise<ICompany | undefined>;
}

export interface CompanyGetters extends GetterTree<State, RootState> {
  currentCompany: (state: State) => ICompany | null;
  hasFetchedSubscription: (state: State) => boolean;
  subscription: (state: State) => {
    isBasic: boolean;
    isStandard: boolean;
    isPremium: boolean;
    isElite: boolean;
  };
}

export interface CompanyMutations extends MutationTree<State> {}
export interface CompanyMutationsPrivate extends CompanyMutations {
  updateCompanySubscription: (state: State, company?: ICompany) => void;
  updateCompanyOverview: (state: State, company?: ICompany) => void;
}

const setSelectedCompany = (state: State, companyId: number) => {
  state.currentCompanyId = -1;
  state.currentCompanyId = companyId;
};

const checkSubscriptionLevel = (company: ICompany | undefined, level: string) =>
  company?.subscription?.level.toLowerCase() === level.toLowerCase();

export const stateFactory: () => State = () => ({
  companies: {},
  currentCompanyId: undefined,
});

const getters: CompanyGetters = {
  currentCompany: (state) =>
    state.currentCompanyId !== undefined ? state.companies[state.currentCompanyId] : null,
  hasFetchedSubscription: (state) =>
    state.companies[state.currentCompanyId || -1]?.subscription?.level !== undefined,
  subscription: (state) => ({
    isBasic: checkSubscriptionLevel(state.companies[state.currentCompanyId || -1], 'Basic'),
    isStandard: checkSubscriptionLevel(state.companies[state.currentCompanyId || -1], 'Standard'),
    isPremium: checkSubscriptionLevel(state.companies[state.currentCompanyId || -1], 'Premium'),
    isElite: checkSubscriptionLevel(state.companies[state.currentCompanyId || -1], 'Elite'),
  }),
};

const mutations: CompanyMutationsPrivate = {
  updateCompanySubscription: (state, company) => {
    if (!company?.subscription?.level) {
      return;
    }

    if (state.companies[company.id]) {
      state.companies[company.id].subscription = company.subscription;
    } else {
      state.companies[company.id] = company;
    }
    setSelectedCompany(state, company.id);
  },
  updateCompanyOverview: (state, company) => {
    if (!company) {
      return;
    }

    state.companies[company.id] = company;

    setSelectedCompany(state, company.id);
  },
};

const actions: CompanyActions = {
  fetchCompanySubscription: async (ctx, params) => {
    if (!params.id) {
      return;
    }

    try {
      const client = getDefaultClient();
      const results = await client.query<IQuery, GetCompanyByIdParams>({
        query: GetCompanySubscription,
        variables: params,
      });

      ctx.commit('updateCompanySubscription', results.data?.companyByID);

      return results.data?.companyByID;
    } catch (err) {
      return err;
    }
  },
  fetchCompanyOverview: async (ctx, params) => {
    if (!params.id) {
      return;
    }

    const client = getDefaultClient();
    const results = await client.query<IQuery, GetCompanyByIdParams>({
      query: GetCompanyOverview,
      variables: params,
      errorPolicy: 'all',
    });

    ctx.commit('updateCompanyOverview', results.data?.companyByID);
    return results.data?.companyByID;
  },
};

export default {
  namespaced: true,
  state: stateFactory,
  getters,
  mutations,
  actions,
} as Module<State, RootState>;
