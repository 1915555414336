import { regionDomains } from '@/enums/appVars';
import { memoize } from '@/utils/memoize';

export const BI_REGION_ID: number = 1000000000;
export const BI2_REGION_ID: number = 9;

/**
 * Hostname and port (if non-standard), e.g. builtinseattle.com:8888
 */
export const getRegionHost = () => window.location.host;
/**
 * Hostname only (no port), e.g. builtinseattle.com
 */
export const getRegionHostName = () => window.location.hostname;

const getChicagoID = () => 1;
const getColoradoID = () => 2;
const getLosAngelesID = () => 3;
const getAustinID = () => 4;
const getNYCID = () => 5;
const getBostonID = () => 6;
const getSeattleID = () => 7;
const getSanFranciscoID = () => 8;
export const getNationalDotBuiltinComId = () => BI2_REGION_ID;
export const getBuiltinDotComId = () => BI_REGION_ID;
export const getNationalID = () => {
  switch (getRegionHostName()) {
    case regionDomains[BI_REGION_ID]:
      return getBuiltinDotComId();
    case regionDomains[BI2_REGION_ID]:
      return getNationalDotBuiltinComId();
  }
  return 0;
};

/**
 * Obtain the Built In region id for a given Built In domain name. This is aware of the current
 * environment, e.g. local/develop/staging/production.
 *
 * @param domain - the Built In domain name from which a region identifier should be calculated
 */
const getRegionID = (domain: string) => {
  // look up region ID from domain name
  // 'chicago'     => 1
  // 'colorado'    => 2
  // 'los_angeles' => 3
  // 'austin'      => 4
  // 'nyc'         => 5
  // 'boston'      => 6
  // 'seattle'     => 7
  // 'sf'          => 8
  let regionId = getNationalID();

  switch (domain) {
    case regionDomains[1]:
      regionId = getChicagoID();
      break;
    case regionDomains[2]:
      regionId = getColoradoID();
      break;
    case regionDomains[3]:
      regionId = getLosAngelesID();
      break;
    case regionDomains[4]:
      regionId = getAustinID();
      break;
    case regionDomains[5]:
      regionId = getNYCID();
      break;
    case regionDomains[6]:
      regionId = getBostonID();
      break;
    case regionDomains[7]:
      regionId = getSeattleID();
      break;
    case regionDomains[8]:
      regionId = getSanFranciscoID();
      break;
  }

  return regionId;
};

/**
 * Check if regionID provided is a marketID
 *
 * @param id - region id
 */
export const isMarketID = (id: number) => {
  return id > 0 && id <= getSanFranciscoID();
};

/**
 * Get a user-friendly region name from a Built In domain name
 *
 * @param domain - the domain name from which the user-friendly region name is derived
 */
export const getRegionName = (domain: string) => {
  let regionName = '';

  switch (domain) {
    case regionDomains[1]:
      regionName = 'Chicago';
      break;
    case regionDomains[2]:
      regionName = 'Colorado';
      break;
    case regionDomains[3]:
      regionName = 'Los Angeles';
      break;
    case regionDomains[4]:
      regionName = 'Austin';
      break;
    case regionDomains[5]:
      regionName = 'NYC';
      break;
    case regionDomains[6]:
      regionName = 'Boston';
      break;
    case regionDomains[7]:
      regionName = 'Seattle';
      break;
    case regionDomains[8]:
      regionName = 'San Francisco';
      break;
    case regionDomains[9]:
      regionName = 'National';
      break;
    default:
      break;
  }

  return regionName;
};

/**
 * Get a user-friendly and possibly abbreviated region name from a Built In domain name
 *
 * @param domain - the domain name from which the user-friendly region name is derived
 */
export const getRegionShortName = (domain: string) => {
  let regionName = '';

  switch (domain) {
    case regionDomains[1]:
      regionName = 'Chicago';
      break;
    case regionDomains[2]:
      regionName = 'Colorado';
      break;
    case regionDomains[3]:
      regionName = 'LA';
      break;
    case regionDomains[4]:
      regionName = 'Austin';
      break;
    case regionDomains[5]:
      regionName = 'NYC';
      break;
    case regionDomains[6]:
      regionName = 'Boston';
      break;
    case regionDomains[7]:
      regionName = 'Seattle';
      break;
    case regionDomains[8]:
      regionName = 'SF';
      break;
    case regionDomains[9]:
      regionName = 'National';
      break;
    default:
      break;
  }

  return regionName;
};

/**
 * Get a SEO-friendly region name from a Built In domain name
 *
 * @param domain - the domain name from which the user-friendly region name is derived
 */
export const getRegionSeoName = (domain: string) => {
  let regionName = '';

  switch (domain) {
    case regionDomains[1]:
      regionName = 'Chicago';
      break;
    case regionDomains[2]:
      regionName = 'Colorado';
      break;
    case regionDomains[3]:
      regionName = 'LA';
      break;
    case regionDomains[4]:
      regionName = 'Austin';
      break;
    case regionDomains[5]:
      regionName = 'NYC';
      break;
    case regionDomains[6]:
      regionName = 'Boston';
      break;
    case regionDomains[7]:
      regionName = 'Seattle';
      break;
    case regionDomains[8]:
      regionName = 'San Francisco';
      break;
    case regionDomains[9]:
      regionName = 'National';
      break;
    default:
      break;
  }

  return regionName;
};

/**
 * Obtain a user-friendly region abbreviaton from a Built In Domain Name
 *
 * @param domain - the Built In domain name from which a region identifier should be calculated
 */
export const getRegionAbbreviation = (domain: string) => {
  switch (domain) {
    case regionDomains[1]:
      return 'CHI';
    case regionDomains[2]:
      return 'CO';
    case regionDomains[3]:
      return 'LA';
    case regionDomains[4]:
      return 'ATX';
    case regionDomains[5]:
      return 'NYC';
    case regionDomains[6]:
      return 'BOS';
    case regionDomains[7]:
      return 'SEA';
    case regionDomains[8]:
      return 'SF';
    case regionDomains[9]:
      return 'National';
    default:
      return '';
  }
};

/**
 * A list of all regions
 */
export const getAllRegions = memoize(() =>
  Object.keys(regionDomains)
    .filter((r) => +r !== BI_REGION_ID && +r !== BI2_REGION_ID)
    .map((r) => ({
      id: +r,
      domain: regionDomains[+r],
      shortName: getRegionShortName(regionDomains[+r]),
      name: getRegionName(regionDomains[+r]),
    }))
);

/**
 * Pathname, e.g. /jobs/dev-engineering
 */
export const getCurrentPath = () => window.location.pathname;
/**
 * Get the base url from the current domain name - this is environment aware
 * @memoized
 */
export const getRegionBaseURL = memoize(() => `https://${getRegionHost()}`);
/**
 * Get the current region id from the host name - this is environment aware
 * @memoized
 */
export const getCurrentRegionID = memoize(() => getRegionID(getRegionHostName()));
/**
 * Get the current region name from the host name - this is environment aware
 * @memoized
 */
export const getCurrentRegionName = memoize(() => getRegionName(getRegionHostName()));
/**
 * Get the current region short name from the host name - this is environment aware
 * @memoized
 */
export const getCurrentRegionShortName = memoize(() => getRegionShortName(getRegionHostName()));
/**
 * Get the current region SEO name from the host name - this is environment aware
 * @memoized
 */
export const getCurrentRegionSeoName = memoize(() => getRegionSeoName(getRegionHostName()));
/**
 * Check if the current host is a Built In market site or national site
 * @memoized
 */
export const isMarket = memoize(() => getCurrentRegionID() != getNationalID());
/**
 * Check if the current host is a Built In site or national site
 * @memoized
 */
export const isMain = memoize(() => BI_REGION_ID === getNationalID());
/**
 * Check if the current host is a Built In national site or national site
 * @memoized
 */
export const isNational = memoize(() => BI2_REGION_ID === getNationalID());

/**
 * Obtain the Built In domain name, with awareness of local/develop/staging/production environment,
 * for a given region_id, such as those present on a company record. Defaults to the national site
 * if no region is specified or no matching region domain is found.
 *
 * @param regionId - The region_id from which the corresponding Built In domain name is calculated
 */
export const getRegionDomain = (regionId: number) => {
  return regionDomains[regionId] || regionDomains[getNationalID()];
};

/**
 * Return the canonical `regionId` for a given region using any of its "name synonyms" to look it up
 * (with a case-insensitive check for string equality). If no match is found, the Built In National
 * `regionId` will be returned.
 *
 * For example, in various data "New York City" as a Built In market region is referred to as
 * "New York", "New York City", and "NYC". This method will return the region id for the NYC market
 * for *any* of these known "synonyms" of the region name.
 *
 * @param regionNameSynonym - a name by which a region is referred
 *
 */
export const regionIdFromNameSynomym = (regionNameSynonym: string) => {
  const regions = [
    { regionId: 1, synonyms: ['chicago', 'chi'] },
    { regionId: 2, synonyms: ['colorado', 'co'] },
    { regionId: 3, synonyms: ['los angeles', 'los_angeles', 'la'] },
    { regionId: 4, synonyms: ['austin', 'atx'] },
    { regionId: 5, synonyms: ['new york', 'new_york', 'new york city', 'new_york_city', 'nyc'] },
    { regionId: 6, synonyms: ['boston', 'bos'] },
    { regionId: 7, synonyms: ['seattle', 'sea'] },
    { regionId: 8, synonyms: ['san francisco', 'san_francisco', 'sf'] },
    { regionId: 9, synonyms: ['nationwide', 'national'] },
  ];

  const region = regions.find((r) =>
    r.synonyms.some((s) => s === `${regionNameSynonym}`.toLocaleLowerCase())
  );

  return region?.regionId || getNationalID();
};

export default {
  getAustinID,
  getBostonID,
  getChicagoID,
  getColoradoID,
  getCurrentRegionID,
  getCurrentRegionName,
  getCurrentRegionShortName,
  getCurrentRegionSeoName,
  getAllRegions,
  getBuiltinDotComId,
  getCurrentPath,
  getLosAngelesID,
  getNationalDotBuiltinComId,
  getNationalID,
  getNYCID,
  getRegionBaseURL,
  getRegionHost,
  getRegionHostName,
  getRegionID,
  getRegionName,
  getRegionShortName,
  getRegionAbbreviation,
  getRegionDomain,
  getSanFranciscoID,
  getSeattleID,
  isMarket,
  isMain,
  isNational,
  isMarketID,
  regionIdFromNameSynomym,
};
