import regions from '../api/regions';
import { gtmCodes } from '@/enums/appVars';

declare global {
  interface Window {
    dataLayer: any;
    ga: UniversalAnalytics.ga | undefined;
  }
}

window.dataLayer = window.dataLayer || [];

function shouldLoadGTM() {
  const baseUrl: string | undefined = process.env.BASE_URL;
  return (
    baseUrl &&
    (baseUrl.includes('frontendstg.builtin.com') ||
      baseUrl.includes('frontenddev.builtin.com') ||
      baseUrl.includes('frontend.builtin.com'))
  );
}

function getGTMCode() {
  const rid = regions.getCurrentRegionID();
  return gtmCodes[rid] || '';
}

function writeGAGTMHeadScript(code: string) {
  const gaScript: string = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${code}');`;

  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.async = true;
    script.text = gaScript;
    script.charset = 'utf-8';

    head.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
  });
}

function writeGAGTMBodyScript(code: string) {
  const gaScript: string = `<iframe src="https://www.googletagmanager.com/ns.html?id=${code}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  return new Promise((resolve, reject) => {
    const body = document.body || document.getElementsByTagName('body')[0];
    const app = document.getElementById('app');
    const script = document.createElement('noscript');
    script.innerHTML = gaScript;

    body.insertBefore(script, app);

    script.onload = resolve;
    script.onerror = reject;
  });
}

export default {
  install() {
    if (shouldLoadGTM()) {
      const gtmCode = getGTMCode();

      if (gtmCode) {
        writeGAGTMHeadScript(gtmCode)
          .then(() => {
            console.info('ga GTM head loaded!'); // eslint-disable-line no-console
          })
          .catch((error) => {
            console.error(error); // eslint-disable-line no-console
          });

        writeGAGTMBodyScript(gtmCode)
          .then(() => {
            console.info('ga GTM body loaded!'); // eslint-disable-line no-console
          })
          .catch((error) => {
            console.error(error); // eslint-disable-line no-console
          });
      }
    }
  },
};
