import { render, staticRenderFns } from "./error.vue?vue&type=template&id=673807e3&scoped=true&"
import script from "./error.vue?vue&type=script&lang=ts&"
export * from "./error.vue?vue&type=script&lang=ts&"
import style0 from "./error.vue?vue&type=style&index=0&id=673807e3&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673807e3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BixImg: require('/root/project/frontend/src/components/images/BixImg.vue').default,BixLink: require('/root/project/frontend/src/components/BixLink.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VCol,VContainer,VRow})
