import { evaluateFeatureFlagBoolValue } from '@/api/evidently';
import { EvidentlyFeatureFlagParams } from '@/api/types';

function memoizeServerRequest(requestFunction: Function) {
  const cache = new Map();

  return async function (...args: any) {
    // Generate a unique key based on the function arguments
    const key = JSON.stringify(args);

    // Check if the response is already in the cache
    if (cache.has(key)) {
      return cache.get(key);
    }
    // If not in cache, make the server request and store the response in the cache
    const response = await requestFunction(...args);
    cache.set(key, response);

    return response;
  };
}
const isFeatureFlagEnabledFunc = async (
  featureParam: string,
  clientIdParam?: string,
  userEmailParam?: string,
  urlParam?: string
) => {
  try {
    const params: EvidentlyFeatureFlagParams = {
      feature: featureParam,
      clientId: clientIdParam,
      userEmail: userEmailParam,
      url: urlParam,
    };
    const isEnabled = await evaluateFeatureFlagBoolValue(params);
    if (isEnabled) {
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const memoizedFetchDataFromServer = memoizeServerRequest(isFeatureFlagEnabledFunc);

export { memoizedFetchDataFromServer as isFeatureFlagEnabled };
