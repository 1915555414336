




















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import { useAuthentication } from '@/hooks/useAuthentication';
import { IPermission } from '@/typings/api/identity-gql';
import { hasPermission } from '@/utils/user-permissions';
import { UserRoles } from '@/enums/userRoles';
import regions from '@/api/regions';
import { forEmployerURL } from '@/utils/navigation';

const JobPostPermissions = [
  UserRoles.GlobalAdmin,
  UserRoles.CompanyAdmin,
  UserRoles.CompanyJobPoster,
  UserRoles.UnlimitedJobPoster,
  UserRoles.DrupalAdmin,
];

export default defineComponent({
  name: 'JobPostAuthButton',
  props: {
    userPermissions: {
      type: Array as PropType<IPermission[]>,
      default: () => [],
    },
    userJobSlots: {
      type: Number,
      default: () => 0,
    },
  },
  setup(props) {
    const { isAuthenticated } = useAuthentication();
    const checkPermissions = (permissionsToCheck: string[]) => {
      return hasPermission(props.userPermissions, permissionsToCheck);
    };
    const isNational = regions.isNational();
    const forEmployerlink = forEmployerURL();
    const href = computed((): string => {
      if (!isNational) {
        return '/job-slots';
      } else {
        return forEmployerlink;
      }
    });
    const hasJobSlots = () => {
      /**
       * @see https://jira.builtin.com/browse/PODC-2303?focusedCommentId=45328&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-45328
       */
      return props.userJobSlots > 0 && props.userJobSlots <= 4;
    };
    return {
      isAuthenticated,
      checkPermissions,
      JobPostPermissions,
      hasJobSlots,
      forEmployerlink,
      href,
    };
  },
});
