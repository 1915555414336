import bixAxios from '@/api/bixAxios';
import regions from '@/api/regions';
import TrackingAPI from '@/enums/trackingApiRoutes';
import { b64urlDecode } from '@/utils/base64';
import { isoNow } from '@/utils/date';
import { UserSessionIdService, userSessionIdService } from '@/utils/userSessionId';
import { UserMetadataService, userMetadataService } from '@/utils/userMetadata';
import { ClientIdentifierService, clientIdentifierService } from '@/utils/clientIdentifier';
import { debounce } from 'lodash-es';
import { Experiment } from '@/experiments/types';
import { getOngoingExperiments } from '@/experiments/helpers';
export interface Context {
  clientIdentifierService: ClientIdentifierService;
  host: string;
  provideCreatedAt: () => string;
  userMetadataService: UserMetadataService;
  userSessionIdService: UserSessionIdService;
}

export interface CrossDomainState {
  clientIdentifier: string;
  isAuthenticated: boolean;
  referredBy: string;
  userSessionIdentifier: string;
}

export interface Metadata {
  client_identifier?: string;
  created_at?: string;
  cross_domain_state?: CrossDomainState;
  drupal_user_id?: number;
  event_url?: string;
  experiments?: Experiment[];
  generated_by?: string;
  market_id?: number;
  referer?: string;
  session_id?: string;
  subject_id?: string;
  user_id?: number;
  [key: string]: any;
}

interface BulkEvent {
  event: string;
  data: Metadata;
}

const sendBeacon = (url: string, data: string): boolean => {
  try {
    return navigator.sendBeacon(url, data);
  } catch {
    // navigator.sendBeacon isn't supported
    try {
      bixAxios.post(url, data);
      return true;
    } catch {
      return false;
    }
  }
};

/**
 * events.postEvent batches up events then bulk posts them. It ensures it
 * posts before the page is unloaded.
 */
export const events = (() => {
  let queue: BulkEvent[] = [];

  const queueEvent = (event: string, data: Metadata) => {
    queue.push({ event, data });
  };

  const postEvents = () => {
    if (queue.length === 0) {
      return;
    }

    const events = [...queue];
    queue = [];
    sendBeacon(TrackingAPI.ClientEvents, JSON.stringify(events));
  };

  window.addEventListener('unload', postEvents);

  const debouncedPostEvents = debounce(postEvents, 500, { maxWait: 1000 });

  return {
    postEvent: (event: string, data: Metadata) => {
      queueEvent(event, data);
      debouncedPostEvents();
    },
  };
})();

/**
 * adds the market_id identifier using region_id from the Context`
 * @param context the client side Context available to functions which augment the body to navigator.sendBeacon
 */
export const _addMarketId =
  (context: Context) =>
  (current: Metadata): Metadata => {
    const { host } = context;
    if (host) {
      return {
        ...current,
        market_id: regions.getRegionID(host),
      };
    }
    return current;
  };

/**
 * adds the builtin client identifier by deferring to the clientIdentifierService
 * @param context the client side Context available to functions which augment the body to navigator.sendBeacon
 */
export const _addClientIdentifier =
  (context: Context) =>
  async (current: Metadata): Promise<Metadata> => {
    const { clientIdentifierService } = context;
    const { cross_domain_state } = current;
    const client_identifier = await clientIdentifierService.getItem(
      cross_domain_state?.clientIdentifier
    );
    if (client_identifier) {
      return {
        ...current,
        client_identifier,
      };
    }
    return current;
  };

export const _addCreatedAt =
  (context: Context) =>
  (current: Metadata): Metadata => {
    const { provideCreatedAt } = context;
    return {
      ...current,
      created_at: provideCreatedAt(),
    };
  };

export const _addCrossDomainState = (current: Metadata): Metadata => {
  const { event_url } = current;
  if (!event_url) {
    return current;
  }
  try {
    const state = new URL(event_url).searchParams.get('state');
    if (!state) {
      return current;
    }
    return {
      ...current,
      cross_domain_state: JSON.parse(b64urlDecode(state)),
    };
  } catch {}

  return current;
};

/**
 * adds the user meta data found in localstorage keyed by deferring to the userMetadataService
 * @param context the client side Context available to functions which augment the body to navigator.sendBeacon
 */
export const _addUserMetadata =
  (context: Context) =>
  (current: Metadata): Metadata => {
    try {
      const { userMetadataService } = context;
      const { id, drupal_user_id, subject_id } = userMetadataService.getItem();
      return {
        ...current,
        user_id: id,
        drupal_user_id: drupal_user_id || undefined,
        subject_id,
      };
    } catch (e) {
      return current;
    }
  };

export const _addSessionId =
  (context: Context) =>
  (current: Metadata): Metadata => {
    const { userSessionIdService } = context;
    const { cross_domain_state } = current;
    const session_id = userSessionIdService.getValidSessionId(
      cross_domain_state?.userSessionIdentifier
    );
    return {
      ...current,
      session_id,
    };
  };

export const _addExperiments = (current: Metadata): Metadata => {
  return {
    ...current,
    experiments: getOngoingExperiments(),
  };
};

export const _addURL = (current: Metadata): Metadata => {
  return {
    ...current,
    event_url: document.URL,
  };
};

/**
 * Log a user interaction event along with any passed metadata.
 * This will include the market_id as metadata even if none is passed.
 * @param event the event name in snake case format
 * @param metadata additional metadata to include for the event
 */
const logEvent = async (event: string, metadata?: Metadata) => {
  try {
    const host = window.location.hostname;
    const initialMetadata = metadata || {};
    initialMetadata.generated_by = 'vue';
    initialMetadata.referer = initialMetadata.referer || document.referrer;

    const context = {
      host,
      clientIdentifierService,
      userMetadataService,
      userSessionIdService,
      provideCreatedAt: isoNow,
    };

    let eventMetadata = _addCreatedAt(context)(initialMetadata);
    eventMetadata = _addURL(eventMetadata);
    eventMetadata = _addCrossDomainState(eventMetadata);
    eventMetadata = _addMarketId(context)(eventMetadata);
    eventMetadata = _addUserMetadata(context)(eventMetadata);
    eventMetadata = _addSessionId(context)(eventMetadata);
    eventMetadata = _addExperiments(eventMetadata);
    eventMetadata = await _addClientIdentifier(context)(eventMetadata);

    events.postEvent(event, eventMetadata);
  } catch (e) {}
};

export default { logEvent };
