import { Context } from '@nuxt/types/app';
import { FeatureFlags } from '@/plugins/types';

/**
 * @returns A feature flags object if it exists,
 * or an empty object if it doesn't.
 */
export const getFeatureFlags = (): FeatureFlags =>
  JSON.parse(window.localStorage.getItem('featureFlags') || '{}') as FeatureFlags;

const saveFeatureFlags = (featureFlags: FeatureFlags, ctx?: Context) => {
  localStorage.setItem('featureFlags', JSON.stringify(featureFlags));

  if (ctx) {
    ctx.app.featureFlags = featureFlags;
  }

  return featureFlags;
};

const initializeFeatureFlags = (ctx: Context): FeatureFlags => {
  const persistedFlags: FeatureFlags = getFeatureFlags();

  // Overwrites default environment flags with browser local storage flags
  const accumulatedFlags = {
    ...persistedFlags,
  };

  return saveFeatureFlags(accumulatedFlags, ctx);
};

export default (ctx: Context) => {
  ctx.app.featureFlags = initializeFeatureFlags(ctx);
};
