
















import { stringToAnchorText } from '@/utils/formatting';
import { defineComponent } from '@nuxtjs/composition-api';
import { isNational } from '@/api/regions';
import { industryLinks } from '@/utils/navigation';

export default defineComponent({
  setup() {
    const links: Array<{ title: string; url: string }> = industryLinks;
    return {
      links,
      stringToAnchorText,
      isNational,
    };
  },
});
