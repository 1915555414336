import { render, staticRenderFns } from "./JobsDropdown.vue?vue&type=template&id=2474f2ac&scoped=true&"
import script from "./JobsDropdown.vue?vue&type=script&lang=ts&"
export * from "./JobsDropdown.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2474f2ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DropdownNationalHubsLayout: require('/root/project/frontend/src/components/navigation/main/DropdownNationalHubsLayout.vue').default})
