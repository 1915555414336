








import Vue from 'vue';
import Component from 'vue-class-component';

import { mapState } from '@/utils/vuex-shim';
import { RootState } from '@/store/types';

@Component({
  computed: {
    ...mapState<RootState>(['loading']),
  },
})
export default class Loading extends Vue {
  circleTime = 4000;
  processing = false;
  loading!: RootState['loading'];

  firstStep = false;
  firstFixed = false;
  secondStep = false;

  animation() {
    if (this.processing) {
      const circle = () => {
        this.firstStep = true;
        setTimeout(() => {
          this.firstFixed = true;
          this.firstStep = false;
          this.secondStep = true;
        }, this.circleTime * 0.5);
        setTimeout(() => {
          this.secondStep = false;
          this.firstFixed = false;
          if (this.processing) {
            circle();
          }
        }, this.circleTime);
      };
      circle();
    }
  }

  get isLoading() {
    if (this.loading.length && !this.processing) {
      this.processing = true;
      setTimeout(() => {
        this.animation();
      }, 50);
    } else if (!this.loading.length && this.processing) {
      this.processing = false;
    }
    return this.loading.length;
  }
}
