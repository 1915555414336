















import { computed, defineComponent } from '@nuxtjs/composition-api';

/**
 * Shows the company logo contained in a white circular avatar
 */
export default defineComponent({
  props: {
    /** The size in pixels */
    size: {
      type: [Number, String],
      default: 96,
    },
    /** The source image to display */
    src: {
      type: String,
      required: true,
    },
    /** The accessibility text to add to the logo */
    alt: {
      type: String,
      default: '',
    },
    /** The region of the company that owns the logo.
     * Pass this if you need to build a CDN URL */
    regionId: {
      type: Number,
      required: false,
    },
  },
  setup: (props) => {
    const size = computed(() => Number(props.size));

    return {
      style: computed(() => ({
        '--logo-size': size.value,
      })),
      fallback: 'assets/company-logo-fallback.png',
    };
  },
});
