import { intersectionBy } from 'lodash-es';
import { City, CityDropdown, Location, RemoteNonUSID, RemoteUSID } from '@/store/onboarding/types';
import { Region } from '@/store/types';
// eslint-disable-next-line bix/no-component-imports
import DropdownSelectItem from '@/components/controls/types/DropdownSelectItem';

/**
 *  transform cities data as options for BixDropdownSelect
 */
export const transformCityDropdownData = (cities: CityDropdown[]) => {
  const locationOptions: DropdownSelectItem[] = [];

  return cities.reduce((items: DropdownSelectItem[], city: CityDropdown) => {
    if (!city.hideParent) {
      items.push({
        name: `${city.name}${city.locations.length && !city.onlyParent ? ' (All)' : ''}`,
        value: city.id,
        parent: true,
      });
    }

    if (city.onlyParent) {
      return items;
    }

    if (city.locations) {
      const locations = [...city.locations];

      locations.forEach((location: Location) => {
        locationOptions.push({
          name: location.name ? location.name : '',
          value: location.location_id,
          child: true,
          parentKey: city.id,
        });
      });
    }
    return items || [];
  }, locationOptions);
};

/**
 * transformation dropdown data of cities to data received by the server
 */
export const getDataActiveCities = (
  citiesList: City[],
  activeCities: DropdownSelectItem[],
  activeRemoteJob: boolean
) => {
  const initialData: Array<{ id: number | string; locations: Location[] }> = [];

  if (activeRemoteJob) {
    initialData.push({ id: RemoteUSID, locations: [{ city_id: RemoteUSID, location_id: 0 }] });
  }

  if (!activeCities.length) {
    return initialData;
  }

  return activeCities.reduce((acc, city) => {
    if (city.child) {
      const parentIndex = acc.findIndex((item) => item.id === city.parentKey);
      // @ts-ignore
      const location: Location = { city_id: city.parentKey, location_id: city.value };
      if (parentIndex > -1) {
        acc[parentIndex].locations.push(location);
        return acc;
      }

      // @ts-ignore
      acc.push({ id: city.parentKey, locations: [location] });
      return acc;
    }

    if (city.parent) {
      const fullInfoCity = citiesList.find((val: City) => city.value === val.id);
      const parentIndex = city.value;
      if (fullInfoCity && !fullInfoCity.locations.length) {
        // @ts-ignore
        acc.push({ id: parentIndex, locations: [{ city_id: city.value, location_id: 0 }] });
        return acc;
      }

      if (fullInfoCity && fullInfoCity.locations.length > 0) {
        const newUserCity = {
          id: parentIndex as number,
          locations: [],
        };
        fullInfoCity.locations.forEach((location: Location) => {
          // @ts-ignore
          newUserCity.locations.push({ city_id: parentIndex, location_id: location.location_id });
        });
        acc.push(newUserCity);
        return acc;
      }
    }

    return acc;
  }, initialData);
};

/**
 * transform cities data selected by user for BixDropdownSelect
 *
 * @param cities - all cities for user selection on builtin
 * @param values - user selected values from profile.cities store/onboarding
 */
export const transformUserCitiesSelected = (
  cities: City[],
  values: City[]
): DropdownSelectItem[] => {
  if (!cities) {
    return [];
  }
  const fullCitiesInfo = intersectionBy(cities, values, 'id');

  const userSelection = values.filter((value) => {
    return value.id != 0 && value.id != RemoteUSID && value.id != RemoteNonUSID;
  });

  const items: CityDropdown[] = userSelection.map((selectedCity) => {
    const city = fullCitiesInfo.find((city) => {
      return city.id === selectedCity.id;
    });
    if (!city) {
      throw new Error('The region selected by the user was not found in the regions data');
    }
    const selectedLocations = selectedCity.locations?.length ? selectedCity.locations : [];
    const filteredLocations = selectedLocations.filter(
      (location: Location) => location.location_id > 0
    );

    if (city.locations?.length === filteredLocations.length) {
      /**
       * Select only parent city
       */
      return { ...city, onlyParent: true };
    }

    const userLocations = intersectionBy(city.locations, selectedLocations, 'location_id');
    // only sublocations will selected
    return { ...city, locations: userLocations, hideParent: true };
  });

  return transformCityDropdownData(items);
};

/***
 * transform data from Region interface into Onboarding City interface
 * @param cityResponse
 */

export const transformApiCity = (cityResponse: Region) => {
  const city: City = {
    id: cityResponse.id,
    name: cityResponse.name,
    locations: [],
  };
  city.locations = cityResponse.locations
    .map((locationResponse: any) => {
      const location: Location = {
        location_id: locationResponse.id,
        name: locationResponse.name,
        city_id: cityResponse.id,
      };

      return location;
    })
    .filter((l: Location) => {
      return l.name !== 'Remote';
    });

  return city;
};

export const onboardingCityList = (cities: Region[]) => {
  const mappedCities: City[] = cities.map(transformApiCity);

  // Adding remote US placeholder
  mappedCities.push({ id: RemoteUSID, name: 'Remote U.S.', locations: [] });

  // Adding remote Non-US placeholder
  mappedCities.push({
    id: RemoteNonUSID,
    name: 'Remote Non-U.S.',
    locations: [],
  });

  return mappedCities;
};

export const isSafari = (): boolean => {
  return /(iPhone; CPU iPhone OS 1[0-9]|iPad; CPU OS 1[0-9]|iPod touch; CPU iPhone OS 1[0-9]|Macintosh; Intel Mac OS X.*Version\x2F1[0-9].*Safari|Macintosh;.*Mac OS X 10_14.* AppleWebKit.*Version\x2F1[0-9].*Safari)/i.test(
    window.navigator.userAgent
  );
};

export const validateEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.toLowerCase()
  );
};

export default {
  isSafari,
  addWhyCompanies(why: number): number {
    return this.updateWhy(why, 0);
  },
  addWhyJobs(why: number): number {
    return this.updateWhy(why, 1);
  },
  addWhyTopics(why: number): number {
    return this.updateWhy(why, 2);
  },
  removeWhyCompanies(why: number): number {
    return this.updateWhy(why, 0, false);
  },
  removeWhyJobs(why: number): number {
    return this.updateWhy(why, 1, false);
  },
  removeWhyTopics(why: number): number {
    return this.updateWhy(why, 2, false);
  },
  toggleWhy(why: number, index: number): number {
    const data = this.makeWhyBinary(why);
    data[index] ^= 1;
    return this.makeWhy(data);
  },
  isActive(why: number, index: number): boolean {
    const data = this.makeWhyBinary(why);
    return data[index] == 1;
  },
  updateWhy(why: number, index: number, action: boolean = true): number {
    const data = this.makeWhyBinary(why);
    if (action) {
      data[index] = 1;
    } else {
      data[index] = 0;
    }
    return this.makeWhy(data);
  },
  makeWhyBinary(why: number): Array<number> {
    return why
      .toString(2)
      .padStart(4, '0')
      .split('')
      .map((i) => parseInt(i));
  },
  makeWhy(data: Array<number>): number {
    return parseInt(data.join(''), 2);
  },
};
