import { render, staticRenderFns } from "./no-footer.vue?vue&type=template&id=65eb41ed&scoped=true&"
import script from "./no-footer.vue?vue&type=script&lang=ts&"
export * from "./no-footer.vue?vue&type=script&lang=ts&"
import style0 from "./no-footer.vue?vue&type=style&index=0&id=65eb41ed&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65eb41ed",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkipLink: require('/root/project/frontend/src/components/SkipLink.vue').default,Navigation: require('/root/project/frontend/src/components/navigation/Navigation.vue').default,MobileBottomNavigation: require('/root/project/frontend/src/components/navigation/main/MobileBottomNavigation.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
