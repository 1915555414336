





























import {
  computed,
  defineComponent,
  PropType,
  ref,
  Ref,
  onMounted,
  reactive,
  toRefs,
} from '@nuxtjs/composition-api';

import appVars from '@/enums/appVars';
import files, { BixBreakpoints } from '@/utils/files';
import { useVuetify } from '@/hooks/useVuetify';
import constants from '@/enums/constants';

/**
 * An <img /> wrapper with the following features:
 *
 * * prepends the public assets path to the src URI for static assets
 * * prepends the CDN prefix for images hosted on a regional CDN
 * * optimizes the image using Cloudflare when in a non-local environment
 * * by default, lazy-loads the image unless `props.lazy = false`
 * * shows a transparent placeholder if `props.src` fails to load
 */
export default defineComponent({
  name: 'BixImg',
  props: {
    /**
     * The URI or URL to the image.
     * If a static asset is being loaded, ensure `assets/` is included in the path.
     * If a CDN-hosted image, `props.regionId` is necessary.
     */
    src: {
      type: String,
      required: true,
    },
    /**
     * The region ID for images hosted on a CDN.
     * Necessary for all CDN-hosted images.
     */
    regionId: {
      type: Number,
      required: false,
    },
    /**
     * The image's alt text.
     * Leave empty if the image is decorative to hide from screenreaders.
     */
    alt: {
      type: String,
      default: '',
    },
    /**
     * Width to resize the image to.
     * Ignored when `props.responsiveSet` is true.
     */
    width: {
      type: [Number, String],
      required: false,
    },
    /**
     * Height to resize the image to.
     */
    height: {
      type: [Number, String],
      required: false,
    },
    /**
     * Quality to downsample the image to (0-100).
     */
    quality: {
      type: [Number, String],
      default: constants.CLOUDFLARE_IMG_QUALITY,
    },
    /**
     * Resize behavior.
     * Docs: https://developers.cloudflare.com/images/url-format
     */
    fit: {
      type: String as PropType<'scale-down' | 'contain' | 'cover' | 'crop' | 'pad'>,
      required: false,
    },
    /**
     * Whether to size the image based on
     * the current breakpoint. Useful for full-screen images.
     *
     * When set to true, `props.width` is ignored.
     */
    responsiveSet: {
      type: Boolean,
      default: false,
    },
    /**
     * Mimics Vuetify's `v-skeleton-loader` as an animated background of <img />.
     * Intentionally continues to animate on [lazy=loaded] to handle slow-to-decode images.
     */
    skeletonLoader: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether to lazy-load the image.
     */
    lazy: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether source image is pulled directly from Drupal db
     * Example usage is in RecentArticles, a Vue component loaded on a Drupal page
     */
    drupalSrc: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const { breakpoints } = useVuetify();
    let lazyLoadObserver: IntersectionObserver | null;
    const lazyLoadTrigger: Ref<HTMLElement | null> = ref(null);
    const placeholderImg = files.getPlaceholderImage(props.width, props.height);
    const cloudflareOptions = {
      q: Number(props.quality),
      fit: props.fit,
    };

    const state = reactive({
      imgDidIntersect: false,
      imgThrewError: false,
      imgDidLoad: false,
    });

    const setImgThrewError = () => (state.imgThrewError = true);
    const setImgDidLoad = () => (state.imgDidLoad = true);

    const shouldShowImage = computed(() => {
      if (!props.lazy) return true;
      return state.imgDidIntersect;
    });

    /**
     * IntersectionObserver handler method.
     * This gets called when `props.lazyload` is true and the
     * trigger element has been intersected.
     */
    const onImageIntersect = (entries: IntersectionObserverEntry[]) => {
      const isIntersecting = entries.some((entry) => entry.isIntersecting);
      if (isIntersecting) {
        // If the intersection threshold has been crossed,
        // show the image, hide the placeholder, and stop watching for intersections
        state.imgDidIntersect = true;
        lazyLoadObserver!.disconnect();
      }
    };

    onMounted(() => {
      if (props.lazy) {
        // Observe intersections of the placeholder image.
        lazyLoadObserver = new IntersectionObserver(onImageIntersect);
        lazyLoadObserver.observe(lazyLoadTrigger.value!);
      }
    });

    /**
     * @returns The image's optimized `src` sized for the current breakpoint.
     */
    const getResponsiveSrcSet = (imageUrl: string) => {
      const breakpointThresholds = breakpoints.value.thresholds as BixBreakpoints;
      const currentBreakpoint = breakpoints.value.name;

      const responsiveSrcSet = files.getResponsiveImageSet(
        imageUrl,
        breakpointThresholds,
        Number(props.height),
        Number(props.regionId) || undefined,
        cloudflareOptions
      );

      return responsiveSrcSet[currentBreakpoint];
    };

    /**
     * @returns The image's compiled `src`.
     */
    const imgSrc = computed(() => {
      let imgSrc = props.src;

      if (!props.src) return placeholderImg;

      // Compile a full URL for static assets.
      const isFullUrl = /^https?:\/\//i.test(imgSrc);
      const isStaticAsset = /^assets\//i.test(imgSrc);
      if (isStaticAsset && !isFullUrl) imgSrc = `${appVars.BaseUrl}/${imgSrc}`;

      if (props.responsiveSet) return getResponsiveSrcSet(imgSrc);

      // If a region ID is passed, this is a CDN-hosted image.
      if (props.regionId) {
        return files.getResponsiveImage(
          imgSrc,
          props.regionId,
          Number(props.width),
          Number(props.height),
          cloudflareOptions,
          props.drupalSrc
        );
      }

      return files.getResponsiveImageFromURL(
        imgSrc,
        Number(props.width),
        Number(props.height),
        cloudflareOptions
      );
    });

    return {
      ...toRefs(state),
      shouldShowImage,
      lazyLoadTrigger,
      placeholderImg,
      imgSrc,
      onImageIntersect,
      setImgThrewError,
      setImgDidLoad,
    };
  },
});
