import jwtDecode from 'jwt-decode';
import { IDTokenClaims, Profile, User, UserInfoService, UserManager } from 'oidc-client';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import regionUtils, { getRegionBaseURL } from '@/api/regions';
import appVars from '@/enums/appVars';
import { getAuthenticatedClient, getIdentityClient } from '@/plugins/vueApollo';
import { userMetadataService } from '@/utils/userMetadata';

import {
  GetIdpProfile,
  GetUserProfile,
  GetUserProfileParams,
  UpdateIdentityProfile,
  UpdateIdentityProfileParams,
} from '@/graphql/identity';
import { IMutation, IPermission, IQuery, IUser } from '@/typings/api/identity-gql';
import { isFullUrl } from '@/utils/formatting';
import { getPathFromUrl } from '@/utils/uri';
import { RootState } from '../types';
import oidcSettings from '@/stores/user/oidcSettings';

export type AuthType = {
  accessToken: string;
  idToken: string;
  expirationDate: Date;
  isImpersonating: boolean;
} | null;

export type ProfileType =
  | ({
      name: string;
      givenName: string;
      familyName: string;
      picture: string;
      email: string;
      locale: string;
    } & IUser)
  | null;

/** The state structure of UserModule */
export interface State {
  auth: AuthType;
  profile: ProfileType;
  hasBeenRedirected: boolean;
}
export interface StatePrivate extends State {
  _eventsAreBound: boolean;
}

export interface UserActions extends ActionTree<StatePrivate, RootState> {
  authenticate: (
    ctx: ActionContext<StatePrivate, RootState>,
    payload: AuthenticationPayload
  ) => Promise<void>;
  authenticateCallback: (ctx: ActionContext<StatePrivate, RootState>) => Promise<boolean>;
  silentAuthenticate: (ctx: ActionContext<StatePrivate, RootState>) => Promise<boolean>;
  signout: (ctx: ActionContext<StatePrivate, RootState>) => Promise<void>;
  hasBeenRedirectEmployer: (ctx: ActionContext<StatePrivate, RootState>) => Promise<void>;
  getUserProfile: (ctx: ActionContext<StatePrivate, RootState>) => Promise<void>;
  updateUserProfile: (
    ctx: ActionContext<StatePrivate, RootState>,
    profile: Partial<UserProfile>
  ) => Promise<boolean>;
  impersonate: (ctx: ActionContext<StatePrivate, RootState>, subjectId: string) => Promise<void>;
  stopImpersonating: (ctx: ActionContext<StatePrivate, RootState>) => void;
}

export interface UserActionsPrivate extends UserActions {
  _enableOidcEvents: (ctx: ActionContext<StatePrivate, RootState>) => void;
  _fetchLatestOidcUser: (
    ctx: ActionContext<StatePrivate, RootState>,
    token: string
  ) => Promise<User | null>;
}

export interface UserGetters extends GetterTree<StatePrivate, RootState> {
  hasBeenRedirectedGetter: (state: StatePrivate) => boolean;
  isAuthenticated: (state: StatePrivate) => boolean;
  isImpersonating: (state: StatePrivate) => boolean;
  expiresAt: (state: StatePrivate) => Date;
  authHeader: (state: StatePrivate) => string;
  getPermissionsForRegion: (
    state: StatePrivate
  ) => (regionId: number | null) => (IPermission | null)[];
  hasPermission: (
    state: StatePrivate
  ) => (regionId: number | null, ...permissions: string[]) => boolean;
  fullName: (state: StatePrivate) => string | null;
}

export interface UserMutations extends MutationTree<StatePrivate> {}
interface UserMutationsPrivate extends UserMutations {
  _clearAuth: (state: StatePrivate) => void;
  _setOidcEventsAreBound: (state: StatePrivate) => void;
  _updateAuth: (state: StatePrivate, user: User) => void;
  _updateProfile: (state: StatePrivate, user: UpdateProfilePayload) => void;
}

export interface UserProfile {
  givenName: string;
  familyName: string;
  picture: string;
  email: string;
}

export interface AuthenticationPayload {
  redirectUrl: string;
  useSignup: boolean;
}

interface IdPTokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}

interface UpdateProfilePayload {
  go: IUser | null;
  idp: IUser | null;
}

const regionUri = regionUtils.getRegionBaseURL();

export const manager = new UserManager(oidcSettings);

const userInfoService = (
  manager as unknown as {
    _validator: { _userInfoService: UserInfoService };
  }
)._validator._userInfoService;

const emptyGuid = '00000000-0000-0000-0000-000000000000';
const getZeroDate = () => new Date(0);

const buildOauthProfile = (profile: Profile) =>
  ({
    givenName: profile.given_name || '',
    familyName: profile.family_name || '',
    picture: profile.picture || '',
    email: profile.email || '',
    locale: profile.locale || '',
    subjectId: profile.sub,
  } as ProfileType);

const userPermissionsForRegion = (profile: ProfileType | null, regionId: number | null) =>
  profile?.permissions?.filter(
    (perm) => perm?.locationId === regionId || perm?.locationId == null
  ) || [];

const state: () => StatePrivate = () => ({
  /** Information about the authenication. */
  auth: null,
  /** Basic user profile of the currently authenticated user. */
  profile: null,
  /** Internal state to determine if the events have been added to the OIDC User Manager */
  _eventsAreBound: false,
  hasBeenRedirected: false,
});

const getters: UserGetters = {
  /**
   * Checks if the user is authenticated.
   *
   * @returns A boolean indicating if the user is authenticated.
   */
  isAuthenticated: (state) => {
    const now = new Date();
    const expirationDate = state.auth?.expirationDate || getZeroDate();

    return !!state.auth?.accessToken && expirationDate >= now;
  },
  hasBeenRedirectedGetter: (state) => {
    return state.hasBeenRedirected;
  },
  isImpersonating: (state) => {
    return state.auth?.isImpersonating || false;
  },
  expiresAt: (state) => {
    const expiresAt = state.auth?.expirationDate;

    return expiresAt || new Date();
  },
  /**
   * Generated an auth header for requests.
   *
   * @returns The bearer auth header value.
   */
  authHeader: (state) => {
    return `Bearer ${state.auth?.accessToken || ''}`;
  },
  /**
   * @returns Any permission for a specified region and admin roles.
   */
  getPermissionsForRegion: (state) => {
    return (regionId: number | null = null) => userPermissionsForRegion(state.profile, regionId);
  },
  hasPermission: (state) => {
    return (regionId: number | null = null, ...permissions: string[]) => {
      const perms = userPermissionsForRegion(state.profile, regionId);
      return perms.some((perm) => perm && permissions.includes(perm.name));
    };
  },
  fullName: (state): string | null => {
    const givenName = state.profile?.givenName || '';
    const familyName = state.profile?.familyName || '';

    return `${givenName} ${familyName}`.trim() || null;
  },
};
const mutations: UserMutationsPrivate = {
  /**
   * Clears the user module's state and clears any local storage keys.
   */
  _clearAuth: (state) => {
    state.auth = null;
    state.profile = null;
    state.hasBeenRedirected = false;
    manager.clearStaleState();
    manager.removeUser();
    userMetadataService.clearItem();
    state._eventsAreBound = false;
  },
  /**
   * Set TRUE to hasBeenredirected state when user is once redirected to /employer after.
   */
  _hasBeenRedirectedEmployer: (state) => {
    state.hasBeenRedirected = true;
  },
  /**
   * Tells the module that oidc events are bound.
   */
  _setOidcEventsAreBound: (state) => {
    state._eventsAreBound = true;
  },
  /**
   * Updates the user module's state with a new user.
   */
  _updateAuth: (state, user) => {
    const { profile } = user ?? {};
    if (!profile) {
      return;
    }

    state.auth = {
      accessToken: user.access_token,
      idToken: user.id_token,
      expirationDate: new Date(user.expires_at * 1000),
      isImpersonating: !!profile.iid,
    };
    state.profile = buildOauthProfile(profile);
  },
  _updateProfile: (state, user) => {
    if (user.go == null || user.idp == null) {
      return;
    }

    if (state.profile == null) {
      state.profile = {} as ProfileType;
    }

    state.profile = { ...state.profile, ...user.go, ...user.idp } as ProfileType;

    userMetadataService.setItem({
      id: state.profile?.id,
      drupal_user_id: state.profile?.drupalId,
      subject_id: state.profile?.subjectId,
    });
  },
};

const actions: UserActionsPrivate = {
  /**
   * Initiates the authentication flow with the IdP.
   *
   * @param payload.redirectUrl - The path you'd like to be redirected to after authentication.
   *                              This function will remove the origin if a full url is passed in,
   *                              so prefer passing in a path directly instead of a full url to save
   *                              that work.
   *
   * @param payload.useSignup - Display /Signup UI in IDP
   */
  authenticate: async (_, payload = { redirectUrl: '/', useSignup: false }) => {
    const { useSignup } = payload;
    let { redirectUrl } = payload;

    if (isFullUrl(redirectUrl)) {
      redirectUrl = getPathFromUrl(redirectUrl);
    }

    const encodedRedirectUrl = appVars.CurrentEnv.isDev
      ? redirectUrl
      : `/bilogin?destination=${encodeURIComponent(redirectUrl)}`;

    try {
      await manager.signinRedirect({
        state: {
          redirectUrl: encodedRedirectUrl,
        },
        extraQueryParams: {
          signup: useSignup,
        },
      });
    } catch (err) {
      console.error(err);
    }
  },
  /**
   * Processes the authenticated user and stores auth tokens, and
   * basic user profile.  If the user does not come back then
   * silently authenticate.
   */
  authenticateCallback: async (context) => {
    let user = await manager.getUser();

    if (user == null || user.expired) {
      const success: boolean = await context.dispatch('silentAuthenticate');
      return success;
    } else {
      user = await context.dispatch('_fetchLatestOidcUser', user.access_token);
    }

    if (!context.state._eventsAreBound) {
      context.dispatch('_enableOidcEvents');
    }

    context.commit('_updateAuth', user);
    await context.dispatch('getUserProfile');
    return true;
  },
  /**
   * Tries to silently authenticate a user.  This will only
   * succeed if the user has already logged into the identity
   * server and has a valid session cookie on the IdP.
   *
   * @returns True if the authentication was succesful,
   *          otherwise False on authentication failures.
   */
  silentAuthenticate: async (context) => {
    try {
      let user = await manager.signinSilent();

      if (user == null || user.expired) {
        context.commit('_clearAuth');
        return false;
      } else {
        if (!context.state._eventsAreBound) {
          context.dispatch('_enableOidcEvents');
        }

        user = await context.dispatch('_fetchLatestOidcUser', user.access_token);
        context.commit('_updateAuth', user);

        await context.dispatch('getUserProfile');
        return true;
      }
    } catch (err: any) {
      context.commit('_clearAuth');
      if (typeof err === 'object' && err.error !== 'login_required') {
        console.error(err);
      }
      return false;
    }
  },
  /**
   * Completes the signout proccess of the IdP.  This also clears
   * any current auth state.
   */
  signout: async (context) => {
    await manager.signoutRedirect();
    context.commit('_clearAuth');
  },
  /**
   * Completes the signout proccess of the IdP.  This also clears
   * any current auth state.
   */
  hasBeenRedirectEmployer: async (context) => {
    context.commit('_hasBeenRedirectedEmployer');
  },
  /**
   * Fetches an extended user profile from the external api.
   */
  getUserProfile: async (context) => {
    try {
      const regionId = regionUtils.getCurrentRegionID();

      const idpClient = getIdentityClient();
      const idpResults = await idpClient.query<IQuery>({
        query: GetIdpProfile,
        errorPolicy: 'ignore',
      });

      const goClient = getAuthenticatedClient();
      const goResults = await goClient.query<IQuery, GetUserProfileParams>({
        query: GetUserProfile,
        variables: {
          regionId,
        },
        errorPolicy: 'ignore',
      });

      context.commit('_updateProfile', {
        go: goResults.data.userProfile,
        idp: idpResults.data.principal,
      } as UpdateProfilePayload);

      await context.dispatch('onboarding/userProfile', undefined, { root: true });

      // TODO: If user has permssions with company id's then prefetch the basic company info
      //       in the CompanyModule
    } catch (err) {
      console.error(err);
      /* Nop */
    }
  },
  /**
   * Updates a users profile.  This calls out to identity for given name, family name,
   * the avatar, and email address.
   *
   * @param profile - The user profile to update.
   */
  updateUserProfile: async (context, profile) => {
    try {
      const idpClient = getIdentityClient();
      const results = await idpClient.mutate<IMutation, UpdateIdentityProfileParams>({
        mutation: UpdateIdentityProfile,
        variables: {
          profile: {
            givenName: profile.givenName,
            familyName: profile.familyName,
            avatar: profile.picture,
            email: profile.email,
          },
        },
      });

      const wasSuccessful = !!results.data?.modifyProfile;
      if (wasSuccessful) {
        if (context.state.auth?.isImpersonating) {
          await context.dispatch('impersonate', context.state.profile?.subjectId);
        } else {
          await context.dispatch('silentAuthenticate');
        }
      }

      return wasSuccessful;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  impersonate: async (context, subjectId) => {
    if (subjectId === emptyGuid) {
      throw new Error('User was not found.');
    }

    const _buildImpersonationTokenRequest = (subjectId: string, accessToken: string) => {
      const scopes = oidcSettings.scope?.split(' ').filter((x) => x !== 'offline_access') || [];
      const data = new URLSearchParams();
      data.append('grant_type', 'impersonation');
      data.append('scope', ['impersonate', ...scopes].join(' '));
      data.append('token', accessToken);
      data.append('sub_id', subjectId);
      data.append('client_id', oidcSettings.client_id || '');
      return data;
    };

    const _decodeTokenResponse = async ({ access_token, scope, token_type }: IdPTokenResponse) => {
      const profile: IDTokenClaims = jwtDecode(access_token);

      return {
        access_token,
        expires_at: profile.exp,
        profile,
        scope,
        token_type,
      } as Partial<User>;
    };

    const updateOidcStorage = async (impersonatedUser: Partial<User>) => {
      const oidcUser = await manager.getUser();
      if (oidcUser == null) {
        throw new Error('Failed to read current user.');
      }

      Object.assign(oidcUser, impersonatedUser);

      await manager.storeUser(oidcUser);
    };

    const fetchImpersonationToken = async (
      tokenEndpoint: string,
      accessToken: string,
      subjectId: string
    ) => {
      const data = _buildImpersonationTokenRequest(subjectId, accessToken);
      const res = await fetch(tokenEndpoint, {
        method: 'POST',
        mode: 'cors',
        body: data,
      });

      if (!res.ok) {
        throw new Error('Could not get impersonation token.');
      }
      const tokenRes: IdPTokenResponse = await res.json();

      return _decodeTokenResponse(tokenRes);
    };

    const fetchDrupalCsrfToken = async (regionUri: string) => {
      const tokenEndpoint = `${regionUri}/session/token`;
      const res = await fetch(tokenEndpoint, { mode: 'cors' });

      if (!res.ok) {
        throw new Error('Could not get drupal CSRF token.');
      }

      const csrfToken = await res.text();

      return csrfToken;
    };

    const syncDrupal = async (token: string) => {
      const regionUri = getRegionBaseURL();
      const csrfToken = await fetchDrupalCsrfToken(regionUri);
      const res = await fetch(`${regionUri}/drupal-api/impersonate`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-csrf-token': csrfToken,
        },
      });

      if (!res.ok) {
        throw new Error('Could not sync with drupal.');
      }
    };

    const tokenEndpoint = await manager.metadataService.getTokenEndpoint();
    if (!tokenEndpoint) {
      throw new Error('Could not connect to identity.');
    }

    const accessToken = context.state.auth?.isImpersonating
      ? localStorage.getItem('oidc.access_token') || ''
      : context.state.auth?.accessToken || '';

    const impersonatedUser = await fetchImpersonationToken(tokenEndpoint, accessToken, subjectId);
    await syncDrupal(impersonatedUser.access_token!);
    await updateOidcStorage(impersonatedUser);

    localStorage.setItem('oidc.access_token', accessToken);

    window.location.href = '/';
  },
  stopImpersonating: (context) => {
    manager.clearStaleState();
    manager.removeUser();

    context.dispatch('authenticate', { redirectUrl: '/tools/admin/token' });
  },
  /**
   * Setup the oidc events for expired tokens and expiring
   * tokens.  When a token expires, clear the auth.  When
   * the token is expiring then try to fetch a new token.
   */
  _enableOidcEvents: (context) => {
    if (context.state._eventsAreBound) {
      return;
    }

    manager.events.addUserSignedOut(async () => {
      // TODO: Look into if this needs national
      if (regionUtils.isMarket()) {
        return;
      }

      context.commit('_clearAuth');
      window.location.href = regionUri;
    });
    manager.events.addAccessTokenExpired(() => {
      const isImpersonating = context.state.auth?.isImpersonating;

      context.commit('_clearAuth');

      if (isImpersonating) {
        context.dispatch('stopImpersonating');
      } else {
        window.location.href = regionUri;
      }
    });
    manager.events.addAccessTokenExpiring(() => {
      if (context.state.auth?.isImpersonating) {
        return;
      }

      context.dispatch('silentAuthenticate');
    });
    context.commit('_setOidcEventsAreBound');
  },
  _fetchLatestOidcUser: async (context, token) => {
    // This keeps user profile information up to date across domains
    const claims = await userInfoService.getClaims(token);
    const oidcUser = await manager.getUser();
    if (oidcUser != null) {
      Object.assign(oidcUser.profile, claims);
      await manager.storeUser(oidcUser);
    }

    return oidcUser;
  },
};

export const UserNamespace = 'UserModule';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
} as Module<StatePrivate, RootState>;
