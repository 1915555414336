/**
 * Memoize (cache) a function which produces a result that only needs to be
 * computed one time. Useful for computations that are idempotent, in particular
 * if they are expensive to compute.
 *
 * @export
 * @template T
 * @param {(...args: any) => T} toMemoize - the function to execute
 * @param {boolean} [testsCanByass=true] - whether or not tests are allowed to bypass memoization and *always* re-compute
 * @returns a memoized version of `toMemoize` which will only perform the work of executing one time (except if permitted to bypass in tests)
 */
export function memoize<T>(toMemoize: (...args: any) => T, testsCanByass: boolean = true) {
  let memoized: any = null;

  return (...args: any): T => {
    if (memoized === null || (testsCanByass && process?.env?.NODE_ENV === 'test')) {
      memoized = toMemoize(...args);
    }

    return memoized;
  };
}
