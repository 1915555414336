import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=7360640c&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=ts&"
export * from "./Navigation.vue?vue&type=script&lang=ts&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=7360640c&prod&lang=sass&"
import style1 from "./Navigation.vue?vue&type=style&index=1&id=7360640c&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7360640c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpersonateBar: require('/root/project/frontend/src/components/admin/ImpersonateBar.vue').default,Loading: require('/root/project/frontend/src/components/Loading.vue').default,Logo: require('/root/project/frontend/src/components/navigation/Logo.vue').default,MainNav: require('/root/project/frontend/src/components/navigation/main/MainNav.vue').default,SubNavigation: require('/root/project/frontend/src/components/navigation/main/SubNavigation.vue').default,DesktopDropdownMenu: require('/root/project/frontend/src/components/navigation/DesktopDropdownMenu.vue').default,Search: require('/root/project/frontend/src/components/navigation/Search.vue').default,LoadingOverlay: require('/root/project/frontend/src/components/LoadingOverlay.vue').default})
