import Vue from 'vue';

/* eslint-disable bix/no-component-registration */
/* eslint-disable-next-line bix/no-component-imports */
import BixLink from '@/components/BixLink.vue';

export default async () => {
  Vue.use({
    install: (vue: typeof Vue) => {
      vue.mixin({
        components: {
          BixLink,
        },
      });
    },
  });
};
