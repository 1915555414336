import { isEmpty } from 'lodash-es';

import jobsApiService, { shouldUseJobsApiSorting } from '@/api/jobs';
import regions from '@/api/regions';

import {
  JobsFilterParams,
  JobsRouteParams,
  JobsWithCriterias,
  JobsDestinationParams,
  DefaultJobsApiSorting,
  SortNameHotJobs,
} from '@/api/types';
import {
  Category,
  JobCompany,
  Experience,
  ExperiencesList,
  FacetItem,
  Industry,
  JobsCompaniesData,
  JobsData,
  State,
  Subcategory,
  SubcategoryMap,
  JobsGetters,
  JobsMutations,
  JobsActions,
  JobsCompaniesCollapsedResponse,
  LoadCompanyJobs,
  CompanyJobs,
  Job,
  FiltersData,
  FiltersDataState,
} from '@/store/jobs/types';
import { Region, Location, RootActionNames } from '@/store/types';
import { SelectedExpertise } from '@/store/onboarding/types';
import { InferType } from '@/utils/vuex-shim';

import AppVars from '@/enums/appVars';
import constants from '@/enums/constants';
import regionsData from '@/api/regions';
import {
  jobTargetLocation,
  formatJobFacetPath,
  isRemoteSelected as getIsRemoteSelected,
  isHybridSelected as getIsHybridSelected,
  isInOfficeSelected as getIsInOfficeSelected,
} from '@/utils/jobs';

const state = (): State => ({
  // API data
  categories: [],
  basicCompaniesJobs: {
    paginationCount: 0,
    companyCount: 0,
    companiesJobs: [],
    jobsCount: 0,
    jobAllCount: 0,
    searchWeightId: '',
  },
  eliteJobs: {
    highVolumeJobs: {},
    jobs: [],
    job_count: 0,
    job_all_count: 0,
    searchWeightId: '',
  },
  jobsWithCompaniesRequests: [],
  jobsWithCompaniesResponds: [],
  eliteCompanies: [],
  subcategories: {},
  experiences: ExperiencesList,
  industries: [],
  loadMoreCompany: {},
  filteredJobs: [],
  // Filtering data
  activeCategories: [],
  activeSubcategories: [],
  activeExperiences: [],
  activeIndustries: [],
  activeCompanySizes: [],
  activeRegions: [],
  activeRegionsSorting: [],
  activeLocations: [],
  activeSorting: DefaultJobsApiSorting,
  nationwide: !regions.isMarket(),
  page: 1,
  search: '',
  remoteStatus: [],
  selectedFilters: {
    categories: [],
    subCategories: [],
    experiences: [],
    industries: [],
    companySize: [],
    regions: [],
    locations: [],
    remoteStatus: [],
    nationwide: false,
    page: 1,
    search: '',
  },
  currentRoutePath: '',
});
const defaultPerPage = 10;
const per_page: number = parseInt(`${AppVars.PerPage}`) || defaultPerPage;

const getters: JobsGetters = {
  perPage: () => per_page,

  filteredJobs: (state) => state.filteredJobs,

  searchWeightId: (state) => state.basicCompaniesJobs.searchWeightId,

  activeCategoryIds: (state) => state.activeCategories.map((category: Category) => category.id),

  activeCategoryName: (state) =>
    state.activeCategories.map((category: Category) => category.seo_name),

  activeCategoryPath: (state) => state.activeCategories.map((category: Category) => category.alias),

  activeCompanySizes: (state) => state.activeCompanySizes.map((size) => size.value),

  activeExperienceValues: (state) => {
    const values: string[] = [];
    if (state.activeExperiences) {
      state.activeExperiences.forEach((experience: Experience) => {
        const item = ExperiencesList.find((item) => item.id == experience.id);
        if (item) {
          item.values.forEach((value) => {
            if (!values.includes(value)) {
              values.push(value);
            }
          });
        }
      });
    }
    return values;
  },
  isRemoteSelected: (state) => {
    if (!state.remoteStatus.length) return false;
    return getIsRemoteSelected(state.remoteStatus);
  },
  isHybridSelected: (state) => {
    if (!state.remoteStatus.length) return false;
    return getIsHybridSelected(state.remoteStatus);
  },
  isInOfficeSelected: (state) => {
    if (!state.remoteStatus.length) return false;
    return getIsInOfficeSelected(state.remoteStatus);
  },
  hasSelectedFilters: (state, getters) => {
    let status: boolean = false;

    if (state.activeCategories && state.activeCategories.length) {
      status = true;
    }
    if (state.activeSubcategories && state.activeSubcategories.length) {
      status = true;
    }
    if (state.activeExperiences && state.activeExperiences.length) {
      status = true;
    }
    if (state.activeIndustries && state.activeIndustries.length) {
      status = true;
    }
    if (state.activeCompanySizes && state.activeCompanySizes.length) {
      status = true;
    }
    if (
      state.activeRegions &&
      (state.activeRegions.length > 1 ||
        (state.activeRegions.length == 1 && !getters.defaultRegionIsActive))
    ) {
      status = true;
    }
    if (state.activeLocations && state.activeLocations.length) {
      status = true;
    }
    if (getters.isRemoteSelected) {
      status = true;
    }
    if (state.nationwide) {
      status = true;
    }
    if (state.search.length) {
      status = true;
    }

    return status;
  },

  defaultRegionIsActive: (state) => {
    const i = state.activeRegions.findIndex((item) => item.id === regions.getCurrentRegionID());
    return i > -1 && state.activeRegions.length === 1 && !state.activeLocations.length;
  },

  activeExperiencePath: (state) =>
    state.activeExperiences.map((experience: Experience) => experience.alias),

  activeExperienceNames: (state) =>
    state.activeExperiences.map((experience: Experience) => experience.label),

  activeIndustryIds: (state) => state.activeIndustries.map((item: Industry) => item.id),

  activeIndustryPath: (state) => state.activeIndustries.map((industry: Industry) => industry.alias),

  activeIndustryNames: (state) =>
    state.activeIndustries.map((industry: Industry) => industry.seo_name),

  activeRegions: (state) => [...state.activeRegions],

  activeRegionPath: (state) => state.activeRegions.map((region: Region) => region.alias),

  activeRegionNames: (state) => state.activeRegions.map((region: Region) => region.name),

  activeLocationPath: (state) => state.activeLocations.map((location: Location) => location.alias),

  activeLocationNames: (state) =>
    state.activeLocations.map((location: Location) => location.seo_name),

  activeRemotePath: (state) => {
    const remoteStatus = state.remoteStatus;
    const hybridIds = constants.HYBRID_JOBS_TYPES_JRS;
    const paths = [] as string[];

    if (remoteStatus.includes(hybridIds.HYBRID)) paths.push('hybrid');
    if (remoteStatus.includes(hybridIds.IN_OFFICE)) paths.push('office');
    if (remoteStatus.includes(hybridIds.REMOTE)) paths.push('remote');

    return paths.join('/');
  },

  regions: (state, getters, rootState) => rootState.regions,

  activeSubcategoryIds: (state) => state.activeSubcategories.map((catID) => `${catID}`),

  activeRegionsIds: (state) => state.activeRegions.map((region) => region.id),

  activeLocationsIds: (state) => {
    const rl_ids: number[] = [];
    const l_ids: number[] = [];

    state.activeLocations.forEach((location) => {
      rl_ids.push(location.id);
    });
    state.activeRegions.forEach((region) => {
      const i = state.activeLocations.findIndex((l) => l.region_id === region.id);
      if (i === -1 && region.locations) {
        region.locations.forEach((l) => {
          l_ids.push(l.id);
        });
      }
    });
    return [...rl_ids, ...l_ids];
  },

  /**
   * Targeted Remote - Job Board Display
   * @see https://jira.builtin.com/browse/PODC-2286
   * @param state
   */
  targetJobsLocations: (state): string[] => {
    return jobTargetLocation(state.activeLocations, state.activeRegions);
  },

  multiLocationSelected: (state) => {
    let status: boolean = false;
    if (state.activeRegions.length > 1 || state.activeLocations.length > 1) {
      status = true;
    }
    return status;
  },

  ownLocationSelected: (state) => {
    let status: boolean = true;
    for (const i in state.activeLocations) {
      if (state.activeLocations[i].region_id != regions.getCurrentRegionID()) {
        status = false;
      }
    }
    for (const i in state.activeRegions) {
      if (state.activeRegions[i].id != regions.getCurrentRegionID()) {
        status = false;
      }
    }
    return status;
  },

  activeSubcategoryPath: (state) => {
    let subCategories: Subcategory[] | undefined;
    state.activeCategories.forEach((c: Category) => {
      if (state.subcategories && state.subcategories[c.id]) {
        subCategories = state.subcategories[c.id].filter((s: Subcategory) =>
          state.activeSubcategories.includes(s.id)
        );
      }
    });
    if (subCategories) {
      return subCategories.map((s: Subcategory) => s.alias);
    }
    return [];
  },

  activeSubcategoryNames: (state) => {
    let subCategories: Subcategory[] | undefined;
    state.activeCategories.forEach((c: Category) => {
      if (state.subcategories && state.subcategories[c.id]) {
        subCategories = state.subcategories[c.id].filter((s: Subcategory) =>
          state.activeSubcategories.includes(s.id)
        );
      }
    });
    if (subCategories) {
      return subCategories.map((s: Subcategory) => s.seo_name);
    }
    return [];
  },

  filteringParams: (state, getters) => {
    const { activeRegionsIds } = getters;
    const { activeLocations } = state;
    // does not select a region if a location is selected in it
    const region = activeRegionsIds.filter((regionId: number) => {
      return !activeLocations?.some((location: Location) => location.region_id === regionId);
    });

    const params: JobsWithCriterias = {
      categories: getters.activeCategoryIds.join(','),
      subcategories: getters.activeSubcategoryIds.join(','),
      experiences: getters.activeExperienceValues.join(','),
      industry: getters.activeIndustryIds.join(','),
      company_sizes: state.activeCompanySizes.map((size) => size.value).join(','),
      regions: !regions.isNational() && getters.activeLocationsIds.length ? '' : region.join(','),
      locations: getters.activeLocationsIds.join(','),
      remote: state.remoteStatus,
      working_option: state.remoteStatus,
      per_page,
      page: state.page,
      search: state.search,
      sortStrategy: state.activeSorting,
      job_locations: getters.targetJobsLocations.join(','),
      company_locations: getters.targetJobsLocations.join(','),
    };
    return params;
  },

  routeParams: (state, getters) => {
    const params: JobsRouteParams = {
      categoryPath: getters.activeCategoryPath,
      subCategoryPath: getters.activeSubcategoryPath,
      experiencePath: getters.activeExperiencePath,
      industryPath: getters.activeIndustryPath,
      regionPath: getters.activeRegionPath,
      companySizePath: getters.companySizePath,
      locationPath: getters.activeLocationPath,
      remotePath: getters.activeRemotePath,
      multiLocationSelected: getters.multiLocationSelected,
      ownLocationSelected: getters.ownLocationSelected,
      sorting: state.activeSorting,
      search: state.search,
    };
    return params;
  },

  jobsPathPreFiltered:
    (state, getters, rootState: any) =>
    (remote: boolean = false) => {
      const path: string[] = ['jobs'];
      const expertise: SelectedExpertise = rootState?.onboarding?.profile?.expertise.length
        ? rootState?.onboarding?.profile?.expertise[0]
        : false;

      // Adds remote part
      if (remote) {
        path.push('remote');
      }

      // Adds expertise category part
      if (expertise && expertise.expertise_id && rootState?.onboarding?.expertise_list.length) {
        const exp: Experience = rootState.onboarding.expertise_list.find(
          (item: Experience) => item.id == expertise.expertise_id
        );
        if (exp) {
          path.push(exp.alias);
        }
      }

      let url = `/${path.join('/')}`;
      url += '?prefilled';

      return url;
    },

  /*
   * @deprecated
   * Legacy, used only on homepage.
   * Use getRouteCriterias() in utils/jobs.ts instead.
   */
  buildRoute:
    (state, getters, rootState) =>
    (
      params: JobsRouteParams = {} as JobsRouteParams,
      destinationParams: JobsDestinationParams = {} as JobsDestinationParams
    ) => {
      let categoryPath = params.categoryPath ? [...params.categoryPath] : [];
      let subCategoryPath = params.subCategoryPath ? [...params.subCategoryPath] : [];
      let regionPath = params.regionPath ? [...params.regionPath] : [];
      let locationPath = params.locationPath ? [...params.locationPath] : [];
      let experiencePath = params.experiencePath ? [...params.experiencePath] : [];
      let industryPath = params.industryPath ? [...params.industryPath] : [];
      let companySizePath = params.companySizePath ? [...params.companySizePath] : [];
      let remotePath = params.remotePath ? params.remotePath : '';
      let ownLocationSelected = params.ownLocationSelected ? params.ownLocationSelected : false;
      let multiLocationSelected = params.multiLocationSelected
        ? params.multiLocationSelected
        : false;
      let nationwide = destinationParams?.nationwide
        ? destinationParams.nationwide
        : state.nationwide;
      let regionJobBoardPath: string[] = [];
      const sorting = destinationParams?.sorting ? destinationParams.sorting : params.sorting;
      const search = destinationParams?.search ? destinationParams.search : params.search;

      // Remote
      if (destinationParams.remote !== undefined) {
        remotePath = destinationParams.remote;
      }

      // National
      if (regions.isNational()) {
        // do not append /nationwide to URLs for nationwide region only.
        nationwide = false;
      }

      // Region
      if (destinationParams?.region) {
        nationwide = false;
        const region = destinationParams.region;
        if (regionPath.includes(region.alias)) {
          regionPath = regionPath.filter((item) => item !== region.alias);
          locationPath = state.activeLocations
            .filter((item) => item.region_id != region.id)
            .map((item) => item.alias);
        } else {
          regionPath.push(region.alias);
        }
      }

      // Location
      if (destinationParams?.location) {
        nationwide = false;
        const location = destinationParams.location;
        if (locationPath.includes(location.alias)) {
          locationPath = locationPath.filter((item) => item !== location.alias);
        } else {
          locationPath.push(location.alias);
          const lregion = rootState.regions.find((r: Region) => r.id === location.region_id);
          if (lregion && !regionPath.includes(lregion.alias)) {
            regionPath.push(lregion.alias);
          }
        }
      }

      // Category
      if (destinationParams?.category) {
        if (!categoryPath.includes(destinationParams.category)) {
          subCategoryPath = [];
        }
        categoryPath = [destinationParams.category];
      }

      // Subcategory
      if (destinationParams?.subcategory) {
        if (subCategoryPath.includes(destinationParams.subcategory)) {
          subCategoryPath = subCategoryPath.filter(
            (item) => item !== destinationParams.subcategory
          );
        } else {
          subCategoryPath.push(destinationParams.subcategory);
        }
      }

      // Experience
      if (destinationParams?.experience) {
        if (experiencePath.includes(destinationParams.experience)) {
          experiencePath = experiencePath.filter((item) => item !== destinationParams.experience);
        } else {
          experiencePath.push(destinationParams.experience);
        }
      }
      if (destinationParams?.experienceAll) {
        experiencePath = [];
      }

      // Industry
      if (destinationParams?.industry) {
        if (industryPath.includes(destinationParams.industry)) {
          industryPath = industryPath.filter((item) => item !== destinationParams.industry);
        } else {
          industryPath.push(destinationParams.industry);
        }
      }
      if (destinationParams?.industryAll) {
        industryPath = [];
      }

      // Company size
      if (destinationParams?.companySize) {
        if (companySizePath.includes(destinationParams.companySize)) {
          companySizePath = companySizePath.filter(
            (item) => item !== destinationParams.companySize
          );
        } else {
          companySizePath.push(destinationParams.companySize);
        }
      }

      // update ni param
      if (destinationParams?.region || destinationParams?.location) {
        multiLocationSelected = false;
        ownLocationSelected = false;
        if (regionPath.length > 1 || locationPath.length > 1) {
          multiLocationSelected = true;
        } else {
          const currentRegion = rootState.regions.find(
            (item: Region) => item.id == regions.getCurrentRegionID()
          );
          if (regionPath.length == 1 && currentRegion && regionPath.includes(currentRegion.alias)) {
            ownLocationSelected = true;
          }
          if (!regionPath.length && !destinationParams?.nationwide) {
            ownLocationSelected = true;
          }
        }
      }
      const multiFilterOptions =
        industryPath.length > 1 ||
        experiencePath.length > 1 ||
        subCategoryPath.length > 1 ||
        multiLocationSelected;

      // Arguments
      let suffix: string = '';
      const suffix_args: string[] = [];
      let ni: string = '';
      let page = '';

      if ((regions.isMarket() && !ownLocationSelected) || multiFilterOptions) {
        ni = 'ni=2';
      }
      if (ni) {
        suffix_args.push(ni);
      }
      if (destinationParams?.page && destinationParams.page > 1) {
        page = `page=${destinationParams.page}`;
        suffix_args.push(page);
      }
      if (search?.length) {
        suffix_args.push(`search=${encodeURIComponent(search)}`);
      }
      if (sorting === SortNameHotJobs && shouldUseJobsApiSorting()) {
        suffix_args.push('trending');
      }
      if (suffix_args.length) {
        suffix = `?${suffix_args.join('&')}`;
      }
      // remove region name from local job board when sub location is not selected
      regionJobBoardPath = regionPath;
      if (!state.nationwide) {
        regionJobBoardPath =
          destinationParams?.location || state.activeLocations?.length ? regionPath : [];
      }

      return formatJobFacetPath(
        [
          '/jobs',
          remotePath,
          nationwide ? 'nationwide' : regionJobBoardPath,
          nationwide ? '' : locationPath,
          categoryPath,
          subCategoryPath,
          experiencePath,
          industryPath,
          companySizePath,
        ],
        suffix
      );
    },

  metaTagsInfo: (state, getters) => {
    let category = getters.activeCategoryName;
    let subCategories = getters.activeSubcategoryNames;
    let experiences = getters.activeExperienceNames;
    let industries = getters.activeIndustryNames;
    const regions = getters.activeRegionNames;
    const locations = getters.activeLocationNames;
    const remote = state.remoteStatus;
    const categoryList = category;
    // Exceptional cases:
    if (subCategories.length > 0) {
      category = [];
    }
    if (subCategories.length > 1) {
      subCategories = [];
    }
    if (experiences.length > 1) {
      experiences = [];
    }
    if (industries.length > 1) {
      industries = [];
    }

    return {
      title: [
        experiences.join(' '),
        industries.join(' '),
        category.join(', '),
        subCategories.join(' '),
      ]
        .filter((item: string) => item !== '')
        .join(' '),
      h1: [
        experiences.join(' '),
        industries.join(' '),
        category.join(', '),
        subCategories.join(' '),
      ]
        .filter((item: string) => item !== '')
        .join(' '),
      description: [
        experiences.join(' '),
        industries.join(' '),
        category.join(', '),
        subCategories.join(' '),
      ]
        .filter((item: string) => item !== '')
        .join(' '),
      locationNames: locations,
      regionNames: regions,
      remoteStatus: remote,
      categories: categoryList,
      isRemoteSelected: getters.isRemoteSelected,
      isHybridSelected: getters.isHybridSelected,
      isInOfficeSelected: getters.isInOfficeSelected,
    };
  },

  listedCompany: (state) => (companyId: number) => {
    return state.eliteCompanies.find((company: JobCompany) => company.id === companyId);
  },

  companyLogo: (state) => (companyId: number) => {
    const companyData = state.basicCompaniesJobs.companiesJobs.find(
      (basicData) => basicData.company.id === companyId
    );

    if (companyData) {
      return companyData.company.logo;
    }
    return `${AppVars.BaseUrl}/assets/company-logo-fallback.png`;
  },

  getNationwide: (state) => state.nationwide,

  checkingParamsRelevance: (state, getters) => () => {
    const params = getters.filteringParams;
    const value = JSON.stringify(params);
    let lastValue: boolean | string = false;
    const lastValueIndex = state.jobsWithCompaniesRequests.length - 1;
    if (lastValueIndex >= 0) {
      lastValue = state.jobsWithCompaniesRequests[lastValueIndex];
    }
    if (value && lastValue && value !== lastValue) {
      return false;
    }
    return true;
  },
  eliteJobsData: (state) => {
    return state.eliteJobs;
  },
  jobCount: (state) => {
    return state.basicCompaniesJobs.jobsCount;
  },
};

const mutations: JobsMutations = {
  setActiveSorting(state, name) {
    state.activeSorting = name;
  },

  setNationwide(state, status) {
    state.nationwide = status;
  },

  setCategories(state, categories) {
    state.categories = categories;
  },

  setIndustries(state, industries) {
    state.industries = industries;
  },

  setJobs(
    state,
    data: {
      basicJobs: JobsCompaniesCollapsedResponse;
      eliteJobs: InferType<State['eliteJobs']>;
      eliteCompanies: InferType<State['eliteCompanies']>;
    }
  ) {
    const { basicJobs, eliteJobs, eliteCompanies } = data;

    state.basicCompaniesJobs = {
      paginationCount: basicJobs.pagination_count ?? 0,
      companyCount: basicJobs.company_count,
      companiesJobs: basicJobs.company_jobs.map((companyJob) => {
        return {
          company: companyJob.company,
          jobs: companyJob.jobs,
          jobTotal: companyJob.job_total,
        };
      }),
      jobsCount: basicJobs.job_count,
      jobAllCount: basicJobs.job_all_count,
      searchWeightId: basicJobs.search_weight_id,
    };

    state.eliteJobs = eliteJobs;
    state.eliteCompanies = eliteCompanies;
  },

  updateCompanyJobsData(state, data: CompanyJobs) {
    const { companiesJobs } = state.basicCompaniesJobs;
    const newCompaniesJobs = companiesJobs.map((companyJobs) => {
      if (data.company.id === companyJobs.company.id) {
        return data;
      }

      return companyJobs;
    });

    state.basicCompaniesJobs = {
      ...state.basicCompaniesJobs,
      companiesJobs: newCompaniesJobs,
    };
  },

  setFilteredJobs(state, { jobs }) {
    state.filteredJobs = jobs;
  },

  setPage(state, page) {
    state.page = page;
  },

  setSearchKeyword(state, keyword) {
    state.search = keyword;
  },

  setSubcategories(state, subcategories) {
    state.subcategories = subcategories;
  },

  activateCategory(state, category) {
    const i = state.activeCategories.findIndex(
      (activeCategory) => activeCategory.id === category.id
    );
    if (i == -1) {
      state.activeCategories = [category];
    }
  },

  deactivateCategory(state, category) {
    const i = state.activeCategories.findIndex(
      (activeCategory) => activeCategory.id === category.id
    );

    if (i > -1) {
      state.activeCategories.splice(i, 1);
    }
  },

  activateSubcategory(state, subcategoryID) {
    const i = state.activeSubcategories.findIndex(
      (activeSubcategory) => activeSubcategory === subcategoryID
    );
    if (i == -1) {
      state.activeSubcategories.push(subcategoryID);
    }
  },

  activateSubcategories(state, subcategoryIDs: number[]) {
    state.activeSubcategories = subcategoryIDs;
  },

  deactivateSubcategory(state, subcategoryID: number) {
    const i = state.activeSubcategories.findIndex(
      (activeSubcategory) => activeSubcategory === subcategoryID
    );
    if (i > -1) {
      state.activeSubcategories.splice(i, 1);
    }
  },

  deactivateSubcategoriesForCategory(state, category) {
    if (state.subcategories[category.catId] != undefined) {
      const subcatIDsForCategory = state.subcategories[category.catId].map(
        (subcategory) => subcategory.id
      );

      const newActiveSubcategories = state.activeSubcategories.filter(function (subcatID) {
        return !subcatIDsForCategory.includes(subcatID);
      });

      state.activeSubcategories = newActiveSubcategories;
    }
  },

  activateExperience(state, item) {
    const i = state.activeExperiences.findIndex(
      (activeExperience) => activeExperience.id === item.id
    );
    if (i == -1) {
      const experience = state.experiences.find((e) => e.id === item.id);
      if (experience) {
        state.activeExperiences.push(experience);
      }
    }
  },

  deactivateExperience(state, item) {
    if (item == undefined) {
      state.activeExperiences = [];
    } else {
      const i = state.activeExperiences.findIndex(
        (activeExperience) => activeExperience.id === item.id
      );
      if (i > -1) {
        state.activeExperiences.splice(i, 1);
      }
    }
  },

  activateExperiences(state, items: FacetItem[]) {
    const experiences: Experience[] = state.experiences.filter((e: Experience) =>
      items.map((i) => i.id).includes(e.id)
    );

    if (experiences) {
      state.activeExperiences = experiences;
    }
  },

  activateIndustry(state, item) {
    const i = state.activeIndustries.findIndex((activeIndustry) => activeIndustry.id === item.id);
    if (i == -1) {
      const industry: Industry | undefined = state.industries.find(
        (i: Industry) => i.id === item.id
      );
      if (industry) {
        state.activeIndustries.push(industry);
      }
    }
  },

  deactivateIndustry(state, item?: FacetItem) {
    if (item == undefined) {
      state.activeIndustries = [];
    } else {
      const i = state.activeIndustries.findIndex((activeIndustry) => activeIndustry.id === item.id);
      if (i > -1) {
        state.activeIndustries.splice(i, 1);
      }
    }
  },

  activateIndustries(state, items: FacetItem[]) {
    const industries: Industry[] = state.industries.filter((i: Industry) =>
      items.map((f: FacetItem) => f.id).includes(i.id)
    );

    if (industries) {
      state.activeIndustries = industries;
    }
  },

  activateRegionSingle(state, region) {
    const i = state.activeRegions.findIndex((activeRegion) => activeRegion.id === region.id);
    if (i == -1) {
      state.activeRegions.push(region);
    }
  },

  activateRegions(state, regions: Region[]) {
    state.activeRegions = regions;
  },

  deactivateRegionSingle(state, item: FacetItem | { id: number }) {
    const i = state.activeRegions.findIndex((activeRegion) => activeRegion.id === item.id);
    if (i > -1) {
      state.activeRegions.splice(i, 1);
      const locations = state.activeLocations.filter(
        (location: Location) => location.region_id !== item.id
      );
      state.activeLocations = locations;
    }
  },

  activateLocations(state, locations: Location[]) {
    state.activeLocations = locations;
  },

  deactivateLocation(state, item: FacetItem | { id: number }) {
    const i = state.activeLocations.findIndex((activeLocation) => activeLocation.id === item.id);
    if (i > -1) {
      state.activeLocations.splice(i, 1);
    }
  },

  deactivateRegionsAndLocations(state) {
    state.activeRegions = [];
    state.activeLocations = [];
  },

  setRemoteStatus(state, status) {
    state.remoteStatus = status;
  },

  setJobsWithCompaniesRequest(state, params: JobsWithCriterias) {
    const value = JSON.stringify(params);
    state.jobsWithCompaniesRequests.push(value);
  },

  setJobsWithCompaniesRespond(state, params: JobsWithCriterias) {
    const value = JSON.stringify(params);
    state.jobsWithCompaniesResponds.push(value);
  },
  _updateFiltersData(state, data: FiltersDataState) {
    const {
      page,
      categories,
      subCategories,
      industries,
      companySize,
      search,
      remoteStatus,
      experiences,
      locations,
      regions,
      sorting,
    } = data;

    state.page = page;
    state.search = search;
    state.remoteStatus = remoteStatus;
    state.activeSorting = sorting;
    state.activeCategories = categories;
    state.activeSubcategories = subCategories;
    state.activeIndustries = industries;
    state.activeCompanySizes = companySize;
    state.activeExperiences = experiences;
    state.activeRegions = regions;
    state.activeLocations = locations;
  },
  setSelectedFilters(state, filter) {
    state.selectedFilters = {
      categories: [],
      subCategories: [],
      experiences: [],
      industries: [],
      companySize: [],
      regions: [],
      locations: [],
      remoteStatus: [],
      nationwide: false,
      page: 1,
      search: undefined,
      ...filter,
    };

    let categoryPath = [] as string[];
    let subCategoryPath = [] as string[];
    let experiencePath = [] as string[];
    let industryPath = [] as string[];
    let companySizePath = [] as string[];
    let locationPath = [] as string[];
    let regionJobBoardPath = [] as string[];
    let remotePath = [] as string[];

    const getRemoteStringFromId = (id: number) => {
      switch (id) {
        case constants.HYBRID_JOBS_TYPES_JRS.IN_OFFICE:
          return 'office';
        case constants.HYBRID_JOBS_TYPES_JRS.REMOTE:
          return 'remote';
        case constants.HYBRID_JOBS_TYPES_JRS.HYBRID:
          return 'hybrid';
      }

      return '';
    };

    remotePath = state.selectedFilters.remoteStatus.map((selectionId) =>
      getRemoteStringFromId(selectionId)
    );

    categoryPath = state.selectedFilters.categories.map((object) => {
      return object.alias;
    });

    subCategoryPath = state.selectedFilters.subCategories.map((object) => {
      return object.alias;
    });

    experiencePath = state.selectedFilters.experiences.map((object) => {
      return object.alias;
    });

    industryPath = state.selectedFilters.industries.map((object) => {
      return object.alias;
    });

    companySizePath = state.selectedFilters.companySize.map((object) => {
      return object.value;
    });

    locationPath = state.selectedFilters.locations.map((object) => {
      return object.alias;
    });

    regionJobBoardPath = state.selectedFilters.regions.map((object) => {
      return object.alias;
    });

    const multiFilterOptions =
      state.selectedFilters.industries?.length > 1 ||
      state.selectedFilters.experiences?.length > 1 ||
      state.selectedFilters.categories?.length > 1 ||
      state.selectedFilters.locations?.length > 1 ||
      state.selectedFilters.remoteStatus?.length > 1 ||
      state.selectedFilters.companySize?.length > 1 ||
      state.selectedFilters.regions?.length > 1;

    // Arguments
    let suffix = '';
    const suffixArgs: string[] = [];
    let ni = '';
    let page = '';

    if (multiFilterOptions) {
      ni = 'ni=2';
    }
    if (ni) {
      suffixArgs.push(ni);
    }
    if (state.selectedFilters.page && state.selectedFilters.page > 1) {
      page = `page=${state.selectedFilters.page}`;
      suffixArgs.push(page);
    }
    if (state.selectedFilters.search?.length) {
      suffixArgs.push(`search=${encodeURIComponent(state.selectedFilters.search)}`);
    }
    if (suffixArgs.length) {
      suffix = `?${suffixArgs.join('&')}`;
    }

    state.currentRoutePath = formatJobFacetPath(
      [
        '/jobs',
        remotePath,
        state.selectedFilters.nationwide ? 'nationwide' : regionJobBoardPath,
        state.selectedFilters.nationwide ? '' : locationPath,
        categoryPath,
        subCategoryPath,
        experiencePath,
        industryPath,
        companySizePath,
      ],
      suffix
    );
  },
};

const actions: JobsActions = {
  async setSelectedFilters({ commit }, filter) {
    commit('setSelectedFilters', filter);
  },

  setRemoteStatusAction({ commit }, status) {
    commit('setRemoteStatus', status);
  },

  setPageAction({ commit }, page) {
    commit('setPage', page);
  },

  toggleActiveRegionsSortingAction({ commit }, id: number) {
    commit('toggleActiveRegionsSorting', id);
  },

  setNationwideAction({ commit }, status) {
    commit('setNationwide', status);
  },

  getCategories({ state, commit }) {
    if (!state?.categories.length) {
      return jobsApiService.getCategories().then((categories: Category[]) => {
        commit('setCategories', categories);
      });
    }
    return Promise.resolve();
  },

  getIndustries({ commit, state }) {
    if (!state?.industries.length) {
      return jobsApiService.getIndustries().then((industries: Industry[]) => {
        commit('setIndustries', industries);
      });
    }
    return Promise.resolve();
  },

  getJobsWithCompanies({ commit, getters }, payload = {}) {
    const params: JobsWithCriterias = isEmpty(payload) ? getters.filteringParams : payload;
    commit(RootActionNames.loadingAdd, 'getJobsWithCompanies', {
      root: true,
    });
    commit('setJobsWithCompaniesRequest', params);

    /**
     * Turn off / ignore HVP whenever keyword search is used
     * PODC-2516
     */
    const basicJobsPromise = !params.search
      ? jobsApiService.getJobsWithCompaniesHVP(params)
      : jobsApiService.getJobsWithCompaniesAsHVP(params);

    const eliteJobsPromise = jobsApiService.getJobsWithCompanies({
      ...params,
      elite: true,
      per_page: 3,
    });
    return new Promise(async (resolve, reject) => {
      try {
        const responses: [JobsCompaniesCollapsedResponse, JobsCompaniesData] = await Promise.all([
          basicJobsPromise,
          eliteJobsPromise,
        ]);
        const [basicJobsData, eliteJobsData] = responses;

        let checkStatus = getters.checkingParamsRelevance;
        if (checkStatus == undefined) {
          checkStatus = true;
        }
        if (checkStatus) {
          // first load, display only 2 high volume job
          let data: JobsCompaniesCollapsedResponse = {
            ...basicJobsData,
            company_jobs: basicJobsData.company_jobs.map((companyJobs) => ({
              ...companyJobs,
              jobs: companyJobs.jobs.slice(0, 3),
            })),
          };

          commit('setJobs', {
            basicJobs: data,
            eliteJobs: {
              jobs: eliteJobsData.jobs,
              high_volume_jobs: eliteJobsData.high_volume_jobs,
              job_count: eliteJobsData.job_count,
              job_all_count: eliteJobsData.job_all_count,
              searchWeightId: eliteJobsData.search_weight_id,
            },
            eliteCompanies: eliteJobsData.companies,
          });
        }
        resolve();
      } catch (e: any) {
        console.error(e);
        reject(e);
        throw new Error(e);
      } finally {
        commit('setJobsWithCompaniesRespond', params);
        commit(RootActionNames.loadingRemove, 'getJobsWithCompanies', {
          root: true,
        });
      }
    });
  },

  getFilteredJobs({ commit }, params: JobsFilterParams) {
    return jobsApiService.getJobs(params).then(({ jobs }: JobsData) => {
      commit('setFilteredJobs', { jobs });
    });
  },

  getSubcategoryData({ commit, state }) {
    if (!state?.subcategories || !Object.keys(state?.subcategories).length) {
      return jobsApiService.getSubcategoryData().then((subcategories: SubcategoryMap) => {
        commit('setSubcategories', subcategories);
      });
    }
    return Promise.resolve();
  },

  /**
   * @param commit
   * @param getters
   * @param state
   * @param data - company id for loading new high volume jobs
   */
  async loadMoreCompanyJobs({ commit, getters, state }, data: LoadCompanyJobs) {
    const { basicCompaniesJobs } = state;
    const { filteringParams } = getters;
    try {
      const companyOldJobs = basicCompaniesJobs.companiesJobs.find((companyJobs) => {
        return data.companyId === companyJobs.company.id;
      });
      if (companyOldJobs) {
        const oldJobs = [...companyOldJobs.jobs];

        const basicJobs: JobsCompaniesCollapsedResponse =
          await jobsApiService.getJobsWithCompaniesHVP({
            ...filteringParams,
            jobs_board: true,
            company_ids: data.companyId.toString(),
            per_page: '',
            page: '',
            exclude_job_ids: oldJobs.map((job) => job.id).join(','),
          });

        const [loadedCompanyJobs] = basicJobs.company_jobs;
        const { jobs: loadedJobs } = loadedCompanyJobs;
        let jobsToDisplay: Job[] = [];
        if (loadedJobs) {
          jobsToDisplay = [...oldJobs, ...loadedJobs];
        }

        commit('updateCompanyJobsData', { ...companyOldJobs, jobs: jobsToDisplay });
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  },
  /**
   * set initial data for filters (category, regions, experience, etc.)
   * @param commit
   * @param state
   * @param data - list of all available filters categorized by type
   */
  filteringParamsInitData({ commit, rootState }, data: FiltersData) {
    const {
      categories,
      subCategories,
      experiences,
      industries,
      companySize,
      regions,
      locations,
      remoteStatus,
      page,
      search,
      sorting,
      isNationwideUrlPath,
    } = data;

    const initData: FiltersDataState = {
      categories: [],
      subCategories: [],
      experiences: [],
      industries: [],
      companySize: [],
      regions: [],
      locations: [],
      remoteStatus,
      page,
      search,
      sorting,
    };

    // Activate filters
    if (categories?.length) {
      initData.categories = [...categories];
      initData.subCategories = subCategories.map((subCategory) => subCategory.id);
    }

    if (experiences?.length) {
      initData.experiences = [...experiences];
    }

    if (industries?.length) {
      initData.industries = [...industries];
    }

    if (companySize?.length) {
      initData.companySize = [...companySize];
    }

    const defaultRegion = rootState.regions.find(
      (region) => region.id === regionsData.getCurrentRegionID()
    );

    if (regions?.length) {
      initData.regions = regions;
      if (locations.length) {
        initData.locations = locations;
      }
    } else if (defaultRegion && !isNationwideUrlPath) {
      initData.regions = defaultRegion ? [defaultRegion] : [];
    }

    commit('_updateFiltersData', initData);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
