










import { defineComponent } from '@nuxtjs/composition-api';

/**
 * Renders a link to the Employer Portal
 * for use in navigation components.
 */
export default defineComponent({
  name: 'EmployerPortalNavLink',
  props: {
    showSeparator: {
      type: Boolean,
      default: true,
    },
  },
});
