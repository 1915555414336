export const Reasons = {
  none: 0b0000,
  notSure: 0b0001,
  techNews: 0b0010,
  jobs: 0b0100,
  companies: 0b1000,
};
export const Steps = {
  signIn: 0,
  confirmInfo: 1,
  whyWereHere: 2,
  experience: 3,
  skills: 4,
  preferredCities: 5,
  citiesTopicsReadAbout: 6,
  companyValues: 7,
  workHistory: 8,
};

const flowFactory = (isMain: boolean) => [
  {
    why: [Reasons.none],
    flow: [
      Steps.signIn,
      Steps.confirmInfo,
      Steps.whyWereHere,
      Steps.companyValues,
      Steps.citiesTopicsReadAbout,
      Steps.experience,
      Steps.skills,
      Steps.preferredCities,
      Steps.workHistory,
    ],
  },
  {
    why: [Reasons.companies, Reasons.companies + Reasons.notSure],
    flow: [
      Steps.signIn,
      Steps.confirmInfo,
      Steps.whyWereHere,
      Steps.companyValues,
      Steps.experience,
      Steps.skills,
      Steps.preferredCities,
    ],
  },
  {
    why: [Reasons.jobs, Reasons.jobs + Reasons.notSure],
    flow: [
      Steps.signIn,
      Steps.confirmInfo,
      Steps.whyWereHere,
      Steps.companyValues,
      Steps.experience,
      Steps.skills,
      Steps.preferredCities,
      Steps.workHistory,
    ],
  },
  {
    why: [Reasons.techNews, Reasons.techNews + Reasons.notSure],
    flow: [
      Steps.signIn,
      Steps.confirmInfo,
      Steps.whyWereHere,
      Steps.citiesTopicsReadAbout,
      Steps.experience,
      Steps.skills,
    ],
  },
  {
    why: [Reasons.companies + Reasons.jobs, Reasons.companies + Reasons.jobs + Reasons.notSure],
    flow: [
      Steps.signIn,
      Steps.confirmInfo,
      Steps.whyWereHere,
      Steps.companyValues,
      Steps.experience,
      Steps.skills,
      Steps.preferredCities,
      Steps.workHistory,
    ],
  },
  {
    why: [
      Reasons.companies + Reasons.techNews,
      Reasons.companies + Reasons.techNews + Reasons.notSure,
    ],
    flow: [
      Steps.signIn,
      Steps.confirmInfo,
      Steps.whyWereHere,
      Steps.companyValues,
      Steps.citiesTopicsReadAbout,
      Steps.experience,
      Steps.skills,
      Steps.preferredCities,
    ],
  },
  {
    why: [Reasons.jobs + Reasons.techNews, Reasons.jobs + Reasons.techNews + Reasons.notSure],
    flow: [
      Steps.signIn,
      Steps.confirmInfo,
      Steps.whyWereHere,
      Steps.companyValues,
      ...(isMain ? [] : [Steps.citiesTopicsReadAbout]),
      Steps.experience,
      Steps.skills,
      Steps.preferredCities,
      Steps.workHistory,
    ],
  },
  {
    why: [
      Reasons.notSure,
      Reasons.companies + Reasons.jobs + Reasons.techNews,
      Reasons.companies + Reasons.jobs + Reasons.techNews + Reasons.notSure,
    ],
    flow: [
      Steps.signIn,
      Steps.confirmInfo,
      Steps.whyWereHere,
      Steps.companyValues,
      Steps.citiesTopicsReadAbout,
      Steps.experience,
      Steps.skills,
      Steps.preferredCities,
      Steps.workHistory,
    ],
  },
];

export const StepsFlow = flowFactory(true);
export const MarketStepsFlow = flowFactory(false);
