import { isNational } from '@/api/regions';
import { getCurrentInstance, computed } from '@nuxtjs/composition-api';
import { isCompanyAdmin } from '@/utils/user-permissions';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { userModule as userData } from '@/utils/store-accessor';

import {
  UserNamespace,
  State as UserState,
  UserGetters,
  UserActions,
  UserMutations,
} from '@/store/modules/user';

const userModule = createNamespacedHelpers<UserState, UserGetters, UserActions, UserMutations>(
  UserNamespace
);

/* Vue hook to provide authentication behaviors
 *
  Responsible for:
  - Checking if a user is authenticated
  - Authenticating a user
  - Signing a user out
 *
*/
export const useAuthentication = () => {
  const instance = getCurrentInstance()?.proxy;
  if (instance == null) {
    throw new Error('useAuthentication must be called from within setup');
  }

  const { isAuthenticated } = userModule.useGetters(['isAuthenticated']);
  const { authenticate, signout: storeSignout } = userModule.useActions([
    'authenticate',
    'signout',
  ]);

  /** @returns ***true** if the user is not signed into the website, else **false**. */
  const isAnonymous = computed(() => !isAuthenticated.value);

  /**
   * Registers the user using the built in identity server
   *
   * @param redirectUrl - The url to return to after onboarding
   */
  const signup = async (redirectUrl: string, skipOnboarding: boolean = false) => {
    if (localStorage) {
      localStorage.setItem('skipOnboarding', String(skipOnboarding));
    }
    const payload = {
      redirectUrl:
        skipOnboarding || isNational()
          ? redirectUrl
          : `/onboarding?destination=${encodeURIComponent(redirectUrl)}`,
      useSignup: true,
    };
    await authenticate(payload);
  };

  /**
   * Signs the user in using the built in identity server
   *
   * @param redirectUrl - The url to return to once signed in
   */
  const signin = async (redirectUrl?: string) => {
    const hasCompanyPermissions = isCompanyAdmin(userData.profile?.permissions || []);
    if (hasCompanyPermissions) {
      redirectUrl = '/employer';
    }
    const payload = {
      redirectUrl: `${redirectUrl}`,
      useSignup: false,
    };
    await authenticate(payload);
  };

  /**
   * - Signs the user out through from identity and clears their information from the store
   * - Clears account upgrade flags so that different users on the same maching can
   *   go through the upgrade flow
   */
  const signout = async () => {
    await storeSignout();
  };

  return {
    isAuthenticated,
    isAnonymous,
    signup,
    signin,
    signout,
  };
};
