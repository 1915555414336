import axios from 'axios';

import AppVars from '@/enums/appVars';
import { userModule } from '@/utils/store-accessor';

/**
 * Wraps Axios requests to append
 * authorization and Vue app version headers.
 */
const bixAxios = axios.create({
  headers: { 'builtin-vue-version': `${AppVars.AppVersion}` },
});

bixAxios.interceptors.request.use((config) => {
  const isSecureBuiltInUrl = (url?: string) => !!url && url.match(/https:\/\/.*\.builtin\.com/);

  if (userModule.isAuthenticated && isSecureBuiltInUrl(config.url)) {
    config.headers!.Authorization = userModule.authHeader;
  }

  return config;
});

export default bixAxios;
