import bixAxios from '@/api/bixAxios';

import AppVars from '@/enums/appVars';

import TrackingAPI from '@/enums/trackingApiRoutes';

let validatedId = false;

export interface ClientIdentifierService {
  getItem(client_identifier?: string): Promise<string>;

  clearItem(): void;
}

interface clientIdentifierResponse {
  builtin_client_id: string;
}

const getLocalId = (): string => window.localStorage.getItem(AppVars.ClientKeyId) || '';

export const clientIdentifierRequest = {
  clientIdentifier(localId: string): Promise<clientIdentifierResponse> {
    let url = TrackingAPI.ClientIdentifier;
    if (localId) {
      url += `?builtin_client_id=${localId}`;
    }
    return bixAxios.get(url).then((response: { data: clientIdentifierResponse }) => response.data);
  },
};

export const clientIdentifierService = {
  async getItem(client_identifier?: string): Promise<string> {
    if (client_identifier) {
      window.localStorage.setItem(AppVars.ClientKeyId, client_identifier);
      return client_identifier;
    }
    const localId = getLocalId();
    if (validatedId) {
      return localId;
    }
    try {
      const { builtin_client_id } = await clientIdentifierRequest.clientIdentifier(localId);
      if (validatedId) {
        return getLocalId();
      }
      window.localStorage.setItem(AppVars.ClientKeyId, builtin_client_id);
      validatedId = true;
      return builtin_client_id;
    } catch {
      // try to use whatever is available locally
      return getLocalId();
    }
  },
  getLocalId,
  clearItem: () => {
    validatedId = false;
    window.localStorage.removeItem(AppVars.ClientKeyId);
  },
};
