import { ICompany, ICompanyJobsFiltersOptions, IJob } from '@/typings/api/external-api-gql';
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index';

import { Job, Category } from '@/store/jobs/types';
import { Metatag, BasicMetatag, RootState } from '@/store/types';
export interface State {
  company: Company;
  companyOverview: ICompany;
  display_jobs: Job[];
  profile_tabs: Tab[];
  display_qa: QA;
  // The current expertise category used to filter jobs
  active_category: number;
  // The height in pixels of the company tabs
  // navigation element. Used to calculate scroll position.
  companyTabsOffset: number;
  // Available filters based on the company's jobs
  IJobFilters: ICompanyJobsFiltersOptions | undefined;
  // Company GraphQL jobs
  IJobs: IJob[];
  // Unpaginated total of filtered company GraphQL jobs
  IJobsTotal: number;
  // Flag to prevent duplicate API calls
  hasFetchedIJobFilters: boolean;
  // Flag used to show loading indicator.
  isFetchingIJobs: boolean;
}
export interface CompaniesGetters extends GetterTree<State, RootState> {
  getMarketURL: () => string;
}

export interface CompaniesMutations extends MutationTree<State> {
  clearCompany: (state: State) => void;
  setCompanyTabsOffset: (state: State, newOffset: number) => void;
  setIJobFilters: (state: State, filters: ICompanyJobsFiltersOptions) => void;
}

export interface CompaniesActions extends ActionTree<State, RootState> {}

export interface Company {
  id: number;
  region_id: number;
  title: string;
  last_updated: Date;
  high_volume_poster: boolean;
  insider_page: boolean;
  show_funding: boolean;
  year_founded: Date;
  local_employees: number;
  total_employees: number;
  street_address_1: string;
  street_address_2: string;
  city: string;
  state: string;
  zipcode: string;
  email: string;
  facebook: string;
  instagram: string;
  intro_header: string;
  mission: string;
  neighborhood: string;
  twitter: string;
  why_work_with_us: string;
  url: string;
  logo: string;
  header_url: string;
  basic_metatag: BasicMetatag;
  funding: FundingData[];
  culture_topics: CultureTopic[];
  jobs: Job[];
  categories: Category[];
  industries: Industry[];
  perks: Perks<PerkWithDescription[]>;
  mini_description: string;
  galleries: string[];
  adjectives: string[];
  elite_page_type: string;
  insiders_view: QA[];
  technologies: Technology[];
  technologies_extra: ExtraTechnology[];
  alias: string;
  job_count: number;
  publish_insiders_page: boolean;
  insider_video: InsiderVideo[];
  subscription_id: number;
  about_perks: string;
  perks_overview: string;
  metatags: Metatag[];
  video: string;
  office_type: number;
  offices: CompanyOffice[];
  limited_listings: boolean;
  exit_history: ExitData[];
  pixel_enabled: boolean;
  pixel: string;
  status: number;
}

export interface CompanyOverview {
  id: number;
  region_id: number;
  alias: string;
  url: string;
  title: string;
  logo: string;
  industries: string[];
  market_site_company_id: number;
  subscription_id: number;
  last_updated: Date;
}

export interface ProfileCompleteness {
  failures: ProfileCompletenessFailures;
  percent_complete: number;
}

export interface ProfileCompletenessFailures {
  [key: string]: {
    percent_complete: number;
    reasons: CompanyProfileSection[];
  };
}

export interface CompanySearchParam {
  company_name_part: string;
  max_results: number;
  region_id: number;
}

export interface CompanyProfileParam {
  alias: string;
  region_id: number;
}

export interface FundingData {
  id: number;
  funding_amt: number;
  funding_cat: number;
  funding_date: string;
  funding_investors: string;
  funding_type: string;
}

export interface CultureTopic {
  id: number;
  company_id: number;
  title: string;
  category: string;
  overview: string;
  quote_question: string;
  quote_text: string;
  quote_name: string;
  quote_title: string;
  files: BixFile[];
  featured_name: string;
  featured_title: string;
}

export interface Industry {
  id: number;
  type_id: number;
  name: string;
}

export interface BixFile {
  uri: string;
  bix_type: string;
}

export interface Tab {
  id: number;
  name: string;
  shortName: string;
  alias: string;
  path: string;
}

export interface Breadcrumb {
  url: string;
  label: string;
}

export interface Perks<T> {
  [perk: string]: T;
}

export interface PerkWithDescription {
  id: number;
  perk_id: number;
  type_id: number;
  name: string;
  description: string;
}

export interface Blog {
  thumbnail: string;
  id: number;
  region_id: number;
  title: string;
  alias: string;
  status: number;
  is_spotlight: number;
  spotlight_images: string[];
  body: string;
}

export interface QA {
  name: string;
  title: string;
  answer: string;
  attr_id: number;
  question: string;
  image_uri: string;
  image_bix_type: string;
}

export interface Technology {
  id: number;
  name: string;
  type_id: number;
  type_name: string;
}

export interface ExtraTechnology {
  id: number;
  name: string;
  type_name: string;
}

export interface InsiderVideo {
  id: number;
  image_url: string;
  image_title: string;
  url: string;
  title: string;
  company_id: number;
}

export interface CompanyOffice {
  city: string;
  company_id: number;
  country: string;
  headquarters: boolean;
  nearest_market: number;
  neighborhood_description: string;
  postal_code: string;
  state: string;
  street_address_1: string;
  street_address_2: string;
}

export enum CompanyProfileSection {
  basicInfo = 'basic_info_section',
  companyInfo = 'company_info_section',
  companyLogo = 'company_logo_section',
  companyLocation = 'company_location_section',
  companySocialMedia = 'company_social_media_section',
  whoWeAre = 'who_we_are_section',
  companyMission = 'company_mission_section',
  whyWorkWithUs = 'why_work_with_us_section',
  galleryImage = 'gallery_image_section',
  insidersView = 'insiders_view_section',
  employeeReviews = 'employee_reviews_section',
  perks = 'perks_section',
  technology = 'technology_section',
  fundingHistory = 'funding_history_section',
}

export enum ActionNames {
  getIndustryNames = 'getIndustryNames',
  getMarketURL = 'getMarketURL',
  getCompanyOverview = 'getCompanyOverview',
  setCompany = 'setCompany',
  mergeCompany = 'mergeCompany',
  setCompanyCategories = 'setCompanyCategories',
  setDisplayJobs = 'setDisplayJobs',
  setProfileTabs = 'setProfileTabs',
  filterDisplayJobs = 'filterDisplayJobs',
  getCompanyProfile = 'getCompanyProfile',
  setActiveCategory = 'setActiveCategory',
  setJobs = 'setJobs',
  updateCompanyOverview = 'updateCompanyOverview',
  setInsiderVideo = 'setInsiderVideo',
  setCompanyTabsOffset = 'setCompanyTabsOffset',
  fetchIJobFilters = 'fetchIJobFilters',
  fetchIJobs = 'fetchIJobs',
  fetchJobs = 'fetchJobs',
  setIJobFilters = 'setIJobFilters',
  setHasFetchedIJobsFilters = 'setHasFetchedIJobsFilters',
  setIJobs = 'setIJobs',
  setIJobsTotal = 'setIJobsTotal',
  setIsFetchingIJobs = 'setIsFetchingIJobs',
}

export interface CompanyJobsParams {
  companyId: number;
  categoryIds?: number | string;
  page?: number;
}
export interface ExitData {
  exit_type: string;
  exit_amt: number;
  exit_date: string;
  exit_post_valuation_amount: number;
  exit_acquirer_investor: string;
}

export interface FullCultureTopic {
  id: number;
  company_id: number;
  title: string;
  category: string;
  overview: string;
  quote_question: string;
  quote_text: string;
  quote_name: string;
  quote_title: string;
  featured_name: string;
  featured_title: string;
  tagline: string;
  section_title: string;
  visible: boolean;
  custom_icon: string;
}

export interface CultureTopicWithFiles {
  culture_topic: FullCultureTopic;
  files: BixFile[];
}
