







import { defineComponent } from '@nuxtjs/composition-api';
import { useAuthentication } from '@/hooks/useAuthentication';

export default defineComponent({
  setup() {
    const { isAuthenticated } = useAuthentication();

    return {
      isAuthenticated,
    };
  },
});
