import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    $isVue: boolean;
  }

  interface VueConstructor {
    $isVue: boolean;
  }
}

export default async () => {
  Vue.use({
    install: (vue: typeof Vue) => {
      vue.mixin({
        computed: {
          isVue: () => true,
        },
      });
      vue.prototype.$isVue = true;
    },
  });
};
