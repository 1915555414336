/* ============
 * Profile Transformer
 * ============
 *
 * The transformer for the account.
 */

import {
  City,
  CompanyValue,
  CompanyValueSub,
  JobTitle,
  Location,
  Profile,
} from '@/store/onboarding/types';
import { isString } from 'lodash-es';
import { ProfileResponse } from '@/api/types';

export default class ProfileTransformer {
  /**
   * Method used to transform a fetched profile.
   *
   * @param data The fetched profile.
   *
   * @returns {Object} The transformed profile.
   */

  static fetch(data: ProfileResponse): Profile {
    // Prepare Cities info
    const citiesInfo: City[] = [];
    if (data.cities != undefined && data.cities.length > 0) {
      for (const city of data.cities) {
        if (city.city_id === undefined) {
          console.error("City isn't defined", city, data);
        }
        let fi = citiesInfo.findIndex((cat) => cat.id == city.city_id);
        if (fi === -1) {
          const l: Location[] = [];
          fi =
            citiesInfo.push({
              id: city.city_id ?? 0,
              locations: l,
            }) - 1;
        }
        if (city?.location_id && city.location_id !== 0) {
          citiesInfo[fi].locations.push({
            city_id: city.city_id ?? 0,
            location_id: city.location_id,
          });
        }
      }
    }

    // Prepare Company Values info
    const companyValuesInfo: CompanyValue[] = [];
    if (data.company_values != undefined && data.company_values.length > 0) {
      for (const company_value of data.company_values) {
        if (company_value.attr_id === undefined) {
          console.error("company_value.attr_id isn't defined", company_value, data);
        }
        let fi = companyValuesInfo.findIndex((cat) => cat.id == company_value.attr_id);
        if (fi === -1) {
          const s: CompanyValueSub[] = [];
          fi =
            companyValuesInfo.push({
              id: company_value.attr_id ?? 0,
              attr_subs: s,
            }) - 1;
        }
        companyValuesInfo[fi].attr_subs.push({
          id: company_value.id,
          attr_id: company_value.attr_id ?? 0,
        });
      }
    }

    let preferredCitiesToRead: number[] = [];
    if (isString(data.read_cities)) {
      preferredCitiesToRead = data.read_cities.split(',').map(Number);
    }

    const topics: number[] = data.topics ? data.topics.split(',').map(Number) : [];

    const jobTitles: JobTitle[] = data.job_titles ?? [];

    return {
      id: data.id,
      available_job_slots: data['available-job-slots'],
      avatar: data.avatar ?? '',
      cancel_why_companies: data.cancel_why_companies ?? 0,
      cancel_why_jobs: data.cancel_why_jobs ?? 0,
      cancel_why_topics: data.cancel_why_topics ?? 0,
      cities_read: preferredCitiesToRead,
      content_subscription: data.content_subscription,
      content_subscription_local: data.content_subscription_local,
      created_at: data.created_at,
      dribbble_url: data.dribbble_url ?? '',
      education: data.education,
      email: data.email,
      events_subscription: data.events_subscription,
      experience: data.experience ?? [],
      expertise: data.expertise,
      first_name: data.first_name ?? '',
      last_name: data.last_name ?? '',
      github_url: data.github_url ?? '',
      has_orders: data.has_orders,
      home_city: data.home_city ?? 0,
      home_location: data.home_location ?? 0,
      kaggle_url: data.kaggle_url ?? '',
      market_id: data.market_id ?? 0,
      onboarded: data.onboarded,
      onboarding_complete: data.onboarding_complete ?? 0,
      other_city: data.other_city ?? '',
      other_country: data.other_country ?? '',
      other_state: data.other_state ?? '',
      personal_url: data.personal_url ?? '',
      phone_number: data.phone_number ?? '',
      picture: data.picture ?? '',
      projects: data.projects,
      quick_bio: data.quick_bio ?? '',
      registration_source: data.registration_source ?? '',
      resume: data.resume ?? '',
      resume_company_id: data.resume_company_id ?? 0,
      resume_company_name: data.resume_company_name ?? '',
      resume_job_title: data.resume_job_title ?? '',
      resume_linked_in: data.resume_linked_in ?? '',
      skills: data.skills ?? [],
      step: data.step ?? 0,
      topics,
      twitter_url: data.twitter_url ?? '',
      user_email: data.user_email ?? '',
      jobs_subscription: data.jobs_subscription ?? 0,
      why: data.why ?? 0,
      work_relocate: data.work_relocate,
      work_relocate_paid: data.work_relocate_paid,
      company_values: companyValuesInfo,
      cities: citiesInfo,
      resume_list: data.resume_list,
      default_resume: data.default_resume ?? {
        id: 0,
        name: '',
        url: '',
        created_at: '',
        updated_at: '',
      },
      job_titles: jobTitles,
    };
  }

  /**
   * Method used to transform a send profile.
   *
   * @returns {Object} The transformed profile.
   * @param profile
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static send(profile: Profile): ProfileResponse {
    throw new Error('not implemented');
  }
}
