import { render, staticRenderFns } from "./MobileBottomNavigation.vue?vue&type=template&id=59307ffc&scoped=true&"
import script from "./MobileBottomNavigation.vue?vue&type=script&lang=ts&"
export * from "./MobileBottomNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./MobileBottomNavigation.vue?vue&type=style&index=0&id=59307ffc&prod&lang=sass&"
import style1 from "./MobileBottomNavigation.vue?vue&type=style&index=1&id=59307ffc&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59307ffc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BixLink: require('/root/project/frontend/src/components/BixLink.vue').default,BixImg: require('/root/project/frontend/src/components/images/BixImg.vue').default,SignButtons: require('/root/project/frontend/src/components/navigation/SignButtons.vue').default,AuthStatus: require('/root/project/frontend/src/components/AuthStatus.vue').default})
