import Vue from 'vue';
import { Component } from 'vue-property-decorator';

/* Mixin to provide common tracking metadata*/
@Component
export default class RegistrationMetadata extends Vue {
  public get registrationClickMetadata() {
    return {
      cta_page: 'navbar',
    };
  }
}

export const registrationClickMetadata = () => {
  return {
    cta_page: 'navbar',
  };
};
