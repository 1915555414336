import bixAxios from '@/api/bixAxios';
import ExternalApis from '@/enums/externalApiRoutesEnum';
import regions from '@/api/regions';
import { errorCheck } from './errors';
import { MinimalUserParams } from './types';
import { post } from './fetch/fetch';
import { getAuthenticatedClient } from '@/plugins/vueApollo';
import { GetUserProfileParams, GetUserResume } from '@/graphql/identity';
import { IQuery } from '@/typings/api/external-api-gql';
import { Profile } from '@/store/onboarding/types';
import ProfileTransformer from '@/api/transformers/ProfileTransformer';

const regionID: number = regions.getCurrentRegionID();

const getUserResume = async (regionId: number): Promise<any> => {
  try {
    const response = await getAuthenticatedClient().query<IQuery, GetUserProfileParams>({
      query: GetUserResume,
      variables: {
        regionId,
      },
      errorPolicy: 'ignore',
    });
    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export default {
  editProfile(data: any): Promise<Profile> {
    return bixAxios
      .post(`${ExternalApis.Onboarding}` + '/' + regionID, data)
      .then((response) => {
        return ProfileTransformer.fetch(response.data);
      })
      .catch((error) => {
        throw new Error(errorCheck(error));
      });
  },
  uploadFile(data: any, callback: Function) {
    return bixAxios
      .post(`${ExternalApis.Onboarding}` + '/file/' + regionID, data)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        throw new Error(errorCheck(error));
      });
  },
  deleteResume: async (id: number) => {
    try {
      const response = await bixAxios.delete(`${ExternalApis.Profile}/${regionID}/resume/${id}`);
      return ProfileTransformer.fetch(response.data);
    } catch (err: any) {
      if (errorCheck(err)) {
        throw new Error(err.message);
      }
      throw new Error(err);
    }
  },
  addResume: async (data: FormData): Promise<Profile> => {
    try {
      const response = await bixAxios.post(`${ExternalApis.Profile}/${regionID}/resume`, data);
      return ProfileTransformer.fetch(response.data);
    } catch (err: any) {
      if (errorCheck(err)) {
        throw new Error(err.message);
      }
      throw new Error(err);
    }
  },
  setDefaultResume: async (id: number): Promise<Profile> => {
    try {
      const response = await bixAxios.post(
        `${ExternalApis.Profile}/${regionID}/resume/${id}/default`
      );
      return ProfileTransformer.fetch(response.data);
    } catch (err: any) {
      if (errorCheck(err)) {
        throw new Error(err.message);
      }
      throw new Error(err);
    }
  },
  getUserResume,
};

export const createMinimalUser = async (params: MinimalUserParams): Promise<any> => {
  try {
    return await post(ExternalApis.Signup, params);
  } catch (err: any) {
    return err.response;
  }
};
