













































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import { mapState } from '@/utils/vuex-shim';
import { RootState } from '@/store/types';

@Component({
  computed: {
    ...mapState<RootState>(['loadingOverlay']),
  },
})
export default class LoadingOverlay extends Vue {
  loadingOverlay!: RootState['loadingOverlay'];

  get isLoading() {
    return this.loadingOverlay.length;
  }
}
