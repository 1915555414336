import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0ab4abd8&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=0ab4abd8&prod&lang=sass&"
import style1 from "./default.vue?vue&type=style&index=1&id=0ab4abd8&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ab4abd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkipLink: require('/root/project/frontend/src/components/SkipLink.vue').default,Navigation: require('/root/project/frontend/src/components/navigation/Navigation.vue').default,Footer: require('/root/project/frontend/src/components/footer/Footer.vue').default})
