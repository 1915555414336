













































































import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import regions from '@/api/regions';
import { useAuthentication } from '@/hooks/useAuthentication';
import { faUserEdit } from '@fortawesome/pro-solid-svg-icons/faUserEdit';
import { faBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import constants from '@/enums/constants';
import { UserRoles } from '@/enums/userRoles';
import { isSafari } from '@/utils/onboarding';
import navigationHelper from '@/utils/navigation';
import { hasPermission as hasAnyPermission } from '@/utils/user-permissions';

import { createNamespacedHelpers } from 'vuex-composition-helpers';
import {
  OnboardingActions,
  OnboardingGetters,
  State as OnboardingState,
} from '@/store/onboarding/types';
import { State as UserState, UserActions, UserGetters, UserNamespace } from '@/store/modules/user';
import { useVuetify } from '@/hooks/useVuetify';
import { isFeatureFlagEnabled } from '@/utils/feature-flag';

const onboarding = createNamespacedHelpers<
  OnboardingState,
  OnboardingGetters,
  OnboardingActions,
  unknown
>('onboarding');

const userModule = createNamespacedHelpers<UserState, UserGetters, UserActions>(UserNamespace);

const companyPermissionNames = [
  UserRoles.CompanyAdmin,
  UserRoles.CompanyProfileEditor,
  UserRoles.CompanyJobPoster,
];

export default defineComponent({
  setup() {
    const { breakpoints } = useVuetify();
    const { profile } = userModule.useState(['profile']);
    const { userPhoto: oldAvatar, userFullName: oldFullName } = onboarding.useGetters([
      'userPhoto',
      'userFullName',
    ]);

    const { fullName: newFullName } = userModule.useGetters(['fullName']);

    const drupalId = computed(() => profile.value?.drupalId);
    const isDrupalIdValid = computed(() => !!drupalId.value);

    const regionId = regions.getCurrentRegionID();
    const isMobile = computed(() => breakpoints.value.mdAndDown);

    const { signout } = useAuthentication();

    const menuName = 'auth-user-mobile-menu';
    const { openDropdownNoDelay, closeDropdown, dropdownContent, display } = navigationHelper();
    const openedMenu = computed(() => display.value && dropdownContent.value === menuName);

    const avatar = computed(() => profile.value?.picture || oldAvatar.value);
    const fullName = computed(() => newFullName.value || oldFullName.value);

    const toggleMenu = () => {
      const body = document.querySelector('body');
      if (openedMenu.value) {
        closeDropdown();
        body?.classList.remove('user-auth-menu-open');
        return;
      }
      openDropdownNoDelay(menuName);
      body?.classList.add('user-auth-menu-open');
    };
    watch(
      () => openedMenu.value,
      (curValue) => {
        if (!curValue) {
          const body = document.querySelector('body');
          body?.classList.remove('user-auth-menu-open');
        }
      }
    );
    const icons = {
      edit: faUserEdit,
      case: faBriefcase,
      settings: faCog,
    };

    /**
     * @returns Boolean if user has any company permissions, regardless of region
     */
    const hasCompanyPermissions = computed(() => {
      return hasAnyPermission(profile.value?.permissions || [], companyPermissionNames);
    });

    const isFeatureJobsNewsletterEnabled = ref(false);

    isFeatureFlagEnabled('jobs_newsletter').then((flagValue) => {
      isFeatureJobsNewsletterEnabled.value = flagValue;
    });

    const isNewJobsNewsletterEnabled = computed(() => {
      return isFeatureJobsNewsletterEnabled.value;
    });

    return {
      isMobile,
      regionId,
      logout: signout,
      toggleMenu,
      avatar,
      openedMenu,
      isDrupalIdValid,
      drupalId,
      fullName,
      userEditProfileLink: constants.USER_EDIT_PROFILE_LINK,
      isSafari: isSafari(),
      icons,
      hasCompanyPermissions,
      isNewJobsNewsletterEnabled,
    };
  },
});
