import { IPermission } from '@/typings/api/identity-gql';
import { UserRoles } from '@/enums/userRoles';

/**
 * User permission utility methods
 */

/**
 * Set of roles that we consider user to be a company admin.
 */
export const companyPermissionNames = [
  UserRoles.CompanyAdmin,
  UserRoles.CompanyProfileEditor,
  UserRoles.CompanyJobPoster,
];

/**
 * Check a user for global permissions.
 *
 * @param userPermissions Array of permissions granted to the user
 * @param permissionsToCheck Array of permissions to check the user for
 * @returns Whether the user has the specified permissions in `permissionsToCheck`
 */
export const hasPermission = (userPermissions: IPermission[], permissionsToCheck: string[]) => {
  return userPermissions.some((perm) => permissionsToCheck.includes(perm.name));
};

/**
 * Check if there are a company admin roles presented
 * in the set of given permissions.
 *
 * @param permissions Array of permissions granted to the user
 * @returns Whether the user has the company admin permissions
 */
export const isCompanyAdmin = (permissions: IPermission[]) => {
  return hasPermission(permissions, companyPermissionNames);
};
