






import { defineComponent } from '@nuxtjs/composition-api';
/**
 * Displays an accessible Skip to content link in the root application
 */
export default defineComponent({});
