import { Enum } from 'enumify';
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex/types/index';
import { RootState } from '../types';
import { CompanyParams } from '@/store/homepage/types';
import { Resume } from '@/api/types';

export interface State {
  step: string;
  previousStep: string;
  steps: {
    why: number[];
    flow: number[];
  }[];
  profile: Profile;
  company_values: CompanyValue[];
  expertise_list: ExpertiseList[];
  skills: Skill[];
  years_list: ExpertiseYear[];
  cities: City[];
  nationalCities: City[];
  topics: Topic[];
}

export interface ExpertiseYear {
  value: string;
  label: string;
}

export interface DegreeType {
  value: number;
  label: string;
}

export interface Market {
  id: number;
  name: string;
}

export const MarketSelections: Market[] = [
  { id: 4, name: 'Austin' },
  { id: 6, name: 'Boston' },
  { id: 1, name: 'Chicago' },
  { id: 2, name: 'Colorado' },
  { id: 3, name: 'Los Angeles' },
  { id: 5, name: 'New York City' },
  { id: 8, name: 'San Francisco' },
  { id: 7, name: 'Seattle' },
  { id: 0, name: 'Unsubscribed from local tech news' },
];

// used in the confirm profile step
export interface Profile {
  id: number;
  created_at: string;
  market_id: number;
  onboarded: number;
  onboarding_complete: number;
  step: number;
  first_name: string;
  last_name: string;
  email: string;
  user_email: string;
  picture: string;
  avatar: string;
  why: number;
  cancel_why_jobs: number;
  cancel_why_companies: number;
  cancel_why_topics: number;
  education: Education[];
  projects: Project[];
  expertise: SelectedExpertise[];
  skills: SelectedSkill[];
  experience: Experience[];
  cities: City[];
  work_relocate: number;
  work_relocate_paid: number;
  home_city: number;
  home_location: number;
  other_country: string;
  other_state: string;
  other_city: string;
  company_values: CompanyValue[];
  cities_read: number[];
  topics: number[];
  resume_job_title: string;
  resume_linked_in: string;
  resume_company_name: string;
  resume_company_id: number;
  resume: string;
  quick_bio: string;
  personal_url: string;
  kaggle_url: string;
  github_url: string;
  dribbble_url: string;
  twitter_url: string;
  content_subscription: number;
  content_subscription_local: number;
  jobs_subscription: number;
  events_subscription: number;
  has_orders: boolean;
  available_job_slots: number;
  registration_source: string;
  phone_number: string;
  resume_list: Resume[];
  default_resume: Resume;
  job_titles: JobTitle[];
}

export type ProfileMutation = Partial<Profile>;

export interface JobTitle {
  id: number;
  user_id: number;
  title: string;
}

// Experience
export interface Experience {
  job_title: string;
  company_id: number;
  company_title?: string;
  year_from: number;
  year_to: number;
  description: string;
  weight: number;
  key?: number | string;
}

// City
export interface City {
  id: number;
  name?: string;
  locations: Location[];
}

export interface CityDropdown extends City {
  hideParent?: boolean;
  onlyParent?: boolean;
}

// Location
export interface Location {
  city_id: number;
  location_id: number;
  name?: string;
}

// Expertise
export interface Expertise {
  id: number;
  type_id: number;
  name: string;
}

export interface ExpertiseList extends Expertise {
  alias?: string;
  attr_subs?: ExpertiseList[] | null;
  category_id?: number;
  seo_name?: string;
}

// Education
export interface Education {
  id?: number;
  organization: string;
  type: number | string;
  year: number | string;
}

// Project
export interface Project {
  id?: number;
  url: string;
  title: string;
  description: string;
  key?: number | string;
}

// Company Values
export interface CompanyValue {
  name?: string;
  id: number;
  attr_subs: CompanyValueSub[];
}

// Company Values Sub
export interface CompanyValueSub {
  name?: string;
  attr_id: number;
  id: number;
}

// Skill
export interface Skill {
  id: number;
  attr_id: number;
  name: string;
}

// Topic
export interface Topic {
  id: number;
  name: string;
}

export interface SelectedExpertise {
  expertise_id: number;
  level: string;
}

export interface SelectedSkill {
  skill_id: number;
  level: string;
}

export interface Subscription {
  type: string;
  label: string;
  value?: number;
  children?: SubscriptionChild[];
}

export interface SubscriptionChild {
  region_id: number;
  label: string;
  value: number;
}
export interface RelocateOption {
  work_relocate: number;
  work_relocate_paid: number;
}
// Declares remote ID for US
export const RemoteUSID: number = 10000;

// Declares remote ID for Non-US
export const RemoteNonUSID: number = 10001;

// Default Years data
export const YearsList: ExpertiseYear[] = [
  { value: '0', label: 'Not Specified' },
  { value: '1', label: '<1' },
  { value: '3', label: '1-3' },
  { value: '5', label: '3-5' },
  { value: '7', label: '5-7' },
  { value: '7+', label: '7+' },
];

// Default Degree Types data
export const DegreeTypes: DegreeType[] = [
  { value: 11, label: 'AA' },
  { value: 12, label: 'AAA' },
  { value: 13, label: 'AAS' },
  { value: 14, label: 'AS' },
  { value: 1, label: 'BA' },
  { value: 2, label: 'BS' },
  { value: 3, label: 'BFA' },
  { value: 15, label: 'Bootcamp' },
  { value: 4, label: 'MD' },
  { value: 5, label: 'MS' },
  { value: 6, label: 'JD' },
  { value: 7, label: 'PHD' },
  { value: 8, label: 'Certificate' },
  { value: 9, label: 'MA' },
  { value: 10, label: 'MBA' },
];

export type Step = {
  why: number[];
  flow: number[];
};

export const namespace = 'onboarding';

export interface OnboardingGetters extends GetterTree<State, RootState> {
  cities: (state: State) => City[];
  userPhoto: (state: State) => string;
  userFullName: (state: State) => string;
  userLocation: (state: State) => string;
  hasRemoteFlag: (state: State) => boolean;
  relocateOption: (state: State) => RelocateOption;
  userYearOfExperience: (state: State) => string[];
  userSelectedExpertise: (state: State) => ExpertiseList | null;
  userAvailableJobSlots: (state: State) => number;
  userCompaniesParams: (state: State) => CompanyParams;
}

export interface OnboardingActions extends ActionTree<State, RootState> {
  nextStep: (ctx: ActionContext<State, RootState>, step: number) => void;
  getExpertises: (ctx: ActionContext<State, RootState>) => Promise<void>;
  /** Loads user account data */
  userProfile: (ctx: ActionContext<State, RootState>) => Promise<boolean>;
  /** Confirm Info Step */
  userProfile1: (ctx: ActionContext<State, RootState>) => void;
  /** Why We're Here Step */
  userProfile2: (ctx: ActionContext<State, RootState>) => void;
  /** Experience Step */
  userProfile3: (ctx: ActionContext<State, RootState>, options: any) => Promise<void>;
  /** Skills Step */
  userProfile4: (ctx: ActionContext<State, RootState>, options: any) => Promise<void>;
  /** Preferred Cities Step */
  userProfile5: (ctx: ActionContext<State, RootState>, options: any) => void;
  /** Tech News Step */
  userProfile6: (ctx: ActionContext<State, RootState>, options: any) => Promise<void>;
  /** Company Values Step */
  userProfile7: (ctx: ActionContext<State, RootState>, options: any) => Promise<void>;
  /** Work History Step */
  userProfile8: (ctx: ActionContext<State, RootState>, options: any) => Promise<void>;
  addResume: (ctx: ActionContext<State, RootState>, formData: FormData) => Promise<boolean>;
  deleteResume: (ctx: ActionContext<State, RootState>, resumeId: number) => Promise<boolean>;
  setDefaultResume: (ctx: ActionContext<State, RootState>, resumeId: number) => Promise<boolean>;
}

export interface OnboardingMutations extends MutationTree<State> {
  updateUserProfileForm: (state: State, payload: ProfileMutation) => void;
  userProfile: (state: State, payload: Profile) => void;
  _clearAuth: (state: State) => void;
}

export enum OnboardingMutationsEnum {
  _clearAuth = '_clearAuth',
  cities = 'cities',
  nationalCities = 'nationalCities',
  expertises = 'expertises',
}

export enum GetterNamesEnum {
  userYearOfExperience = 'userYearOfExperience',
  userSelectedCategory = 'userSelectedCategory',
  userSelectedExpertise = 'userSelectedExpertise',
  userSelectedCities = 'userSelectedCities',
  userPreferredWorkLocations = 'userPreferredWorkLocations',
  hasRemoteFlag = 'hasRemoteFlag',
  userAvailableJobSlots = 'userAvailableJobSlots',
  relocateOption = 'relocateOption',
  userPosition = 'userPosition',
}

export class ActionNames extends Enum {
  getter() {
    return 'onboarding/' + this.name;
  }

  action(id: number) {
    return 'onboarding/' + this.actionName(id);
  }

  mutation() {
    return 'onboarding/' + this.name;
  }

  actionName(id: number) {
    return this.name + (id || '').toString();
  }
}

export const OnboardingGettersObject = {
  userPosition: 'userPosition',
  relocateOption: 'relocateOption',
};

ActionNames.initEnum([
  'cities',
  'nationalCities',
  'companyValues',
  'topics',
  'skills',
  'expertises',

  'userSkills',
  'userPhoto',
  'userLocation',
  'userFullName',
  'userCompaniesParams',

  'updateUserProfileForm',
  'userProfile',
  'userProfileCancelWhy',
  'setProfileAvatar',
  'setProfilePicture',
  'setResumeName',
  'nextStep',
  'setOnboardingComplete',
  'redirect',
  'setOnboardingPrevStep',
]);
