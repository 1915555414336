








import { computed, defineComponent } from '@nuxtjs/composition-api';

const jobCategories = [
  {
    title: 'All Tech Jobs',
    code: 'all',
    path: {
      main: '',
      market: '',
    },
  },
  {
    title: 'Data + Analytics',
    code: 'data-analytics',
    icon: 'data-analytics',
    path: {
      main: 'data-analytics',
      market: 'data-analytics',
    },
  },
  {
    title: 'Design + UX',
    code: 'design-ux',
    icon: 'design-ux',
    path: {
      main: 'design-ux',
      market: 'design-ux',
    },
  },
  {
    title: 'Developer + Engineer',
    code: 'developer-engineer',
    icon: 'developer-engineer',
    path: {
      main: 'dev-engineering',
      market: 'dev-engineering',
    },
  },
  {
    title: 'Finance',
    code: 'finance',
    icon: 'finance',
    path: {
      main: 'finance',
      market: 'finance',
    },
  },
  {
    title: 'HR + Recruiting',
    code: 'recruiting-hr',
    icon: 'hr-recruiting',
    path: {
      main: 'hr',
      market: 'hr',
    },
  },
  {
    title: 'Legal',
    code: '20',
    icon: 'legal',
    path: {
      main: 'legal',
      market: 'legal',
    },
  },
  {
    title: 'Marketing',
    code: 'marketing',
    icon: 'marketing',
    path: {
      main: 'marketing',
      market: 'marketing',
    },
  },
  {
    title: 'Operations',
    code: 'operations',
    icon: 'operations',
    path: {
      main: 'operations',
      market: 'operations',
    },
  },
  {
    title: 'Product',
    code: 'product',
    icon: 'product',
    path: {
      main: 'product',
      market: 'product',
    },
  },
  {
    title: 'Project Mgmt.',
    code: 'project-management',
    icon: 'project-mgmt',
    path: {
      main: 'project-management',
      market: 'project-management',
    },
  },
  {
    title: 'Sales',
    code: 'sales',
    icon: 'sales',
    path: {
      main: 'sales',
      market: 'sales',
    },
  },
];

export default defineComponent({
  setup() {
    const jobCategoriesTransformed = computed(() => {
      const [allCategoryItem, ...restCategoriesItems] = jobCategories;
      const remoteCategory = { title: 'Remote Tech Jobs', path: { main: 'remote' } };
      const jobCategoriesLink = [allCategoryItem, remoteCategory, ...restCategoriesItems];

      return jobCategoriesLink.map((jobs) => {
        const url = '/jobs' + (jobs.path.main ? '/' + jobs.path.main : '');
        return { title: jobs.title, url };
      });
    });
    return { jobCategoriesTransformed };
  },
});
