import { render, staticRenderFns } from "./EmployerPortalNavLink.vue?vue&type=template&id=55d7fbf0&scoped=true&"
import script from "./EmployerPortalNavLink.vue?vue&type=script&lang=ts&"
export * from "./EmployerPortalNavLink.vue?vue&type=script&lang=ts&"
import style0 from "./EmployerPortalNavLink.vue?vue&type=style&index=0&id=55d7fbf0&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55d7fbf0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BixLink: require('/root/project/frontend/src/components/BixLink.vue').default})
