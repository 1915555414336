


























import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';

import navigationHelper from '@/utils/navigation';

export default defineComponent({
  name: 'UserUnAuthMobile',
  setup() {
    const menuName = 'user-unauth-mobile-menu';
    const { openDropdownNoDelay, closeDropdown, dropdownContent, display } = navigationHelper();
    const openedMenu = computed(() => display.value && dropdownContent.value === menuName);
    const toggleMenu = () => {
      if (openedMenu.value) {
        closeDropdown();
        return;
      }
      openDropdownNoDelay(menuName);
    };

    const closeMenu = () => {
      closeDropdown();
    };

    const addOverflow = ref(false);

    const animation = () => {
      addOverflow.value = !addOverflow.value;
    };
    return {
      openedMenu,
      addOverflow,
      toggleMenu,
      closeMenu,
      animation,
      icons: {
        user: faUserCircle,
      },
    };
  },
});
