import Vue from 'vue';
import { DirectiveBinding, DirectiveOptions } from 'vue/types/options';
import { Context } from '@nuxt/types';
import { Route } from 'vue-router';

import track, { Metadata } from '@/utils/track';

// Provides a directive definition to track user click events.
// Exported for testing in a localVue.
export const trackClickDirective: DirectiveOptions = {
  bind: (el: HTMLElement, binding: DirectiveBinding) => {
    el.addEventListener('click', (e: Event) => {
      try {
        e.stopPropagation();
        if (!binding.arg) {
          throw new Error('Track event name missing');
        }

        const value = typeof binding.value === 'function' ? binding.value() : binding.value;
        track.logEvent(`click_${binding.arg}`, value);
      } catch (e) {
        console.error(e);
      }
    });
  },
};

Vue.directive('track-click', trackClickDirective);

const followedVueLink = (fromPath: Route) => {
  return !fromPath || fromPath.matched.length > 0;
};

const urlFrom = (route: Route) =>
  `${window.location.protocol}//${window.location.hostname}${route?.path || ''}`;

/**
 *  track page views with GA / our own web beacon
 */
export const trackPage = ({ store }: Context, toPath: Route, fromPath: Route) => {
  const { query, name, params } = store.$router.currentRoute;
  const eventData: Metadata = {
    query_params: query,
    route_name: name,
    route_params: params,
  };
  if (followedVueLink(fromPath)) {
    eventData.referer = urlFrom(fromPath);
  }
  track.logEvent('page_view', eventData);
};

export default (context: Context) => {
  const { router } = context.app;

  if (!router) {
    return;
  }

  router.afterEach((to, from) => {
    Vue.nextTick(() => {
      trackPage(context, to, from);
    });
  });
};
