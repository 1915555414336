import {
  ActionNames as OnboardingActions,
  namespace as OnboardingNamespace,
  OnboardingMutationsEnum,
  State as OnboardingState,
} from '@/store/onboarding/types';
import track from '@/utils/track';
import { UserNamespace } from '@/store/modules/user';

interface MutationFragment {
  type: string;
}

interface StateFragment {
  onboarding?: OnboardingState;
}

interface StoreFragment {
  commit: Function;
}

export const experienceUpdate = (mutation: MutationFragment, state: StateFragment) => {
  if (mutation.type === OnboardingActions.updateUserProfileForm.action()) {
    if (state && state.onboarding && state.onboarding.profile) {
      const { user_email, experience } = state.onboarding.profile;
      if (experience && experience.length) {
        track.logEvent('experience_update', {
          email: user_email,
          experience,
        });
      }
    }
  }
};

export const clearOnboardingOnSignOut = (
  mutation: MutationFragment,
  state: StateFragment,
  store: StoreFragment
) => {
  if (mutation.type === `${UserNamespace}/_clearAuth`) {
    const name = `${OnboardingNamespace}/${OnboardingMutationsEnum._clearAuth}`;
    store.commit(name);
  }
};
