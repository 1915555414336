import DOMPurify from 'dompurify';
import snarkdown from 'snarkdown';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { jobCategoryUris } from '@/utils/filters';

dayjs.extend(utc);
dayjs.extend(relativeTime);

/**
 * Formatting utility methods.
 */

/**
 * Given a noun, return the appropriate article for it.
 * e.g., if `word` is "animal", article is "an".
 *
 * @return The correct article for the noun.
 */
export const article = (word: string) => {
  word = word.trim();
  if (!word.length) return '';

  return /[aeiou]/i.test(word[0]) ? 'an' : 'a';
};

/**
 * @return A DOM-safe anchor string based on `phrase`.
 */
export const stringToAnchorText = (phrase: string) => {
  phrase = phrase.trim();
  if (!phrase.length) return '';

  const matchWhitespace = /\s/gm;
  return `anchor-${phrase.toLowerCase().replace(matchWhitespace, '-')}`;
};

/**
 * Sanitizes HTML.
 *
 * By default, only allows bold, italic, and link tags.
 * See https://github.com/cure53/DOMPurify for options.
 *
 * @param html Potentially unsafe HTML.
 * @param options Object of DOMPurify options.
 * @return Sanitized HTML.
 */
export const sanitizeHtml = (html: string, options?: DOMPurify.Config) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['strong', 'em', 'a'],
    ALLOW_DATA_ATTR: false,
    ...options,
  });
};

/**
 * @param markdown Text containing Markdown formatting.
 * @param options Object of DOMPurify options (https://github.com/cure53/DOMPurify)
 * @return Sanitized, decoded HTML.
 */
export const getHtmlFromMarkdown = (markdown: string, options?: DOMPurify.Config) => {
  markdown = markdown.trim();
  if (!markdown.length) return '';

  const decodedMarkdown = snarkdown(markdown);
  return sanitizeHtml(decodedMarkdown, options);
};

/**
 * @param testString The string to check.
 * @return Whether the string is a URL.
 */
export const isFullUrl = (testString: string) => /^https?:\/\//i.test(testString);

/**
 * @param categoryLabel String to be mapped
 * @returns Mapped replacement or original string
 */
export const seoCategoryLabel = (categoryLabel: string) => {
  if (categoryLabel === 'Developer + Engineer') {
    categoryLabel = 'Software Engineer';
  }
  return categoryLabel;
};

/**
 * @param categoryLabel The string to be mapped
 * @returns Mapped replacement or original string
 */
export const jobCategoryLabel = (categoryLabel = '') => {
  switch (categoryLabel) {
    case 'Developer + Engineer':
      return 'Dev + Engineer';
    case 'Project Mgmt':
      return 'Project Management';
    default:
      return categoryLabel;
  }
};

/**
 * @param name The string to be mapped
 * @returns Mapped replacement or original string
 */
export const regionNameMapper = (name: string) => {
  switch (name) {
    case 'Los Angeles':
      return 'LA';
    case 'New York City':
      return 'NYC';
    case 'San Francisco':
      return 'SF';
    default:
      return name;
  }
};

/**
 * @param date String to format
 * @returns dayjs formatted Date
 */
export const formatLastUpdated = (date: string) => {
  if (!date) return '';
  const jsDate = new Date(date);
  let timestamp = jsDate.getTime();
  timestamp = timestamp + jsDate.getTimezoneOffset() * 60 * 1000;
  jsDate.setTime(timestamp);
  return dayjs(jsDate, 'YYYY-MM-DD HH:MM:SS').format('MM/DD/YYYY');
};

/**
 * @param value Date to be formatted
 * @returns A relative Date string, "2 days ago"
 */
export const timeAgo = (value: Date) => {
  return dayjs.utc(value).local().fromNow();
};

/**
 * @param categoryName
 * @returns Url pathname
 */
export const categoryPath = (categoryName: string) => {
  return `/jobs/${(jobCategoryUris[categoryName] || categoryName).toLowerCase()}`;
};

/**
 * @param n Number to be formatted
 * @returns A number string formatted for the English language, example: 1000 -> 1,000
 */
export const formatNumber = (n: number) => {
  return Intl.NumberFormat('en').format(n);
};
