import bixAxios from '@/api/bixAxios';
import ExternalApis from '@/enums/externalApiRoutesEnum';
import { JobParams, RemoteJobParams, CompanyParams, AvailableTopic } from '@/store/homepage/types';
import { TechHubStat } from '@/api/types';
import regions from '@/api/regions';
import { errorCheck } from './errors';

const regionID: number = regions.getCurrentRegionID();

export interface TopicParams {
  readTopicId: number;
  excludeTopics?: number[];
  perPage?: number;
  page?: number;
}

export interface TopStoriesParams {
  region_id: number;
  ignore_promoted_blogs: boolean;
  per_page: number;
}

export const getUserCompaniesPerValues = async (params: CompanyParams) => {
  return bixAxios
    .get(`${ExternalApis.HomepageCompaniesPerValues}`, {
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorCheck(error);
    });
};

export const loadTopicsAvailable = async (topicIds: number[]): Promise<AvailableTopic[]> => {
  return bixAxios
    .get(`${ExternalApis.HomepageAvailableTopics}`, { params: { topicIds } })
    .then((response) => {
      return response.data ?? [];
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  async getHomepageArticleStories(params: TopStoriesParams) {
    return bixAxios
      .get(
        `${ExternalApis.TopStoriesArticles}?regionID=${params.region_id}&ignorePromotedBlogs=${params.ignore_promoted_blogs}&perPage=${params.per_page}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  async getTopics(callback: Function) {
    return bixAxios
      .get(`${ExternalApis.Topics}`)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  async getUserBlogTopics(params: TopicParams) {
    return bixAxios
      .get(`${ExternalApis.HomepageBlogs}`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  async getUserTechNews(callback: Function) {
    return bixAxios
      .get(`${ExternalApis.HomepageTechNews}` + '/' + regionID)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  async getUserJobs(params: JobParams, callback: Function) {
    return bixAxios
      .get(`${ExternalApis.HomepageJobs}`, {
        params,
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  async getRemoteUserJobs(params: RemoteJobParams, callback: Function) {
    return bixAxios
      .get(`${ExternalApis.HomepageJobs}`, {
        params,
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  /**
   * @deprecated
   * @param params
   */
  async getUserCompanies(params: CompanyParams) {
    return bixAxios
      .get(`${ExternalApis.HomepageCompanies}`, {
        params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  getUserCompaniesPerValues,
  /**
   * @deprecated
   * @param params
   */
  async getUserCompaniesRemote(params: CompanyParams) {
    return bixAxios
      .get(`${ExternalApis.HomepageCompaniesRemote}`, {
        params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  async getTechHubStats(): Promise<TechHubStat[]> {
    return bixAxios
      .get<Omit<TechHubStat, 'region_id'>[]>(`${ExternalApis.HomepageTechHubStats}`)
      .then((response) => response.data)
      .then((almostTechHubStats): TechHubStat[] =>
        almostTechHubStats.map((stat) => ({
          ...stat,
          region_id: regions.regionIdFromNameSynomym(stat.name),
        }))
      )
      .catch(errorCheck);
  },
};
