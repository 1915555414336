import { render, staticRenderFns } from "./SubNavigation.vue?vue&type=template&id=559a9a50&scoped=true&"
import script from "./SubNavigation.vue?vue&type=script&lang=ts&"
export * from "./SubNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./SubNavigation.vue?vue&type=style&index=0&id=559a9a50&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "559a9a50",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BixLink: require('/root/project/frontend/src/components/BixLink.vue').default})


/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
