import {
  mapState as baseMapState,
  mapGetters as baseMapGetters,
  mapMutations as baseMapMutations,
  mapActions as baseMapActions,
  Computed,
  MutationMethod,
  ActionMethod,
  Store,
  Module,
  CommitOptions,
  DispatchOptions,
} from 'vuex';
import { Accessors } from 'vue/types/options';
import { RootState } from '@/store/types';

declare type OmitFirstArg<F, TReturn> = F extends (x: any, ...args: infer P) => any
  ? (...args: P) => TReturn
  : never;
export declare type InferType<T, TUnknown = any> = T extends (...args: any) => any
  ? OmitFirstArg<T, ReturnType<T>>
  : T extends unknown
  ? TUnknown
  : T;
export declare type InferGetterType<T> = T extends (...args: any) => any ? ReturnType<T> : any;

export declare type KnownKeys<T> = {
  [K in keyof T]: string extends K ? string : number extends K ? number : K;
} extends {
  [_ in keyof T]: infer U;
}
  ? U
  : never;

export declare type ExtractTypes<O, TUnknown = any> = {
  readonly [K in keyof O]: InferType<O[K], TUnknown>;
};

export declare type ExtractGetterTypes<O> = {
  readonly [K in keyof O]: InferGetterType<O[K]>;
};

export function mapState<TState = any>(
  namespaceOrMap: string | KnownKeys<TState>[],
  map?: KnownKeys<TState>[]
): Accessors<Computed> {
  if (arguments.length === 1) {
    return baseMapState(namespaceOrMap as string[]);
  }
  return baseMapState(namespaceOrMap as string, map as string[]);
}

export function mapGetters<TGetters = any>(
  namespaceOrMap: string | KnownKeys<TGetters>[],
  map?: KnownKeys<TGetters>[]
): {
  [x: string]: Computed;
} {
  if (arguments.length === 1) {
    return baseMapGetters(namespaceOrMap as string[]);
  }
  return baseMapGetters(namespaceOrMap as string, map as string[]);
}

export function mapMutations<TMutations = any>(
  namespaceOrMap: string | KnownKeys<TMutations>[],
  map?: KnownKeys<TMutations>[]
): {
  [x: string]: MutationMethod;
} {
  if (arguments.length === 1) {
    return baseMapMutations(namespaceOrMap as string[]);
  }
  return baseMapMutations(namespaceOrMap as string, map as string[]);
}

export function mapActions<TActions = any>(
  namespaceOrMap: string | KnownKeys<TActions>[],
  map?: KnownKeys<TActions>[]
): {
  [x: string]: ActionMethod;
} {
  if (arguments.length === 1) {
    return baseMapActions(namespaceOrMap as string[]);
  }
  return baseMapActions(namespaceOrMap as string, map as string[]);
}

export const genModuleAccessor = <TState, TGetters, TActions, TMutations = unknown>(
  store: Store<any>,
  module: Module<any, RootState>,
  namespace: string
): TState & ExtractTypes<TMutations> & ExtractTypes<TActions> & ExtractGetterTypes<TGetters> => {
  const results = {} as { [key: string]: any };

  if (module.mutations) {
    Object.keys(module.mutations).forEach((mutation) => {
      const mutationFn = (payload?: any, options?: CommitOptions) =>
        store.commit(`${namespace}/${mutation}`, payload, options);
      results[mutation] = mutationFn;
    });
  }

  if (module.actions) {
    Object.keys(module.actions).forEach((action) => {
      const actionFn = async (payload?: any, options?: DispatchOptions) =>
        store.dispatch(`${namespace}/${action}`, payload, options);
      results[action] = actionFn;
    });
  }

  if (module.getters) {
    Object.keys(module.getters).forEach((getter) =>
      Object.defineProperty(results, getter, {
        get() {
          return store.getters[`${namespace}/${getter}`];
        },
      })
    );
  }

  const state = store.state[namespace];
  if (state) {
    Object.keys(state).forEach((x) =>
      Object.defineProperty(results, x, {
        get() {
          return state[x];
        },
      })
    );
  }

  return results as TState &
    ExtractTypes<TMutations> &
    ExtractTypes<TActions> &
    ExtractGetterTypes<TGetters>;
};
