









































import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  Ref,
  ref,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';
import {
  useNamespacedGetters,
  useNamespacedMutations,
  useNamespacedState,
  useState,
} from 'vuex-composition-helpers';

import { NavigationMutations, NavigationState } from '@/store/navigation/types';

import constants from '@/enums/constants';
import regions from '@/api/regions';
import AppVars from '@/enums/appVars';
import { industryNationalLinks, showNavMenu } from '@/utils/navigation';
import { useVuetify } from '@/hooks/useVuetify';
import { RootState } from '@/store/types';
import { UserGetters, UserNamespace } from '@/store/modules/user';

export default defineComponent({
  name: 'Navigation',
  setup: () => {
    const navElement = ref(null) as Ref<HTMLElement | null>;
    const { breakpoints } = useVuetify();

    const { loading, loadingOverlay } = useState<RootState>(['loading', 'loadingOverlay']);

    const { isImpersonating } = useNamespacedGetters<UserGetters>(UserNamespace, [
      'isImpersonating',
    ]);

    const { display, displaySearchMenu, dropdownContent, displayMarketDropdown } =
      useNamespacedState<NavigationState>('navigation', [
        'display',
        'displaySearchMenu',
        'dropdownContent',
        'displayMarketDropdown',
      ]);

    const { setNavElementOffset } = useNamespacedMutations<NavigationMutations>('navigation', [
      'setNavElementOffset',
    ]);

    const isReportsInstance = () => window.location.hostname === AppVars.ReportsAppHost;
    const marketFlag = () => (regions.isMarket() ? constants.MARKETSITE : constants.MAINSITE);
    const instance = getCurrentInstance();

    const isMobile = computed(() => breakpoints.value.mdAndDown);

    const showNavigation = computed(() => {
      return showNavMenu(instance?.proxy.$route.name);
    });
    const regionID: number = regions.getCurrentRegionID();
    const isMarket: boolean = regions.isMarket();
    const isNational: boolean = regions.isNational();

    const route = useRoute();
    const homePagePath = '/';

    // When mounted, set the navigation element height,
    // and update the height when the breakpoint changes.
    watch(
      () => breakpoints.value.name,
      () => {
        const elementHeight = navElement.value?.clientHeight || 0;
        setNavElementOffset(elementHeight);
      }
    );

    onMounted(() => {
      const elementHeight = navElement.value?.clientHeight || 0;
      setNavElementOffset(elementHeight);
    });

    return {
      isReportsInstance,
      marketFlag,
      showNavigation,
      regionID,
      isMarket,
      isNational,
      display,
      displaySearchMenu,
      displayMarketDropdown,
      dropdownContent,
      industryNationalLinks,
      route,
      homePagePath,
      navElement,
      loading,
      loadingOverlay,
      isImpersonating,
      isMobile,
    };
  },
});
