import { render, staticRenderFns } from "./DesktopDropdownMenu.vue?vue&type=template&id=2e21df91&scoped=true&"
import script from "./DesktopDropdownMenu.vue?vue&type=script&lang=ts&"
export * from "./DesktopDropdownMenu.vue?vue&type=script&lang=ts&"
import style0 from "./DesktopDropdownMenu.vue?vue&type=style&index=0&id=2e21df91&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e21df91",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CitiesDropdown: require('/root/project/frontend/src/components/navigation/CitiesDropdown.vue').default,IndustryDropdown: require('/root/project/frontend/src/components/navigation/main/IndustryDropdown.vue').default,DropdownNationalHubsLayout: require('/root/project/frontend/src/components/navigation/main/DropdownNationalHubsLayout.vue').default,JobsDropdown: require('/root/project/frontend/src/components/navigation/JobsDropdown.vue').default,SalariesDropdown: require('/root/project/frontend/src/components/navigation/market/SalariesDropdown.vue').default,CompaniesDropdown: require('/root/project/frontend/src/components/navigation/market/CompaniesDropdown.vue').default,Dropdown: require('/root/project/frontend/src/components/navigation/Dropdown.vue').default})
