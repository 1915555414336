import bixAxios from '@/api/bixAxios';
import ExternalApis from '@/enums/externalApiRoutesEnum';
import { Region, Country, regionsRequestParams } from '@/store/types';

const extractError = (error: any) => {
  if (error.response) {
    return error.response.data;
  } else if (error.request) {
    return error.request;
  }
  return error.message;
};

const getRegions = (params: regionsRequestParams = {}): Promise<Region[]> =>
  bixAxios
    .get(`${ExternalApis.Regions}`, {
      params,
    })
    .then((response: { data: Region[] }) => response.data);

const getAutocompleteCountries = (): Promise<Country[]> => {
  return bixAxios
    .get(`${ExternalApis.AutocompleteCountries}`)
    .then((response: { data: Country[] }) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(extractError(error));
    });
};

export default {
  getRegions,
  getAutocompleteCountries,
};
