import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import user from '@/api/user';
import companies from '@/api/companies';
import {
  State,
  ActionNames,
  CompanySearchUpdateParams,
  CompanySearchResult,
} from '@/store/user/types';
import { ActionNames as Onboarding, City, Profile } from '@/store/onboarding/types';
import { CompanySearchParam } from '@/store/companies/types';
import { RootActionNames } from '@/store/types';

const state = (): State => ({
  companies: [],
  IDtoTitle: {},
});

const getters: GetterTree<State, any> = {
  [ActionNames.years.name]: () => {
    const year = new Date().getFullYear();
    return Array.from({ length: year - 1960 }, (value, index) => year - index);
  },
  [ActionNames.educationYears.name]: () => {
    const year = new Date().getFullYear() + 6;
    return Array.from({ length: year - 1960 }, (value, index) => year - index);
  },
};

const mutations: MutationTree<State> = {
  [ActionNames.receiveCompaniesSuccess.name]: (state, companies: CompanySearchResult[]) => {
    const IDtoTitle = companies
      ? companies.reduce(
          (running: any, current: any) => ({
            ...running,
            [current.id]: current.title,
          }),
          {}
        )
      : {};
    state.companies = companies;
    state.IDtoTitle = IDtoTitle;
  },
};

const toCompanySearchParam = ({
  companyNamePart,
  maxResults,
}: CompanySearchUpdateParams): CompanySearchParam => ({
  company_name_part: companyNamePart,
  max_results: maxResults,
  region_id: 0,
});

const actions: ActionTree<State, any> = {
  editProfile: async (context, dataToStore: Profile | null = null) => {
    const profile: Profile = dataToStore ?? context.rootState.onboarding.profile;
    const company_values = profile.company_values.flatMap((cv) => cv.attr_subs);
    const data = {
      why: profile.why,
      first_name: profile.first_name,
      last_name: profile.last_name,
      user_email: profile.user_email,
      home_city: profile.home_city,
      home_location: profile.home_location,
      other_country: profile.other_country,
      other_state: profile.other_state,
      other_city: profile.other_city,
      resume: profile.resume,
      resume_linked_in: profile.resume_linked_in,
      expertise: profile.expertise,
      experience: profile.experience,
      skills: profile.skills,
      education: profile.education,
      projects: profile.projects,
      topics: profile.topics.join(','),
      cities: profile.cities.flatMap((cv: City) => cv.locations),
      read_cities: profile.cities_read.join(','),
      company_values,
      quick_bio: profile.quick_bio,
      personal_url: profile.personal_url,
      kaggle_url: profile.kaggle_url,
      github_url: profile.github_url,
      dribbble_url: profile.dribbble_url,
      twitter_url: profile.twitter_url,
      content_subscription: profile.content_subscription,
      content_subscription_local: profile.content_subscription_local,
      jobs_subscription: profile.jobs_subscription,
      events_subscription: profile.events_subscription,
      work_relocate: profile.work_relocate,
      work_relocate_paid: profile.work_relocate_paid,
      phone_number: profile.phone_number ?? null,
      job_titles: profile.job_titles ?? [],
    };
    try {
      const profile = await user.editProfile({
        data,
        step: 'edit',
      });
      context.commit(RootActionNames.loadingRemove, ActionNames.editProfile.actionName(1), {
        root: true,
      });
      // Update main user data.
      context.commit('onboarding/userProfile', profile, {
        root: true,
      });
      return profile;
    } catch (e) {
      console.error(e);
      throw new Error('some errors when updating user profile data');
    }
  },
  uploadFile: (context, formData) => {
    return user.uploadFile(formData, (response: AxiosResponse) => {
      if (response.data.picture) {
        context.commit(Onboarding.setProfilePicture.action(), response.data.picture, {
          root: true,
        });
      }
      if (response.data.avatar) {
        context.commit(Onboarding.setProfileAvatar.action(), response.data.avatar, {
          root: true,
        });
      }
    });
  },
  [ActionNames.fetchUserCompanies.name]: async (
    { commit },
    updateParams: CompanySearchUpdateParams
  ) => {
    try {
      const results = await companies.searchByName(toCompanySearchParam(updateParams));
      commit(ActionNames.receiveCompaniesSuccess.name, results);
    } catch {
      /* Nop */
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
