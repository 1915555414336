export default {
  welcome: 'Welcome',
  company: {
    workplacePolicy: {
      'workplace-type': 'Workplace Type',
      'remote-workplace': 'Remote Workplace',
      'hybrid-workplace': 'Hybrid Workplace',
      'onsite-workplace': 'On-site Workplace',
      'employee-remote-description': 'Employees work remotely.',
      'employee-hybrid-description':
        'Employees engage in a combination of remote and on-site work.',
      'employee-traditional-description': 'Employees work from physical offices.',
      'typical-onsite': 'Typical time on-site:',
      'days-per-week': 'days per week',
      'percent-time': '% of the time',
      flexible: 'Flexible',
      'not-specified': 'Not Specified',
    },
  },
};
