


























































import { defineComponent } from '@nuxtjs/composition-api';
import { useState } from 'vuex-composition-helpers';
import { RootState } from '@/store/types';
import { sortBy } from 'lodash-es';
import { marketSites } from '@/enums/appVars';
import apiRegions, { BI2_REGION_ID } from '@/api/regions';

export default defineComponent({
  setup: () => {
    const { regions } = useState<RootState>(['regions']);
    const isNational = apiRegions.isNational();
    const isMarket = apiRegions.isMarket();
    const nationalHost = marketSites[BI2_REGION_ID];
    const allRegions = sortBy(apiRegions.getAllRegions(), (r) => r.name);
    const chunk = (arr: any, size: number) =>
      arr.reduce(
        (chunks: any, el: any, i: number) =>
          (i % size ? chunks[chunks.length - 1].push(el) : chunks.push([el])) && chunks,
        []
      );

    const nationalRegions = sortBy(
      regions.value
        .filter((r) =>
          [
            29, 4, 10, 6, 11, 1, 2, 12, 13, 14, 15, 3, 16, 17, 18, 5, 19, 21, 22, 20, 23, 24, 25,
            26, 8, 7, 27, 28,
          ].includes(r.id)
        )
        .map((region) => {
          const r = { ...region };
          r.url = `${nationalHost}/${region.alias}`;
          return r;
        }),
      (r) => r.name
    );

    return {
      chunk,
      allRegions,
      marketSites,
      nationalHost,
      isMarket,
      isNational,
      nationalRegions,
    };
  },
});
