import ExternalApis from '@/enums/externalApiRoutesEnum';
import bixAxios from '@/api/bixAxios';
import {
  Company,
  CompanyOverview,
  CompanyProfileParam,
  CompanySearchParam,
  ProfileCompleteness,
} from '@/store/companies/types';
import { SearchCompany } from '@/store/search/types';
import { errorCheck } from './errors';

const searchByName = async (params: CompanySearchParam): Promise<SearchCompany[]> => {
  try {
    const response = await bixAxios.get(`${ExternalApis.Companies}/company-search`, {
      params,
    });
    return response.data;
  } catch (err: any) {
    if (errorCheck(err)) {
      throw new Error(err.message);
    }
    throw new Error(err);
  }
};

const uniqueSearchByName = async (params: CompanySearchParam): Promise<SearchCompany[]> => {
  try {
    const response = await bixAxios.get(`${ExternalApis.Companies}/company-search-unique`, {
      params,
    });
    return response.data;
  } catch (err: any) {
    if (errorCheck(err)) {
      throw new Error(err.message);
    }
    throw new Error(err);
  }
};

const getCompanyProfile = async (params: CompanyProfileParam) => {
  try {
    const response = await bixAxios.get<Company>(
      `${ExternalApis.Companies}/alias/${params.alias}?region_id=${params.region_id}`
    );
    return response.data;
  } catch (err: any) {
    errorCheck(err);
    return err.response;
  }
};

const getOverviewById = (id: number): Promise<CompanyOverview> =>
  bixAxios
    .get(`${ExternalApis.Companies}/${id}/overview`)
    .then((response: { data: CompanyOverview }) => response.data)
    .catch((err) => {
      if (errorCheck(err)) {
        throw new Error(err.message);
      }
      throw new Error(err);
    });

const getProfileCompleteness = (id: number): Promise<ProfileCompleteness> =>
  bixAxios
    .get(`${ExternalApis.Companies}/${id}/profile-completeness`)
    .then((response: { data: ProfileCompleteness }) => response.data)
    .catch((err) => {
      if (errorCheck(err)) {
        throw new Error(err.message);
      }
      throw new Error(err);
    });

export default {
  getCompanyProfile,
  searchByName,
  uniqueSearchByName,
  getOverviewById,
  getProfileCompleteness,
};
