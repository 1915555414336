






































/* eslint-disable bix/no-component-imports */
import Component, { mixins } from 'vue-class-component';
import AuthenticationMixin from '@/mixins/authentication';

import {
  InferType,
  mapActions,
  mapMutations,
  mapState,
  mapGetters,
  InferGetterType,
} from '@/utils/vuex-shim';

import appVars from '@/enums/appVars';
import { NavigationMutations, NavigationState } from '@/store/navigation/types';
import { RootActions } from '@/store/types';
import regions from '@/api/regions';

import RegistrationMetadata from '@/components/navigation/registrationMetadata';
import { showNavMenu } from '@/utils/navigation';

import { UserNamespace, State as UserState } from '@/store/modules/user';
import { GetterNamesEnum, namespace, OnboardingGetters } from '@/store/onboarding/types';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';

@Component({
  computed: {
    ...mapState<NavigationState>('navigation', ['display', 'dropdownContent', 'displaySearchMenu']),
    ...mapState<UserState>(UserNamespace, ['profile']),
    ...mapGetters<OnboardingGetters>(namespace, [GetterNamesEnum.userAvailableJobSlots]),
  },
  methods: {
    ...mapMutations<NavigationMutations>('navigation', [
      'hideDropDown',
      'setDisplay',
      'setDropdownContent',
      'toggleDisplaySearchMenu',
      'hideDisplaySearchMenu',
    ]),
    ...mapActions<RootActions>(['getRegions']),
  },
})
export default class BINav extends mixins(AuthenticationMixin, RegistrationMetadata) {
  getRegions!: InferType<RootActions['getRegions']>;
  setDisplay!: InferType<NavigationMutations['setDisplay']>;
  hideDropDown!: InferType<NavigationMutations['hideDropDown']>;
  setDropdownContent!: InferType<NavigationMutations['setDropdownContent']>;
  toggleDisplaySearchMenu!: InferType<NavigationMutations['toggleDisplaySearchMenu']>;
  hideDisplaySearchMenu!: InferType<NavigationMutations['hideDisplaySearchMenu']>;
  dropdownContent!: NavigationState['dropdownContent'];
  displaySearchMenu!: NavigationState['displaySearchMenu'];
  display!: NavigationState['display'];
  toggleIdentifierSearch = false;
  window = window;
  appVars = appVars;
  isNational = regions.isNational;
  userAvailableJobSlots!: InferGetterType<OnboardingGetters[GetterNamesEnum.userAvailableJobSlots]>;
  profile!: UserState['profile'];
  icons = {
    search: faSearch,
  };

  async created() {
    await this.getRegions();
  }

  get regionID() {
    return regions.getCurrentRegionID();
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  updatePath(path: string) {
    if (path.substring(0, 5) == '/user') {
      path = '/';
    }
    if ((path == '/' || path == '') && this.isNational()) {
      path = '/';
    }
    return path;
  }

  /**
   * @returns The current pathname.
   */
  get path() {
    return this.updatePath(window.location.pathname);
  }

  get fullPath() {
    return window.location.origin + this.updatePath(window.location.pathname);
  }

  get loginLink() {
    return `/login?destination=${this.path}`;
  }

  toggleSearch() {
    this.toggleDisplaySearchMenu();
    this.toggleIdentifierSearch = true;
    this.hideDropDown();
  }

  get routeName() {
    return this.$route.name || '';
  }

  /**
   * @returns Whether to show this navigation based
   * on the route.
   */
  get displayNav() {
    return showNavMenu(this.routeName);
  }

  isActive(type: string) {
    const path: string = window.location.pathname;
    return path === `/${type}`;
  }

  get userPermissions() {
    return this.profile?.permissions || [];
  }
}
