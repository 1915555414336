import { CookieStorage } from 'cookie-storage';
import jwtDecode from 'jwt-decode';
import { IDTokenClaims } from 'oidc-client';

export class BixStorage {
  private _cookieStorage: CookieStorage;

  constructor(domain: string) {
    this._cookieStorage = new CookieStorage({
      path: '/',
      sameSite: 'Lax',
      secure: true,
      expires: new Date(Date.now() + 1000 * 60 * 60),
      domain: domain,
    });
  }

  private _parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  setItem(key: string, value: any): void {
    const val = JSON.parse(value);
    if (val.access_token) {
      const profile: IDTokenClaims = jwtDecode(val.access_token);

      this._cookieStorage.setItem(key, val.access_token, {
        expires: new Date(profile.exp * 1000),
      });
    }
    localStorage.setItem(key, value);
  }

  getItem(key: string): any {
    const cookieItem = this._cookieStorage.getItem(key);
    const localStorageItem = localStorage.getItem(key);
    let id_token = '';
    if (localStorageItem) {
      const localItemParsed = JSON.parse(localStorageItem);
      id_token = localItemParsed.id_token;
    }

    if (cookieItem !== null && cookieItem !== undefined) {
      const parsedJwt = this._parseJwt(cookieItem);
      const cookie = {
        id_token: id_token,
        access_token: cookieItem,
        expires_at: parsedJwt.exp,
        profile: parsedJwt,
        scope: parsedJwt.scope.join(' '),
        token_type: 'Bearer',
      };
      return JSON.stringify(cookie);
    }
  }

  removeItem(key: string): void {
    this._cookieStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  clear(): void {
    this._cookieStorage.clear();
    // don't clear all local storage
  }
}
