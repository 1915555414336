export const experienceUrlPaths: Record<string, string> = {
  '<1 Year of Experience': 'entry-level',
  '1-3 Years of Experience': 'mid-level',
  '3-5 Years of Experience': 'mid-level',
  '5-7 Years of Experience': 'senior',
  '7+ Years of Experience': 'senior',
};

// TODO: can this be removed?
export const remoteUrlPaths: Record<string, string> = {
  'Remote + In Office': '',
  'Remote Only': 'remote',
  'In Office': 'office',
};

// TODO: can this be removed?
export const remoteNationalUrlPaths: Record<string, string> = {
  'Remote + In Office': '',
  'Remote Only': 'remote',
  'In Office': 'office',
};

/**
 * Encodes a string of text by replacing +, (, ), --, and spaces to dashes
 * IE: Dev + Engineering -> dev-engineering
 */
export const encodeSelectionUri = (s: string): string => {
  return s === ''
    ? s
    : s.toLowerCase().replace(/[()+]/g, '').replace(/ /g, '-').replace(/--/g, '-');
};

export function getPathFromUrl(url: string) {
  try {
    const u = new URL(url);
    const originRegexp = new RegExp(`^${u.origin}`);

    return url.replace(originRegexp, '');
  } catch (e) {
    return url;
  }
}
