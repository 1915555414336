import { onMounted, onUnmounted, ref, Ref } from '@nuxtjs/composition-api';

const daysInMs = 1_000 * 60 * 60 * 24;
const hoursInMs = 1_000 * 60 * 60;
const minsInMs = 1_000 * 60;
const secondsInMs = 1_000;

/**
 * This will create a count down timer that will be formated `dd:hh:mm:ss`.  It
 * also takes care of counting down for you.
 * @param start - the start date to count from
 * @param end - the end date to count to
 * @returns a ref to the countdown string
 */
export const useTimestampFormat = (start: Ref<Date>, end: Ref<Date> | null = null) => {
  const timeLeft = ref('00:00');

  const formatWithPadding = (value: number) => value.toString().padStart(2, '0');

  const calculate = () => {
    const startMs = start.value.getTime();
    const endMs = end?.value.getTime() || Date.now();
    let diff = startMs - endMs;

    if (diff < 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      timer && clearInterval(timer);
      return;
    }

    const days = Math.floor(diff / daysInMs);
    diff -= days * daysInMs;
    const hours = Math.floor(diff / hoursInMs);
    diff -= hours * hoursInMs;
    const mins = Math.floor(diff / minsInMs);
    diff -= mins * minsInMs;
    const seconds = Math.floor(diff / secondsInMs);

    const formatted: string[] = [];
    if (days > 0) {
      formatted.push(formatWithPadding(days));
    }
    if (hours > 0) {
      formatted.push(formatWithPadding(hours));
    }
    formatted.push(formatWithPadding(mins));
    formatted.push(formatWithPadding(seconds));

    timeLeft.value = formatted.join(':');
  };

  const timer = setInterval(calculate, 1_000);

  onMounted(() => {
    calculate();
  });

  onUnmounted(() => {
    clearInterval(timer);
  });

  return timeLeft;
};
