import { getDefaultClient } from '@/plugins/vueApollo';
import Vue from 'vue';

import companies from '@/api/companies';
import regions from '@/api/regions';
import { marketSites } from '@/enums/appVars';
import {
  ActionNames,
  CompaniesActions,
  CompaniesGetters,
  CompaniesMutations,
  Company,
  CompanyProfileParam,
  State,
  Tab,
} from '@/store/companies/types';

import getCompanyIJobs from '@/graphql/query-getCompanyIJobs.gql';
import getCompanyJobsFilterOptions from '@/graphql/query-getCompanyJobsFilterOptions.gql';
import {
  ICompany,
  ICompanyJobsFilterOptionsInput,
  ICompanyJobsFiltersOptions,
  IGetCompanyJobsFilterOptionsQueryVariables,
  IGetFilteredJobsQueryVariables,
  IJob,
  IQuery,
} from '@/typings/api/external-api-gql';
import jobsApi from '@/api/jobs';

import { JobsSearchCriterias } from '@/api/types';
import { mapJobDtoToIJob } from '@/utils/jobs';

const emptyCompany = {
  id: 0,
  region_id: 0,
  title: '',
  last_updated: new Date(),
  high_volume_poster: false,
  insider_page: false,
  show_funding: false,
  year_founded: new Date(0),
  local_employees: 0,
  total_employees: 0,
  street_address_1: '',
  street_address_2: '',
  city: '',
  state: '',
  zipcode: '',
  email: '',
  facebook: '',
  instagram: '',
  intro_header: '',
  mission: '',
  neighborhood: '',
  twitter: '',
  why_work_with_us: '',
  url: '',
  logo: '',
  header_url: '',
  basic_metatag: {
    title: '',
    description: '',
  },
  funding: [],
  culture_topics: [],
  jobs: [],
  categories: [],
  industries: [],
  perks: {},
  mini_description: '',
  galleries: [],
  adjectives: [],
  elite_page_type: '',
  subscription_id: 0,
  insiders_view: [],
  technologies: [],
  technologies_extra: [],
  alias: '',
  job_count: 0,
  publish_insiders_page: false,
  insider_video: [],
  about_perks: '',
  perks_overview: '',
  metatags: [],
  video: '',
  office_type: 0,
  offices: [],
  limited_listings: false,
  exit_history: [],
  pixel_enabled: false,
  pixel: '',
  status: 1,
} as Company;
Object.freeze(emptyCompany);

const state = (): State => ({
  company: { ...emptyCompany },
  companyOverview: {} as ICompany,
  display_jobs: [],
  profile_tabs: [],
  display_qa: {
    name: '',
    title: '',
    answer: '',
    attr_id: 0,
    question: '',
    image_uri: '',
    image_bix_type: '',
  },
  active_category: -1,
  companyTabsOffset: 0,
  IJobFilters: undefined as ICompanyJobsFiltersOptions | undefined,
  hasFetchedIJobFilters: false,
  IJobs: [],
  IJobsTotal: 0,
  isFetchingIJobs: false,
});

const getters: CompaniesGetters = {
  [ActionNames.getIndustryNames]: (state) => {
    const industries = state.company.industries;

    if (industries) {
      return industries.map((industry) => industry.name).join(', ');
    }

    return '';
  },

  getMarketURL: () => {
    const regionID = regions.getCurrentRegionID();

    return marketSites[regionID];
  },
};

const mutations: CompaniesMutations = {
  clearCompany: (state) => {
    state.company = { ...emptyCompany };
  },

  [ActionNames.setCompany]: (state, company) => {
    state.company = company;
  },

  [ActionNames.mergeCompany]: (state, company) => {
    Object.assign(state.company, company);
  },

  [ActionNames.updateCompanyOverview]: (state, companyOverview) => {
    Object.assign(state.companyOverview, companyOverview);
  },

  [ActionNames.setCompanyCategories]: (state, categories) => {
    Vue.set(state.company, 'categories', categories);
  },

  [ActionNames.setJobs]: (state, jobs) => {
    state.company = { ...state.company, jobs };
  },
  [ActionNames.setDisplayJobs]: (state, jobs) => {
    Vue.set(state, 'display_jobs', jobs);
  },

  [ActionNames.setProfileTabs]: (state, company) => {
    const profileTabs: Tab[] = [
      { id: 1, name: 'Overview', shortName: 'Overview', alias: 'view', path: '' },
    ];
    if (company.insider_page && company.elite_page_type) {
      let insiderPage: { name: string; shortName: string; path: string };
      switch (company.elite_page_type) {
        case 'diversity_inclusion':
          insiderPage = {
            name: 'Diversity + Inclusion',
            shortName: 'Diversity',
            path: 'diversity-inclusion',
          };
          break;
        case 'product_tech':
          insiderPage = {
            name: 'Product + Tech',
            shortName: 'Prod + Tech',
            path: 'product-tech',
          };
          break;
        case 'remote_culture':
          insiderPage = {
            name: 'Remote Culture',
            shortName: 'Remote',
            path: 'remote-culture',
          };
          break;
        case 'offices':
          insiderPage = {
            name: 'Offices',
            shortName: 'Offices',
            path: 'offices',
          };
          break;
        default:
          insiderPage = {
            name: 'Company Culture',
            shortName: 'Culture',
            path: 'office-culture',
          };
          break;
      }
      profileTabs.push({
        id: profileTabs.length + 1,
        name: insiderPage.name,
        shortName: insiderPage.shortName,
        alias: 'office-culture',
        path: insiderPage.path,
      });
    }
    if (Object.keys(company.perks).length > 0) {
      profileTabs.push({
        id: profileTabs.length + 1,
        name: 'Perks + Benefits',
        shortName: 'Benefits',
        alias: 'benefits',
        path: 'benefits',
      });
    }
    profileTabs.push({
      id: profileTabs.length + 1,
      name: 'Jobs',
      shortName: 'Jobs',
      alias: 'jobs',
      path: 'jobs',
    });
    state.profile_tabs = profileTabs;
  },

  [ActionNames.filterDisplayJobs]: (state, catID) => {
    if (catID > -1) {
      state.display_jobs = state.company.jobs?.filter((job) => job.category_id == catID);
    } else {
      state.display_jobs = state.company.jobs;
    }
  },

  [ActionNames.setActiveCategory]: (state, catID) => {
    state.active_category = catID;
  },

  [ActionNames.setInsiderVideo]: (state, insiderVideo) => {
    state.company.insider_video = insiderVideo;
  },

  [ActionNames.setCompanyTabsOffset]: (state, newOffset: number) => {
    state.companyTabsOffset = newOffset;
  },

  [ActionNames.setIJobFilters]: (state, filters: ICompanyJobsFiltersOptions) => {
    state.IJobFilters = filters;
  },

  [ActionNames.setHasFetchedIJobsFilters]: (state) => {
    state.hasFetchedIJobFilters = true;
  },

  [ActionNames.setIJobs]: (state, jobs: IJob[]) => {
    state.IJobs = jobs;
  },

  [ActionNames.setIJobsTotal]: (state, total: number) => {
    state.IJobsTotal = total;
  },

  [ActionNames.setIsFetchingIJobs]: (state, value: boolean) => {
    state.isFetchingIJobs = value;
  },
};

const actions: CompaniesActions = {
  [ActionNames.getCompanyProfile]: async ({ commit }, params: CompanyProfileParam) => {
    try {
      commit('clearCompany');
      const company = await companies.getCompanyProfile(params);
      if (company.id !== undefined) {
        commit('setCompany', company);
        commit('setProfileTabs', company);
        commit('setInsiderVideo', company.insider_video);
      }
      return company;
    } catch (err) {
      return err;
    }
  },

  [ActionNames.updateCompanyOverview]: ({ commit }, companyOverview: ICompany) => {
    commit('updateCompanyOverview', companyOverview);
  },

  [ActionNames.fetchIJobFilters]: async ({ commit }, params: ICompanyJobsFilterOptionsInput) => {
    const graphQlClient = getDefaultClient();

    try {
      const results = await graphQlClient.query<IQuery, IGetCompanyJobsFilterOptionsQueryVariables>(
        {
          query: getCompanyJobsFilterOptions,
          variables: {
            filters: params,
          },
        }
      );

      commit('setIJobFilters', results.data.companyJobsFiltersOptions);
      commit('setHasFetchedIJobsFilters');
    } catch (err) {
      console.error(err);
    }
  },
  [ActionNames.fetchIJobs]: async ({ commit }, params: IGetFilteredJobsQueryVariables) => {
    commit('setIsFetchingIJobs', true);

    const graphQlClient = getDefaultClient();

    try {
      const results = await graphQlClient.query<IQuery, IGetFilteredJobsQueryVariables>({
        query: getCompanyIJobs,
        variables: {
          pagination: params.pagination,
          filters: params.filters,
        },
      });

      commit('setIJobs', results.data.customFilteredJobs.customFilteredJobs);
      commit('setIJobsTotal', results.data.customFilteredJobs.jobCount);
      commit('setIsFetchingIJobs', false);
    } catch (err) {
      console.error(err);
    }
  },
  [ActionNames.fetchJobs]: async (
    { commit },
    { params, company }: { params: JobsSearchCriterias; company: ICompany }
  ) => {
    commit('setIsFetchingIJobs', true);

    try {
      const data = await jobsApi.getJobsFromJobsApi(params);

      const jobs = data.items.map((jobDto) => mapJobDtoToIJob(jobDto, company));

      commit('setIJobs', jobs);
      commit('setIJobsTotal', data.totalItems);
      commit('setIsFetchingIJobs', false);
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
