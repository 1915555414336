import { Context } from '@nuxt/types';

export default async ({ route }: Context) => {
  //running locally this will put you in a redirect loop but in a hosted env the redirect will work as it will redirect off of the vue app
  if (
    route.path === '/' ||
    route.path.startsWith('/home') ||
    route.path.startsWith('/jobs') ||
    route.path.startsWith('/company') ||
    route.path.startsWith('/companies') ||
    route.path.startsWith('/profile')
  ) {
    window.location.href = `${window.location.protocol}//${window.location.host}${route.path}${window.location.search}`;
  }
};
