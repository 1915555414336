import bixAxios from '@/api/bixAxios';
import ExternalApis from '@/enums/externalApiRoutesEnum';
import FormData from 'form-data';
import regions from '@/api/regions';
import { errorCheck } from './errors';
import { extractError } from '@/api/helpers/errors';
import { Profile } from '@/store/onboarding/types';
import ProfileTransformer from '@/api/transformers/ProfileTransformer';
import { ProfileResponse } from '@/api/types';

export default {
  stepPost(data: any, callback: Function): Promise<Profile> {
    const regionID = regions.getCurrentRegionID();
    return bixAxios
      .post(`${ExternalApis.Onboarding}` + '/' + regionID, data)
      .then((response) => {
        callback(response);
        return ProfileTransformer.fetch(response.data);
      })
      .catch((error) => {
        errorCheck(error);
        throw new Error(error.error);
      });
  },
  stepPostFile(data: FormData, callback: Function) {
    const regionID = regions.getCurrentRegionID();
    return bixAxios
      .post(`${ExternalApis.Onboarding}` + '/file/' + regionID, data)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  getExpertise(callback: Function) {
    const params = {
      t: Date.now(),
    };
    return bixAxios
      .get(ExternalApis.Expertise, { params })
      .then((response) => {
        callback(response.data);
        return response.data;
      })
      .catch(function (error) {
        errorCheck(error);
      });
  },
  getSkills(expertise_id: number, callback: Function) {
    const params = {
      expertise_id,
      t: Date.now(),
    };
    return bixAxios
      .get(ExternalApis.Skills, {
        params,
      })
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  getCompanyValues(callback: Function) {
    return bixAxios
      .get(ExternalApis.CompanyValues)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  getTopics(callback: Function) {
    return bixAxios
      .get(`${ExternalApis.Topics}`)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCheck(error);
      });
  },
  async getProfileAsync(): Promise<Profile> {
    const regionID = regions.getCurrentRegionID();
    return await bixAxios
      .get(`${ExternalApis.Profile}` + '/' + regionID)
      .then((response: { data: ProfileResponse }) => ProfileTransformer.fetch(response.data))
      .catch((error) => {
        throw new Error(extractError(error));
      });
  },
};
