import { ActionTree } from 'vuex';

import ExternalApis from '@/enums/externalApiRoutesEnum';
import bixAxios from '@/api/bixAxios';
import { RootState } from '@/store/types';
import { TrackingActionNames, TrackingData } from '@/store/tracking/types';

const actions: ActionTree<RootState, any> = {
  [TrackingActionNames.postInfo]: async (_, data: TrackingData) => {
    try {
      await bixAxios.post(ExternalApis.Views, data);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
};
