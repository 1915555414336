import bixAxios from '@/api/bixAxios';
import ExternalApis from '@/enums/externalApiRoutesEnum';
import { errorCheck } from './errors';

const sendContentEmail = (
  regionID: number,
  customEmail: string,
  useCache: string,
  callback: Function
) => {
  bixAxios
    .get(`${ExternalApis.InternalEmailContent}/${regionID}/${customEmail}/${useCache}`, {})
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      callback(error.response.data);
      errorCheck(error);
    });
};

export const sendUtcContentEmail = async (data: Record<string, any>) => {
  try {
    const response = await bixAxios.post(ExternalApis.QAEmailUtcTime, data);
    return response.data;
  } catch (error: any) {
    const err = errorCheck(error);
    return err.response.data;
  }
};

const sendContentGroupEmail = (regionID: number, callback: Function) => {
  bixAxios
    .get(`${ExternalApis.GroupEmailContent}/${regionID}`, {})
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      callback(error.response.data);
      errorCheck(error);
    });
};

const sendContentAllCombinationsEmail = (
  regionID: number,
  customEmail: string,
  callback: Function
) => {
  bixAxios
    .get(`${ExternalApis.AllCombinationsEmailContent}/${regionID}/${customEmail}`, {})
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      callback(error.response.data);
      errorCheck(error);
    });
};

const unsubscribeContent = (secret: string, callback: Function) => {
  bixAxios
    .post(`${ExternalApis.UnsubscribeEmailContent}/${secret}`, {})
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCheck(error);
    });
};

const pauseContent = (secret: string, callback: Function) => {
  bixAxios
    .post(`${ExternalApis.PauseEmailContent}/${secret}`, {})
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCheck(error);
    });
};

const cancelPauseContent = (secret: string, callback: Function) => {
  bixAxios
    .post(`${ExternalApis.CancelPauseEmailContent}/${secret}`, {})
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorCheck(error);
    });
};

export default {
  sendContentEmail,
  sendContentGroupEmail,
  sendContentAllCombinationsEmail,
  unsubscribeContent,
  pauseContent,
  cancelPauseContent,
};
