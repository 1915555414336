import { Request, Response } from 'miragejs';
import { RealtimeFeatureFlag } from '@/api/types';

export function realtimeFeatureFlags(schema: unknown, request: Request): Response {
  const flagName = request?.queryParams?.name;
  const regionID = request?.queryParams?.region_id;

  const aswFlag = 'articleSignUpWallEnabled';
  const raFlag = 'RecentArticles';

  if (flagName) {
    const flag: Partial<RealtimeFeatureFlag> = {};

    switch (flagName) {
      case aswFlag:
        flag.id = 1;
        flag.name = flagName;
        flag.region_id = Number(regionID);
        flag.value = localStorage.getItem(aswFlag) === 'on' ? 1 : 0;
        break;
      case raFlag:
        flag.id = 2;
        flag.name = flagName;
        flag.region_id = Number(regionID);
        flag.value = localStorage.getItem(raFlag) === 'on' ? 1 : 0;
        break;
      default:
        break;
    }

    return new Response(200, { 'cache-control': 'no-cache' }, flag);
  } else {
    return new Response(
      500,
      { 'cache-control': 'no-cache' },
      {
        message: 'Error retrieving featureFlag',
      }
    );
  }
}
