





























































































import {
  defineComponent,
  reactive,
  computed,
  toRefs,
  watch,
  onUnmounted,
  ref,
} from '@nuxtjs/composition-api';
import { marketSites } from '@/enums/appVars';
import { useNamespacedGetters, useNamespacedActions } from 'vuex-composition-helpers';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { State as UserState, UserActions, UserGetters, UserNamespace } from '@/store/modules/user';

import { appVars } from '@/enums/appVars';
import { CompaniesGetters } from '@/store/companies/types';

import { UserRoles } from '@/enums/userRoles';
import { hasPermission as hasAnyPermission } from '@/utils/user-permissions';
import { CompanyActions, namespace } from '@/store/modules/company';

import { ICompany, ICompanySubscriptionLevel } from '@/typings/api/external-api-gql';

import { getFeatureFlags } from '@/plugins/featureFlags';
import { isFeatureFlagEnabled } from '@/utils/feature-flag';
const subscriptionAllowsContentManager = [
  'ELITE' as ICompanySubscriptionLevel,
  'PREMIUM' as ICompanySubscriptionLevel,
];

const subscriptionAllowsAnalyticsDashboard = [
  'ELITE' as ICompanySubscriptionLevel,
  'PREMIUM' as ICompanySubscriptionLevel,
  'STANDARD' as ICompanySubscriptionLevel,
];

const companyPermissionNames = [
  UserRoles.CompanyAdmin,
  UserRoles.CompanyProfileEditor,
  UserRoles.CompanyJobPoster,
];

import regions, { isNational } from '@/api/regions';

export default defineComponent({
  props: {
    regionId: {
      type: Number,
      required: true,
    },
  },
  setup: (props) => {
    const userModule = createNamespacedHelpers<UserState, UserGetters, UserActions>(UserNamespace);

    const { hasPermission, getPermissionsForRegion } = useNamespacedGetters<UserGetters>(
      'UserModule',
      ['hasPermission', 'getPermissionsForRegion']
    );

    const { profile } = userModule.useState(['profile']);

    const { getMarketURL: marketUrl } = useNamespacedGetters<CompaniesGetters>('companies', [
      'getMarketURL',
    ]);

    const { fetchCompanyOverview } = useNamespacedActions<CompanyActions>(namespace, [
      'fetchCompanyOverview',
    ]);

    const state = reactive({
      overview: undefined as ICompany | undefined,
      isLoading: true,
      showContentManagerLink: false,
      showAnalyticsDashboardLink: false,
      drupalCompanyId: undefined as number | undefined,
      featureFlags: getFeatureFlags(),
    });

    const AccessCandidateViewerFeatureFlagValue = ref(false);
    isFeatureFlagEnabled('access_cv').then((flagValue) => {
      AccessCandidateViewerFeatureFlagValue.value = flagValue;
    });

    const companyPhoto = computed(() => {
      if (state.overview) {
        return {
          url: state.overview.logo || '',
          region_id: regions.getRegionID(state.overview.regionName),
        };
      }
      return {
        url: '',
        region_id: regions.getCurrentRegionID(),
      };
    });

    const companyLink = computed(() =>
      state.overview?.alias ? state.overview.alias : '/employer'
    );

    const companyTitle = computed(() => state.overview?.name || '');

    const companyId = computed(() => state.overview?.id);

    const dashboardURL = computed(
      () =>
        `${marketSites[regions.getNationalDotBuiltinComId()]}/reports/dashboard/${
          state.overview?.id
        }`
    );

    /**
     * @returns Boolean if user has any company permissions, regardless of region
     */
    const hasCompanyPermissions = computed(() => {
      return hasAnyPermission(profile.value?.permissions || [], companyPermissionNames);
    });

    /**
     * @returns Array of company permissions specific to current region
     * Filter out permissions that don't have a locationId
     */
    const locationPermissions = computed(() => {
      return getPermissionsForRegion.value(props.regionId).filter((perm) => perm?.locationId);
    });

    const checkUserPermission = (...names: string[]) =>
      hasPermission.value(props.regionId, ...names);

    const checkContentManagerAccess = () => {
      state.showContentManagerLink = !!subscriptionAllowsContentManager.find(
        (sub) => sub === state.overview?.subscription.level
      );
    };

    const checkAnalyticsDashboardAccess = async () => {
      state.showAnalyticsDashboardLink = !!subscriptionAllowsAnalyticsDashboard.find(
        (sub) => sub === state.overview?.subscription.level
      );
    };

    /**
     * @returns First company permission within props.regionId
     */
    const firstCompanyAdmin = computed(
      () =>
        locationPermissions.value?.find(
          (perm) => perm && perm.locationId === props.regionId && perm.companyId != null
        )?.companyId
    );

    const companyWatcher = watch(
      () => firstCompanyAdmin,
      async (companyId) => {
        if (companyId.value == null) {
          return;
        }
        const result = (await fetchCompanyOverview({ id: companyId.value })) as ICompany;
        if (result?.id) {
          state.overview = result;
          state.drupalCompanyId = result.marketSites[0].companyID || 0;
          checkContentManagerAccess();
          checkAnalyticsDashboardAccess();
        }
        state.isLoading = false;
      },
      {
        immediate: true,
      }
    );

    onUnmounted(() => {
      companyWatcher();
    });

    return {
      ...toRefs(state),
      checkUserPermission,
      companyLink,
      companyPhoto,
      companyTitle,
      companyId,
      marketUrl,
      hasCompanyPermissions,
      locationPermissions,
      appVars,
      dashboardURL,
      isNational: isNational(),
      userPermissions: profile.value?.permissions || [],
      AccessCandidateViewerFeatureFlagValue,
    };
  },
});
