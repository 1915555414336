import { useNamespacedMutations, useNamespacedState } from 'vuex-composition-helpers';
import { NavigationMutations, NavigationState } from '@/store/navigation/types';
import regions, { isNational } from '@/api/regions';
import { sortBy } from 'lodash-es';

import { Link } from '@/interfaces/links';

import { stringToAnchorText } from '@/utils/formatting';

/**
 * main links for industry nav menu for National
 */
export const industryLinks: Link[] = [
  { title: 'Artificial Intelligence', url: '/artificial-intelligence' },
  { title: 'Blockchain', url: '/blockchain' },
  { title: 'Cloud Computing', url: '/cloud-computing' },
  { title: 'Cyber Security', url: '/cybersecurity' },
  { title: 'Data Science', url: '/data-science' },
  { title: 'Edtech', url: '/edtech' },
  { title: 'Expert Contributors', url: '/expert-contributors' },
  { title: 'Fintech', url: '/fintech' },
  { title: 'Hardware', url: '/hardware' },
  { title: 'Healthcare Technology', url: '/healthcare-technology' },
  { title: 'IoT: The Internet of Things', url: '/internet-things' },
  { title: 'Remote Work', url: '/remote-work' },
  { title: 'Robotics', url: '/robotics' },
];

export const industryNationalLinks: Link[] = [
  ...industryLinks,
  { title: 'Transportation Tech', url: '/transportation-tech' },
  { title: 'Software Engineering', url: '/software-engineering-perspectives' },
];

/**
 * main links for industry nav menu
 */

export const industryTechNationalLinks = () => {
  let techTopicLinks = industryNationalLinks.filter((item) => item.title !== 'Expert Contributors');
  return techTopicLinks;
};

/**
 * main links for salaries nav menu
 */
export const salaries = () => {
  const salaries: Link[] = [
    { title: 'Data Scientist', url: 'data-analytics/data-scientist' },
    { title: 'Front End Developer', url: 'dev-engineer/front-end-developer' },
    { title: 'Account Executive', url: 'sales/account-executive' },
    { title: 'Operations Manager', url: 'operations/operations-manager' },
    { title: 'HR Manager', url: 'hr-recruiting/hr-manager' },
    { title: 'Account Manager', url: 'sales/account-manager' },
    { title: 'Product Manager', url: 'product/product-manager' },
    { title: 'Product Designer', url: 'design-ux/product-designer' },
    { title: 'Marketing Manager', url: 'marketing/marketing-manager' },
    { title: 'Software Engineer', url: 'dev-engineer/software-engineer' },
    { title: 'UX Designer', url: 'design-ux/ux-designer' },
    { title: 'Project Manager', url: 'project-mgmt/project-manager' },
  ];
  const salaryLinks = regions.isNational() ? sortBy(salaries, ['title']) : salaries;
  return {
    salaryLinks,
  };
};

export const showNavMenu = (routeName: string | null | undefined) => {
  switch (routeName) {
    case 'onboarding':
    case 'onboarding-welcome':
    case 'login':
      return false;
    default:
      return true;
  }
};

export const updatePath = (path: string) => {
  if (path.substring(0, 5) == '/user') {
    path = '/';
  }
  if ((path == '/' || path == '') && isNational()) {
    path = '/';
  }
  return path;
};

/**
 * @returns The current pathname.
 */
export const getPath = () => {
  return updatePath(`${window.location.pathname}${window.location.search}`);
};

/**
 * new url for marketing for employer link
 */

export const forEmployerURL = () => {
  let employerURL = `https://employers.builtin.com/membership?utm_medium=BIReferral&utm_source=foremployers`;
  return employerURL;
};

/**
 * new url for marketing for employer link
 */

export const forEmployerURLHomePage = () => {
  let employerURL = `https://employers.builtin.com/membership/?utm_medium=bireferral&utm_source=cta&utm_campaign=na&utm_content=na`;
  return employerURL;
};

/***
 * Helper for main navigation
 * basic functions for the controls of dropdown menu in the top navigation block
 *
 *  use only in setup method in vue 3
 *
 *  @return Object
 */
export default () => {
  const { display, dropdownContent } = useNamespacedState<NavigationState>('navigation', [
    'display',
    'dropdownContent',
  ]);

  const { setDisplay, setDropdownContent, hideDisplaySearchMenu } =
    useNamespacedMutations<NavigationMutations>('navigation', [
      'setDisplay',
      'setDropdownContent',
      'hideDisplaySearchMenu',
    ]);

  let timeOutId: any = 0;
  const openDropdown = (content: string, delay = 200) => {
    timeOutId = setTimeout(() => {
      if (!display.value) {
        setDropdownContent(content);
        setDisplay(true);
      }
    }, delay);
  };

  const openDropdownNoDelay = (content: string) => {
    setDropdownContent(content);
    setDisplay(true);
    hideDisplaySearchMenu();
  };

  const closeDropdown = () => {
    if (timeOutId) {
      clearTimeout(timeOutId);
      timeOutId = 0;
    }
    setDisplay(false);
  };

  const isOpened = (dropdownName: string) => {
    return display.value && dropdownContent.value === dropdownName;
  };

  const dropDownOpen = display;
  return {
    openDropdown,
    closeDropdown,
    dropdownContent,
    dropDownOpen,
    isOpened,
    display,
    openDropdownNoDelay,
  };
};

/**
 *  Returns an object with link attributes such as href, class, and target
 *  @param menuItem - The link item to get attributes for.
 *  @returns An object with link attributes.
 */
export const getLinkAttributes = (menuItem: Link) => {
  const itemClass = stringToAnchorText(menuItem.title).replace('anchor-', 'nav-').replace('.', '-');
  const target = menuItem.openInNewTab ? { target: '_blank' } : {};
  return !menuItem.isSpaLink
    ? { href: menuItem.url, class: ['drupal_link', itemClass], ...target }
    : { to: menuItem.url, class: ['bix_link', itemClass], ...target };
};
