import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import { getDefaultClient } from '@/plugins/vueApollo';
import { GetJobCategories } from '@/graphql';
import { theme } from '@/styles/variables/colors';
import { IQuery, IJobCategory } from '@/typings/api/external-api-gql';

interface Dictionary<T> {
  [key: number]: T;
}

/** The state structure of JobsModule */
export interface State {
  /** A dictionary of ID (number) and category (JobCategory) containing the categories from the api */
  categories: Dictionary<JobCategory>;
}

/** Extends the API response for a job category to include the category color */
export interface JobCategory extends IJobCategory {
  /** The category color */
  color?: string;
}

/**
 * Gets the color code based on the category name
 * @param name - The name of the category
 *
 * @returns The hex color code of the category
 */
export const getCategoryColor = (name: string) => {
  switch (name.toLowerCase()) {
    case 'content':
      return theme.contentYellow;
    case 'data + analytics':
      return theme.dataBlue;
    case 'design + ux':
      return theme.designPink;
    case 'dev + engineer':
    case 'developer + engineer':
      return theme.devRed;
    case 'finance':
      return theme.financeGreen;
    case 'hr + recruiting':
      return theme.hrGreen;
    case 'internships':
      return theme.internshipOrange;
    case 'legal':
      return theme.legalOrange;
    case 'marketing':
      return theme.marketingPurple;
    case 'operations':
      return theme.operationsPurple;
    case 'product':
      return theme.productBlue;
    case 'project mgmt':
      return theme.projectMgmtBlue;
    case 'sales':
      return theme.salesBlue;
    default:
      return undefined;
  }
};

/** The fully typed vuex jobs module */
@Module({
  stateFactory: true,
  name: 'JobsModule',
  namespaced: true,
})
export default class JobsModule extends VuexModule implements State {
  /** The known categories */
  public categories: Dictionary<JobCategory> = {};

  /**
   * Converts the category dictionary into a list
   *
   * @returns A list of job categories
   */
  get categoryList(): JobCategory[] {
    const values: JobCategory[] = Object.values(this.categories);
    return values.sort((x, y) => x.name.toLowerCase().localeCompare(y.name.toLowerCase()));
  }

  /**
   * Checks if there are any categories in the store
   *
   * @returns A boolean indicating if there are any known categories
   */
  get hasCategories() {
    return this.categoryList.length > 0;
  }

  /**
   * Generates a list of supplied categoies that are not known to the store
   *
   * @returns A function taking in a list of category IDs (numbers) and
   *          returns a list of category IDs (numbers) that are known to
   *          the store but not the incoming list
   */
  get disabledCategories() {
    return (companyJobCategoryIds: number[]) => {
      const categoryIds = this.categoryList.map((categoryId) => categoryId.id);
      return categoryIds.filter(
        (categoryId) => !companyJobCategoryIds.some((jobCategoryId) => categoryId === jobCategoryId)
      );
    };
  }

  /**
   * Replaces the currently known categories with the new list of categories
   * @param this - The state of this module
   * @param categories - The categoies to add to the store
   */
  @Mutation
  updateJobCategories(this: State, categories: (JobCategory | null)[]) {
    this.categories = {};

    const categoryMap = categories.map((category) => {
      if (category) {
        const isEngineering = /Developer \+ Engineer/i.test(category.name);
        return {
          ...category,
          name: isEngineering ? 'Dev + Engineer' : category.name,
          color: getCategoryColor(category.name),
        };
      }
    });

    categoryMap.forEach((category) => {
      if (category) this.categories[category.id] = category;
    });
  }

  /**
   * Fetches the job categories asynchronous and commits the updated
   * job categories
   */
  @Action
  async fetchJobCategories() {
    if (this.hasCategories) return;

    try {
      const client = getDefaultClient();
      const results = await client.query<IQuery>({
        query: GetJobCategories,
      });

      this.context.commit('updateJobCategories', results.data.jobCategories);
    } catch {
      /* Nop */
    }
  }
}
