




































import Vue from 'vue';
import Component from 'vue-class-component';
import regions from '@/api/regions';
import { mapActions } from 'vuex';
import { RootActionNames as Root } from '@/store/types';
import { getRegionBestPlacesToWorkPath } from '@/utils/best-places-to-work';
import { isFeatureFlagEnabled } from '@/utils/feature-flag';
import { marketSites } from '@/enums/appVars';

const getYearToDisplay = () => {
  return new Date().getFullYear();
};

@Component({
  methods: {
    ...mapActions({
      loadCities: Root.getRegions,
    }),
  },
})
export default class CompaniesDropdown extends Vue {
  loadCities!: Function;
  bptwBannerFeatureFlagValue = false;

  created() {
    this.loadCities();
    isFeatureFlagEnabled('bptw_banner').then((flagValue: boolean) => {
      this.bptwBannerFeatureFlagValue = flagValue;
    });
  }

  get pathAllCompanies() {
    return '/companies';
  }

  get pathHiringNow() {
    return '/companies?status=hiring_now';
  }

  get pathBestPlaces() {
    var url = getRegionBestPlacesToWorkPath(
      regions.getRegionHostName(),
      'overall',
      this.bptwBannerFeatureFlagValue ? getYearToDisplay() : getYearToDisplay() - 1,
      this.bptwBannerFeatureFlagValue
    );
    if (this.bptwBannerFeatureFlagValue) {
      return `${marketSites[9]}${url}`;
    }
    return url;
  }

  get viewAll() {
    return 'View all';
  }
}
