import { Enum } from 'enumify';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '../types';
import { CompanyOffice } from '@/store/companies/types';
import { Job as JobInterface } from '@/store/jobs/types';

export interface State {
  step: string;
  empty_state: boolean | string;
  topics: Topic[];
  jobs: { [key: number]: Job[] }; // key - region id
  tech_news: Tech[];
  tech_news_empty_results: boolean;
  companies_per_values: Company[];
  companies_per_values_empty_results: boolean;
  slider_type_news: string;
  slider_type_jobs: string;
  slider_type_companies: string;
  popup_type: boolean | string;
  popup_flow_type: boolean | string;
  popup_step: boolean | number;
  popup_steps: {
    type: string;
    altered: boolean;
    flow: number[];
  }[];
  popup_empty_state_steps: {
    type: string;
    altered: boolean;
    flow: number[];
  }[];
}

export interface AvailableTopic {
  topicId: number;
  maxPublishDate: string;
}

export interface TopicBlog {
  id: number;
  title: string;
  region_id: number;
  subtitle: string;
  teaser: string;
  thumbnail: string;
  alias: string;
  topics: { id: number }[];
  spotlight: number;
  spotlight_images: string[] | null;
  created_at: string;
  updated_at: string;
}

export interface Job extends JobInterface {
  location_name?: null | string;
  experience_level: string;
  company_title: string | undefined;
  company_logo: string | null;
  company_region: number;
  remote_status: string;
  limited_listing?: boolean | null;
}

export interface Tech {
  id: number;
  title: string;
  image_url: string;
  alias: string;
  city: number;
  created_at: string;
}

export interface Company {
  id: number;
  title: string;
  image_url: string;
  gallery_url: string;
  logo: string;
  alias: string;
  region_id: number;
  industry: string;
  open_jobs: number;
  perks: number[];
  subscription_id: number;
  location?: string;
  match_values?: CompanyMatchValues;
  offices?: CompanyOffice[];
}

export interface CompanyResponse {
  id: number;
  title: string;
  header: string;
  gallery: string;
  logo: string;
  alias: string;
  region_id: number;
  industry: string;
  open_jobs: number;
  perks: string;
  subscription_id: number;
  location?: string;
  match_values?: CompanyMatchValues;
  offices?: CompanyOffice[];
}

export interface Topic {
  id: number;
  name: string;
}

export interface JobParams {
  expertises?: string;
  regions: string;
  locations?: string;
  remote?: number;
  slider?: string;
  exclude_national?: number;
}

export interface RemoteJobParams extends JobParams {
  remote: number;
}

export interface CompanyParams {
  count: string;
  regions: string;
}

export interface CompanyMatchValues {
  count: number;
  cats: CompanyMatchCategory[];
}

export interface CompanyMatchCategory {
  count: number;
  cat_id: number;
  name: string;
}

// Limit slides count in slider
export const LimitSlidesJobs: number = 16;
export const LimitSlidesCompanies: number = 24;
export const LimitSlidesTopics: number = 23;
export const LimitSlidesNews: number = 23;

// Slider types
export const SliderTypeTopic: string = 'topic';
export const SliderTypeNews: string = 'news';
export const SliderTypeJobs: string = 'jobs';
export const SliderTypeCompanies: string = 'companies';
export const SliderTypeCompaniesRemote: string = 'companies_remote';
export const SliderTypeCompaniesPerValues: string = 'companies_per_values';

// Popup types
export const PopupTypeUpdate: string = 'update';
export const PopupTypeEmptyState: string = 'empty_state';

// Popup Steps Flow:
export const PopupStepsFlow = [
  { type: SliderTypeTopic, altered: false, flow: [6] },
  { type: SliderTypeJobs, altered: false, flow: [3, 4] },
  { type: SliderTypeCompanies, altered: false, flow: [7, 3, 4] },
  { type: SliderTypeCompaniesPerValues, altered: false, flow: [7] },
];

// Empty State Popup Steps Flow:
export const PopupEmptyStateStepsFlow = [
  { type: SliderTypeTopic, altered: false, flow: [6] },
  { type: SliderTypeJobs, altered: false, flow: [5, 3, 4, 8] },
  { type: SliderTypeCompanies, altered: false, flow: [5, 3, 4, 7] },
];

export interface StepOptions {
  popup_type?: boolean | string;
  popup_flow_type?: boolean | string;
  continue?: boolean;
  back?: boolean;
  save?: boolean;
}

export enum HomepageActionsEnum {
  userJobs = 'userJobs',
}

export interface HomepageActions extends ActionTree<State, RootState> {
  step: (ctx: ActionContext<State, RootState>, payload?: StepOptions) => void;
}

export class ActionNames extends Enum {
  getter() {
    return 'homepage/' + this.name;
  }

  action() {
    return 'homepage/' + this.name;
  }

  mutation() {
    return 'homepage/' + this.name;
  }
}

ActionNames.initEnum([
  'topics',
  'userTechNews',
  'userTechNewsEmpty',
  'userJobs',
  'step',
  'popupStep',
  'prevStep',
  'nextStep',
  'popupType',
  'popupFlowType',
  'currentFlow',
  'addWorkCityFlow',

  'whyCompanies',
  'whyJobs',
  'whyTopics',

  'emptyState',
]);
