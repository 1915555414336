import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1b23fe3f = () => interopDefault(import('../src/pages/course-directory/index.vue' /* webpackChunkName: "pages/course-directory/index" */))
const _626cd93c = () => interopDefault(import('../src/pages/employer.vue' /* webpackChunkName: "pages/employer" */))
const _2c62bc32 = () => interopDefault(import('../src/pages/employer/admins.vue' /* webpackChunkName: "pages/employer/admins" */))
const _6b6148f3 = () => interopDefault(import('../src/pages/employer/analytics.vue' /* webpackChunkName: "pages/employer/analytics" */))
const _0be632b3 = () => interopDefault(import('../src/pages/employer/candidates.vue' /* webpackChunkName: "pages/employer/candidates" */))
const _166b92f4 = () => interopDefault(import('../src/pages/employer/content-manager.vue' /* webpackChunkName: "pages/employer/content-manager" */))
const _7e76928d = () => interopDefault(import('../src/pages/employer/content-studio.vue' /* webpackChunkName: "pages/employer/content-studio" */))
const _c64e8b38 = () => interopDefault(import('../src/pages/employer/help.vue' /* webpackChunkName: "pages/employer/help" */))
const _6cb334f9 = () => interopDefault(import('../src/pages/employer/jobs.vue' /* webpackChunkName: "pages/employer/jobs" */))
const _583c9d0d = () => interopDefault(import('../src/pages/employer/profiles.vue' /* webpackChunkName: "pages/employer/profiles" */))
const _f92efa82 = () => interopDefault(import('../src/pages/employer/index.vue' /* webpackChunkName: "pages/employer/index" */))
const _0d49a4be = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _22eded3b = () => interopDefault(import('../src/pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _ae595de4 = () => interopDefault(import('../src/pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _e7ee9606 = () => interopDefault(import('../src/pages/newsletter/index.vue' /* webpackChunkName: "pages/newsletter/index" */))
const _a653d842 = () => interopDefault(import('../src/pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _7696131c = () => interopDefault(import('../src/pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _50db313b = () => interopDefault(import('../src/pages/ssr-health/index.vue' /* webpackChunkName: "pages/ssr-health/index" */))
const _836f95a2 = () => interopDefault(import('../src/pages/onboarding/welcome.vue' /* webpackChunkName: "pages/onboarding/welcome" */))
const _6ff480b2 = () => interopDefault(import('../src/pages/qa/internal_email/content.vue' /* webpackChunkName: "pages/qa/internal_email/content" */))
const _3cf74c58 = () => interopDefault(import('../src/pages/tools/admin/token.vue' /* webpackChunkName: "pages/tools/admin/token" */))
const _4a0e7bb3 = () => interopDefault(import('../src/pages/byte/pause/_secret/index.vue' /* webpackChunkName: "pages/byte/pause/_secret/index" */))
const _bc26e364 = () => interopDefault(import('../src/pages/byte/unsubscribe/_secret/index.vue' /* webpackChunkName: "pages/byte/unsubscribe/_secret/index" */))
const _b4faf902 = () => interopDefault(import('../src/pages/newsletter/unsubscribe/_secret/index.vue' /* webpackChunkName: "pages/newsletter/unsubscribe/_secret/index" */))
const _57474bbe = () => interopDefault(import('../src/pages/reports/dashboard/_id.vue' /* webpackChunkName: "pages/reports/dashboard/_id" */))
const _71d66808 = () => interopDefault(import('../src/pages/tools/company/_companyId/security/manage-admins.vue' /* webpackChunkName: "pages/tools/company/_companyId/security/manage-admins" */))
const _67dce83e = () => interopDefault(import('../src/pages/apply/job/_category/_alias/_jobId.vue' /* webpackChunkName: "pages/apply/job/_category/_alias/_jobId" */))
const _784dda38 = () => interopDefault(import('../src/pages/company/_alias.vue' /* webpackChunkName: "pages/company/_alias" */))
const _3a133a37 = () => interopDefault(import('../src/pages/company/_alias/benefits.vue' /* webpackChunkName: "pages/company/_alias/benefits" */))
const _f899ebd8 = () => interopDefault(import('../src/pages/company/_alias/jobs/index.vue' /* webpackChunkName: "pages/company/_alias/jobs/index" */))
const _21d50858 = () => interopDefault(import('../src/pages/company/_alias/office-culture.vue' /* webpackChunkName: "pages/company/_alias/office-culture" */))
const _1e1cde67 = () => interopDefault(import('../src/pages/company/_alias/index.vue' /* webpackChunkName: "pages/company/_alias/index" */))
const _d427a5c2 = () => interopDefault(import('../src/pages/company/_alias/jobs/_category.vue' /* webpackChunkName: "pages/company/_alias/jobs/_category" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth"
  }, {
    path: "/course-directory",
    component: _1b23fe3f,
    pathToRegexpOptions: {"strict":true},
    name: "course-directory___en"
  }, {
    path: "/employer",
    component: _626cd93c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "admins",
      component: _2c62bc32,
      pathToRegexpOptions: {"strict":true},
      name: "employer-admins___en"
    }, {
      path: "analytics",
      component: _6b6148f3,
      pathToRegexpOptions: {"strict":true},
      name: "employer-analytics___en"
    }, {
      path: "candidates",
      component: _0be632b3,
      pathToRegexpOptions: {"strict":true},
      name: "employer-candidates___en"
    }, {
      path: "content-manager",
      component: _166b92f4,
      pathToRegexpOptions: {"strict":true},
      name: "employer-content-manager___en"
    }, {
      path: "content-studio",
      component: _7e76928d,
      pathToRegexpOptions: {"strict":true},
      name: "employer-content-studio___en"
    }, {
      path: "help",
      component: _c64e8b38,
      pathToRegexpOptions: {"strict":true},
      name: "employer-help___en"
    }, {
      path: "jobs",
      component: _6cb334f9,
      pathToRegexpOptions: {"strict":true},
      name: "employer-jobs___en"
    }, {
      path: "profiles",
      component: _583c9d0d,
      pathToRegexpOptions: {"strict":true},
      name: "employer-profiles___en"
    }, {
      path: "/employer",
      component: _f92efa82,
      pathToRegexpOptions: {"strict":true},
      name: "employer___en"
    }]
  }, {
    path: "/es",
    component: _0d49a4be,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/fr",
    component: _0d49a4be,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr"
  }, {
    path: "/homepage-redirect",
    redirect: "/"
  }, {
    path: "/login",
    component: _22eded3b,
    pathToRegexpOptions: {"strict":true},
    name: "login___en"
  }, {
    path: "/logout",
    component: _ae595de4,
    pathToRegexpOptions: {"strict":true},
    name: "logout___en"
  }, {
    path: "/newsletter",
    component: _e7ee9606,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___en"
  }, {
    path: "/onboarding",
    component: _a653d842,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding___en"
  }, {
    path: "/signup",
    component: _7696131c,
    pathToRegexpOptions: {"strict":true},
    name: "signup___en"
  }, {
    path: "/ssr-health",
    component: _50db313b,
    pathToRegexpOptions: {"strict":true},
    name: "ssr-health___en"
  }, {
    path: "/es/course-directory",
    component: _1b23fe3f,
    pathToRegexpOptions: {"strict":true},
    name: "course-directory___es"
  }, {
    path: "/es/employer",
    component: _626cd93c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "admins",
      component: _2c62bc32,
      pathToRegexpOptions: {"strict":true},
      name: "employer-admins___es"
    }, {
      path: "analytics",
      component: _6b6148f3,
      pathToRegexpOptions: {"strict":true},
      name: "employer-analytics___es"
    }, {
      path: "candidates",
      component: _0be632b3,
      pathToRegexpOptions: {"strict":true},
      name: "employer-candidates___es"
    }, {
      path: "content-manager",
      component: _166b92f4,
      pathToRegexpOptions: {"strict":true},
      name: "employer-content-manager___es"
    }, {
      path: "content-studio",
      component: _7e76928d,
      pathToRegexpOptions: {"strict":true},
      name: "employer-content-studio___es"
    }, {
      path: "help",
      component: _c64e8b38,
      pathToRegexpOptions: {"strict":true},
      name: "employer-help___es"
    }, {
      path: "jobs",
      component: _6cb334f9,
      pathToRegexpOptions: {"strict":true},
      name: "employer-jobs___es"
    }, {
      path: "profiles",
      component: _583c9d0d,
      pathToRegexpOptions: {"strict":true},
      name: "employer-profiles___es"
    }, {
      path: "/es/employer",
      component: _f92efa82,
      pathToRegexpOptions: {"strict":true},
      name: "employer___es"
    }]
  }, {
    path: "/es/login",
    component: _22eded3b,
    pathToRegexpOptions: {"strict":true},
    name: "login___es"
  }, {
    path: "/es/logout",
    component: _ae595de4,
    pathToRegexpOptions: {"strict":true},
    name: "logout___es"
  }, {
    path: "/es/newsletter",
    component: _e7ee9606,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___es"
  }, {
    path: "/es/onboarding",
    component: _a653d842,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding___es"
  }, {
    path: "/es/signup",
    component: _7696131c,
    pathToRegexpOptions: {"strict":true},
    name: "signup___es"
  }, {
    path: "/es/ssr-health",
    component: _50db313b,
    pathToRegexpOptions: {"strict":true},
    name: "ssr-health___es"
  }, {
    path: "/fr/course-directory",
    component: _1b23fe3f,
    pathToRegexpOptions: {"strict":true},
    name: "course-directory___fr"
  }, {
    path: "/fr/employer",
    component: _626cd93c,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "admins",
      component: _2c62bc32,
      pathToRegexpOptions: {"strict":true},
      name: "employer-admins___fr"
    }, {
      path: "analytics",
      component: _6b6148f3,
      pathToRegexpOptions: {"strict":true},
      name: "employer-analytics___fr"
    }, {
      path: "candidates",
      component: _0be632b3,
      pathToRegexpOptions: {"strict":true},
      name: "employer-candidates___fr"
    }, {
      path: "content-manager",
      component: _166b92f4,
      pathToRegexpOptions: {"strict":true},
      name: "employer-content-manager___fr"
    }, {
      path: "content-studio",
      component: _7e76928d,
      pathToRegexpOptions: {"strict":true},
      name: "employer-content-studio___fr"
    }, {
      path: "help",
      component: _c64e8b38,
      pathToRegexpOptions: {"strict":true},
      name: "employer-help___fr"
    }, {
      path: "jobs",
      component: _6cb334f9,
      pathToRegexpOptions: {"strict":true},
      name: "employer-jobs___fr"
    }, {
      path: "profiles",
      component: _583c9d0d,
      pathToRegexpOptions: {"strict":true},
      name: "employer-profiles___fr"
    }, {
      path: "/fr/employer",
      component: _f92efa82,
      pathToRegexpOptions: {"strict":true},
      name: "employer___fr"
    }]
  }, {
    path: "/fr/login",
    component: _22eded3b,
    pathToRegexpOptions: {"strict":true},
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _ae595de4,
    pathToRegexpOptions: {"strict":true},
    name: "logout___fr"
  }, {
    path: "/fr/newsletter",
    component: _e7ee9606,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___fr"
  }, {
    path: "/fr/onboarding",
    component: _a653d842,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding___fr"
  }, {
    path: "/fr/signup",
    component: _7696131c,
    pathToRegexpOptions: {"strict":true},
    name: "signup___fr"
  }, {
    path: "/fr/ssr-health",
    component: _50db313b,
    pathToRegexpOptions: {"strict":true},
    name: "ssr-health___fr"
  }, {
    path: "/learn/directory*",
    component: _1b23fe3f,
    name: "learn-directory___en"
  }, {
    path: "/onboarding/welcome",
    component: _836f95a2,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding-welcome___en"
  }, {
    path: "/es/learn/directory*",
    component: _1b23fe3f,
    name: "learn-directory___es"
  }, {
    path: "/es/onboarding/welcome",
    component: _836f95a2,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding-welcome___es"
  }, {
    path: "/fr/learn/directory*",
    component: _1b23fe3f,
    name: "learn-directory___fr"
  }, {
    path: "/fr/onboarding/welcome",
    component: _836f95a2,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding-welcome___fr"
  }, {
    path: "/qa/internal_email/content",
    component: _6ff480b2,
    pathToRegexpOptions: {"strict":true},
    name: "qa-internal_email-content___en"
  }, {
    path: "/tools/admin/token",
    component: _3cf74c58,
    pathToRegexpOptions: {"strict":true},
    name: "tools-admin-token___en"
  }, {
    path: "/es/qa/internal_email/content",
    component: _6ff480b2,
    pathToRegexpOptions: {"strict":true},
    name: "qa-internal_email-content___es"
  }, {
    path: "/es/tools/admin/token",
    component: _3cf74c58,
    pathToRegexpOptions: {"strict":true},
    name: "tools-admin-token___es"
  }, {
    path: "/fr/qa/internal_email/content",
    component: _6ff480b2,
    pathToRegexpOptions: {"strict":true},
    name: "qa-internal_email-content___fr"
  }, {
    path: "/fr/tools/admin/token",
    component: _3cf74c58,
    pathToRegexpOptions: {"strict":true},
    name: "tools-admin-token___fr"
  }, {
    path: "/es/byte/pause/:secret",
    component: _4a0e7bb3,
    pathToRegexpOptions: {"strict":true},
    name: "byte-pause-secret___es"
  }, {
    path: "/es/byte/unsubscribe/:secret",
    component: _bc26e364,
    pathToRegexpOptions: {"strict":true},
    name: "byte-unsubscribe-secret___es"
  }, {
    path: "/es/newsletter/unsubscribe/:secret",
    component: _b4faf902,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-unsubscribe-secret___es"
  }, {
    path: "/es/reports/dashboard/:id?",
    component: _57474bbe,
    pathToRegexpOptions: {"strict":true},
    name: "reports-dashboard-id___es"
  }, {
    path: "/fr/byte/pause/:secret",
    component: _4a0e7bb3,
    pathToRegexpOptions: {"strict":true},
    name: "byte-pause-secret___fr"
  }, {
    path: "/fr/byte/unsubscribe/:secret",
    component: _bc26e364,
    pathToRegexpOptions: {"strict":true},
    name: "byte-unsubscribe-secret___fr"
  }, {
    path: "/fr/newsletter/unsubscribe/:secret",
    component: _b4faf902,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-unsubscribe-secret___fr"
  }, {
    path: "/fr/reports/dashboard/:id?",
    component: _57474bbe,
    pathToRegexpOptions: {"strict":true},
    name: "reports-dashboard-id___fr"
  }, {
    path: "/es/tools/company/:companyId?/security/manage-admins",
    component: _71d66808,
    pathToRegexpOptions: {"strict":true},
    name: "tools-company-companyId-security-manage-admins___es"
  }, {
    path: "/fr/tools/company/:companyId?/security/manage-admins",
    component: _71d66808,
    pathToRegexpOptions: {"strict":true},
    name: "tools-company-companyId-security-manage-admins___fr"
  }, {
    path: "/es/apply/job/:category?/:alias?/:jobId?",
    component: _67dce83e,
    pathToRegexpOptions: {"strict":true},
    name: "apply-job-category-alias-jobId___es"
  }, {
    path: "/fr/apply/job/:category?/:alias?/:jobId?",
    component: _67dce83e,
    pathToRegexpOptions: {"strict":true},
    name: "apply-job-category-alias-jobId___fr"
  }, {
    path: "/byte/pause/:secret",
    component: _4a0e7bb3,
    pathToRegexpOptions: {"strict":true},
    name: "byte-pause-secret___en"
  }, {
    path: "/byte/unsubscribe/:secret",
    component: _bc26e364,
    pathToRegexpOptions: {"strict":true},
    name: "byte-unsubscribe-secret___en"
  }, {
    path: "/es/company/:alias?",
    component: _784dda38,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "benefits",
      component: _3a133a37,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-benefits___es"
    }, {
      path: "jobs",
      component: _f899ebd8,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-jobs___es"
    }, {
      path: "office-culture",
      component: _21d50858,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-office-culture___es"
    }, {
      path: "/es/company/:alias?",
      component: _1e1cde67,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias___es"
    }, {
      path: "jobs/:category",
      component: _d427a5c2,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-jobs-category___es"
    }]
  }, {
    path: "/fr/company/:alias?",
    component: _784dda38,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "benefits",
      component: _3a133a37,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-benefits___fr"
    }, {
      path: "jobs",
      component: _f899ebd8,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-jobs___fr"
    }, {
      path: "office-culture",
      component: _21d50858,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-office-culture___fr"
    }, {
      path: "/fr/company/:alias?",
      component: _1e1cde67,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias___fr"
    }, {
      path: "jobs/:category",
      component: _d427a5c2,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-jobs-category___fr"
    }]
  }, {
    path: "/newsletter/unsubscribe/:secret",
    component: _b4faf902,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-unsubscribe-secret___en"
  }, {
    path: "/reports/dashboard/:id?",
    component: _57474bbe,
    pathToRegexpOptions: {"strict":true},
    name: "reports-dashboard-id___en"
  }, {
    path: "/tools/company/:companyId?/security/manage-admins",
    component: _71d66808,
    pathToRegexpOptions: {"strict":true},
    name: "tools-company-companyId-security-manage-admins___en"
  }, {
    path: "/apply/job/:category?/:alias?/:jobId?",
    component: _67dce83e,
    pathToRegexpOptions: {"strict":true},
    name: "apply-job-category-alias-jobId___en"
  }, {
    path: "/company/:alias?",
    component: _784dda38,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "benefits",
      component: _3a133a37,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-benefits___en"
    }, {
      path: "jobs",
      component: _f899ebd8,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-jobs___en"
    }, {
      path: "office-culture",
      component: _21d50858,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-office-culture___en"
    }, {
      path: "/company/:alias?",
      component: _1e1cde67,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias___en"
    }, {
      path: "jobs/:category",
      component: _d427a5c2,
      pathToRegexpOptions: {"strict":true},
      name: "company-alias-jobs-category___en"
    }]
  }, {
    path: "/company/:alias?",
    children: [{
      path: "office-culture",
      component: _21d50858,
      alias: ["remote-culture","product-tech","diversity-inclusion","offices"]
    }]
  }, {
    path: "/",
    component: _0d49a4be,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
