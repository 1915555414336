

















import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState, mapMutations } from 'vuex';

@Component({
  props: {
    dropdownClass: {
      type: String,
    },
  },
  computed: mapState('navigation', ['display']),
  methods: {
    ...mapMutations('navigation', ['setDisplay']),
  },
})
export default class Dropdown extends Vue {
  display!: boolean;
  setDisplay!: Function;

  get displayClasses() {
    return {
      active: this.display,
      inactive: !this.display,
    };
  }

  get links() {
    return this.$props.content;
  }

  openDropdown() {
    if (!this.display) {
      this.setDisplay(true);
    }
  }

  closeDropdown() {
    this.setDisplay(false);
  }
}
