
















































import { defineComponent, useMeta } from '@nuxtjs/composition-api';

import constants from '@/enums/constants';

export default defineComponent({
  name: 'ErrorPage',
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head: () => ({}),
  setup(props) {
    const { title, meta } = useMeta();
    title.value = props.error.statusCode === 404 ? 'Page Not Found' : 'An Error Occurred';
    meta.value = [
      {
        hid: 'robots',
        name: 'robots',
        content: props.error.statusCode === 500 ? '' : 'noindex',
      },
    ];

    return {
      constants,
    };
  },
});
