












/* eslint-disable @typescript-eslint/no-use-before-define */

import { defineComponent } from '@nuxtjs/composition-api';
import { useNamespacedActions, useNamespacedGetters } from 'vuex-composition-helpers';

import { UserActions, UserGetters, UserNamespace } from '@/store/modules/user';
import { useTimestampFormat } from '@/hooks/useTimestampFormat';

export default defineComponent({
  setup: () => {
    const { isImpersonating, expiresAt } = useNamespacedGetters<UserGetters>(UserNamespace, [
      'isImpersonating',
      'expiresAt',
    ]);

    const { stopImpersonating } = useNamespacedActions<UserActions>(UserNamespace, [
      'stopImpersonating',
    ]);
    const timeLeft = useTimestampFormat(expiresAt);

    return {
      timeLeft,
      isImpersonating,
      stopImpersonating,
    };
  },
});
