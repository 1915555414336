










import onboardingRedirect from '@/middleware/onboardingRedirect';
import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api';
import { useVuetify } from '@/hooks/useVuetify';
import employerRedirect from '@/middleware/employerRedirect';
import cassiniRedirects from '@/middleware/cassiniRedirects';

export default defineComponent({
  name: 'App',
  middleware: [onboardingRedirect, employerRedirect, cassiniRedirects],
  setup() {
    const route = useRoute();
    const routeName = computed(() => route.value.name);

    const { breakpoints } = useVuetify();

    const isMobile = computed(() => breakpoints.value.mdAndDown);

    return { isMobile, routeName };
  },
});
