import { render, staticRenderFns } from "./IndustryDropdown.vue?vue&type=template&id=0c27c651&scoped=true&"
import script from "./IndustryDropdown.vue?vue&type=script&lang=ts&"
export * from "./IndustryDropdown.vue?vue&type=script&lang=ts&"
import style0 from "./IndustryDropdown.vue?vue&type=style&index=0&id=0c27c651&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c27c651",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BixLink: require('/root/project/frontend/src/components/BixLink.vue').default})
