import { Context } from '@nuxt/types';
import { isNational } from '@/api/regions';
import { isCompanyAdmin } from '@/utils/user-permissions';
import { userModule } from '@/store';

export default async ({ redirect, route }: Context) => {
  const hasCompanyPermissions = isCompanyAdmin(userModule.profile?.permissions || []);

  if (
    userModule.isAuthenticated &&
    hasCompanyPermissions &&
    (!route.path || route.path === '/') &&
    !userModule.hasBeenRedirectedGetter &&
    !document.referrer.includes('/employer')
  ) {
    await userModule.hasBeenRedirectEmployer();
    return redirect('/employer');
  }

  if (
    userModule.isAuthenticated &&
    !hasCompanyPermissions &&
    isNational() &&
    (!route.path || route.path === '/') &&
    !document.referrer.includes('/employer')
  ) {
    window.location.href = '/';
    return;
  }
  if (
    userModule.isAuthenticated &&
    isNational() &&
    !hasCompanyPermissions &&
    (!route.path || route.path === '/')
  ) {
    window.location.href = '/';
    return;
  }
};
