import { ActionTree, ActionContext } from 'vuex/types/index';
import { Dayjs } from 'dayjs';
import { RootState } from '../types';
import { CompanyOverview as CompanyOverviewType } from '@/store/companies/types';
import { SearchCompany } from '@/store/search/types';

export interface State {
  error: string;
  company: Company;
  quicksightDashboardData: QuicksightDashboard;
}

export interface DashboardActions extends ActionTree<State, RootState> {
  fetchUniqueCompanies: (
    ctx: ActionContext<State, RootState>,
    updateParams: CompanySearchUpdateParams
  ) => Promise<void>;
  fetchCompanyOverview: (
    ctx: ActionContext<State, RootState>,
    id: number
  ) => Promise<CompanyOverview | Error>;
  fetchQuicksightDashboardURL: (
    ctx: ActionContext<State, RootState>,
    param: DashboardUpdateParams
  ) => Promise<QuicksightDashboard | Error>;
}

export interface Company {
  companies: Array<SearchCompany>;
  overview: CompanyOverview;
  titleToId: { [key: string]: number };
  loadingOverview: boolean;
  loadingSearch: boolean;
}

export type CompanyOverview = CompanyOverviewType;

export interface QuicksightDashboard {
  loading: boolean;
  companyId: number;
  url: string;
  salesforceID: number;
}

export interface APIError {
  message: string;
}

export interface DateRange {
  startDate: Dayjs;
  endDate: Dayjs;
}

// Possible aggregations for the engagement metrics line chart
export type DateAggregation = 'daily' | 'weekly' | 'monthly';

export interface DashboardUpdateParams {
  companyId: number;
  startDate: Dayjs;
  endDate: Dayjs;
  dateAggregation?: DateAggregation;
}

export interface CompanySearchUpdateParams {
  companyNamePart: string;
  maxResults: number;
}

export enum ActionNames {
  fetchUniqueCompanies = 'fetchUniqueCompanies',
  loadingCompanies = 'loadingCompanies',
  receiveCompaniesSuccess = 'receiveCompaniesSuccess',
  receiveCompaniesFailure = 'receiveCompaniesFailure',

  fetchCompanyOverview = 'fetchCompanyOverview',
  loadingCompanyOverview = 'loadingCompanyOverview',
  receiveCompanyOverviewSuccess = 'receiveCompanyOverviewSuccess',
  receiveCompanyOverviewFailure = 'receiveCompanyOverviewFailure',

  fetchQuicksightDashboardURL = 'fetchQuicksightDashboardURL',
  loadingQuicksightDashboardURL = 'loadingQuicksightDashboardURL',
  receiveQuicksightDashboardURLSuccess = 'receiveQuicksightDashboardURLSuccess',
  receiveQuicksightDashboardURLFailure = 'receiveQuicksightDashboardURLFailure',
}
