import { NavigationState, NavigationMutations, NavigationActions } from '@/store/navigation/types';

const state = (): NavigationState => ({
  display: false,
  displaySearchMenu: false,
  dropdownContent: '',
  displayMarketDropdown: false,
  navElementOffset: 0,
});

const mutations: NavigationMutations = {
  setDisplay(state, status) {
    state.display = status;
    state.displaySearchMenu = false;
    state.displayMarketDropdown = false;
  },
  toggleDisplaySearchMenu(state) {
    state.displaySearchMenu = !state.displaySearchMenu;
  },
  hideDisplaySearchMenu(state) {
    state.displaySearchMenu = false;
  },
  setDropdownContent(state, content: string) {
    state.dropdownContent = content;
  },
  toggleMarketDropdown(state) {
    state.displayMarketDropdown = !state.displayMarketDropdown;
  },
  hideDropDown(state) {
    state.display = false;
  },
  setNavElementOffset(state, newOffset: number) {
    state.navElementOffset = newOffset;
  },
};

const actions: NavigationActions = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
