import VuexPersistence from 'vuex-persist';
import { Context } from '@nuxt/types/app';
import { RootActionNames, RootState } from '@/store/types';
import { JobMutationAlias, namespace as JobNamespace, State as JobState } from '@/store/jobs/types';
import {
  namespace as OnboardingNamespace,
  OnboardingMutationsEnum,
  State as OnboardingState,
} from '@/store/onboarding/types';
import Cookies from 'js-cookie';
type rootStatePartials = Pick<RootState, 'regions' | 'nationalRegions'>;

type MainState = rootStatePartials & {
  jobs: Partial<JobState>;
} & { onboarding: Partial<OnboardingState> };

export const storePersistKey = 'bix-state-cache';

const setStoreExpireTime = (minutes = 60) => {
  // Set the store expiration time to half an hour
  if (!Cookies.get(storePersistKey)) {
    const expireTime = new Date();
    expireTime.setTime(expireTime.getTime() + minutes * 60 * 1000);
    Cookies.set(storePersistKey, '1', { expires: expireTime });
  }
};

const isStoreExpire = () => {
  return !Cookies.get(storePersistKey);
};

const emptyState = (): MainState => ({
  regions: [],
  nationalRegions: [],
  jobs: {
    categories: [],
    industries: [],
    subcategories: [],
  },
  onboarding: {
    cities: [],
    nationalCities: [],
    expertise_list: [],
  },
});

/**
 *  vuex-persist documentation
 *  @see https://github.com/championswimmer/vuex-persist#readme
 * @param ctx - nuxt app context
 */

export default (ctx: Context) => {
  /**
   * Attention!
   * Data isn't cleared after logging out, please do not put sensitive information or user session related data here.
   * if you want to store sensitive data, create another instance of VuexPersistence and store the data there.
   * Then you can add data clearing code to the _clearAuth mutation.
   */

  const persistStore = new VuexPersistence({
    key: storePersistKey, // The key to store the state in the storage.
    storage: window.localStorage, // or window.sessionStorage or localForage
    /**
     * Restore state before app loading
     * @param key - store key
     * @param storage - window.localStorage or window.sessionStorage or localForage etc.
     */
    restoreState: (key, storage: Storage | undefined) => {
      const serializedData = storage?.getItem(key);

      if (isStoreExpire() || !serializedData) {
        return emptyState();
      }

      try {
        return JSON.parse(serializedData);
      } catch (e) {
        console.error(e);
        return emptyState();
      }
    },
    /**
     * Saving data when a mutation from filter method is triggered.
     * @param key - store key
     * @param state - state from reducer
     * @param storage - window.localStorage or window.sessionStorage or localForage etc.
     */
    saveState: (key, state, storage) => {
      setStoreExpireTime();
      storage?.setItem(key, JSON.stringify(state));
    },
    /**
     * Reduces state to only those values you want to save.
     * @param state
     *
     */
    reducer: (state: MainState): MainState => ({
      regions: state.regions,
      nationalRegions: state.nationalRegions,
      jobs: {
        categories: state.jobs.categories,
        industries: state.jobs.industries,
        subcategories: state.jobs.subcategories,
      },
      onboarding: {
        cities: state.onboarding.cities,
        nationalCities: state.onboarding.nationalCities,
        expertise_list: state.onboarding.expertise_list,
      },
    }),
    /**
     *
     * Activate data saving for mutations
     *
     * @param mutation - current vuex mutation
     *
     * @return boolean
     */
    filter: (mutation) => {
      return (
        mutation.type === RootActionNames.setRegions ||
        mutation.type === `${OnboardingNamespace}/${OnboardingMutationsEnum.cities}` ||
        mutation.type === `${JobNamespace}/${JobMutationAlias.setCategories}` ||
        mutation.type === `${JobNamespace}/${JobMutationAlias.setIndustries}` ||
        mutation.type === `${JobNamespace}/${JobMutationAlias.setSubcategories}` ||
        mutation.type === `${OnboardingNamespace}/${OnboardingMutationsEnum.nationalCities}` ||
        mutation.type === RootActionNames.setNationalRegions ||
        mutation.type === `${OnboardingNamespace}/${OnboardingMutationsEnum.expertises}`
      );
    },
  });
  persistStore.plugin(ctx.store);
};
