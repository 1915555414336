/** Commonly used builtin colors */
export const theme = {
  mint: '#4FE2C1',
  midnightBlue: '#04003F',
  lightSlateBlue: '#374886',
  darkSlateBlue: '#28386F',
  brightSkyBlue: '#00B9FE',
  charcoalGrey: '#3A3B41',
  tomatoRed: '#E72D00',
  midGreen: '#42A44D',
  gradientPink: '#eb38a6',
  gradientOrange: '#e27300',
  gradientYellow: '#ffed00',
  gradientGreen: '#8ae680',

  // These are used for job categories
  contentYellow: '#FFED00',
  dataBlue: '#008697',
  designPink: '#D800E3',
  devRed: '#E2001A',
  financeGreen: '#009036',
  hrGreen: '#97BE0D',
  internshipOrange: '#F2AA00',
  legalOrange: '#F26C00',
  marketingPurple: '#8700E2',
  operationsPurple: '#4D1096',
  productBlue: '#173183',
  projectMgmtBlue: '#006AB2',
  salesBlue: '#009EE0',
  skyBlue: '#ebfaff',
};
