import AppVars from '@/enums/appVars';

export interface UserMetadata {
  id?: number;
  drupal_user_id?: number | null;
  subject_id?: string;
}

export interface UserMetadataService {
  getItem(): UserMetadata;
  setItem(item: UserMetadata): void;
  clearItem(): void;
}

export const userMetadataService = {
  getItem: (): UserMetadata => {
    const raw = window.localStorage.getItem(AppVars.UserMetadataKey);
    if (!raw) {
      throw new Error('no user metadata found in local storage');
    }
    return JSON.parse(raw);
  },
  setItem: (item: UserMetadata) => {
    let metadata: UserMetadata = {};
    try {
      metadata = userMetadataService.getItem();
    } catch (e) {
      // do nothing
    }
    if (item.id) {
      metadata.id = item.id;
    }
    if (item.drupal_user_id) {
      metadata.drupal_user_id = item.drupal_user_id;
    }
    if (item.subject_id) {
      metadata.subject_id = item.subject_id;
    }
    window.localStorage.setItem(AppVars.UserMetadataKey, JSON.stringify(metadata));
  },
  clearItem: () => {
    window.localStorage.removeItem(AppVars.UserMetadataKey);
  },
};
