
























import { stringToAnchorText } from '@/utils/formatting';
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { isNational } from '@/api/regions';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { chunk } from 'lodash-es';

interface Link {
  title: string;
  url: string;
}

export default defineComponent({
  props: {
    links: {
      type: Array as PropType<Link[]>,
      required: true,
    },
    dropDownTitle: {
      type: String,
      default: '',
    },
    singleLink: {
      type: Object as PropType<Link>,
      default: () => ({}),
    },
    maxColumnItems: {
      type: Number,
      default: 5,
    },
  },
  setup(props, ctx) {
    const icon = faArrowRight;

    const groupedLinks = computed(() => {
      if (ctx.root.$vuetify.breakpoint.lg) {
        /**
         * if large 2 columns
         */
        return chunk(props.links, props.links.length / 2);
      }
      /**
       * split array by 5 items in item
       */
      return chunk(props.links, props.maxColumnItems);
    });
    return {
      groupedLinks,
      stringToAnchorText,
      isNational,
      icon,
    };
  },
});
