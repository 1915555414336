import { getCurrentInstance, ref } from '@nuxtjs/composition-api';

export const useVuetify = () => {
  const instance = getCurrentInstance()?.proxy;
  if (instance == null) {
    throw new Error('useVuetify must be called from within setup');
  }
  const vuetify = ref(instance.$vuetify);
  const breakpoints = ref(instance.$vuetify.breakpoint);

  return {
    vuetify,
    breakpoints,
  };
};
