import { render, staticRenderFns } from "./JobPostAuthButton.vue?vue&type=template&id=07caf33e&scoped=true&"
import script from "./JobPostAuthButton.vue?vue&type=script&lang=ts&"
export * from "./JobPostAuthButton.vue?vue&type=script&lang=ts&"
import style0 from "./JobPostAuthButton.vue?vue&type=style&index=0&id=07caf33e&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07caf33e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonPostJob: require('/root/project/frontend/src/components/navigation/market/ButtonPostJob.vue').default,BixLink: require('/root/project/frontend/src/components/BixLink.vue').default})
