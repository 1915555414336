import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=22505dd2&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=22505dd2&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22505dd2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BixImg: require('/root/project/frontend/src/components/images/BixImg.vue').default,BixLink: require('/root/project/frontend/src/components/BixLink.vue').default,BixLink: require('/root/project/frontend/src/components/BixLink.vue').default})
