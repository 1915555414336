import { getCurrentInstance, ref } from '@nuxtjs/composition-api';

export const useVueSiteDetect = () => {
  const instance = getCurrentInstance()?.proxy;
  if (instance == null) {
    throw new Error('useVueSiteDetect must be called from within setup');
  }
  const isVue = ref(!!instance.$isVue);

  return {
    isVue,
  };
};
