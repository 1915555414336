import Vue from 'vue';
import { Store } from 'vuex';
import root from '@/api/root';
import {
  RootActionNames,
  Region,
  RootState,
  History,
  Country,
  RootMutations,
  RootActions,
} from '@/store/types';

import { ActionNames as OnboardingActionsNames } from '@/store/onboarding/types';
import history from '@/utils/history';
import { experienceUpdate, clearOnboardingOnSignOut } from '@/store/plugins';

import { initializeStores } from '@/utils/store-accessor';
import appVars from '@/enums/appVars';
import modules from './modules';
/** The store plugin to initialize the fully typed module accessors */
export const typedStorePlugin = [(store: Store<any>) => initializeStores(store)];
export * from '@/utils/store-accessor';

const state = (): RootState =>
  ({
    currentSite: {
      host: process.server ? appVars.CurrentSiteHostFallback : window?.location?.host,
      initialPath: process.server ? '/' : window?.location?.pathname,
    },
    regions: [],
    nationalRegions: [],
    currentRegion: {
      id: 0,
      name: '',
      state: '',
      code: '',
      url: '',
      country: '',
      twitter: '',
      linked_in: '',
      facebook: '',
      created_at: '',
      updated_at: '',
      locations: [],
      timezone_name: '',
      site_name: '',
      alias: '',
    },
    history: [],
    loading: [],
    loadingOverlay: [],
    countries: [],
    nationalNavbarCollapsed: false,
  } as RootState);

const mutations: RootMutations = {
  /**
   * @internal do not dispatch manually
   *
   * Mutation called by https://nuxtjs.org/docs/2.x/directory-structure/store#the-nuxtserverinit-action
   * which will set the `currentSite.host` if and only if it is (a) not set yet, or (b) set, but to the
   * environment variable based fallback.
   */
  [RootActionNames.setCurrentSiteHost]: (state, siteHost = '') => {
    if (
      !state.currentSite.host ||
      (state.currentSite.host === appVars.CurrentSiteHostFallback && siteHost.length)
    ) {
      state.currentSite.host = siteHost;
    }
  },
  /**
   * @internal do not dispatch manually
   *
   * Mutation called by https://nuxtjs.org/docs/2.x/directory-structure/store#the-nuxtserverinit-action
   * for first page load only in an SSR context. After that, the $router should be used to determine the
   * current path.
   */
  [RootActionNames.setCurrentSiteInitialPath]: (state, sitePath: string = '/') => {
    state.currentSite.initialPath = sitePath;
  },
  [RootActionNames.setRegions]: (state, regions) => {
    state.regions = regions;
  },
  [RootActionNames.setNationalRegions]: (state, nationalRegions) => {
    state.nationalRegions = nationalRegions;
  },
  setCurrentRegion: (state, id) => {
    const currentRegion = state.regions.find((region) => {
      return region.id === id;
    });
    if (currentRegion) {
      state.currentRegion = currentRegion;
    }
  },
  [RootActionNames.setHistory]: (state, item: History) => {
    state.history = history.add(state.history, item);
  },
  [RootActionNames.removeHistory]: (state, item: History) => {
    state.history = history.remove(state.history, item);
  },
  [RootActionNames.setAutocompleteCountries]: (state, countries: Country[]) => {
    state.countries = countries;
  },
  [RootActionNames.loadingAdd]: (state, value) => {
    const loading = [...state.loading];
    const index = loading.findIndex((item) => item === value);
    if (index === -1) {
      loading.push(value);
      Vue.set(state, 'loading', loading);
    }
  },
  [RootActionNames.loadingOverlayAdd]: (state, value) => {
    const loadingOverlay = [...state.loadingOverlay];
    const index = loadingOverlay.findIndex((item) => item === value);
    if (index === -1) {
      loadingOverlay.push(value);
      Vue.set(state, 'loadingOverlay', loadingOverlay);
    }
  },
  [RootActionNames.loadingRemove]: (state, value) => {
    const loading = [...state.loading];
    const index = loading.findIndex((item) => item === value);
    if (index !== -1) {
      loading.splice(index, 1);
      Vue.set(state, 'loading', loading);
    }
  },
  [RootActionNames.loadingOverlayRemove]: (state, value) => {
    const loadingOverlay = [...state.loadingOverlay];
    const index = loadingOverlay.findIndex((item) => item === value);
    if (index !== -1) {
      loadingOverlay.splice(index, 1);
      Vue.set(state, 'loadingOverlay', loadingOverlay);
    }
  },
  [RootActionNames.nationalNavbarCollapse]: (state, status) => {
    Vue.set(state, 'nationalNavbarCollapsed', !!status);
  },
};

const actions: RootActions = {
  /**
   * Specially named root store action called automatically by Nuxt within an SSR context.
   * Any logic that pre-seeds the store server side can take place inside this action.
   *
   * @see https://nuxtjs.org/docs/2.x/directory-structure/store#the-nuxtserverinit-action
   */
  nuxtServerInit({ commit }, { req }: any) {
    if (process?.server) {
      // Get the request host from the headers, per the docs https://nodejs.org/api/http.html#http_message_url
      if (req?.headers?.host) {
        commit(RootActionNames.setCurrentSiteHost, req.headers.host);
      }
      if (req?.url) {
        commit(RootActionNames.setCurrentSiteInitialPath, req.url);
      }
    } else {
      if (window?.location?.host) {
        commit(RootActionNames.setCurrentSiteHost, window.location.host);
      }
      if (window?.location?.pathname) {
        commit(RootActionNames.setCurrentSiteInitialPath, window.location.pathname);
      }
    }
  },
  getRegions: async ({ commit, state }) => {
    if (!state.regions?.length && !history.exist(state.history, RootActionNames.getRegions)) {
      commit(RootActionNames.setHistory, { name: RootActionNames.getRegions }, { root: true });
      commit(RootActionNames.loadingAdd, RootActionNames.getRegions, {
        root: true,
      });
      return root
        .getRegions()
        .then((regions: Region[]) => {
          commit(RootActionNames.loadingRemove, RootActionNames.getRegions, {
            root: true,
          });
          commit('setRegions', regions);
          commit(OnboardingActionsNames.cities.mutation(), regions);
        })
        .catch(() => {
          commit('setRegions', []);
          commit(
            RootActionNames.removeHistory,
            { name: RootActionNames.getRegions },
            { root: true }
          );
        });
    }
    return Promise.resolve();
  },
  getNationalRegions: async ({ commit, state }) => {
    if (!!state.nationalRegions?.length) {
      return Promise.resolve();
    }

    return root.getRegions({ national: true }).then((regions: Region[]) => {
      commit(OnboardingActionsNames.nationalCities.mutation(), regions);
      commit('setNationalRegions', regions);
    });
  },
  getAutocompleteCountries: async ({ commit }) => {
    try {
      const countries = await root.getAutocompleteCountries();
      commit('setAutocompleteCountries', countries);
    } catch {
      commit('setAutocompleteCountries', []);
    }
  },
  setNavigationCollapseStatus: ({ commit }, status: boolean) => {
    commit(RootActionNames.nationalNavbarCollapse, status);
  },
};

const additionalPlugins = [experienceUpdate, clearOnboardingOnSignOut].map(
  (handler: Function) => (store: any) =>
    store.subscribe((mutation: any, state: any) => {
      handler.call(null, mutation, state, store);
    })
);

export default {
  state,
  actions,
  mutations,
  modules,
  plugins: [...typedStorePlugin, ...additionalPlugins],
};
