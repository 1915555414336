














import Vue from 'vue';
import Component from 'vue-class-component';
import regions from '@/api/regions';
import { mapActions, mapState } from 'vuex';
import { RootActionNames as Root, Region } from '@/store/types';

import { salaries } from '@/utils/navigation';

@Component({
  computed: {
    ...mapState(['regions']),
  },
  methods: {
    ...mapActions({
      loadCities: Root.getRegions,
    }),
  },
})
export default class SalariesDropdown extends Vue {
  loadCities!: Function;
  regions!: Region[];
  isNational = regions.isNational();

  created() {
    this.loadCities();
  }

  get regionID() {
    return regions.getCurrentRegionID();
  }

  get regionCode() {
    let code = '';
    const region = this.regions.find((item) => item.id == this.regionID);
    if (region != undefined) {
      code = region.code;
    }
    return code;
  }

  get pathSuffix() {
    let code = this.regionCode;
    if (code == 'la') {
      code = 'los-angeles';
    }
    if (code == 'nyc') {
      code = 'new-york';
    }
    if (code == 'san_francisco') {
      code = 'san-francisco';
    }
    return '/' + code;
  }

  get pathPrefix() {
    return '/salaries';
  }

  get links() {
    const { salaryLinks } = salaries();
    return salaryLinks;
  }
  get transformedLinks() {
    return this.links.map((link) => {
      const str = this.pathPrefix + '/' + link.url + this.pathSuffix;
      const url = str.endsWith('/') ? str.slice(0, -1) : str;
      return { ...link, url };
    });
  }
}
