






















































































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import { getFeatureFlags } from '@/plugins/featureFlags';
import {
  State as OnboardingState,
  OnboardingActions,
  OnboardingGetters,
} from '@/store/onboarding/types';
import regions from '@/api/regions';
import constants from '@/enums/constants';
import { appVars } from '@/enums/appVars';
import { useAuthentication } from '@/hooks/useAuthentication';
import { State as UserState, UserActions, UserGetters, UserNamespace } from '@/store/modules/user';
import { UserRoles } from '@/enums/userRoles';
import { marketSites } from '@/enums/appVars';

const onboarding = createNamespacedHelpers<
  OnboardingState,
  OnboardingGetters,
  OnboardingActions,
  unknown
>('onboarding');
const userModule = createNamespacedHelpers<UserState, UserGetters, UserActions, unknown>(
  UserNamespace
);

export default defineComponent({
  props: {
    national: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {
    const featureFlags = getFeatureFlags();
    const { signout } = useAuthentication();
    const { profile } = userModule.useState(['profile']);
    const { fullName: newFullName, hasPermission } = userModule.useGetters([
      'fullName',
      'hasPermission',
    ]);

    const { profile: onboardingProfile } = onboarding.useState(['profile']);
    const { userPhoto: oldAvatar, userFullName: oldFullName } = onboarding.useGetters([
      'userPhoto',
      'userFullName',
    ]);

    const avatar = computed(() => profile.value?.picture || oldAvatar.value);
    const fullName = computed(() => newFullName.value || oldFullName.value);
    const drupalId = computed(() => profile.value?.drupalId);
    const hasOrders = computed(() => onboardingProfile.value?.has_orders);
    const isDrupalIdValid = computed(() => !!drupalId.value);

    const mgmtPermission = [UserRoles.GlobalAdmin, UserRoles.AccountManager, UserRoles.SalesRep];
    const accountAdmins = [UserRoles.GlobalAdmin, UserRoles.AccountManager];
    const adminPermission = UserRoles.GlobalAdmin;
    const checkPermissions = (...roles: string[]) => hasPermission.value(null, ...roles);
    const regionId = regions.getCurrentRegionID();
    const isClosedMenu = ref(false);

    const dashboardURL = `${marketSites[9]}/reports/dashboard`;

    const isFeatureJobsNewsletterEnabled = ref(false);

    const closeMenu = () => {
      isClosedMenu.value = true;

      setTimeout(() => {
        isClosedMenu.value = false;
      }, 500);
    };

    const isNewJobsNewsletterEnabled = computed(() => {
      return isFeatureJobsNewsletterEnabled.value;
    });

    const myItemsURL = ref('/saved-jobs');
    const myItemsLabel = ref('My Saved Jobs');

    return {
      appVars,
      userProfileLink: constants.USER_EDIT_PROFILE_LINK,
      avatar,
      fullName,
      drupalId,
      isDrupalIdValid,
      hasOrders,
      mgmtPermission,
      accountAdmins,
      adminPermission,
      dashboardURL,
      checkPermissions,
      logoutUser: signout,
      featureFlags,
      regionId,
      isClosedMenu,
      closeMenu,
      isNewJobsNewsletterEnabled,
      myItemsURL,
      myItemsLabel,
    };
  },
});
