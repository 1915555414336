import { Server } from 'miragejs';
import appVars from '@/enums/appVars';
import ExternalApis from '@/enums/externalApiRoutesEnum';
import { realtimeFeatureFlags } from '@/mirage/handlers/realtime-feature-flags';

export interface ApiMockServerConfig {
  environment: string;
  urlPrefix: string;
}

/**
 * Helper to obtain the fully qualified domain origin for a supplied url.
 * If no origin can be obtained, an error will be logged.
 *
 * @param url the url from which the origin should be calculated
 */
function origin(url?: string) {
  try {
    if (url) {
      return new URL(url).origin;
    }
  } catch {}

  console.error(
    `[MirageJS] Illegal URL encountered for [${url}]. Mirage server passthrough might not work as expected!`
  );
}

export function createMockServer(
  config: ApiMockServerConfig = {
    environment: 'development',
    urlPrefix: `${appVars.ExternalApi}`,
  }
): Server {
  const server = new Server({
    environment: config.environment,
    urlPrefix: config.urlPrefix,

    routes() {
      this.get(`${ExternalApis.RealtimeFeatureFlags}`, realtimeFeatureFlags);

      /**
       * Any route not handled above this comment block will "pass through" mirage and reach out
       * to the external network https://miragejs.com/docs/getting-started/overview/#passthrough
       */
      this.passthrough();
      this.passthrough(`${origin(appVars.IdPAuthority)}/**`);
      this.passthrough(`${origin(appVars.JobsApi)}/**`);
      this.passthrough(`${origin(appVars.TrackApi)}/**`);
      this.passthrough(`${window.location.origin}/drupal-api/**`);
    },
  });

  return server;
}
