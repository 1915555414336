import Vue from 'vue';
import Vuex, { GetterTree, MutationTree } from 'vuex';
import history from '@/utils/history';

import homepage from '@/api/homepage';
import {
  ActionNames,
  State,
  Topic,
  Tech,
  SliderTypeNews,
  SliderTypeJobs,
  SliderTypeCompanies,
  PopupStepsFlow,
  PopupEmptyStateStepsFlow,
  PopupTypeUpdate,
  PopupTypeEmptyState,
  HomepageActions,
  Job,
  HomepageActionsEnum,
} from '@/store/homepage/types';
import { RootActionNames } from '@/store/types';
import regions from '@/api/regions';
import { JobsWithCriterias } from '@/api/types';
import jobs from '@/api/jobs';
import { JobsCompaniesData } from '@/store/jobs/types';

Vue.use(Vuex);

export const requestJobsService = (jobParams: JobsWithCriterias) => {
  return jobs.getJobsWithCompanies(jobParams);
};

export const mapDataSliderJob = (data: JobsCompaniesData) => {
  const { jobs, companies } = data;
  return jobs?.map((jobItem): Job => {
    const company = companies.find((companyItem) => companyItem.id === jobItem.company_id);
    return {
      ...jobItem,
      location: jobItem.location,
      location_name: jobItem.location,
      company_title: company?.title,
      company_logo: company?.logo ? `public://${company.logo}` : null,
      company_region: company?.region_id ?? regions.getCurrentRegionID(),
      limited_listing: company?.limited_listing,
    };
  });
};

const sortJobs = (data: Job[]) => {
  return data.sort((job1, job2) => {
    const limitedListing1 = Number(!!job1.limited_listing); // if true should be at the end
    const limitedListing2 = Number(!!job2.limited_listing); // if true should be at the end

    if (limitedListing1 > limitedListing2) {
      return 1;
    }
    if (limitedListing1 < limitedListing2) {
      return -1;
    }

    return 0;
  });
};

const state = (): State => ({
  step: '',
  topics: [],
  jobs: {},
  companies_per_values: [],
  companies_per_values_empty_results: false,
  tech_news: [],
  tech_news_empty_results: false,
  slider_type_news: SliderTypeNews,
  slider_type_jobs: SliderTypeJobs,
  slider_type_companies: SliderTypeCompanies,
  popup_steps: PopupStepsFlow,
  popup_empty_state_steps: PopupEmptyStateStepsFlow,
  popup_type: false,
  popup_flow_type: false,
  popup_step: false,
  empty_state: false,
});

const getters: GetterTree<State, any> = {
  [ActionNames.emptyState.name]: (state) => {
    return state.empty_state;
  },
  [ActionNames.currentFlow.name]: (state) => {
    let item: any = {};

    if (state.popup_type === PopupTypeUpdate) {
      item = state.popup_steps.find((item) => item.type === state.popup_flow_type);
    }
    if (state.popup_type === PopupTypeEmptyState) {
      item = state.popup_empty_state_steps.find((item) => item.type === state.popup_flow_type);
    }

    return item && item.flow !== undefined ? item.flow : [];
  },
  [ActionNames.nextStep.name]: (state, getters) => {
    const flow: number[] = getters[ActionNames.currentFlow.name];
    if (flow) {
      // Start popup
      if (!state.popup_step) {
        return flow[0];
      }
      // During popup
      else {
        const index = flow.findIndex((item) => item === state.popup_step);
        return index !== undefined && flow[index + 1] ? flow[index + 1] : false;
      }
    }
    return false;
  },
  [ActionNames.prevStep.name]: (state, getters) => {
    const flow: number[] = getters[ActionNames.currentFlow.name];
    if (flow && state.popup_step) {
      const index = flow.findIndex((item) => item === state.popup_step);
      if (index === undefined || index === 0) {
        return false;
      }
      return flow[index - 1];
    }
    return false;
  },
  [ActionNames.whyCompanies.name]: (state, getters, rootState) => {
    const why = rootState.onboarding.profile.why;
    return (
      why === 1 ||
      why === 14 ||
      why === 15 ||
      why === 8 ||
      why === 9 ||
      why === 12 ||
      why === 13 ||
      why === 10 ||
      why === 11
    );
  },
  [ActionNames.whyJobs.name]: (state, getters, rootState) => {
    const why = rootState.onboarding.profile.why;
    return (
      why === 1 ||
      why === 14 ||
      why === 15 ||
      why === 4 ||
      why === 5 ||
      why === 12 ||
      why === 13 ||
      why === 6 ||
      why === 7
    );
  },
  [ActionNames.whyTopics.name]: (state, getters, rootState) => {
    const why = rootState.onboarding.profile.why;
    return (
      why === 1 ||
      why === 14 ||
      why === 15 ||
      why === 2 ||
      why === 3 ||
      why === 10 ||
      why === 11 ||
      why === 6 ||
      why === 7
    );
  },
};

const actions: HomepageActions = {
  [ActionNames.topics.name]: ({ commit, rootState }) => {
    if (!history.exist(rootState.history, ActionNames.topics.action())) {
      commit(RootActionNames.setHistory, { name: ActionNames.topics.action() }, { root: true });
      commit(RootActionNames.loadingAdd, ActionNames.topics.name, {
        root: true,
      });
      return homepage
        .getTopics((data: any) => {
          if (data) {
            let topics: Topic[] = [];
            topics = data.map((item: any) => {
              return {
                id: item.id,
                name: item.name,
              };
            });
            commit(ActionNames.topics.name, topics);
          }
        })
        .finally(() => {
          commit(RootActionNames.loadingRemove, ActionNames.topics.name, {
            root: true,
          });
        });
    }
  },
  [ActionNames.userTechNews.name]: ({ commit }) => {
    commit(RootActionNames.loadingAdd, ActionNames.userTechNews.name, {
      root: true,
    });
    commit(ActionNames.userTechNewsEmpty.name, false);
    return homepage
      .getUserTechNews((data: any) => {
        if (data) {
          const tech_news: Tech[] = data.map((item: any) => {
            return {
              id: item.id,
              title: item.title,
              image_url: item.thumbnail,
              alias: item.alias,
              city: item.region_id,
              created_at: item.created_at,
            };
          });
          commit(ActionNames.userTechNews.name, tech_news);
        } else {
          commit(ActionNames.userTechNews.name, []);
          commit(ActionNames.userTechNewsEmpty.name, true);
        }
      })
      .finally(() => {
        commit(RootActionNames.loadingRemove, ActionNames.userTechNews.name, {
          root: true,
        });
      });
  },
  [HomepageActionsEnum.userJobs]: (
    { commit },
    data: { params: JobsWithCriterias[]; regionId: number }
  ) => {
    const { params, regionId } = data;
    const isNationalId = regionId === regions.getNationalID();
    return Promise.all(params.map(requestJobsService)).then((jobs: JobsCompaniesData[]) => {
      let jobsData: Job[] = [];
      jobs.forEach((data) => {
        if (!!data.job_count) {
          jobsData = jobsData.concat(sortJobs(mapDataSliderJob(data)));
        }
      });
      if (isNationalId) {
        jobsData = [...new Set(jobsData)].sort(() => (Math.random() > 0.5 ? 1 : -1));
      }

      commit(ActionNames.userJobs.name, { jobs: jobsData ?? [], regionId });
    });
  },
  step: ({ state, rootState, getters, commit }, args) => {
    const options = args || {};
    let close_popup = true;

    // Start popup
    if (options.popup_type !== undefined && options.popup_flow_type !== undefined) {
      commit(ActionNames.popupType.name, options.popup_type);
      commit(ActionNames.popupFlowType.name, options.popup_flow_type);

      // Start empty state
      if (state.popup_type === PopupTypeEmptyState && !state.empty_state) {
        commit(ActionNames.emptyState.name, true);
      }

      // Add work cities
      if (
        state.popup_type === PopupTypeUpdate &&
        (state.popup_flow_type === SliderTypeJobs ||
          state.popup_flow_type === SliderTypeCompanies) &&
        !rootState.onboarding?.profile.cities.length
      ) {
        commit(ActionNames.addWorkCityFlow.name);
      }

      const next_step = getters[ActionNames.nextStep.name];
      if (next_step) {
        commit(ActionNames.popupStep.name, next_step);
        close_popup = false;
      }
    }
    // Continue popup
    else if (options.continue !== undefined && options.continue) {
      const next_step = getters[ActionNames.nextStep.name];
      if (next_step) {
        commit(ActionNames.popupStep.name, next_step);
        close_popup = false;
      }
    }
    // Back popup
    else if (options.back !== undefined && options.back) {
      const prev_step = getters[ActionNames.prevStep.name];
      if (prev_step) {
        commit(ActionNames.popupStep.name, prev_step);
        close_popup = false;
      }
    }

    if (close_popup) {
      commit(ActionNames.popupType.name, false);
      commit(ActionNames.popupFlowType.name, false);
      commit(ActionNames.popupStep.name, false);
      if (
        options.save === undefined &&
        options.continue === undefined &&
        options.back === undefined
      ) {
        commit(ActionNames.emptyState.name, false);
      }
    }

    // Set step in popup
    const step = state.popup_step ? 'Step' + state.popup_step : '';
    commit(ActionNames.step.name, step);
  },
};

const mutations: MutationTree<State> = {
  [ActionNames.topics.name]: (state, topics) => {
    Vue.set(state, 'topics', topics);
  },
  [ActionNames.userTechNews.name]: (state, tech_news) => {
    Vue.set(state, 'tech_news', tech_news);
  },
  [ActionNames.userTechNewsEmpty.name]: (state, status) => {
    Vue.set(state, 'tech_news_empty_results', status);
  },
  [ActionNames.userJobs.name]: (state: State, data: { jobs: Job[]; regionId: number }) => {
    const { jobs, regionId } = data;
    state.jobs = { ...state.jobs, [regionId]: jobs };
  },
  [ActionNames.step.name]: (state, value) => {
    Vue.set(state, 'step', value);
  },
  [ActionNames.emptyState.name]: (state, value) => {
    Vue.set(state, 'empty_state', value);
  },
  [ActionNames.popupType.name]: (state, value) => {
    Vue.set(state, 'popup_type', value);
  },
  [ActionNames.popupFlowType.name]: (state, value) => {
    Vue.set(state, 'popup_flow_type', value);
  },
  [ActionNames.popupStep.name]: (state, value) => {
    Vue.set(state, 'popup_step', value);
  },
  [ActionNames.addWorkCityFlow.name]: (state) => {
    const steps = [...state.popup_steps];
    const index = steps.findIndex((v) => v.type === state.popup_flow_type);
    if (index && steps[index] !== undefined && !steps[index].altered) {
      steps[index].altered = true;
      steps[index].flow.unshift(5);
      Vue.set(state, 'popup_steps', steps);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
