










































































































































import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  watch,
  useRoute,
} from '@nuxtjs/composition-api';
import track from '@/utils/track';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { marketSites } from '@/enums/appVars';
import { mixin as clickaway } from 'vue-clickaway';
import { NavigationMutations } from '@/store/navigation/types';
import { SearchCompany } from '@/store/search/types';
import { throttle } from 'lodash-es';
import { useNamespacedMutations } from 'vuex-composition-helpers';
import { useVuetify } from '@/hooks/useVuetify';
import apiCompanies from '@/api/companies';
import apiRegions from '@/api/regions';

import { addYourCompanyHref } from '@/utils/companies';

/**
 * Provides a company search.
 */
export default defineComponent({
  name: 'Search',
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    national: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [clickaway],
  setup: (props) => {
    const { breakpoints } = useVuetify();
    let trackTyping = true;

    const { setDisplay, hideDisplaySearchMenu } = useNamespacedMutations<NavigationMutations>(
      'navigation',
      ['setDisplay', 'hideDisplaySearchMenu']
    );

    const route = useRoute();

    const icons = {
      search: faSearch,
      delete: faTimes,
      back: faArrowLeft,
    };

    /**
     * Handle unicode characters with `normalize` form
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
     */
    const normalizeForm = 'NFD';

    const searchForm = ref<HTMLInputElement | null>(null);

    const searchResults = ref<HTMLElement | null>(null);

    const inJobsLink = ref<{ $el: HTMLAnchorElement } | null>(null);

    const searchRegion = apiRegions.getCurrentRegionID();

    const searchInputMinLimit = 2;

    const state = reactive({
      activeIndex: -1,
      company_results: [] as SearchCompany[],
      searchInput: '',
      loading: false,
    });

    const trackClickEvent = () => {
      track.logEvent('click_nav_search_create_profile');
    };

    const marketUrl = computed(() => {
      return marketSites[searchRegion];
    });

    const modeFixed = computed(() => {
      return !!props.fixed;
    });

    const isMobile = computed(() => {
      return breakpoints.value.mdAndDown;
    });

    /**
     * Route for authenticated and unauthenticated users for Add Your Company Links
     * for use on the Company Directory.
     */

    const addYourCompanyhref = addYourCompanyHref();

    /**
     * @param companyRegion region_id of company
     * @returns Boolean if company.region_id equals the current region ID
     */
    const isCompanyOffsite = (companyRegion: number) => {
      return companyRegion !== searchRegion;
    };

    const isDirty = computed(() => {
      return !!state.searchInput;
    });

    const ariaAttributes = computed(() => {
      let activedescendant = '';
      if (state.activeIndex >= 0 && state.company_results.length) {
        activedescendant = `nav-search-result-${state.activeIndex}`;
      }
      return {
        role: 'searchbox',
        'aria-controls': 'nav-search-listbox',
        'aria-label': 'Search company by name',
        'aria-activedescendant': activedescendant,
        'aria-autocomplete': 'list',
      };
    });

    /**
     * @param company
     * @return An absolute market URL to a company profile
     */
    const getCompanyMarketUrl = (company: SearchCompany) => {
      return marketSites[company.region_id] + company.alias;
    };

    const getJobsBoardUrl = () => {
      return `/jobs?search=${encodeURIComponent(state.searchInput)}`;
    };

    const getViewAllResultsUrl = () => {
      return '/jobs';
    };

    const showInJobsBoardLink = computed(() => {
      return state.searchInput?.length >= searchInputMinLimit;
    });

    const showAddCompanyLink = computed(() => {
      return state.searchInput?.length >= searchInputMinLimit && state.company_results.length < 1;
    });

    const clearSelectIndex = () => {
      state.activeIndex = -1;
    };

    const resetSearchState = () => {
      state.searchInput = '';
      state.company_results = [];
      if (!props.national) {
        searchForm.value?.focus();
      }
      clearSelectIndex();
    };

    /**
     * Perform a filter on companies based on user input.
     *
     * @param query The search query.
     * @param companies Array of companies to search in.
     * @return Companies with titles that contain queried special character(s)
     */
    const handleSpecialCharacters = (query: string, companies: SearchCompany[]) => {
      const matchedCompanies = companies.filter((company) => {
        const normalizedQuery = query.normalize(normalizeForm).toLowerCase();
        const normalizedTitle = company.title.normalize(normalizeForm).toLowerCase();
        return normalizedTitle.includes(normalizedQuery);
      });
      return matchedCompanies;
    };

    const submitSearch = () => {
      track.logEvent('nav_search_enter');

      if (state.searchInput === '') {
        return;
      }

      // If a company search result is actively selected,
      // then navigate to the company profile
      if (state.activeIndex > -1 && state.activeIndex < state.company_results.length) {
        const searchLink = searchResults.value?.children[state.activeIndex]?.children[0];
        if (searchLink && searchLink instanceof HTMLAnchorElement) {
          searchLink.click();
        }
        clearSelectIndex();
        return;
      }
      // else open the site search page
      if (inJobsLink.value && inJobsLink.value.$el instanceof HTMLAnchorElement) {
        inJobsLink.value.$el.click();
      }
    };

    /**
     * When the user enters a search query,
     * get the three top results.
     *
     * If the search query is empty, return an empty
     * array.
     *
     * Prevent the search from being called more than once
     * every 100ms to lessen performance impact of server-side search.
     */
    const handleInput = () => {
      clearSelectIndex();

      if (trackTyping) {
        track.logEvent('nav_search_kw');
        trackTyping = false;
      }

      if (state.searchInput?.length < searchInputMinLimit) {
        state.company_results = [];
        return;
      }
      throttle(async () => {
        state.loading = true;

        try {
          const results = await apiCompanies.searchByName({
            company_name_part: state.searchInput,
            max_results: 5,
            region_id: searchRegion,
          });
          state.company_results = state.searchInput.length ? results : [];
          const matched = handleSpecialCharacters(state.searchInput, state.company_results);
          state.company_results = matched;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
          state.company_results = [];
        }
        state.loading = false;
      }, 100)();
    };

    /**
     * When using up/down arrow keypress to select results, scroll to selected result within the view container
     */
    const scrollResultIntoView = () => {
      if (state.activeIndex > -1 && state.activeIndex < state.company_results.length) {
        const result = searchResults.value?.children[state.activeIndex];
        if (result && result instanceof HTMLElement) {
          result.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    const selectNextResult = () => {
      const next = state.activeIndex + 1;
      state.activeIndex = next <= state.company_results.length - 1 ? next : 0;
      scrollResultIntoView();
    };

    const selectPrevResult = () => {
      const prev = state.activeIndex - 1;
      state.activeIndex = prev >= 0 ? prev : state.company_results.length - 1;
      scrollResultIntoView();
    };

    const closeSearch = () => {
      trackTyping = true;
      hideDisplaySearchMenu();
    };

    // Close the search menu when the route changes in SPA mode
    watch(
      () => route.value,
      () => {
        resetSearchState();
        setDisplay(false);
      }
    );

    onMounted(() => {
      if (breakpoints.value.lgAndUp || props.national) {
        searchForm.value?.focus();
      }
    });

    return {
      ...toRefs(state),
      ariaAttributes,
      closeSearch,
      getCompanyMarketUrl,
      getJobsBoardUrl,
      getViewAllResultsUrl,
      showInJobsBoardLink,
      showAddCompanyLink,
      handleInput,
      icons,
      addYourCompanyHref,
      addYourCompanyhref,
      isCompanyOffsite,
      isDirty,
      isMobile,
      marketUrl,
      modeFixed,
      resetSearchState,
      searchForm,
      searchResults,
      inJobsLink,
      selectNextResult,
      selectPrevResult,
      submitSearch,
      trackClickEvent,
    };
  },
});
