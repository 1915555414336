import appVars from '@/enums/appVars';
import { createMockServer } from '@/mirage/server';
import { getFeatureFlags } from '@/plugins/featureFlags';

/**
 * Only create a mock server in lower environments. In all other
 * environments, the miragejs module will be omitted from the bundle
 * and so attempts to instantiate it will error.
 */
if (getFeatureFlags().mirage && (appVars?.CurrentEnv?.isDev || appVars?.CurrentEnv?.isDevelop)) {
  try {
    const server = createMockServer();

    // As a debugging tool, simply attach this server to the global scope.
    if (window) {
      (window as any).__BI_MIRAGE_SERVER = server;
    }
  } catch (e) {
    console.error(
      '[MirageJS] Could not construct the mirage server! Was it omitted from webpack by the null-loader?\n\n',
      e
    );
  }
}
