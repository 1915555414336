import { JobsFilterParams, JobsWithCriterias, JobsSearchCriterias } from '@/api/types';
import ExternalApis from '@/enums/externalApiRoutesEnum';
import { FeatureFlags } from '@/plugins/types';
import { getFeatureFlags } from '@/plugins/featureFlags';
import regions, { isNational } from '@/api/regions';
import { appVars } from '@/enums/appVars';
import * as fetch from '@/api/fetch/fetch';
import {
  CompanyJobsItemResponse,
  EasyApplyJob,
  JobsCompaniesCollapsedResponse,
  JobsCompaniesData,
  JobsFromAPIData,
} from '@/store/jobs/types';
import { errorCheck } from './errors';
import bixAxios from '@/api/bixAxios';
import axios from 'axios';

/**
 * @returns Whether to use the jobs retrieval service.
 */
const shouldUseJobsApi = (flags: FeatureFlags) => {
  // There is no jobs retrieval servive for local development.
  if (appVars.CurrentEnv.isDev) return false;

  return (
    !!flags.jobsService ||
    regions.getCurrentRegionID() === regions.getBuiltinDotComId() ||
    regions.getCurrentRegionID() === regions.getNationalDotBuiltinComId() ||
    regions.getCurrentRegionID() === regions.getAustinID() ||
    regions.getCurrentRegionID() === regions.getBostonID() ||
    regions.getCurrentRegionID() === regions.getChicagoID() ||
    regions.getCurrentRegionID() === regions.getColoradoID() ||
    regions.getCurrentRegionID() === regions.getLosAngelesID() ||
    regions.getCurrentRegionID() === regions.getNYCID() ||
    regions.getCurrentRegionID() === regions.getSanFranciscoID() ||
    regions.getCurrentRegionID() === regions.getSeattleID()
  );
};

export const shouldUseJobsApiSorting = (): boolean => {
  const flags = getFeatureFlags();
  return !!(!!flags.jobsServiceSort || appVars.JobsAPISorting);
};

const getEasyApplyJob = async (alias: string, regionId: number): Promise<EasyApplyJob> => {
  try {
    const response = await bixAxios.get(`${ExternalApis.JobApply}${alias}?region_id=${regionId}`);
    return response.data;
  } catch (err: any) {
    if (errorCheck(err)) {
      throw new Error(err.message);
    }
    throw new Error(err);
  }
};

const getLocationData = async (searchString: string): Promise<any> => {
  try {
    const response = await axios.get(`${ExternalApis.LocationApi}${searchString}`);
    return response.data;
  } catch (err: any) {
    // 400 meaning we are trying to ask coordinates without actual SearchText given
    // so lets just return empty data. For any other response codes lets console log
    if (err.response && err.response.status != 400) {
      console.error(err);
    }
    return {};
  }
};

export default {
  getCategories: () => fetch.get(`${ExternalApis.Categories}`),
  getJobs: (params: JobsFilterParams) => fetch.get(`${ExternalApis.Jobs}`, { params }),
  getJobsFromJobsApi: (params: JobsSearchCriterias): Promise<JobsFromAPIData> =>
    fetch.get(`${ExternalApis.JobsApi}`, { params }),
  getLocationData,
  getSubcategoryData: () => fetch.get(`${ExternalApis.JobSubcategories}`),
  getIndustries: () => fetch.get(`${ExternalApis.CompanyTypes}`),
  getJobsWithCompanies(params: JobsWithCriterias) {
    const flags = getFeatureFlags();
    const url = ExternalApis.JobsApiLegacyJobs;

    const requestData: {
      params: JobsWithCriterias;
      withCredentials?: boolean;
    } = {
      params: { ...params, national: isNational() },
      withCredentials: false,
    };

    if (!shouldUseJobsApi(flags)) {
      delete requestData.withCredentials;
      if (!shouldUseJobsApiSorting()) {
        delete requestData.params.sortStrategy;
      }
    }

    // If the hybrid feature flag is on,
    // the JRS requires a different param.
    if (requestData.params.hybridEnabled) {
      delete requestData.params.remote;
    } else {
      delete requestData.params.working_option;
    }

    // The API request doesn't need the feature flag param
    delete requestData.params.hybridEnabled;

    return fetch.get(url, requestData);
  },
  /**
   *
   * same as getJobsWithCompanies method but convert response to the JobsCompaniesCollapsedResponse interface
   */
  getJobsWithCompaniesAsHVP(
    requestParams: JobsWithCriterias
  ): Promise<JobsCompaniesCollapsedResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const data: JobsCompaniesData = await this.getJobsWithCompanies(requestParams);

        const { companies, jobs, job_count, job_all_count, search_weight_id } = data;

        const companyJobsData: CompanyJobsItemResponse[] = jobs.reduce(
          (acc: CompanyJobsItemResponse[], job) => {
            const company = companies.find((company) => job.company_id === company.id);

            if (!company) {
              return acc;
            }
            const companyJobData: CompanyJobsItemResponse = {
              company,
              jobs: [job],
              job_total: 1,
            };

            return [...acc, companyJobData];
          },
          []
        );
        const perPage = requestParams.per_page ? +requestParams.per_page : 10;

        const basicJobsResponse: JobsCompaniesCollapsedResponse = {
          company_jobs: companyJobsData,
          high_volume_jobs: [],
          industries: [],
          pagination_count: Math.floor(job_all_count / perPage),
          company_count: job_all_count,
          job_all_count,
          job_count,
          search_weight_id,
        };

        resolve(basicJobsResponse);
      } catch (e) {
        reject(e);
      }
    });
  },

  getJobsWithCompaniesHVP(params: JobsWithCriterias) {
    const flags = getFeatureFlags();
    const url = ExternalApis.JobsApiCollapsedJobs;

    const requestData: {
      params: JobsWithCriterias;
      withCredentials?: boolean;
    } = {
      params: { ...params, national: isNational() },
      withCredentials: false,
    };

    if (!shouldUseJobsApi(flags)) {
      delete requestData.withCredentials;
      if (!shouldUseJobsApiSorting()) {
        delete requestData.params.sortStrategy;
      }
    }

    return fetch.get(url, requestData);
  },
  getEasyApplyJob,
};
