import bixAxios from '@/api/bixAxios';
import { QuicksightDashboard, DashboardUpdateParams } from '@/store/dashboard/types';
import ExternalApis from '@/enums/externalApiRoutesEnum';
import { extractError } from '@/api/helpers/errors';

const dashboardApi = ExternalApis.Dashboard;
const headers = {
  'Cache-Control': 'no-cache',
};

const getQuicksightDashboard = (params: DashboardUpdateParams): Promise<QuicksightDashboard> => {
  let { companyId } = params;
  return bixAxios
    .get(`${dashboardApi}/companies/${companyId}/quicksight-data`, { headers, params })
    .then((response: { data: QuicksightDashboard }) => response.data)
    .catch((error) => {
      throw new Error(extractError(error));
    });
};

export default {
  getQuicksightDashboard,
};
