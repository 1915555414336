

























































import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@nuxtjs/composition-api';
import { isNational as isNationalDetect } from '@/api/regions';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faSignalStream } from '@fortawesome/pro-solid-svg-icons/faSignalStream';
import navigationHelper, { getLinkAttributes } from '@/utils/navigation';
import { useVuetify } from '@/hooks/useVuetify';
import { useActions, useState } from 'vuex-composition-helpers';
import { throttle } from 'lodash-es';
import {
  blogsLink,
  companyLink,
  jobsLink,
  learnLink,
  myItemsLink,
  remoteLink,
  salariesLink,
  techTopicLink,
} from '@/enums/links';
import { Link } from '@/interfaces/links';
import { useAuthentication } from '@/hooks/useAuthentication';

type SubNavLink = Link & { dropdownId?: string };

const jobsSubNavLink = { ...jobsLink, dropdownId: 'jobs' };
const salariesSubNavLink = { ...salariesLink, dropdownId: 'salaries' };
const articlesSubNavLink = { ...techTopicLink, dropdownId: isNationalDetect() ? 'industries' : '' };
const remoteSubNavLink = { ...remoteLink, icon: faSignalStream };

const getNavigationLinks = (isNational = false) => {
  const nationalMenu: ComputedRef<Set<SubNavLink>> = computed(
    () => new Set([jobsSubNavLink, companyLink, articlesSubNavLink, salariesSubNavLink, learnLink])
  );
  const marketMenu: ComputedRef<Set<SubNavLink>> = computed(
    () => new Set([jobsSubNavLink, companyLink, remoteLink, blogsLink, salariesSubNavLink])
  );
  const navigationLinks = isNational ? nationalMenu : marketMenu;

  return { navigationLinks };
};

export default defineComponent({
  name: 'SubNavigation',
  setup() {
    const isNational: boolean = isNationalDetect();
    const { signin, isAnonymous } = useAuthentication();
    const { navigationLinks } = getNavigationLinks(isNational);

    const dynamicMenuItem = computed<SubNavLink>(() => {
      return isNational ? myItemsLink : remoteSubNavLink;
    });

    const { openDropdown, closeDropdown, dropDownOpen, isOpened, display } = navigationHelper();

    const { nationalNavbarCollapsed } = useState(['nationalNavbarCollapsed']);
    const { setNavigationCollapseStatus } = useActions(['setNavigationCollapseStatus']);
    /**
     *  Saved data from window.scrollY before calling _windowScroll
     */
    let scrollTop = 0;

    const hideMenu = ref(false);
    const bodyTag: null | HTMLElement = document.querySelector('body');

    const { breakpoints } = useVuetify();

    const _windowScroll = () => {
      /**
       * Adding the js-hide-sub-menu class to the body tag will always hide the submenu on scrolling.
       */
      const shouldAlwaysHideSubMenu = bodyTag?.classList?.contains('js-hide-sub-menu');

      const isScrollDirectionDown = scrollTop < window.scrollY;

      /**
       * Determine if submenu should be hidden or displayed state
       * It has 250px gap from the top before the menu is hidden.
       */
      const hideMenuCondition = window.scrollY > 250 && isScrollDirectionDown;

      if (breakpoints.value.mdAndDown) {
        return;
      }

      if (dropDownOpen.value) {
        bodyTag?.classList?.remove('menuHidden');
        return;
      }

      /**
       * don't show submenu when scrolling
       */
      if (shouldAlwaysHideSubMenu) {
        // if submenu is displayed hide it
        if (!hideMenu.value) {
          bodyTag?.classList?.add('menuHidden');
          setNavigationCollapseStatus(true);
          hideMenu.value = true;
        }
        return;
      }

      /**
       * Toggle menu state(hidden , displayed).
       */
      if (nationalNavbarCollapsed.value !== hideMenuCondition) {
        setNavigationCollapseStatus(hideMenuCondition);
        hideMenu.value = hideMenuCondition;
        bodyTag?.classList?.toggle('menuHidden');
      }

      /**
       * Save user scroll data from last call.
       */
      scrollTop = window.scrollY;
    };

    const getLinkContainerAtts = (menuItem: SubNavLink) => {
      if (!!menuItem.dropdownId) {
        return {
          'aria-expanded': isOpened(menuItem.dropdownId),
          class: { active: isOpened(menuItem.dropdownId) },
        };
      }
      return {};
    };

    const windowScrollDetect = throttle(_windowScroll, 200);

    onMounted(() => {
      window.addEventListener('scroll', windowScrollDetect, { passive: true });
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', windowScrollDetect);
    });

    const getLinkEvents = (menuItem: SubNavLink) => {
      if (!isAnonymous.value || menuItem.url !== myItemsLink.url) {
        return {};
      }
      return {
        click: (event: any) => {
          if (!isNational) {
            return {};
          }
          event.preventDefault();
          signin(myItemsLink.url);
          return false;
        },
      };
    };

    const getLinkWrapperEvents = (menuItem: SubNavLink) => {
      if (!!menuItem.dropdownId) {
        return {
          mouseenter: () => openDropdown(menuItem.dropdownId ?? ''),
          mouseleave: closeDropdown,
          'keydown.enter.space': () => openDropdown(menuItem.dropdownId ?? ''),
          'keydown.esc': closeDropdown,
          click: closeDropdown,
        };
      }

      return {
        mouseenter: closeDropdown,
      };
    };

    return {
      dynamicMenuItem,
      myItemsLink,
      getLinkAttributes,
      getLinkWrapperEvents,
      getLinkContainerAtts,
      learnLink,
      openDropdown,
      closeDropdown,
      isOpened,
      isNational,
      dropDownOpen,
      hideMenu,
      display,
      navigationLinks,
      getLinkEvents,
      icon: {
        down: faChevronDown,
        remote: faSignalStream,
      },
    };
  },
});
