import { Link } from '@/interfaces/links';
import regions, { getNationalDotBuiltinComId } from '@/api/regions';

import {
  faBriefcase,
  faSackDollar,
  faGraduationCap,
  faSignalStream,
  faRocketLaunch,
  faNewspaper,
  faMapMarkerAlt,
  faHeart,
} from '@fortawesome/pro-solid-svg-icons';

import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';

const nationalUrl = regions.getRegionDomain(getNationalDotBuiltinComId());

export const nationalLink: Link = {
  title: 'Builtin.com',
  url: `https://${nationalUrl}`,
  isSpaLink: false,
  icon: faExternalLink,
  trackEventName: '',
};

export const companyLink: Link = {
  title: 'Companies',
  url: '/companies',
  isSpaLink: true,
  icon: faRocketLaunch,
  trackEventName: 'companies_topnav_link',
};
export const techTopicLink: Link = {
  title: 'Articles',
  url: '/tech-topics',
  isSpaLink: false,
  icon: faNewspaper,
  trackEventName: 'tech_topics_topnav_link',
};
export const techHubLink: Link = {
  title: 'Tech Hubs',
  url: '/tech-hubs',
  isSpaLink: false,
  icon: faMapMarkerAlt,
  trackEventName: 'tech_hub_topnav_link',
};
export const salariesLink: Link = {
  title: 'Salaries',
  url: '/salaries',
  isSpaLink: false,
  icon: faSackDollar,
  trackEventName: 'salaries_topnav_link',
};
const remoteUrl = regions.isNational()
  ? '/companies/location/fully-remote'
  : '/companies/location/hiring/remote';

export const myItemsLink: Link = {
  title: 'My Items',
  url: '/home/jobs/saved',
  isSpaLink: false,
  icon: faHeart,
  trackEventName: 'remote_topnav_link',
};

export const remoteLink: Link = {
  title: 'Remote',
  url: remoteUrl,
  isSpaLink: true,
  icon: faSignalStream,
  trackEventName: 'remote_topnav_link',
};
export const learnLink: Link = {
  title: 'Courses',
  url: `https://${nationalUrl}/learn`,
  isSpaLink: false,
  icon: faGraduationCap,
  trackEventName: 'learn_topnav_link',
};
export const forEmployersLink: Link = {
  title: 'For employers',
  url: 'https://employers.builtin.com/membership?utm_medium=BIReferral&utm_source=foremployers',
  isSpaLink: false,
  authTitle: 'Post Job',
};

export const jobsLink: Link = {
  title: 'Jobs',
  url: '/jobs',
  isSpaLink: true,
  icon: faBriefcase,
  trackEventName: 'jobs_topnav_link',
};

export const blogsLink: Link = {
  title: 'Articles',
  isSpaLink: false,
  url: '/blogs',
  trackEventName: 'tech_topics_topnav_link',
  icon: faNewspaper,
};
