import { Context } from '@nuxt/types';

import { parseStateFromQuery, removeQueryParam, hasQueryParam } from '@/utils/http';
import { trackPage } from '@/plugins/track';

/**
 * Saves the authenticated user to the store when loading the application.
 */
export default (ctx: Context) => {
  const { app, from, route, redirect } = ctx;
  const hasState = hasQueryParam(route, 'state');
  app.routeState = hasState ? parseStateFromQuery(route) : {};

  if (hasState) {
    try {
      trackPage(ctx, route, from);
    } finally {
      redirect(removeQueryParam(route.fullPath, 'state'));
    }
  }
};
