export interface RegionConfig {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  1000000000: string;
  [key: number]: string;
}

/**
 * The Nuxt environment set in nuxt.base.config.ts to the
 * ENVIRONMENT_CTX environment variable
 */
export interface EnvironmentCtx {
  isProd: boolean;
  isStaging: boolean;
  isDevelop: boolean;
  isDeployed: boolean;
  isDev: boolean;
  isSsr: boolean;
  isSpa: boolean;
}

export const imageCDNs: RegionConfig = {
  1: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL1 || '',
  2: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL2 || '',
  3: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL3 || '',
  4: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL4 || '',
  5: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL5 || '',
  6: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL6 || '',
  7: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL7 || '',
  8: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL8 || '',
  9: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL9 || '',
  1000000000: process.env.NUXT_ENV_MARKET_SITE_CDN_IMAGE_URL1000000000 || '',
};
export const marketSites: RegionConfig = {
  1: process.env.NUXT_ENV_MARKET_SITE_URL1 || '',
  2: process.env.NUXT_ENV_MARKET_SITE_URL2 || '',
  3: process.env.NUXT_ENV_MARKET_SITE_URL3 || '',
  4: process.env.NUXT_ENV_MARKET_SITE_URL4 || '',
  5: process.env.NUXT_ENV_MARKET_SITE_URL5 || '',
  6: process.env.NUXT_ENV_MARKET_SITE_URL6 || '',
  7: process.env.NUXT_ENV_MARKET_SITE_URL7 || '',
  8: process.env.NUXT_ENV_MARKET_SITE_URL8 || '',
  9: process.env.NUXT_ENV_MARKET_SITE_URL9 || '',
  1000000000: process.env.NUXT_ENV_MARKET_SITE_URL1000000000 || '',
};
export const regionDomains: RegionConfig = {
  1: process.env.NUXT_ENV_REGION_DOMAIN1 || '',
  2: process.env.NUXT_ENV_REGION_DOMAIN2 || '',
  3: process.env.NUXT_ENV_REGION_DOMAIN3 || '',
  4: process.env.NUXT_ENV_REGION_DOMAIN4 || '',
  5: process.env.NUXT_ENV_REGION_DOMAIN5 || '',
  6: process.env.NUXT_ENV_REGION_DOMAIN6 || '',
  7: process.env.NUXT_ENV_REGION_DOMAIN7 || '',
  8: process.env.NUXT_ENV_REGION_DOMAIN8 || '',
  9: process.env.NUXT_ENV_REGION_DOMAIN9 || '',
  1000000000: process.env.NUXT_ENV_REGION_DOMAIN1000000000 || '',
} as RegionConfig;
export const gtmCodes: RegionConfig = {
  1: process.env.NUXT_ENV_GTM_CODE1 || '',
  2: process.env.NUXT_ENV_GTM_CODE2 || '',
  3: process.env.NUXT_ENV_GTM_CODE3 || '',
  4: process.env.NUXT_ENV_GTM_CODE4 || '',
  5: process.env.NUXT_ENV_GTM_CODE5 || '',
  6: process.env.NUXT_ENV_GTM_CODE6 || '',
  7: process.env.NUXT_ENV_GTM_CODE7 || '',
  8: process.env.NUXT_ENV_GTM_CODE8 || '',
  9: process.env.NUXT_ENV_GTM_CODE9 || '',
  1000000000: process.env.NUXT_ENV_GTM_CODE1000000000 || '',
};

export const appVars = {
  AppVersion: process.env.NUXT_ENV_VERSION,
  BaseUrl: process.env.BASE_URL || '',
  ClientKeyId: process.env.NUXT_ENV_CLIENT_ID_KEY || '',
  ContentManager: process.env.NUXT_ENV_CONTENT_MANAGER || '',
  ContentStudio: process.env.NUXT_ENV_CONTENT_STUDIO || '',
  ContentApi: process.env.NUXT_ENV_CONTENT_API || '',
  CurrentEnv: JSON.parse(process.env.ENVIRONMENT_CTX || '{}') as EnvironmentCtx,
  CurrentSiteHostFallback: process.env.NUXT_ENV_CURRENT_SITE_HOST_FALLBACK || '',
  ExternalApi: process.env.NUXT_ENV_EXTERNAL_API,
  GTMCodes: gtmCodes,
  GoogleClientId: process.env.NUXT_ENV_API_GOOGLE_CLIENT_ID || '',
  GmapsKey: process.env.NUXT_ENV_API_GOOGLE_MAPS_KEY,
  IdPAuthority: process.env.NUXT_ENV_IDP_AUTHORITY || '',
  IdPClientId: process.env.NUXT_ENV_IDP_CLIENT_ID || '',
  ImageCDNs: imageCDNs,
  JobsAPISorting: JSON.parse(process.env.NUXT_ENV_JOBS_API_SORTING || 'false'),
  JobsApi: process.env.NUXT_ENV_JOBS_API,
  MarketSites: marketSites,
  PerPage: process.env.NUXT_ENV_API_PER_PAGE,
  RegionDomains: regionDomains,
  ReportsAppHost: process.env.NUXT_ENV_REPORTS_APP_HOST,
  TrackApi: process.env.NUXT_ENV_TRACK_API,
  UserMetadataKey: process.env.NUXT_ENV_USER_METADATA_KEY || '',
  UserSessionIdKey: process.env.NUXT_ENV_USER_SESSION_ID_KEY || '',
  launchNational: process.env.NUXT_ENV_NATIONAL_LAUNCHED || '',
  JobsCollapsedEndpoint: process.env.NUXT_ENV_USE_JOBS_COLLAPSED_ENDPOINT || '',
  JobApplicationServiceApi: process.env.NUXT_ENV_JOB_APP_SERVICE_API,
  LearnLabBannerDisplay: process.env.NUXT_ENV_LEARN_LAB_BANNER || '',
  homepageV2: process.env.NUXT_ENV_HOMEPAGEV2 || '',
  GrecaptchaSiteKey: process.env.NUXT_ENV_RECAPTCHA_BUILTIN_SITE_KEY,
  StaticAssetPath: process.env.NUXT_ENV_BUILTIN_STATIC_FQDN || '',
};

export default appVars;
