import ExternalApis from '@/enums/externalApiRoutesEnum';
import bixAxios from './bixAxios';
import { EvidentlyFeatureFlagParams } from './types';
import { clientIdentifierService } from '@/utils/clientIdentifier';
import { userModule } from '@/utils/store-accessor';
import regionsData from '@/api/regions';

export const hydrateParams = (params: EvidentlyFeatureFlagParams): EvidentlyFeatureFlagParams => {
  if (!params.clientId) {
    params.clientId = clientIdentifierService.getLocalId();
  }
  if (!params.userEmail) {
    const profile = userModule?.profile;
    const email = profile?.email || '';
    params.userEmail = email;
  }
  if (!params.url) {
    params.url = regionsData.getRegionBaseURL();
  }
  return params;
};

// Evidently returns different value types depending on how the feature flag is set up.
// Possible Golang return types are: boolean, float64, int64, and string.
// We should explicitly know and send requests for the type associated with each individual feature flag.

// Use for Evidently feature flags returning a boolean value
export const evaluateFeatureFlagBoolValue = async (
  params: EvidentlyFeatureFlagParams
): Promise<boolean> => {
  try {
    params = hydrateParams(params);
    const response = await bixAxios.get(`${ExternalApis.RealtimeFeatureFlags}/evidently`, {
      params,
    });
    return response.data;
  } catch (err: any) {
    return err.response;
  }
};

// Use for Evidently feature flags returning a Long or Double value
export const evaluateFeatureFlagNumberValue = async (
  params: EvidentlyFeatureFlagParams
): Promise<number> => {
  try {
    params = hydrateParams(params);
    const response = await bixAxios.get(`${ExternalApis.RealtimeFeatureFlags}/evidently`, {
      params,
    });
    return response.data;
  } catch (err: any) {
    return err.response;
  }
};

// Use for Evidently feature flags returning a string value
export const evaluateFeatureFlagStringValue = async (
  params: EvidentlyFeatureFlagParams
): Promise<string> => {
  try {
    params = hydrateParams(params);
    const response = await bixAxios.get(`${ExternalApis.RealtimeFeatureFlags}/evidently`, {
      params,
    });
    return response.data;
  } catch (err: any) {
    return err.response;
  }
};
