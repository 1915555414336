import { render, staticRenderFns } from "./SignButtons.vue?vue&type=template&id=6b22f9a9&scoped=true&"
import script from "./SignButtons.vue?vue&type=script&lang=ts&"
export * from "./SignButtons.vue?vue&type=script&lang=ts&"
import style0 from "./SignButtons.vue?vue&type=style&index=0&id=6b22f9a9&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b22f9a9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GoogleOneTap: require('/root/project/frontend/src/components/GoogleOneTap.vue').default})
