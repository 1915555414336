import { Route } from 'vue-router';

import { clientIdentifierService } from '@/utils/clientIdentifier';
import { userSessionIdService } from '@/utils/userSessionId';
import { b64urlDecode, b64urlEncode } from '@/utils/base64';

// The URL constructor requires a valid origin to parse a url,
// so in cases where we only have a path, use a fake origin to parse
// the path so the URL methods can be used to modify
const validOrigin = 'https://www.builtin.com';

export const parseStateFromQuery = (route: Route) => {
  const rawState = route.query.state as string;
  try {
    const state = b64urlDecode(rawState);
    return JSON.parse(state);
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const addStateToUrl = (href: string, state: any) => {
  let url: URL | undefined;
  let removeBaseUrl = false;

  if (href.toLowerCase().startsWith('http')) {
    url = new URL(href);
  } else {
    url = new URL(href, validOrigin);
    removeBaseUrl = true;
  }
  const query = url.searchParams;
  const encodedState = b64urlEncode(
    JSON.stringify({
      ...state,
      clientIdentifier: clientIdentifierService.getLocalId(),
      userSessionIdentifier: userSessionIdService.getValidSessionId(),
    })
  );

  query.append('state', encodedState);

  return removeBaseUrl ? url.href.split(validOrigin)[1] : url.href;
};

export const hasQueryParam = (route: Route, param: string) => {
  const keys = Object.keys(route.query);
  return keys.some((key) => key === param);
};

export const removeQueryParam = (fullPath: string, ...params: string[]) => {
  const url = new URL(fullPath, validOrigin);
  params.forEach((param) => url.searchParams.delete(param));

  const relativeUrl = url.toString().split(validOrigin)[1];
  return relativeUrl;
};
