import { render, staticRenderFns } from "./no-header.vue?vue&type=template&id=77aff26c&"
import script from "./no-header.vue?vue&type=script&lang=ts&"
export * from "./no-header.vue?vue&type=script&lang=ts&"
import style0 from "./no-header.vue?vue&type=style&index=0&id=77aff26c&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkipLink: require('/root/project/frontend/src/components/SkipLink.vue').default,MobileBottomNavigation: require('/root/project/frontend/src/components/navigation/main/MobileBottomNavigation.vue').default,Footer: require('/root/project/frontend/src/components/footer/Footer.vue').default})
