import { render, staticRenderFns } from "./AuthMobileUserMenu.vue?vue&type=template&id=5490821b&scoped=true&"
import script from "./AuthMobileUserMenu.vue?vue&type=script&lang=ts&"
export * from "./AuthMobileUserMenu.vue?vue&type=script&lang=ts&"
import style0 from "./AuthMobileUserMenu.vue?vue&type=style&index=0&id=5490821b&prod&lang=sass&"
import style1 from "./AuthMobileUserMenu.vue?vue&type=style&index=1&id=5490821b&prod&lang=sass&scoped=true&"
import style2 from "./AuthMobileUserMenu.vue?vue&type=style&index=2&id=5490821b&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5490821b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BixUserAvatar: require('/root/project/frontend/src/components/user/BixUserAvatar.vue').default,UserProfileInfoMobile: require('/root/project/frontend/src/components/navigation/main/profile/UserProfileInfoMobile.vue').default,EmployerPortalNavLink: require('/root/project/frontend/src/components/navigation/EmployerPortalNavLink.vue').default,BixLink: require('/root/project/frontend/src/components/BixLink.vue').default})
