import { MutationTree, ActionTree } from 'vuex/types/index';
import { RootState } from '../types';

export interface NavigationState {
  display: boolean;
  displaySearchMenu: boolean;
  displayMarketDropdown: boolean;
  dropdownContent: string;
  navElementOffset: number;
}

export interface NavigationMutations extends MutationTree<NavigationState> {
  setDisplay: (state: NavigationState, status: boolean) => void;
  setDropdownContent: (state: NavigationState, content: string) => void;
  toggleDisplaySearchMenu: (state: NavigationState) => void;
  hideDisplaySearchMenu: (state: NavigationState) => void;
  toggleMarketDropdown: (state: NavigationState) => void;
  hideDropDown: (state: NavigationState) => void;
  setNavElementOffset: (state: NavigationState, offset: number) => void;
}

export interface NavigationActions extends ActionTree<NavigationState, RootState> {}

export const AllowedTopics: any = {
  550: '/adtech-martech',
  551: '/agile',
  552: '/artificial-intelligence',
  554: '/blockchain',
  555: '/cloud-computing',
  548: '/company-culture',
  556: '/consumer-tech',
  577: '/corporate-innovation',
  579: '/customer-success',
  557: '/cybersecurity',
  553: '/data-science',
  558: '/design-ux',
  559: '/diversity-inclusion',
  560: '/edtech',
  581: '/finance',
  561: '/fintech',
  562: '/founders-entrepreneurship',
  563: '/growth-hacking',
  564: '/hardware',
  565: '/healthcare-technology',
  566: '/internet-things',
  582: '/marketing',
  568: '/media-gaming',
  580: '/operations',
  570: '/people-management',
  571: '/product',
  583: '/remote-work',
  572: '/robotics',
  578: '/sales',
  573: '/software-engineering-perspectives',
  574: '/transportation-tech',
  575: '/women-tech',
};

export const AdditionalBlogTopics = {
  593: '/3d-printing',
  594: '/aerospace',
  595: '/articles',
  596: '/big-data',
  597: '/biotech',
  599: '/devops',
  600: '/drones',
  601: '/e-commerce',
  591: '/editors-picks',
  576: '/employee-engagement',
  603: '/employer-branding',
  592: '/expert-contributors',
  604: '/gaming',
  605: '/greentech',
  567: '/investments-exits',
  549: '/job-descriptions',
  607: '/logistics',
  608: '/machine-learning',
  609: '/mobile-technology',
  546: '/recruiting',
  610: '/remote-recruiting',
  611: '/sass-metrics',
  612: '/tech-recruiter-resources',
  613: '/united-we-tech',
  614: '/wearables',
};
