import { ActionNames, MutationNames, State } from '@/store/email/types';
import email from '@/api/email';
import { ActionTree, MutationTree } from 'vuex';

const state = (): State => ({
  message: '',
  customEmail: '',
  useCache: 'yes',
});

const mutations: MutationTree<State> = {
  [MutationNames.setEmailMessage]: (state, message) => {
    state.message = message;
  },
  [MutationNames.updateEmailCache]: (state, cache) => {
    state.useCache = cache;
  },
};

const actions: ActionTree<State, any> = {
  [ActionNames.sendContentEmail]: ({ commit }, { regionID, customEmail, useCache }) => {
    email.sendContentEmail(regionID, customEmail, useCache, (message: string) => {
      commit(MutationNames.setEmailMessage, message);
    });
  },
  [ActionNames.sendContentGroupEmail]: ({ commit }, { regionID }) => {
    email.sendContentGroupEmail(regionID, (message: string) => {
      commit(MutationNames.setEmailMessage, message);
    });
  },
  [ActionNames.sendContentAllCombinationsEmail]: ({ commit }, { regionID, customEmail }) => {
    customEmail = customEmail || 'builtin@litmusemail.com';
    email.sendContentAllCombinationsEmail(regionID, customEmail, (message: string) => {
      commit(MutationNames.setEmailMessage, message);
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
